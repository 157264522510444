import React, { useState, forwardRef } from "react";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ko } from 'date-fns/esm/locale';
import * as Utils from '../../features/Utils';
import Dropdown from '../Dropdown'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquareMinus } from "@fortawesome/free-solid-svg-icons";

export default function SalesInput(props) {
  const navigate = useNavigate();

  const DateCustomInput = forwardRef(({ value, onClick }, ref) => (
    <button className="example-custom-input" onClick={onClick} ref={ref} style={{ borderWidth: 1, padding: 10, borderRadius: 5, width: "100%" }}>
      {value}
    </button>
  ));

  const listHandler = (value, idx, type) => {
    const updatedState = props.list;

    const updatedFormElement = {
      ...updatedState[idx]
    }

    switch (type) {
      case 'date':
        updatedFormElement.date = value;
        break;
      case 'type':
        updatedFormElement.type = value;
        break;
      case 'amount':
        updatedFormElement.amount = value;
        break;
      case 'memo':
        updatedFormElement.memo = value;
        break;
    }

    updatedState[idx] = updatedFormElement;

    props.setList([...updatedState]);
  }

  function checkExistTour(date) {
    let endDate = Utils.convertLocalToUTCDate(new Date(props.tourInfo.endDate.replace(" ", "T")));

    if (date >= new Date(props.tourInfo.startDate.replace(" ", "T")) && date <= endDate)
      return true;
    else
      return false;
  }

  return (
    <div className="flex flex-row md:flex-col text-[13px] items-center">
      <div className="text-[18px] text-[grey] mr-2 md:mr-0 cursor-pointer" onClick={() => {
        props.handler();
      }}>
        <FontAwesomeIcon icon={faSquareMinus} />
      </div>

      <div className="flex flex-col items-center w-full">
        <DatePicker
          onChange={date => {
            let checkDate = Utils.convertLocalToUTCDate(date);
            if (checkExistTour(checkDate))
              listHandler(checkDate, props.index, "date");
            else
              alert("투어 기간 내의 날짜를 선택해주세요.");
          }}
          selected={Utils.convertLocalToUTCDate(props.list[props.index].date)}
          locale={ko}
          dateFormat="yyyy/MM/dd"
          customInput={<DateCustomInput />}
          showPopperArrow={false} />
      </div>

      <div className="flex flex-col items-center w-full">
        <Dropdown title="분류"
          data={props.companyList?.map((item) => {
            return {
              id: item.id,
              type: item.id,
              name: item.name,
            }
          })}
          setHandler={listHandler}
          handlerIndex={props.index}
          handlerType={"type"}
          handlerList={props.list}
        />
      </div>

      <div className="flex flex-col items-center w-full">
        <input
          className="w-full placeholder:text-slate-400 block bg-white border border-[#E5E7EB] rounded-md py-[10px] pl-3 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1"
          placeholder="ex) 매출액 or 수량"
          type="text"
          name="amount"
          style={{ flex: "100%" }}
          onChange={(e) => {
            listHandler(e.target.value.replace(/\D\./, '').replace(/,/g, ""), props.index, "amount");
          }}
          value={Utils.formatNumber(props.list[props.index].amount)}
        />
      </div>

      <div className="flex flex-col items-center w-full">
        <input
          className="w-full placeholder:text-slate-400 block bg-white border border-[#E5E7EB] rounded-md py-[10px] pl-3 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1"
          placeholder="ex) 특이사항"
          type="text"
          name="memo"
          style={{ flex: "100%" }}
          onChange={(e) => {
            listHandler(e.target.value, props.index, "memo");
          }}
          value={(props.list[props.index].memo === undefined || props.list[props.index].memo === null) ? "" : props.list[props.index].memo}
        />
      </div>
    </div>
  )
}