import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as HTTPManager from "../../features/HTTPManager";
import { useSetRecoilState } from "recoil";
import { UserInfo, PageLocale } from "../../recoil";
import { useRecoilValue } from "recoil";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX, } from "@fortawesome/free-solid-svg-icons";
import "../../fonts/Font.css"
import Btn_View from "../../assets/btn_view.png"
import Btn_View_Disable from "../../assets/btn_view_disable.png"
import LocaleKR from "../../features/LocaleKR";
import LocaleEN from "../../features/LocaleEN";
import FindAccountModal from "./FindAccountModal";
import FindPwModal from "./FindPwModal";

const SignIn = ({ showModal, setShowModal }) => {
  const navigate = useNavigate();

  const [account, setAccount] = useState("");
  const [pw, setPw] = useState("");

  const setUserInfo = useSetRecoilState(UserInfo);

  const [cbAutoLogin, setCbAutoLogin] = useState(false);
  const [focusPw, setFocusPw] = useState(false);
  const [showPw, setShowPw] = useState(false);

  const pageLocale = useRecoilValue(PageLocale);
  const languageSet = pageLocale === "ko" ? LocaleKR : LocaleEN;

  const [showAccountModal, setShowAccountModal] = useState(false);
  const [showPasswordModal, setShowPasswordModal] = useState(false);

  //체크박스 체인지 함수
  const checkBoxSwitch = () => {
    setCbAutoLogin(!cbAutoLogin);
  };

  //회원가입 버튼 페이지 이동
  const goSignUp = () => {
    setShowModal(false);
    navigate("/signUp");
  };

  //로그인 엔터키 동작
  const onEnterPress = (e) => {
    if (e.key === "Enter") {
      onSignInButton();
    }
  };

  //로그인 버튼
  const onSignInButton = async () => {
    setFocusPw(false);

    let paramAccount = account.replace(/\s/g, "");
    let paramPassword = pw.replace(/\s/g, "");

    if (paramAccount.length === 0 || paramPassword.length === 0) {
      return;
    }

    const result = await HTTPManager.SignIn({
      account: paramAccount,
      password: paramPassword,
      autoLogin: cbAutoLogin,
    });

    if (result === undefined) {
      return;
    }

    if (result.accessToken !== undefined) {
      setUserInfo(result);

      setAccount("");
      setPw("");

      setShowModal(false);
      //원래 있던 페이지로 돌아가기 매개변수에 0 은 동작하지 않는다.
      // navigate();
    } else {
      alert("로그인에 실패하였습니다.");
    }
  };

  return (
    <>
      {showModal ? (
        <>
          <div className="mx-6 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none font-medium"
            style={{
              fontFamily: "Noto Sans KR",
            }}>
            <div className="relative w-full my-6 mx-auto max-w-lg">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex justify-end pt-3 px-5 rounded-t">
                  <span
                    className="cursor-pointer pr-2"
                    onClick={() => {
                      setShowModal(false);
                    }}
                  >
                    <FontAwesomeIcon icon={faX} className="text-gray-500" />
                  </span>
                </div>
                {/*body*/}
                <div className="relative p-6 flex-auto">
                  <input
                    className="w-full placeholder:text-slate-400 block bg-white border border-slate-300 rounded-md py-2 px-5 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 text-base font-medium"
                    placeholder={languageSet.signinIdHint}
                    type="text"
                    name="userId"
                    onChange={(e) => {
                      setAccount(e.target.value);
                    }}
                    value={account}
                  />
                  <div className="relative">
                    <input
                      className="w-full mt-2 placeholder:text-slate-400 block bg-white border border-slate-300 rounded-md py-2 px-5 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 text-base font-medium"
                      placeholder={languageSet.signinPwHint}
                      onFocus={() => setFocusPw(true)}
                      type={!showPw ? "password" : "text"}
                      name="password"
                      onChange={(e) => {
                        if (e.target.value.length === 0)
                          setFocusPw(false);

                        if (e.target.value.length > 0)
                          setFocusPw(true);

                        setPw(e.target.value);
                      }}
                      value={pw}
                      onKeyDown={onEnterPress}
                    />
                    {focusPw && <span
                      className="absolute flex items-center justify-center h-full top-0 right-0 cursor-pointer px-3"
                      onClick={() => {
                        setShowPw(!showPw);
                      }}
                    >
                      <img src={!showPw ? Btn_View : Btn_View_Disable} width={20} />
                    </span>}
                  </div>
                  <div className="grid grid-cols-2 justify-center mt-4 divide-x-[1px] divide-[#D3D5D7] items-center text-center text-[#606060] text-[13.65px]">
                    <div className="cursor-pointer" onClick={() => {
                      setShowAccountModal(true);
                    }}>{languageSet.signinFindAccount}</div>
                    <div className="cursor-pointer" onClick={() => {
                      setShowPasswordModal(true);
                    }}>{languageSet.signinPwAccount}</div>
                  </div>
                </div>
                {/*footer*/}
                <div className="flex items-center justify-around rounded-b">
                  <button
                    className="flex w-full bg-[black] text-[white] justify-center py-3 rounded-b focus:outline-none"
                    type="button"
                    onClick={() => onSignInButton()}
                  >
                    {languageSet.signinTitle}
                  </button>
                </div>
                {/* <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                  <button
                    className="w-full bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xl px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => goSignUp()}
                  >
                    회원가입
                  </button>
                </div> */}
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null
      }
      {
        showAccountModal && <FindAccountModal setShowModal={setShowAccountModal} setShowPw={setShowPasswordModal} />
      }
      {
        showPasswordModal && <FindPwModal setShowModal={setShowPasswordModal} setShowAccount={setShowAccountModal} />
      }
    </>
  );
};

export default SignIn;
