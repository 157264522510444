import React, { useState, useEffect, useMemo, useRef } from "react";
import ReactDOM from "react-dom";
import { useRecoilValue } from "recoil";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import * as HTTPManager from '../../features/HTTPManager';
import { UserInfo } from "../../recoil";
import ColorButton from "../ColorButton"
import { useLocation, useNavigate } from "react-router-dom";


export default function ReservationTourListEx(Props) {
  const location = useLocation();
  const navigate = useNavigate();
  const userInfo = useRecoilValue(UserInfo);

  const [reservationInfo, setReservationInfo] = useState([]);
  const [reservationFilterInfo, setReservationFilterInfo] = useState([]);

  const [receiver, setReceiver] = useState("담당자 입력");
  const [title, setTitle] = useState("행사 목록");
  const [sender, setSender] = useState("대표 김종주");
  const [team, setTeam] = useState("국내사업부");
  const [phone, setPhone] = useState("+82-10-2326-2825");
  const [email, setEmail] = useState("james@ktravelstore.com");
  const [address, setAddress] = useState("서울시 마포구 연희로 11, 5층 코리아 트래블 스토어");

  const [showInput, setShowInput] = useState(false);
  const [inputHint, setInputHint] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [inputType, setInputType] = useState("");

  async function handleKeyDown(event) {
    if (event.key === 'Enter') {
      changeValue();
    } else if (event.keyCode === 27) {
      setShowInput(false);
    }
  }

  function changeValue() {
    if (inputValue.length === 0) {
      alert("빈칸확인");
      return;
    }

    switch (inputType) {
      case "manager":
        setReceiver(inputValue);
        break;
      case "title":
        setTitle(inputValue);
        break;
      case "team":
        setTeam(inputValue);
        break;
      case "sender":
        setSender(inputValue);
        break;
      case "phone":
        setPhone(inputValue);
        break;
      case "email":
        setEmail(inputValue);
        break;
      default:
        break;
    }

    setShowInput(false);
  }

  async function fetchData() {
    let busListRes = await HTTPManager.GetBusList({
      accessToken: userInfo.accessToken
    });

    let userList = [];
    let response = await HTTPManager.GetUserList({
      accessToken: userInfo.accessToken
    });

    if (response !== undefined) {
      userList = response.data.result;
    }

    let list = location.state.reservationTour;
    let toursGuideInfo = location.state.toursGuideInfo;
    let reservationList = list.map(reservation => {

      let reservationHotelInfo = location.state.reservationHotel.filter(item => item.tourId === reservation.id).map(item => {
        let resHotel = location.state.hotelList.find(hotel => hotel.id === item.hotelId).name + " : " + item.startDate.slice(5, 10) + "~" + item.endDate.slice(5, 10);
        return <div key={item.id}>{resHotel}</div>;
      });

      let reservationBusInfo = location.state.reservationBus.filter(item => item.tourId === reservation.id).map(item => {
        let busInfo = busListRes.data.busList.find(bus => bus.id === item.busId);
        let busName = busInfo?.name;
        let driverName = busName.includes("글로벌컴퍼니") ? busInfo?.manager : "";
        let resBus = busName + ", " + (driverName !== "" ? driverName + ", " : "") + item.busType;

        return resBus
      });

      let guideList = toursGuideInfo.filter(item => item.tourId === reservation.id).map(item => {
        let user = userList.find(user => user.id === item.guideId);
        let needInfo = user.name.replace(/\d+/g, '') + "(" + user.phoneNumber + ")";
        return needInfo;
      });

      return ({
        id: reservation.id,
        countryId: reservation.countryId,
        startDate: reservation.startDate.slice(0, 10),
        endDate: reservation.endDate.slice(0, 10),
        guide: guideList.toString(),
        pax: reservation.pax.replace("명", ""),
        country: reservation.name,
        hotel: reservationHotelInfo,
        bus: reservationBusInfo,
        cancel: reservation.cancel,
        eta: reservation.eta,
        etd: reservation.etd,
      });
    });

    reservationList.sort((a, b) => {
      // 시작일 빠른순 정렬
      if (new Date(a.startDate.replace(" ", "T")) > new Date(b.startDate.replace(" ", "T")))
        return 1;
      if (new Date(a.startDate.replace(" ", "T")) < new Date(b.startDate.replace(" ", "T")))
        return -1;

      // 종료일 빠른순 정렬
      if (new Date(a.endDate.replace(" ", "T")) > new Date(b.endDate.replace(" ", "T")))
        return 1;
      if (new Date(a.endDate.replace(" ", "T")) < new Date(b.endDate.replace(" ", "T")))
        return -1;
    })

    setReservationInfo(reservationList);
    setReservationFilterInfo(reservationList);
  }

  useEffect(() => {
    fetchData();
  }, [])

  const [searchText, setSearchText] = useState("");

  const a4Notice = useRef();

  // 인쇄 기능 Handler
  const onPrintHandler = () => {
    let printContents = ReactDOM.findDOMNode(a4Notice.current).innerHTML;
    let windowObject = window.open('', "PrintWindow", "width=1000, height=800, top=100, left=300, toolbars=no, scrollbars=no, status=no, resizale=no");

    windowObject.document.writeln(printContents);
    windowObject.document.close();
    windowObject.focus();
    windowObject.print();
    //windowObject.close();
  }

  function removePax(paxStr) {
    if (paxStr.indexOf('[') > 0) {
      paxStr = paxStr.slice(0, paxStr.indexOf('['));
    }
    paxStr = paxStr.trim();

    if (!paxStr.includes("명"))
      paxStr += "명";
    return paxStr;
  }

  return (
    <div className="flex w-full flex-col mt-2 md:mt-14" style={{ padding: 10, width: "100%" }}>
      <div className="w-full pr-5 pl-5" style={{ marginBottom: 10, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', }}>
        <input
          className="placeholder:text-slate-400 block bg-white border border-slate-300 rounded-md py-2 pl-3 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1"
          placeholder="검색"
          type="text"
          name="search"
          style={{ flex: 1, marginTop: 5, marginLeft: 5 }}
          onChange={(e) => {
            setSearchText(e.target.value);
            setReservationFilterInfo(reservationInfo.filter(item => item.id === Number(e.target.value)
              || item.country.includes(e.target.value) || item.guide.includes(e.target.value)
              || item.startDate.includes(e.target.value) || item.endDate.includes(e.target.value)
            ));

            if (e.target.value.length === 0)
              setReservationFilterInfo(reservationInfo);
          }}
          value={searchText}
        />
      </div>
      <div className="w-full pr-5 pl-6">
        <ColorButton color={"slateblue"} title={"인쇄"} handler={() => {
          onPrintHandler();
        }} />
      </div>
      <div ref={a4Notice} style={{ padding: 10, width: "100%", display: "flex", flexDirection: "column", fontSize: 13, }}>
        <div style={{ marginTop: 5, borderWidth: "0px 0px 3px 0px", borderBottomStyle: "double", borderBottomColor: "black", }}>
          <div style={{ position: "absolute", right: 5, }}>
            <img src="https://www.ktravelstore.com/static/media/logo_kts.d464f76115f5ea7366ec.png" alt="not found" width={120} />
          </div>
          <div style={{ marginBottom: 20, textAlign: "center", fontWeight: "bold", fontSize: 20, }}>코리아 트래블 스토어(KTS)</div>
          <div style={{ padding: 10, display: "flex", flexDirection: "row" }}>
            <div style={{ marginRight: 10 }}>수 신</div>
            <div style={{ color: "deepskyblue", cursor: "pointer", textTransform: "none", }} onClick={() => {
              setInputType("manager");
              setInputHint("담당자");
              setInputValue(receiver);
              setShowInput(true);
            }}>{receiver}</div>
          </div>
          <div style={{ padding: 10, display: "flex", flexDirection: "row", marginBottom: 5 }}>
            <div style={{ marginRight: 10, }}>제 목</div>
            <div style={{ color: "deepskyblue", fontWeight: "bold", textTransform: "none", cursor: "pointer", }} onClick={() => {
              setInputType("title");
              setInputHint("제목");
              setInputValue(title);
              setShowInput(true);
            }}>{title}</div>
          </div>
        </div>
        <div style={{ display: "flex", flexDirection: "column", flex: "100%", justifyContent: "center", marginTop: 10 }}>
          <div className="grid grid-cols-6 gap-2 md:grid-cols-2">
            {
              reservationFilterInfo?.filter(item => item.cancel === 0).map(item => {
                let tourStyle = "";
                if (item.countryId === 3) {
                  tourStyle = "bg-[#FCF2F3] p-3 rounded-md";
                } else if (item.countryId === 2) {
                  tourStyle = "bg-[#EDF1FD] p-3 rounded-md";
                }

                return (
                  <div key={item.id} className={tourStyle}>
                    <div className="font-bold text-center mb-1">{item.id}</div>
                    <div className="">일정 : {item.startDate.slice(5, 10)}~{item.endDate.slice(5, 10)}</div>
                    <div className="">입국 : {item.eta}</div>
                    <div className="">출국 : {item.etd}</div>
                    <div className="">인원 : {removePax(item.pax)}</div>
                    <div className="">{item.hotel}</div>
                    <div className="">버스 : {item.bus}</div>
                    <div className="">가이드 : {item.guide}</div>
                    <div className="text-right">{item.country}</div>
                  </div>
                );
              })
            }
          </div>
        </div>
        <div style={{ display: "flex", marginTop: 50, marginBottom: 20, borderWidth: "0px 0px 10px 0px", borderBottomStyle: "solid", borderBottomColor: "#DDD9C3", }} />
        <div style={{ display: "flex", flexDirection: "row", marginBottom: 10, }}>
          <div style={{ marginRight: 20, cursor: "pointer", textTransform: "none", }} onClick={() => {
            setInputType("team");
            setInputHint("부서명");
            setInputValue(team);
            setShowInput(true);
          }}>
            {team}
          </div>
          <div style={{ cursor: "pointer", textTransform: "none", }} onClick={() => {
            setInputType("sender");
            setInputHint("발신자");
            setInputValue(sender);
            setShowInput(true);
          }}>
            {sender}
          </div>
        </div>
        <div style={{ display: "flex", flexDirection: "row", marginBottom: 10, cursor: "pointer", textTransform: "none", }} onClick={() => {
          setInputType("phone");
          setInputHint("전화번호");
          setInputValue(phone);
          setShowInput(true);
        }}>
          {"전화 " + phone}
        </div>
        <div style={{ display: "flex", flexDirection: "row", marginBottom: 10, cursor: "pointer", textTransform: "none", }} onClick={() => {
          setInputType("email");
          setInputHint("이메일");
          setInputValue(email);
          setShowInput(true);
        }}>
          {"메일 " + email}
        </div>
      </div>
      {
        // 수정 입력창 다이얼로그
        showInput &&
        <div className="left-20 md:left-0 top-20" style={{ position: "fixed", display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: 500, }}>
          <div style={{ display: "flex", flexDirection: "column", width: "80%", background: "#000a", padding: 20, borderRadius: 10 }}>
            <div style={{ display: "flex", width: "100%", justifyContent: "flex-end" }}>
              <div
                style={{
                  width: 25,
                  height: 25,
                  fontSize: 15,
                  color: "white",
                  cursor: "pointer",
                  textAlign: "center"
                }}
                onClick={() => { setShowInput(false) }}
              >
                <FontAwesomeIcon icon={faXmark} />
              </div>
            </div>
            <div style={{ marginBottom: 10, display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', }}>
              <input
                className="placeholder:text-slate-400 block bg-white border border-slate-300 rounded-md py-2 pl-3 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1"
                placeholder={inputHint}
                type="text"
                name="input"
                style={{ width: "100%", marginBottom: 10, }}
                onChange={(e) => setInputValue(e.target.value)}
                value={inputValue}
                onKeyDown={(e) => handleKeyDown(e)}
              />
              <ColorButton title={"변경"} color={"black"} width={"100%"} handler={() => {
                changeValue();
              }} />
            </div>
          </div>
        </div>
      }
    </div >
  )
}