import React, { useState, useEffect } from "react";
import { useRecoilState, } from "recoil";
import { ROLES } from "../../features/Constant";
import * as HTTPManager from "../../features/HTTPManager";
import * as HTTPSettlementManager from "../../features/HTTPSettlementManager";
import * as Utils from "../../features/Utils";
import { UserInfo } from "../../recoil";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { Tooltip } from "react-tooltip";

const GuideAttractionPage = () => {
  const [userInfo, setUserInfo] = useRecoilState(UserInfo);
  const [myTourList, setMyTourList] = useState([]);

  // 이슈 : ToursCardList에 현재 달과 다른 달의 투어 정보가 표시됨.
  // 재현경로 : 다른 달로 이동한 상태 > 다른 메뉴 페이지 확인 > 이 페이지로 복귀
  // 수정 : 전역 변수 > 지역변수
  // Calendar 컴포넌트에서 set함수 받아서 처리
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const [year, setYear] = useState(today.getFullYear());
  const [month, setMonth] = useState(today.getMonth());

  const [userList, setUserList] = useState([]);
  const [toursGuideInfo, setToursGuideInfo] = useState([]);
  const [toursGuideIncentive, setToursGuideIncentive] = useState([]);

  async function fecthData() {
    if (userInfo.accessToken === undefined) return;

    let res = "";

    if (userInfo.rolesId === ROLES.LEADERGUIDE || userInfo.rolesId === ROLES.ADMIN) {
      res = await HTTPManager.GetUserList({
        accessToken: userInfo.accessToken,
      });

      setUserList(res.data.result);
    }

    res = await HTTPManager.GetMyToursList({
      accessToken: userInfo.accessToken,
      year,
      month,
    });

    if (res.data.accessToken) {
      setUserInfo((prev) => {
        const returnValue = { ...prev };
        returnValue.accessToken = res.data.accessToken;

        return { ...returnValue };
      });
    }

    // [확인필요] 같은 행사 중복 수신
    // 취소 행사 제외    
    setMyTourList(res.data.result.filter(item => item.cancel !== 1));
    if (res.data !== undefined) {
      setToursGuideInfo(res.data.toursGuide.filter(item => item.cancel !== 1).sort((a, b) => {
        // 시작일 빠른순 정렬
        if (new Date(a.startDate.replace(" ", "T")) > new Date(b.startDate.replace(" ", "T")))
          return 1;
        if (new Date(a.startDate.replace(" ", "T")) < new Date(b.startDate.replace(" ", "T")))
          return -1;

        // 종료일 빠른순 정렬
        if (new Date(a.endDate.replace(" ", "T")) > new Date(b.endDate.replace(" ", "T")))
          return 1;
        if (new Date(a.endDate.replace(" ", "T")) < new Date(b.endDate.replace(" ", "T")))
          return -1;
      }));
    }

    res = await HTTPSettlementManager.getAdditionalIncentiveList({
      accessToken: userInfo.accessToken,
    });

    if (res !== undefined) {
      setToursGuideIncentive(res.data.result);
    }
  }

  useEffect(() => {
    fecthData();
  }, [userInfo, year, month, setUserInfo]);

  // 상태 정리
  const stateSymbol = (receiptCheck, guideCheck, accountFinish, index) => {
    // 영수증 확인 > 가이드 확인 > 정산완료    
    if (accountFinish === 1) {
      return <div className={`tooltip${index} w-4 h-4 cursor-pointer bg-[#4ED964] rounded-full`} >
        <Tooltip anchorSelect={`.tooltip${index}`} place="top">
          정산완료
        </Tooltip>
      </div>
    }

    if (guideCheck === 1) {
      return <div className={`tooltip${index} w-4 h-4 cursor-pointer bg-[#4E64D9] rounded-full`} >
        <Tooltip anchorSelect={`.tooltip${index}`} place="top">
          가이드확인완료
        </Tooltip>
      </div>
    }

    if (receiptCheck === 1) {
      return <div className={`tooltip${index} w-4 h-4 cursor-pointer bg-[#FFEA00] rounded-full`}>
        <Tooltip anchorSelect={`.tooltip${index}`} place="top">
          영수증확인완료
        </Tooltip>
      </div>
    }
  }

  return (
    <div className="flex flex-col w-full h-full pt-[50px] pb-[37px] px-[30px]">
      <div className="flex flex-col">
        <div className="text-[32px] font-extrabold mb-[25px] md:mt-[10px]">관광지</div>
        <div className="text-[18px] p-[0.7px] font-bold">정보</div>
        {/* <div className="text-[#485763] font-bold normal-case mb-[25px] flex flex-col gap-1">
          <div className="flex flex-row items-center gap-3">
            <FontAwesomeIcon icon={faCircle} style={{ color: "#000", width: 5 }} />
            <div className="text-[14px]">관광지 정보 페이지</div>
          </div>
        </div> */}
      </div>

      <div className="w-full h-[1px] bg-[#DCDCDC] my-[25px]" />

      <div className="grid grid-cols-6 md:grid-cols-2 items-center justify-center w-fit gap-2">
        <div className="flex flex-col w-[150px] mb-[30px] bg-[#F8F9FA] text-[#1B1E21] rounded-lg p-5 hover:bg-[#cdcdcd] hover:cursor-pointer">
          <div className="font-bold text-center cursor-pointer" onClick={() => {
            window.open("https://www.naminara.net/main/service/qrcode/barcode_travel.php?tr_idx=137");
          }}>남이섬<br />QR Code</div>
        </div>
        <div className="flex flex-col w-[150px] mb-[30px] bg-[#F8F9FA] text-[#1B1E21] rounded-lg p-5 hover:bg-[#cdcdcd] hover:cursor-pointer">
          <div className="font-bold text-center cursor-pointer" onClick={() => {
            window.open("https://ktravelstore.com:9786/images/kts/tsm/pocheonartvalley.jpg");
          }}>포천아트밸리<br />제휴카드</div>
        </div>
        <div className="flex flex-col w-[150px] mb-[30px] bg-[#F8F9FA] text-[#1B1E21] rounded-lg p-5 hover:bg-[#cdcdcd] hover:cursor-pointer">
          <div className="font-bold text-center cursor-pointer" onClick={() => {
            window.open("https://www.maketicket.co.kr/ticket/GD23294");
          }}>한강<br />크루즈</div>
        </div>
        <div className="flex flex-col w-[150px] mb-[30px] bg-[#F8F9FA] text-[#1B1E21] rounded-lg p-5 hover:bg-[#cdcdcd] hover:cursor-pointer">
          <div className="font-bold text-center cursor-pointer" onClick={() => {
            window.open("https://reservation.hwadamsup.com/reserve/resMain.do");
          }}>화담숲<br />홈페이지</div>
        </div>
        <div className="flex flex-col w-[150px] mb-[30px] bg-[#F8F9FA] text-[#1B1E21] rounded-lg p-5 hover:bg-[#cdcdcd] hover:cursor-pointer">
          <div className="font-bold text-center cursor-pointer" onClick={() => {
            window.open("https://www.bluelinepark.com/booking.do#");
          }}>해운대<br />블루라인</div>
        </div>
        <div className="flex flex-col w-[150px] mb-[30px] bg-[#F8F9FA] text-[#1B1E21] rounded-lg p-5 hover:bg-[#cdcdcd] hover:cursor-pointer">
          <div className="font-bold text-center cursor-pointer" onClick={() => {
            window.open("https://ktravelstore.com:9786/images/kts/tsm/엘리시안.png");
          }}>강촌<br />엘리시안</div>
        </div>
      </div>
    </div>
  );
};

export default GuideAttractionPage;
