import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import * as HTTPManager from "../../features/HTTPManager";
import { useSetRecoilState } from "recoil";
import { UserInfo, LanguageSet } from "../../recoil";
import { useRecoilValue, useRecoilState } from "recoil";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX, } from "@fortawesome/free-solid-svg-icons";
import ChangePwDialog from "./ChangePwDialog";
import ChangeNameDialog from "./ChangeNameDialog";


const UserMenu = ({ showModal, setShowModal }) => {
  const navigate = useNavigate();

  const [myInfo, setMyInfo] = useRecoilState(UserInfo);
  const [languageSet, setLanguageSet] = useRecoilState(LanguageSet);
  const [showChangePwDialog, setShowChangePwDialog] = useState(false);
  const [showChangeNameDialog, setShowChangeNameDialog] = useState(false);

  useEffect(() => {

  }, []);

  //로그아웃 처리
  const removeRefreshToken = async () => {
    await HTTPManager.Logout();
    setMyInfo({});
    setShowModal(false);
    // replace true시 로그아웃 상태로 뒤로가기 동작 안됨. 로그인시 사용했던 페이지 접근 불가
    navigate("/", { replace: true });
  };

  const liCommonCSSUserMenu = "py-1 hover:bg-gray-500 hover:text-[white] select-none";
  const btnCommonCSSUserMenu = "hover:bg-grey-200 w-full h-full py-2 px-2 text-center focus:outline-none";
  const userLoginMenu = [
    {
      title: languageSet.changeENName,
      clickable: () => { setShowChangeNameDialog(true) },
      underDivider: true,
      cssClass: btnCommonCSSUserMenu,
      rounded: "hover:rounded-t"
    },
    {
      title: languageSet.changePw,
      clickable: () => { setShowChangePwDialog(true) },
      underDivider: true,
      cssClass: btnCommonCSSUserMenu,
      rounded: ""
    },
    {
      title: languageSet.signout,
      path: "",
      element: undefined,
      clickable: removeRefreshToken,
      underDivider: true,
      cssClass: btnCommonCSSUserMenu,
      rounded: ""
    },
    {
      title: languageSet.signinCancel,
      path: "",
      element: undefined,
      clickable: () => { setShowModal(false) },
      underDivider: false,
      cssClass: btnCommonCSSUserMenu,
      rounded: "hover:rounded-b"
    },
  ];

  return (
    <>
      {showModal ? (
        <>
          <div className="mx-6 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none font-medium">
            <div className="relative w-full my-6 mx-auto max-w-lg">
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col bg-white outline-none focus:outline-none">
                {
                  userLoginMenu.map((item, index) => (
                    <div key={index}
                      className={item.underDivider
                        ? `border-b ${liCommonCSSUserMenu} ${item.rounded}`
                        : `${liCommonCSSUserMenu} ${item.rounded}`
                      }>
                      <button
                        className={btnCommonCSSUserMenu}
                        onClick={item.clickable}
                      >
                        {item.title}
                      </button>
                    </div>
                  ))
                }
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black" />
          <ChangePwDialog showModal={showChangePwDialog} setShowModal={setShowChangePwDialog} />
          <ChangeNameDialog showModal={showChangeNameDialog} setShowModal={setShowChangeNameDialog} />
        </>
      ) : null}
    </>
  );
};

export default UserMenu;
