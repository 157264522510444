import React, { useState, useEffect } from "react";
import * as HTTPAuthManager from "../../features/HTTPAuthManager";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX, } from "@fortawesome/free-solid-svg-icons";
import "../../fonts/Font.css"

const FindPwModal = (props) => {
  const MAX_SECONDS = 120;
  const [time, setTime] = useState(MAX_SECONDS);
  const [timer, setTimer] = useState(null);

  useEffect(() => {
    if (time <= 0) {
      clearAllIntervals();
    }
  }, [time]);

  function clearAllIntervals() {
    for (var i = 1; i < 99999; i++)
      window.clearInterval(i);
  };

  const [requestAuthCode, setRequestAuthCode] = useState(false);
  const [findSuccess, setFindSuccess] = useState(false);
  const [account, setAccount] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [authCode, setAuthCode] = useState("");

  const getSeconds = (time) => {
    let result = "";
    const minutes = Number(Math.floor(time / 60));
    const seconds = Number(time % 60);

    if (minutes < 10) {
      result += ("0" + String(minutes));
    } else {
      result += String(minutes);
    }

    result += ":";

    if (seconds < 10) {
      result += ("0" + String(seconds));
    } else {
      result += String(seconds);
    }

    return result;
  }


  const findAccount = async () => {
    setFindSuccess(false);
    setAuthCode("");

    const result = await HTTPAuthManager.findAccount({
      phoneNumber: phoneNumber,
    });


    if (result !== undefined && result?.code === 200) {
      setTime(MAX_SECONDS);
      setRequestAuthCode(true);

      setTimer(setInterval(() => {
        setTime((prev) => prev - 1);
      }, 1000));
    } else {
      if (result.response.data.code === 4041) {
        alert('인증 시도 횟수를 초과하였습니다.\n잠시 후 다시 시도해 주세요.');
      }
    }
  }

  const checkCode = async () => {
    const result = await HTTPAuthManager.checkCode({
      account: account,
      phoneNumber: phoneNumber,
      authCode: authCode
    });

    if (result !== undefined && result.code === 200) {
      clearInterval(timer);
      setAccount("");
      setPhoneNumber("");
      setAuthCode("");
      setRequestAuthCode(false);
      setFindSuccess(true);
    } else {
      if (result?.response?.data.code === 4042) {
        alert("인증번호가 일치하지 않습니다.");
      }
    }
  }

  return (
    <div className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-[999] outline-none focus:outline-none bg-[white]">
      <div className="flex w-full h-full relatvie justify-center items-center">
        <span
          className="absolute cursor-pointer top-3 right-3"
          onClick={() => {
            setPhoneNumber("");
            setAuthCode("");
            setFindSuccess(false);
            setRequestAuthCode(false);
            props.setShowModal(false);
          }}
        >
          <FontAwesomeIcon icon={faX} className="text-black" />
        </span>
        <div className="flex flex-col justify-center items-center w-[500px] px-[20px]">
          <div className="font-bold text-[33px]">KTS</div>
          {!findSuccess && <div className="font-medium text-[18px]">아이디 및 연결된 휴대전화 번호를 입력해주세요.</div>}
          {findSuccess && <div className="font-bold normal-case mt-5">휴대전화로 임시비밀번호를 발송했습니다.</div>}
          {
            !findSuccess && <div className="w-full mt-10 font-medium text-[16px]">
              <input
                disabled={requestAuthCode}
                className="w-full h-full py-[16px] px-5 placeholder:text-slate-400 border border-slate-300 rounded-md shadow-sm focus:outline-none"
                style={{
                  color: requestAuthCode ? "#94a3b8" : "#000000"
                }}
                placeholder="아이디"
                type={"text"}
                name="phoneNumber"
                onChange={(e) => {
                  setAccount(e.target.value);
                }}
                value={account}
                onKeyDown={async (e) => {
                  if (e.key === "Enter") {
                    await findAccount();
                  }
                }} />
            </div>
          }
          {
            !findSuccess && <div className="w-full mt-3 font-medium text-[16px]">
              <input
                disabled={requestAuthCode}
                className="w-full h-full py-[16px] px-5 placeholder:text-slate-400 border border-slate-300 rounded-md shadow-sm focus:outline-none"
                style={{
                  color: requestAuthCode ? "#94a3b8" : "#000000"
                }}
                placeholder="010-XXXX-XXXX"
                type={"text"}
                name="phoneNumber"
                onChange={(e) => {
                  setPhoneNumber(e.target.value);
                }}
                value={phoneNumber}
                onKeyDown={async (e) => {
                  if (e.key === "Enter") {
                    await findAccount();
                  }
                }} />
            </div>
          }
          {
            requestAuthCode &&
            <div className="flex flex-row h-full w-full items-center mt-3 gap-2">
              <input
                className="w-full font-medium placeholder:text-slate-400 bg-white border border-slate-300 rounded-md py-[16px] px-5 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 text-base text-[16px]"
                placeholder="인증번호"
                type={"text"}
                name="authCode"
                maxLength={6}
                onChange={(e) => {
                  setAuthCode(e.target.value);
                }}
                value={authCode}
                onKeyDown={async (e) => {
                  if (e.key === "Enter") {
                    checkCode();
                  }
                }} />
              <div className="flex w-full justify-center items-center bg-[black] text-[white] font-medium rounded-[6px] py-[16px] px-5 cursor-pointer"
                onClick={async () => {
                  if (time > 0) {
                    checkCode();
                  }
                }}>
                확인 ({getSeconds(time)})
              </div>
            </div>
          }
          {
            !findSuccess && <div className="mt-3 bg-[black] text-[white] font-bold w-full flex justify-center py-[16px] rounded-[6px] cursor-pointer"
              onClick={async () => {
                await findAccount();
              }}>
              인증번호 요청
            </div>
          }
          {
            findSuccess && <div className="mt-10 bg-[black] text-[white] font-bold w-full flex justify-center py-[16px] rounded-[6px] cursor-pointer"
              onClick={async () => {
                setFindSuccess(false);
              }}>
              돌아가기
            </div>
          }
          <div className="flex flex-row text-[14px] font-medium mt-7 gap-2">
            <div className="text-[#92A0C1]">아이디가 기억나지 않는다면?</div>
            <div className="text-[black] cursor-pointer" onClick={() => {
              props.setShowAccount(true);
              props.setShowModal(false);
            }}>아이디 찾기</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FindPwModal;