import React, { useState, useEffect, } from "react";
import { Link } from "react-router-dom";
import menuItems from "./menuItems";
import { useRecoilValue, useRecoilState, } from "recoil";
import { UserInfo, PageLocale, RequestPage } from "../recoil";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { ROLES } from "../features/Constant";
import { useNavigate } from "react-router-dom";
import "../fonts/Font.css"
import LocaleKR from "../features/LocaleKR";
import LocaleEN from "../features/LocaleEN";

const SideBar = ({ menuToggle, setMenuToggle }) => {
  const navigate = useNavigate();
  const userInfo = useRecoilValue(UserInfo);
  const [menusByRoles, setMenusByRoles] = useState([]);

  const pageLocale = useRecoilValue(PageLocale);
  const languageSet = pageLocale === "ko" ? LocaleKR : LocaleEN;

  const [selectedMenu, setSelectedMenu] = useState("");
  const [requestPage, setRequestPage] = useRecoilState(RequestPage);

  const toggleSwitch = () => {
    setMenuToggle(!menuToggle);
  };

  const clickedBtnDropdown = (e) => {
    if (e.target.closest("li").querySelector("ul")) {
      e.target.closest("li").querySelector("ul").classList.toggle("hidden");
    }
  };

  //sub메뉴 구현하기 위한 최하위 컴포넌트
  const menuItem = (item, index) => {
    let subMenus = [];
    if (item.subMenu) {
      if (userInfo.rolesId === ROLES.GOD) {
        subMenus = item.subMenu.filter((ktem) => ktem.type !== "extra" && ktem.type !== "guide");
      }
      else if (userInfo.rolesId === ROLES.ADMIN) {
        subMenus = item.subMenu.filter((ktem) => ktem.type !== "extra" && ktem.type !== "guide" && ktem.type !== "god");
      } else if (
        userInfo.rolesId === ROLES.GUIDE ||
        userInfo.rolesId === ROLES.LEADERGUIDE
      ) {
        subMenus = item.subMenu.filter((ktem) => ktem.type === "guide");
      } else if (userInfo.rolesId === ROLES.ITS) {
        subMenus = item.subMenu.filter((ktem) => ktem.type === "its");
      } else {
        subMenus = item.subMenu.filter((ktem) => ktem.type === "side");
      }
    }

    // 최상단 버튼 subMenu 있고 없고 상관없이 css class 공통인자.
    const btnCategClass = "flex justify-between w-full";

    return (
      <>
        <li className="relative py-2 px-4 rounded-md text-sm"
          style={{
            background: selectedMenu === item.key ? "#D1D5DB" : "",
            color: selectedMenu === item.key ? "#ffffff" : "",
          }}>
          <button
            className={item.subMenu ? "py-1 focus:outline-none font-bold " + btnCategClass : btnCategClass}
            onClick={(e) => {
              if (item.path?.length > 0) {
                navigate(item.path);
                setSelectedMenu(item.key);
              } else {
                clickedBtnDropdown(e);
              }
            }}
          >
            <span>
              {item.key !== undefined ? languageSet[item.key] : item.name}
            </span>
            {item.subMenu.length > 0 ? (
              <span>
                <FontAwesomeIcon icon={faCaretDown} />
              </span>
            ) : (
              ""
            )}
          </button>
          {item.subMenu ? (
            <ul className="hidden">
              {subMenus.map((jtem, jndex) => {
                return (
                  <Link to={jtem.path} key={jndex} onClick={() => {
                    setRequestPage(0);
                    toggleSwitch();
                    setSelectedMenu(jtem.key);
                  }}>
                    <li className="py-2 px-4 hover:bg-gray-300 rounded-md hover:text-[white] font-medium"
                      style={{
                        background: selectedMenu === jtem.key ? "#D1D5DB" : "",
                        color: selectedMenu === jtem.key ? "#ffffff" : "",
                      }}>
                      <button className="pl-4 w-full text-left focus:outline-none">
                        {jtem.key !== undefined
                          ? languageSet[jtem.key]
                          : jtem.name}
                      </button>
                    </li>
                  </Link>
                );
              })}
            </ul>
          ) : (
            ""
          )}
        </li >
      </>
    );
  };

  useEffect(() => {
    if (userInfo.rolesId === ROLES.GOD) {
      setMenusByRoles(menuItems.filter((item) => item.type !== "extra" && item.type !== "guide"));
    }
    else if (userInfo.rolesId === ROLES.ADMIN) {
      setMenusByRoles(menuItems.filter((item) => item.type !== "extra" && item.type !== "god" && item.type !== "guide"));
    } else if (
      userInfo.rolesId === ROLES.GUIDE ||
      userInfo.rolesId === ROLES.LEADERGUIDE
    ) {
      setMenusByRoles(
        menuItems.filter(
          (item) =>
            item.type !== "extra" &&
            item.type !== "admin" &&
            item.type !== "its" &&
            item.type !== "god"
        )
      );
    } else if (userInfo.rolesId === ROLES.ITS) {
      setMenusByRoles(menuItems.filter((item) => item.type === "its"));
    } else {
      setMenusByRoles(menuItems.filter((item) => item.type === "side"));
    }
  }, [userInfo]);

  //사이드 메뉴 버튼 모듈화
  const menuButtons = (item, index) => {
    return item.subMenu ? (
      <React.Fragment key={index}>{menuItem(item, index)}</React.Fragment>
    ) : (
      <Link to={item.path} key={index} onClick={toggleSwitch}>
        {menuItem(item, index)}
      </Link>
    );
  };

  return (
    <div
      className={
        menuToggle
          ? "relative flex flex-col flex-wrap bg-white border-r border-gray-300 p-4 flex-none w-60 md:-ml-0 md:fixed md:top-0 md:z-30 md:h-screen md:shadow-xl animated faster overflow-y-auto"
          : "relative flex flex-col flex-wrap bg-white border-r border-gray-300 p-4 flex-none w-60 md:-ml-60 md:fixed md:top-0 md:z-30 md:h-screen md:shadow-xl animated faster"
      }
      style={{
        fontFamily: "Noto Sans KR",
      }}
    >
      <ul className="flex flex-col">
        <li className="md:mb-4">
          <button
            className="px-2 md:block hidden pr-20 pb-6 rounded-md"
            onClick={toggleSwitch}
          >
            <i className="fad fa-times-circle" />
          </button>
        </li>
        {menusByRoles.map((item, index) => {
          return menuButtons(item, index);
        })}
      </ul>
    </div>
  );
};

export default SideBar;
