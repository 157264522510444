import React, { useState, } from "react";
import * as HTTPManager from "../../features/HTTPManager";
import * as Utils from "../../features/Utils";
import { UserInfo, LanguageSet } from "../../recoil";
import { useRecoilValue } from "recoil";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark, } from "@fortawesome/free-solid-svg-icons";
import ColorButton from "../ColorButton"

// 전도금, 가이드 비용, 쇼핑인센, 보류) 인보이스, 팁
export default function AdminInputDialog(Props) {
  const languageSet = useRecoilValue(LanguageSet);
  const userInfo = useRecoilValue(UserInfo);

  const toursGuideInfo = Props.data;

  const [preCharge, setPreCharge] = useState((toursGuideInfo.preCharge === undefined || toursGuideInfo.preCharge === null) ? "" : toursGuideInfo.preCharge);
  const [guideFee, setGuideFee] = useState((toursGuideInfo.guideFee === undefined || toursGuideInfo.guideFee === null) ? "" : toursGuideInfo.guideFee);
  const [shopIncentive, setShopIncentive] = useState((toursGuideInfo.shopIncentive === undefined || toursGuideInfo.shopIncentive === null) ? "" : toursGuideInfo.shopIncentive);

  async function modifyInfo() {
    let res = await HTTPManager.ModifyToursGuide({
      accessToken: userInfo.accessToken,
      updateList: [{
        id: toursGuideInfo.id,
        type: "preCharge",
        value: preCharge.replaceAll(",", ""),
      }, {
        id: toursGuideInfo.id,
        type: "guideFee",
        value: guideFee.replaceAll(",", ""),
      }, {
        id: toursGuideInfo.id,
        type: "shopIncentive",
        value: shopIncentive.replaceAll(",", ""),
      }]
    });

    if (res !== undefined && res.data.result) {
      Props.refresh();
      Props.setShow(false);
    }
  }

  async function handleKeyDown(event) {
    if (event.key === 'Enter') {
      await modifyInfo();
    } else if (event.keyCode === 27) {
      Props.setShow(false);
    }
  }

  return (
    <div className="fixed h-full w-full top-0 left-60 md:left-0 md:top-10 text-sm bg-[#0007] overflow-auto z-[100] pt-44 md:pt-10 pl-20 pr-80 md:px-5">
      <div className="w-full border-t border-l border-r-2 border-b-2 rounded-md bg-[white] p-2 overflow-auto">
        <div className="flex justify-between items-center mb-2">
          <div>{Props.title}</div>
          <span
            className={"cursor-pointer py-1 rounded text-[20px]"}
            onClick={() => {
              Props.setShow(false);
            }}>
            <FontAwesomeIcon icon={faCircleXmark} style={{ color: "black" }} />
          </span>
        </div>
        <div className="flex flex-col mb-2">
          <div className="relative flex w-full font-bold">
            <input
              className="placeholder:text-slate-400 block bg-white border border-slate-300 py-2 pl-2 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 mr-[5px] rounded"
              placeholder="전도금"
              type="text"
              name="preCharge"
              style={{ flex: 1, marginTop: 5, marginLeft: 5 }}
              onChange={(e) => {
                setPreCharge(e.target.value.replace(/,/g, "").replace(/\D/, ''));
              }}
              onKeyDown={(e) => handleKeyDown(e)}
              value={Utils.formatNumber(preCharge)}
            />
          </div>
          <div className="relative flex w-full font-bold">
            <input
              className="placeholder:text-slate-400 block bg-white border border-slate-300 py-2 pl-2 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 mr-[5px] rounded"
              placeholder="가이드 비용"
              type="text"
              name="guideFee"
              style={{ flex: 1, marginTop: 5, marginLeft: 5 }}
              onChange={(e) => {
                setGuideFee(e.target.value.replace(/,/g, "").replace(/\D/, ''));
              }}
              onKeyDown={(e) => handleKeyDown(e)}
              value={Utils.formatNumber(guideFee)}
            />
          </div>
          <div className="relative flex w-full font-bold">
            <input
              className="placeholder:text-slate-400 block bg-white border border-slate-300 py-2 pl-2 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 mr-[5px] rounded"
              placeholder="쇼핑 인센티브"
              type="text"
              name="shopIncentive"
              style={{ flex: 1, marginTop: 5, marginLeft: 5 }}
              onChange={(e) => {
                setShopIncentive(e.target.value.replace(/,/g, "").replace(/\D/, ''));
              }}
              onKeyDown={(e) => handleKeyDown(e)}
              value={Utils.formatNumber(shopIncentive)}
            />
          </div>
        </div>
        <div className="flex flex-col">
          <span>
            <ColorButton
              title={languageSet.roomingAddListDialogConfirmButton}
              color="slateblue"
              handler={async () => {
                await modifyInfo();
              }}
            />
          </span>
        </div>
      </div>
    </div >
  )
}