import React, { } from "react";
import { useNavigate } from "react-router-dom";
import { UserInfo, LanguageSet } from "../../recoil";
import { useRecoilValue } from "recoil";
import * as HTTPRequestManager from "../../features/HTTPRequestManager";
import { ROLES } from "../../features/Constant";

export default function RequestCommentItem(Props) {
  const data = Props.data;
  const userInfo = useRecoilValue(UserInfo);

  return (
    <div className="flex flex-col w-full justify-items-center items-center text-[16px] normal-case">
      <div className="flex flex-row w-full">
        <div className="w-[100px] text-start py-2 text-[#777777]">{data.name}</div>
        <div className="w-full break-all py-2 line-clamp-3">
          {
            (data.content.includes("http") && data.originalFileName !== null) ? <div className="text-[blue] normal-case cursor-pointer"
              onClick={async () => {
                fetch(data.content, {
                  method: 'GET',
                  headers: {
                  },
                })
                  .then((response) => response.blob())
                  .then((blob) => {
                    const url = window.URL.createObjectURL(
                      new Blob([blob]),
                    );
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute(
                      'download',
                      data.originalFileName,
                    );

                    document.body.appendChild(link);
                    link.click();
                    link.parentNode.removeChild(link);
                  });
              }}
            >{data.originalFileName}</div> : data.content
          }
        </div>
        {
          (userInfo.id === data.userId || userInfo.rolesId === ROLES.GOD) ?
            <div className="py-2 text-[crimson] cursor-pointer"
              onClick={async (e) => {
                let answer = window.confirm(`${data.originalFileName !== null ? data.originalFileName : data.content} Delete?`);
                if (answer) {
                  let res = await HTTPRequestManager.removeRequestComment({
                    accessToken: userInfo.accessToken,
                    id: data.id
                  });

                  if (res !== undefined) {
                    Props.refresh();
                  }
                }
              }}>
              DEL
            </div>
            : null
        }
        <div className="w-[200px] text-center text-[#777777] py-2">{data.createdAt.slice(2, 19)}</div>
      </div>
      <div className="flex w-full h-[1px] bg-[#DEE2E6]" />
    </div>
  );
}