import React, { useRef, useState, useEffect } from 'react';
import useDetectClose from '../hooks/useDetectClose'

const Dropdown = props => {
  const dropDownRef = useRef(null);
  const [isOpen, setIsOpen] = useDetectClose(dropDownRef, false);
  const [selectedItem, setSelectedItem] = useState("");
  const [selectedColor, setSelectedColor] = useState(props.initId === undefined ? '#000' : props.data?.find(item => item.id === props.initId)?.color);

  return (
    <div style={{ width: '100%', }}>
      <div style={{
        position: 'relative',
        width: "100%",
        background: props.background ? props.background : '#fff',
        display: 'flex',
        padding: props.padding !== undefined ? props.padding : 10,
        borderRadius: 5,
        borderWidth: 1,
      }}>
        {
          props.handlerList !== undefined ?
            <button ref={dropDownRef} onClick={() => { setIsOpen(!isOpen); }}
              style={{
                outline: 'none',
                flex: 1,
                textAlign: 'center',
                color: props.handlerList[props.handlerIndex][props.handlerType] !== "" ? selectedColor : '#9ca3af',
              }}>
              {
                (props.handlerList !== undefined && props.handlerList[props.handlerIndex][props.handlerType] !== "" ? props.data?.find(item => item.id === props.handlerList[props.handlerIndex][props.handlerType])?.name : props.title)
              }
            </button>
            :
            <button ref={dropDownRef} onClick={() => { setIsOpen(!isOpen); }}
              style={{
                outline: 'none',
                flex: 1,
                textAlign: 'center',
                color: selectedItem !== "" ? selectedColor : (props.initId === undefined ? '#9ca3af' : selectedColor),
              }}>
              {
                selectedItem !== "" ? selectedItem : (props.initId === undefined ? props.title : props.data?.find(item => item.id === props.initId)?.name)
              }
            </button>
        }
        {
          isOpen &&
          <ul style={{
            background: '#000000cc',
            borderRadius: 0,
            position: 'absolute',
            top: props.padding === undefined && 50,
            left: 0,
            height: props.data?.length * 35 > 300 && 300,
            width: "100%",
            padding: 10,
            zIndex: 1,
            visibility: !isOpen ? 'hidden' : 'visible',
            overflow: "hidden",
            // 왜 가능한지 분석 필요
            overflowY: props.scroll && "scroll",
          }}>
            {
              props.data?.map((item, index) => {
                return <li
                  key={item + index}
                  style={{
                    color: item.color !== undefined ? item.color : '#fff',
                    cursor: 'pointer',
                  }}
                  onClick={(e) => {
                    setIsOpen(!isOpen);
                    setSelectedItem(e.target.textContent);

                    // 너무 복잡하다.....
                    if (props.setStartDate !== undefined) {
                      props.setStartDate(item.startDate);
                    }

                    if (props.setEndDate !== undefined) {
                      props.setEndDate(item.endDate);
                    }

                    if (props.setFunc !== undefined) {
                      props.setFunc(item.id);
                    }

                    if (props.setHandler !== undefined && props.handlerIndex !== undefined && props.handlerType !== undefined) {
                      props.setHandler(item.id, props.handlerIndex, props.handlerType);
                    }

                    if (props.onClick !== undefined) {
                      props.onClick();
                    }

                    if (item.color !== undefined) {
                      setSelectedColor(item.color);
                    }
                  }}
                >
                  {
                    item.name
                  }
                </li>;
              })
            }
          </ul>
        }
      </div>
    </div>
  )
};

export default Dropdown;