import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { HamburgerIcon } from "./Icons";
import SignIn from "./users/SignIn";
import { useRecoilState } from "recoil";
import { UserInfo, PageLocale, LanguageSet } from "../recoil";
import LocaleKR from "../features/LocaleKR";
import LocaleEN from "../features/LocaleEN";
import UserMenu from "./users/UserMenu";
import "../fonts/Font.css"

const TopBar = (Props) => {
  const navigate = useNavigate();

  const [myInfo, setMyInfo] = useRecoilState(UserInfo);
  const [pageLocale, setPageLocale] = useRecoilState(PageLocale);
  const [languageSet, setLanguageSet] = useRecoilState(LanguageSet);

  const [signInModal, setSignInModal] = useState(false);
  const [userDropdown, setUserDropdown] = useState(false);
  const [showUserMenuModal, setShowUserMenuModal] = useState(false);

  useEffect(() => { }, []);

  return (
    <div className="md:fixed md:w-full md:top-0 md:h-14 h-20 flex flex-row flex-wrap items-center bg-white border-b border-gray-300 z-[104]"
      style={{
        fontFamily: "Noto Sans KR"
      }}
    >
      <div className="flex-none flex flex-row items-center">
        <button
          className="flex-none text-gray-900 hidden md:block px-4 focus:outline-none"
          onClick={() => {
            Props.setMenuToggle(!Props.menuToggle);
          }}
        >
          <HamburgerIcon />
        </button>
      </div>
      <div className="flex flex-auto px-8 md:px-0">
        <Link to="/">
          <div className="mr-2 pl-2 py-1 rounded flex justify-center font-medium">
            <span className="md:hidden">Korea Travel Store</span>
            <span className="hidden md:block">KTS</span>
          </div>
        </Link>
      </div>

      <div className="flex md:flex-auto w-1/5 justify-end">
        <div className="flex flex-row">
          <div className="flex items-center mr-2 justify-center cursor-pointer select-none" onClick={() => {
            setPageLocale("en");
            setLanguageSet(LocaleEN);
          }}>
            <span className="text-[white] text-[14px] font-bold rounded-full py-[2px] px-[8px]" style={{
              background: pageLocale === "en" ? "black" : "grey"
            }}>E</span>
          </div>
          <div className="flex items-center mr-2 justify-center cursor-pointer select-none" onClick={() => {
            setPageLocale("ko");
            setLanguageSet(LocaleKR);
          }}>
            <span className="bg-[black] text-[white] text-[14px] font-bold rounded-full py-[2px] px-[8px]" style={{
              background: pageLocale === "ko" ? "black" : "grey"
            }}>K</span>
          </div>
        </div>
        {myInfo.name !== undefined ? (
          <>
            <button
              className="px-6 md:px-3 mr-6 whitespace-nowrap font-bold bg-transparent focus:outline-none rounded-lg md:py-1 py-2 inline-flex items-center"
              onClick={() => {
                //setUserDropdown(!userDropdown);
                setShowUserMenuModal(!showUserMenuModal);
              }}
            >
              {myInfo.name}{myInfo.rolesId === 2 && "(" + myInfo.nameEN + ")"}
            </button>
            <ul
              className={`transform ${userDropdown ? `scale-y-100` : `scale-y-0`
                }
                transition duration-500 ease-in-out origin-top
                mt-12 mr-6 rounded
                w-44 bg-white absolute text-black border-2`}
              onClick={() => setUserDropdown(!userDropdown)}
            >
              {/* 상단에 uesrLoginMenu 객체 */}

            </ul>
          </>
        ) : (
          <button
            className="px-6 md:px-3 md:py-1 py-2 mr-6 bg-black whitespace-nowrap text-white font-bold rounded-md"
            onClick={() => setSignInModal(true)}
          >
            {languageSet.signin}
          </button>
        )}
        <SignIn showModal={signInModal} setShowModal={setSignInModal} />
        <UserMenu showModal={showUserMenuModal} setShowModal={setShowUserMenuModal} />
      </div>
    </div>
  );
};

export default TopBar;
