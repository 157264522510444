import React, { useEffect, useState, useMemo, useRef } from "react";
import * as Utils from '../../features/Utils';
import { ROLES } from "../../features/Constant";
import { useNavigate } from "react-router-dom";
import { UserInfo, LanguageSet } from "../../recoil";
import * as HTTPHanmaruManager from "../../features/HTTPHanmaruManager";
import { useRecoilValue } from "recoil";
import "../styles/CommonStyle.css";
import { Tooltip } from "react-tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisVertical } from "@fortawesome/free-solid-svg-icons";

export default function AccountItem(Props) {
  let account = Props.account;
  let index = Props.index;

  const navigate = useNavigate();
  const userInfo = useRecoilValue(UserInfo);
  const languageSet = useRecoilValue(LanguageSet);

  const [showMenu, setShowMenu] = useState(false);
  const menuList = ["월세", "인건비", "기타", "잡비", "식자재", "식비", "닫기"];

  const liCommonCSSUserMenu = "py-1 hover:bg-gray-500 hover:text-[white] select-none";
  const btnCommonCSSUserMenu = "hover:bg-grey-200 w-full h-full py-2 px-2 text-center focus:outline-none";

  const redTag = (msg) => {
    return <span
      className="inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-bold text-red-700 ring-1 ring-inset ring-red-600/10 mr-[5px] cursor-pointer"
    > {msg}</span >
  }

  const blueTag = (msg) => {
    return (
      <span className="inline-flex items-center rounded-md bg-blue-50 px-2 py-1 text-xs font-bold text-blue-700 ring-1 ring-inset ring-blue-700/10 mr-[5px]">
        {msg}
      </span>
    );
  }

  const whiteTag = (msg) => {
    return (
      <span className="inline-flex items-center rounded-md bg-white px-2 py-1 text-xs font-bold text-gray-700 ring-1 ring-inset ring-gray-700/10 mr-[5px]">
        {msg}
      </span>
    );
  }

  function getBgColor(index, type) {
    if (type === null || type === undefined) {
      if (index % 2 === 0) return "bg-[#f9f9f9]";
      else return "bg-[#dfdfdf]";
    } else {
      switch (type) {
        case "월세":
          return "bg-blue-400/20";
        case "인건비":
          return "bg-red-500/20";
        case "기타":
          return "bg-green-400/20";
        case "잡비":
          return "bg-purple-500/20";
        case "식자재":
          return "bg-yellow-300/20";
        case "식비":
          return "bg-orange-400/20";
      }
    }
  }

  return (
    <div className={`flex flex-row items-center text-center gap-1 p-2 rounded-lg mr-2 ${getBgColor(index, account.contentType)} justify-between`} key={account.id}>
      <div>
        {account.type === 0 ? redTag("매입") : blueTag("매출")} {account.contentType !== null && whiteTag(account.contentType)} {account.date} // {Utils.formatNumber(account.amount)} // {Utils.formatNumber(account.balance)} // {account.content}
      </div>
      <div className="h-full hover:text-[red] mr-2">
        <div
          style={{
            fontSize: 15,
            cursor: "pointer",
            textAlign: "center"
          }}
          onClick={() => {
            setShowMenu(true);
          }}
        >
          <FontAwesomeIcon icon={faEllipsisVertical} />
        </div>
      </div>
      {
        // 메뉴
        showMenu &&
        <>
          <div className="md:top-14 mx-6 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none font-medium">
            <div className="relative w-full m-auto max-w-lg">
              <div className="border-0 shadow-lg relative flex flex-col bg-white outline-none focus:outline-none">
                {
                  menuList.map((menu, index) => {
                    return (
                      <div key={index}
                        className={index !== menuList.length - 1
                          ? `border-b ${liCommonCSSUserMenu}`
                          : `${liCommonCSSUserMenu}`
                        }>
                        <button
                          className={btnCommonCSSUserMenu}
                          onClick={async () => {
                            if (menu === "닫기") {
                              setShowMenu(false);
                            } else {
                              let res = await HTTPHanmaruManager.UpdateAccount({
                                accessToken: userInfo.accessToken,
                                param: {
                                  id: account.id,
                                  contentType: menu,
                                }
                              });

                              if (res.data !== undefined && res.data.result === true) {
                                setShowMenu(false);
                                Props.refresh();
                              }
                            }
                          }}
                        >
                          {menu}
                        </button>
                      </div>
                    )
                  })
                }
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black" />
        </>
      }
    </div>
  );
}