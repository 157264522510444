

import React, { } from "react";
import { useNavigate } from "react-router-dom";
import "../styles/CommonStyle.css";
import { ResponsiveLine } from "@nivo/line";

export default function LineChart(Props) {
  const navigate = useNavigate();

  const _lineChart = () => {
    return (
      <ResponsiveLine
        colors={Props.data !== undefined && Props.data[0] !== undefined ? Props.data[0].color : "#0f0"}
        curve="linear"
        data={Props.data !== undefined ? Props.data : []}
        margin={{
          bottom: 30,
          left: 35,
          right: 35,
          top: 20
        }}
        yScale={{
          type: 'linear',
          min: 'auto',
          max: 'auto',
          stacked: true,
          nice: 1,
        }}
        pointLabelYOffset={12}
        useMesh={true}
        enableTouchCrosshair={true}
        pointLabel="data.yFormatted"
        enableSlices="x"
        enableGridY={false}
        enableGridX={false}
        sliceTooltip={(_) => {
          return <div className="bg-[white] shadow-2xl px-10 py-5 flex flex-row gap-2">
            <div>{_.slice.points[0].data.x}</div>
            <div className="font-bold" style={{ color: _.slice.points[0].data.y < 0 ? "#f00" : "#000" }}>{_.slice.points[0].data.yFormatted}</div>
          </div>
        }}
      />
    );
  };

  return (
    <div className="scrollRemoveBox flex flex-col w-full h-full justify-items-center items-center text-[13px] normal-case">
      {
        _lineChart()
      }
    </div>
  );
}