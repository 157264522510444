import React, { useState, useEffect, forwardRef } from "react";
import * as Utils from '../features/Utils';
import * as HTTPManager from '../features/HTTPManager';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ko } from 'date-fns/esm/locale';
import Dropdown from './Dropdown'
import { UserInfo } from "../recoil";
import { useRecoilValue } from "recoil";
import { ROLES } from "../features/Constant";
import { useNavigate } from "react-router-dom";
import ColorButton from "./ColorButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass, faSquareMinus, faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import AutoCompleteInput from "./AutoCompleteInput";

export default function CreateTour(Props) {
  const body = document.querySelector('body');
  const scrollPosition = window.pageYOffset;
  const navigate = useNavigate();

  const [startDate, setStartDate] = useState(Utils.convertLocalToUTCDate(new Date()));
  const [endDate, setEndDate] = useState(Utils.convertLocalToUTCDate(new Date()));

  const [countryId, setCountryId] = useState("");
  const [pax, setPax] = useState("");

  const [countryList, setCountryList] = useState([]);

  const userInfo = useRecoilValue(UserInfo);

  // 추가 정보
  const [eta, setEta] = useState("");
  const [etd, setEtd] = useState("");
  const [docNum, setDocNum] = useState("");
  const [memo, setMemo] = useState("");

  const [guideList, setGuideList] = useState([]);
  const [guideFilterList, setGuideFilterList] = useState([]);
  const [guideSelectedList, setGuideSelectedList] = useState([]);

  const [hotelList, setHotelList] = useState([]);
  const [hotelSelectedList, setHotelSelectedList] = useState([]);

  const [searchText, setSearchText] = useState("");

  function clearInput() {
    setPax("");
    setEta("");
    setEtd("");
    setDocNum("");
    setMemo("");
    setGuideSelectedList([]);
    setHotelSelectedList([]);
    setSearchText("");
  }

  const selectedListHandler = (value, idx, type) => {
    const updatedState = guideSelectedList;
    const updatedFormElement = {
      ...updatedState[idx]
    }

    switch (type) {
      case 'startDate':
        updatedFormElement.startDate = value;
        break;
      case 'endDate':
        updatedFormElement.endDate = value;
        break;
    }

    updatedState[idx] = updatedFormElement;

    setGuideSelectedList([...updatedState]);
  }

  const selectedHotelListHandler = (value, idx, type) => {
    const updatedState = hotelSelectedList;
    const updatedFormElement = {
      ...updatedState[idx]
    }

    switch (type) {
      case 'startDate':
        updatedFormElement.startDate = value;
        break;
      case 'endDate':
        updatedFormElement.endDate = value;
        break;
      case 'roomType':
        updatedFormElement.roomType = value
        break;
    }

    updatedState[idx] = updatedFormElement;

    setHotelSelectedList([...updatedState]);
  }

  const fetchData = async () => {
    let response = await HTTPManager.GetCountryList();
    setCountryList(response.data.result);

    response = await HTTPManager.GetUserList({
      accessToken: userInfo.accessToken
    });

    let guideList = [];
    if (response !== undefined && response.data?.result !== undefined) {
      guideList = [...response.data.result.filter(item => (item.rolesId === ROLES.GUIDE || item.rolesId === ROLES.LEADERGUIDE))];
      guideList.sort((a, b) => a.name < b.name ? -1 : a.name > b.name ? 1 : 0);
      setGuideList(guideList);
    }

    response = await HTTPManager.GetHotelList({
      accessToken: userInfo.accessToken
    });

    if (response !== undefined && response.data?.result !== undefined) {
      setHotelList(response.data.hotelList);
    }
  };

  useEffect(() => {
    Utils.allowByPosition(ROLES.ADMIN, userInfo.rolesId)
      .then(async (res) => {
        if (!res) {
          alert("접근 권한이 없습니다.");
          navigate("/", { replace: true });
        }
        else {
          await fetchData();
        }
      })
      .catch((err) => {
        console.log(err);
        alert(err.message);
      });

    body.style.overflow = 'hidden';
    window.scrollTo(0, 0);

    // 모달 종료 시 호출
    return () => {
      body.style.removeProperty('overflow');
      window.scrollTo(0, scrollPosition);
    };
  }, []);

  const DateCustomInput = forwardRef(({ value, onClick }, ref) => (
    <button className="example-custom-input" onClick={onClick} ref={ref} style={{ width: '100%', borderWidth: 1, padding: 10, borderRadius: 5, }}>
      {value}
    </button>
  ));

  return (
    <div className="fixed h-full w-full top-0 text-sm bg-[#0007] overflow-auto z-[200]">
      <div className="fixed h-full w-full bg-white overflow-auto p-6 md:p-2">
        <div style={{ display: "flex", width: "100%", justifyContent: "flex-end" }}>
          <div
            style={{
              width: 25,
              height: 25,
              fontSize: 15,
              color: "grey",
              cursor: "pointer",
              textAlign: "center"
            }}
            onClick={() => { Props.setShow(false) }}
          >
            <FontAwesomeIcon icon={faXmark} />
          </div>
        </div>
        <div style={{ marginBottom: 10, display: 'flex', flexDirection: 'row', alignItems: 'center', fontWeight: "bold" }}>기본 정보(필수)</div>
        <div style={{ marginBottom: 10, display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', }}>
          <div style={{ width: 80, color: '#9ca3af' }}>시작</div>
          <DatePicker onChange={date => setStartDate(Utils.convertLocalToUTCDate(date))} selected={Utils.convertUTCToLocalDate(startDate)} locale={ko} dateFormat="yyyy/MM/dd" customInput={<DateCustomInput />} showPopperArrow={false} />
        </div>
        <div style={{ marginBottom: 10, display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', }}>
          <div style={{ width: 80, color: '#9ca3af' }}>종료</div>
          <DatePicker onChange={date => setEndDate(Utils.convertLocalToUTCDate(date))} selected={Utils.convertUTCToLocalDate(endDate)}
            locale={ko} dateFormat="yyyy/MM/dd" customInput={<DateCustomInput />} showPopperArrow={false} />
        </div>

        <div style={{ marginBottom: 10, display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', }}>
          <div style={{ width: 80, color: '#9ca3af' }}>국가</div>
          <Dropdown title="목록" data={countryList?.map((item) => {
            return {
              id: item.id,
              name: item.nameCountry,
            }
          })} setFunc={setCountryId} />
        </div>

        <div style={{ marginBottom: 10, display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', }}>
          <div style={{ width: 80, color: '#9ca3af' }}>인원</div>
          <input
            className="placeholder:text-slate-400 block bg-white border border-slate-300 rounded-md py-2 pl-3 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1"
            placeholder="ex) 19+1"
            type="text"
            name="pax"
            style={{ flex: "100%" }}
            onChange={(e) => {
              setPax(e.target.value);
            }}
            value={pax}
          />
        </div>

        <div style={{ marginBottom: 10, display: 'flex', flexDirection: 'row', alignItems: 'center', fontWeight: "bold" }}>추가 정보(옵션)</div>

        <div className="relative flex w-full flex-col justify-items-center pb-1 mt-1 bg-[white]">
          <div className="flex flex-row">
            <div className="text-[20px] absolute text-slate-400 top-[14px] left-5 origin-center rotate-90">
              <FontAwesomeIcon icon={faMagnifyingGlass} />
            </div>
            <div title="가이드 목록"
              className={guideFilterList.length > 0 ?
                "text-[20px] absolute text-slate-400 top-[14px] right-5 cursor-pointer origin-center rotate-180"
                : "text-[20px] absolute text-slate-400 top-[12px] right-5 cursor-pointer"
              }
              onClick={() => {
                if (guideFilterList.length > 0) {
                  setGuideFilterList([]);
                } else {
                  setGuideFilterList(guideList);
                }
              }}>
              <FontAwesomeIcon icon={faCaretDown} />
            </div>

            <input
              autoComplete="off"
              className="rounded placeholder:text-slate-400 block bg-white border border-slate-300 py-2 pl-10 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 mb-2"
              placeholder="가이드 검색"
              type="text"
              name="search"
              style={{ flex: 1, marginTop: 5, marginLeft: 5 }}
              onChange={(e) => {
                if (e.target.value.length > 0) {
                  let searchList = [];

                  searchList = guideList.filter(guide => guide.name?.includes(e.target.value));

                  setGuideFilterList(searchList);
                } else {
                  setGuideFilterList([]);
                }

                setSearchText(e.target.value);
              }}
              value={searchText}
            />
          </div>

          {
            guideFilterList.length > 0 &&
            <div className="overflow-auto absolute h-80 w-full bg-[#e6e6e6] border-b mt-11 z-10">
              {
                guideFilterList.map(guide => {
                  return (
                    <div key={guide.id} className="flex flex-row hover:bg-[coral] hover:text-[white] cursor-pointer text-left m-2 pl-1"
                      onClick={(e) => {
                        setSearchText("");
                        setGuideFilterList([]);

                        setGuideSelectedList([...guideSelectedList, {
                          id: guide.id,
                          name: guide.name,
                          startDate: Utils.convertLocalToUTCDate(startDate),
                          endDate: Utils.convertLocalToUTCDate(endDate),
                          phoneNumber: guide.phoneNumber,
                        }])
                      }}
                    >
                      <div className="mr-1">{guide.name}</div>
                      {
                        guide.phoneNumber !== null && <div>({guide.phoneNumber})</div>
                      }
                    </div>
                  )
                })
              }
            </div>
          }
        </div>

        <div style={{ marginBottom: 10, display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', }}>
          <div style={{ width: 80, color: '#9ca3af' }}>입국</div>
          <input
            className="placeholder:text-slate-400 block bg-white border border-slate-300 rounded-md py-2 pl-3 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1"
            placeholder="ex) SQ123"
            type="text"
            name="pax"
            style={{ flex: "100%" }}
            onChange={(e) => {
              setEta(e.target.value);
            }}
            value={eta}
          />
        </div>

        <div style={{ marginBottom: 10, display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', }}>
          <div style={{ width: 80, color: '#9ca3af' }}>출국</div>
          <input
            className="placeholder:text-slate-400 block bg-white border border-slate-300 rounded-md py-2 pl-3 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1"
            placeholder="ex) SQ456"
            type="text"
            name="pax"
            style={{ flex: "100%" }}
            onChange={(e) => {
              setEtd(e.target.value);
            }}
            value={etd}
          />
        </div>

        <div style={{ marginBottom: 10, display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', }}>
          <div style={{ width: 80, color: '#9ca3af' }}>문서번호</div>
          <input
            className="placeholder:text-slate-400 block bg-white border border-slate-300 rounded-md py-2 pl-3 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1"
            placeholder="ex) its_xxx, ph_xxx"
            type="text"
            name="pax"
            style={{ flex: "100%" }}
            onChange={(e) => {
              setDocNum(e.target.value);
            }}
            value={docNum}
          />
        </div>

        <div style={{ marginBottom: 10, display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', }}>
          <div style={{ width: 80, color: '#9ca3af' }}>메모</div>
          <input
            className="placeholder:text-slate-400 block bg-white border border-slate-300 rounded-md py-2 pl-3 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1"
            placeholder="ex)"
            type="text"
            name="pax"
            style={{ flex: "100%" }}
            onChange={(e) => {
              setMemo(e.target.value);
            }}
            value={memo}
          />
        </div>

        <div className="text-left flex flex-row pl-1.5 mt-4 mb-2 font-bold items-center">
          가이드정보
        </div>
        {
          guideSelectedList?.map((guideInfo, index) => {
            return (
              <div
                key={index + "/" + guideInfo.id}
                className="relative flex flex-row text-left text-[13px] pl-2 pr-2 md:pr-4 w-full mb-2 items-center">
                <div className="flex flex-row mr-2 items-center">
                  <div className="text-[18px] text-[grey] mr-2 cursor-pointer" onClick={() => {
                    const tempArray = [...guideSelectedList];
                    tempArray.splice(index, 1);
                    setGuideSelectedList(tempArray);
                  }}>
                    <FontAwesomeIcon icon={faSquareMinus} />
                  </div>

                  <div className="flex text-left mr-1">
                    {guideInfo.name}
                  </div>
                  {
                    guideInfo.phoneNumber !== null && <div className="flex text-left">
                      ({guideInfo.phoneNumber})
                    </div>
                  }
                </div>

                <div className="mr-2">시작</div>
                <div className="mr-2">
                  <DatePicker onChange={date => {
                    selectedListHandler(date, index, "startDate");
                  }}
                    selected={Utils.convertLocalToUTCDate(guideInfo.startDate)}
                    locale={ko}
                    dateFormat="yyyy/MM/dd"
                    customInput={<DateCustomInput />}
                    showPopperArrow={false}
                  />
                </div>
                <div className="mr-2">종료</div>
                <div>
                  <DatePicker onChange={date => {
                    selectedListHandler(date, index, "endDate");
                  }}
                    selected={Utils.convertLocalToUTCDate(guideInfo.endDate)}
                    locale={ko}
                    dateFormat="yyyy/MM/dd"
                    customInput={<DateCustomInput />}
                    showPopperArrow={false}
                  />
                </div>
              </div>
            )
          })
        }

        <div className="text-left flex flex-row pl-1.5 mt-4 mb-2 font-bold items-center">
          호텔정보
        </div>
        <div className="relative flex w-full flex-col justify-items-center pb-1 px-1 mt-1 bg-[white] mb-[10px]">
          <AutoCompleteInput label="호텔 검색 (이동 : ↓↑키, 취소 : ESC, 추가 : Enter)" list={hotelList} selectedType="reservationHotel" selectedList={hotelSelectedList} selectedFunc={setHotelSelectedList} startDate={Utils.convertLocalToUTCDate(startDate)} endDate={Utils.convertLocalToUTCDate(endDate)} />
        </div>
        {
          hotelSelectedList?.map((hotelInfo, index) => {
            return (
              <div
                key={index + "/" + hotelInfo.id}
                className="relative flex flex-row text-left text-[13px] pl-2 pr-2 md:pr-4 w-full mb-2 items-baseline">
                <div className="flex flex-row mr-2 items-center">
                  <div className="text-[18px] text-[grey] mr-2 cursor-pointer" onClick={() => {
                    const tempArray = [...hotelSelectedList];
                    tempArray.splice(index, 1);
                    setHotelSelectedList(tempArray);
                  }}>
                    <FontAwesomeIcon icon={faSquareMinus} />
                  </div>

                  <div className="flex text-left mr-1">
                    {hotelInfo.name}
                  </div>
                  <div className="flex text-left">
                    ({
                      hotelInfo.address1 !== null && hotelInfo.address1
                    },
                    {
                      hotelInfo.address2 !== null && hotelInfo.address2
                    })
                  </div>
                </div>

                <div className="mr-2">체크인</div>
                <div className="mr-2">
                  <DatePicker onChange={date => {
                    selectedHotelListHandler(date, index, "startDate");
                  }}
                    selected={Utils.convertLocalToUTCDate(hotelInfo.startDate)}
                    locale={ko}
                    dateFormat="yyyy/MM/dd"
                    customInput={<DateCustomInput />}
                    showPopperArrow={false}
                  />
                </div>
                <div className="mr-2">체크아웃</div>
                <div>
                  <DatePicker onChange={date => {
                    selectedHotelListHandler(date, index, "endDate");
                  }}
                    selected={Utils.convertLocalToUTCDate(hotelInfo.endDate)}
                    locale={ko}
                    dateFormat="yyyy/MM/dd"
                    customInput={<DateCustomInput />}
                    showPopperArrow={false}
                  />
                </div>
                <input
                  className="rounded placeholder:text-slate-400 block bg-white border border-slate-300 py-[9.4px] pl-2 pr-3 shadow-sm focus:outline-none focus:border-sky-500 "
                  placeholder="타입) 더블 1, 트윈 2, 트리플 3 .."
                  type="text"
                  name="search"
                  style={{ flex: 1, marginTop: 5, marginLeft: 5 }}
                  onChange={(e) => {
                    selectedHotelListHandler(e.target.value, index, "roomType");
                  }}
                  value={hotelInfo.roomType}
                />
              </div>
            )
          })
        }

        <ColorButton title="생성" width={"100%"} color="slateblue" handler={async () => {
          if (countryId.length < 1 || pax.length < 1) {
            alert('빈 칸 확인');
            return;
          }

          var res = await HTTPManager.InsertTour({
            startDate: startDate,
            endDate: endDate,
            countryId: countryId,
            pax: pax,
            accessToken: userInfo.accessToken,
            eta: eta,
            etd: etd,
            docNum: docNum,
            memo: memo,
            guideList: guideSelectedList,
            hotelList: hotelSelectedList,
          });

          if (res === undefined) {
            alert('생성 실패!');
            return
          }

          if (res.data.result) {
            alert('생성 성공!');
            clearInput();
            Props.refresh();
          } else {
            alert('생성 실패!');
          }
        }} />
      </div>
    </div>
  );
}