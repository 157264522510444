import React, { useState, useEffect, } from "react";
import * as HTTPManager from "../../features/HTTPManager";
import { UserInfo } from "../../recoil";
import { useRecoilValue } from "recoil";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark, } from "@fortawesome/free-solid-svg-icons";
import ColorButton from "../ColorButton"

export default function ModifyToursGuideDialog(Props) {
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const tourGuideInfo = Props.tourGuideInfo;

  const body = document.querySelector('body');
  //const scrollPosition = window.pageYOffset;

  const userInfo = useRecoilValue(UserInfo);
  const tourInfo = Props.tourInfo;

  const [dataList, setDataList] = useState(tourGuideInfo.map(item => {
    return (
      {
        id: item.id,
        preCharge: item.preCharge,
        tip: item.tipKTS,
        tipDate: item.tipDate === null ? today : item.tipDate,
      }
    )
  }));

  async function handleKeyDown(event) {
    if (event.key === 'Enter') {
      await modifyInfo();

    } else if (event.keyCode === 27) {
      Props.setShow(false);
    }
  }

  async function modifyInfo() {
    let res = await HTTPManager.ModifyToursGuide({
      accessToken: userInfo.accessToken,
      toursGuideInfo: dataList,
    })

    if (res !== undefined && res.data.result) {
      Props.setShow(false);
      Props.refresh();
    }
  }

  const tourGuideListHandler = (value, idx, type) => {
    const updatedState = dataList;

    const updatedFormElement = {
      ...updatedState[idx]
    }

    switch (type) {
      case 'preCharge':
        updatedFormElement.preCharge = value;
        break;
      case 'tip':
        updatedFormElement.tip = value;
        break;
      case 'tipDate':
        updatedFormElement.tipDate = value;
        break;
      default:
        break;
    }

    updatedState[idx] = updatedFormElement;

    setDataList([...updatedState]);
  }

  useEffect(() => {
    // 모달 팝업 시 호출
    // 스크롤 최상단 위치 후 배경 스크롤 되지 않도록 고정
    body.style.overflow = 'hidden';

    // 모달 종료 시 호출
    return () => {
      body.style.removeProperty('overflow');
    };
  }, []);

  return (
    <div className="fixed h-full w-full top-0 left-60 md:left-0 md:top-10 pl-4 text-sm bg-[#0007] overflow-auto z-[100]">
      <div className="fixed border-t border-l border-r-2 border-b-2 rounded-md bg-white top-24 md:top-20 mr-4 p-2 overflow-auto">
        <span
          className={"flex cursor-pointer py-1 rounded text-[20px] justify-end"}
          onClick={() => {
            Props.setShow(false);
          }}>
          <FontAwesomeIcon icon={faCircleXmark} style={{ color: "black" }} />
        </span>
        <div className="flex flex-col">
          <div className="font-bold mb-4">{tourInfo.id}번</div>
          {
            Props.tourGuideInfo.map((item, idx) => {
              return (
                <div key={item.id}>
                  <div className="text-left flex flex-col w-full px-1.5 mb-3 items-center">
                    <div className="font-bold bg-[black] text-[white] w-full rounded px-1 py-1 mb-1 text-center">{Props.userList?.find(user => user.id === item.guideId)?.name}</div>
                    <div className="flex w-full mb-[5px]">전도금</div>
                    <div className="relative flex w-full font-bold">
                      <input
                        className="placeholder:text-slate-400 block bg-white border border-slate-300 py-2 pl-2 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 mr-[5px] rounded"
                        placeholder="ex) 123,000"
                        type="text"
                        name="search"
                        style={{ flex: 1, marginTop: 5, marginLeft: 5 }}
                        onChange={(e) => {
                          tourGuideListHandler(e.target.value, idx, "preCharge");
                        }}
                        onKeyDown={(e) => handleKeyDown(e)}
                        value={dataList[idx]?.preCharge === null ? "" : dataList[idx]?.preCharge}
                      />
                    </div>
                  </div>

                  <div className="text-left flex flex-col w-full px-1.5 mb-3 items-center">
                    <div className="flex w-full mb-[5px]">팁</div>
                    <div className="relative flex w-full font-bold">
                      <input
                        className="placeholder:text-slate-400 block bg-white border border-slate-300 py-2 pl-2 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 mr-[5px] rounded"
                        placeholder="ex) $450"
                        type="text"
                        name="search"
                        style={{ flex: 1, marginTop: 5, marginLeft: 5 }}
                        onChange={(e) => {
                          tourGuideListHandler(e.target.value, idx, "tip");
                        }}
                        onKeyDown={(e) => handleKeyDown(e)}
                        value={dataList[idx]?.tip === null ? "" : dataList[idx]?.tip}
                      />
                    </div>
                  </div>
                </div>
              );
            })
          }

          <span>
            <ColorButton
              title="확인"
              color="slateblue"
              handler={async () => {
                await modifyInfo();
              }}
            />
          </span>
        </div>
      </div>
    </div>
  )
}