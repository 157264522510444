import React, { useState, useEffect, useMemo } from "react";
import { useRecoilValue } from "recoil";
import { UserInfo } from "../../recoil";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCoins } from "@fortawesome/free-solid-svg-icons";
import { faCalendar } from "@fortawesome/free-regular-svg-icons";
import * as Utils from '../../features/Utils';
import * as HTTPManager from '../../features/HTTPManager';
import * as HTTPLogManager from '../../features/HTTPLogManager';
import { ROLES } from "../../features/Constant";
import "../styles/CommonStyle.css"

const StatisticBoard = () => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const userInfo = useRecoilValue(UserInfo);

  const [tourList, setTourList] = useState([]);
  const [yesterdayLogList, setYesterDayLogList] = useState([]);
  const data = useMemo(() => tourList, [tourList]);

  async function fetchData() {
    let response = await HTTPManager.GetTourList({
      accessToken: userInfo.accessToken,
    });

    if (response !== undefined) {
      setTourList(response.data.result);
    }

    response = await HTTPLogManager.getLogListYesterday({
      accessToken: userInfo.accessToken,
    });

    if (response !== undefined) {
      setYesterDayLogList(response.data.result);
    }
  }

  useEffect(() => {
    if (userInfo.rolesId === ROLES.ADMIN || userInfo.rolesId === ROLES.GOD) {
      // 관리자만
      if (userInfo.accessToken !== undefined)
        fetchData();
      else
        setTourList([]);
    } else {
      setTourList([]);
    }
  }, [userInfo]);

  function getDayStr(day) {
    var result = "";
    switch (day) {
      case 0:
        result = "일";
        break;
      case 1:
        result = "월";
        break;
      case 2:
        result = "화";
        break;
      case 3:
        result = "수";
        break;
      case 4:
        result = "목";
        break;
      case 5:
        result = "금";
        break;
      case 6:
        result = "토";
        break;
    }
    return result;
  }

  const startIndonesiaList = data?.filter(tour => {
    if (tour.countryId === 2 && tour.cancel !== 1) {
      let startDate = new Date(tour.startDate.replace(" ", "T"));
      if (startDate.getTime() === today.getTime())
        return 1;
      else
        return 0;
    }
    else
      return 0;
  });

  const endIndonesiaList = data?.filter(tour => {
    if (tour.countryId === 2 && tour.cancel !== 1) {
      let startDate = new Date(tour.endDate.replace(" ", "T"));
      if (startDate.getTime() === today.getTime())
        return 1;
      else
        return 0;
    }
    else
      return 0;
  });

  const startPhilippineList = data?.filter(tour => {
    if (tour.countryId === 3 && tour.cancel !== 1) {
      let startDate = new Date(tour.startDate.replace(" ", "T"));
      if (startDate.getTime() === today.getTime())
        return 1;
      else
        return 0;
    }
    else
      return 0;
  });

  const endPhilippineList = data?.filter(tour => {
    if (tour.countryId === 3 && tour.cancel !== 1) {
      let startDate = new Date(tour.endDate.replace(" ", "T"));
      if (startDate.getTime() === today.getTime())
        return 1;
      else
        return 0;
    }
    else
      return 0;
  });

  return (
    <div className="flex bg-[white] flex-1 flex-col md:flex-col md:gap-2 md:w-full">
      <div className="flex flex-row gap-4 md:gap-1 items-center font-bold md:justify-between">
        <div className="text-[30px] md:text-[20px]">
          {`${today.getFullYear()}/${(today.getMonth() + 1).toString().padStart(2, "0")}/${today.getDate().toString().padStart(2, "0")} (${getDayStr(today.getDay())})`}
        </div>
        <div className="mt-3 text-[#1D3C77] md:text-[13px]">오늘의 요약정보</div>
      </div>

      <div className="flex flex-row w-full gap-2 mt-[25px] scrollRemoveBox px-1">
        <div className="flex flex-col gap-2 rounded-lg shadow-lg px-5 py-6 w-1/4 md:w-full mb-5 md:flex-none">
          <div className="flex flex-row gap-3 font-bold mb-5">
            <span className={"cursor-pointer"}>
              <FontAwesomeIcon icon={faCalendar} style={{ color: "#1D3C77" }} />
            </span>
            <div>시작행사</div>
          </div>
          <div className="flex flex-row justify-between cursor-pointer" title={startIndonesiaList.map(tour => tour.id).toString()}>
            <div>인도네시아</div>
            <div>{Utils.formatNumber(startIndonesiaList.length)}</div>
          </div>
          <div className="flex flex-row justify-between cursor-pointer" title={startPhilippineList.map(tour => tour.id).toString()}>
            <div>필리핀</div>
            <div>{Utils.formatNumber(startPhilippineList.length)}</div>
          </div>
          <div className="flex w-full justify-end text-[25px] font-bold">{Utils.formatNumber(startIndonesiaList.length + startPhilippineList.length)}건</div>
        </div>

        <div className="flex flex-col gap-2 rounded-lg shadow-lg px-5 py-6 w-1/4 md:w-full  mb-5 md:flex-none">
          <div className="flex flex-row gap-3 font-bold mb-5">
            <span className={"cursor-pointer"}>
              <FontAwesomeIcon icon={faCalendar} style={{ color: "#1D3C77" }} />
            </span>
            <div>종료행사</div>
          </div>
          <div className="flex flex-row justify-between cursor-pointer" title={endIndonesiaList.map(tour => tour.id).toString()}>
            <div>인도네시아</div>
            <div>{Utils.formatNumber(endIndonesiaList.length)}</div>
          </div>
          <div className="flex flex-row justify-between cursor-pointer" title={endPhilippineList.map(tour => tour.id).toString()}>
            <div>필리핀</div>
            <div>{Utils.formatNumber(endPhilippineList.length)}</div>
          </div>
          <div className="flex w-full justify-end text-[25px] font-bold">{Utils.formatNumber(endIndonesiaList.length + endPhilippineList.length)}건</div>
        </div>

        <div className="flex flex-col gap-2 rounded-lg shadow-lg px-5 py-6 w-1/4 md:w-full mb-5 md:flex-none">
          <div className="flex flex-row gap-3 font-bold mb-5">
            <span className={"cursor-pointer"}>
              <FontAwesomeIcon icon={faCalendar} style={{ color: "#1D3C77" }} />
            </span>
            <div>어제 수정된 행사</div>
          </div>
          <div className="flex flex-row justify-between cursor-pointer" title={yesterdayLogList.filter(log => tourList.find(tour => tour.id === log.tourId && tour.countryId === 2) !== undefined)?.map(log => log.tourId).toString()}>
            <div>인도네시아</div>
            <div>{Utils.formatNumber(yesterdayLogList.filter(log => tourList.find(tour => tour.id === log.tourId && tour.countryId === 2) !== undefined)?.length)}</div>
          </div>
          <div className="flex flex-row justify-between cursor-pointer" title={yesterdayLogList.filter(log => tourList.find(tour => tour.id === log.tourId && tour.countryId === 3) !== undefined)?.map(log => log.tourId).toString()}>
            <div>필리핀</div>
            <div>{Utils.formatNumber(yesterdayLogList.filter(log => tourList.find(tour => tour.id === log.tourId && tour.countryId === 3) !== undefined)?.length)}</div>
          </div>
          <div className="flex w-full justify-end text-[25px] font-bold">{Utils.formatNumber(yesterdayLogList?.length)}건</div>
        </div>

        <div className="flex flex-col gap-2 rounded-lg shadow-lg px-5 py-6 w-1/4 md:w-full mb-5 md:flex-none">
          <div className="flex flex-row gap-3 font-bold mb-5">
            <span className={"cursor-pointer"}>
              <FontAwesomeIcon icon={faCoins} style={{ color: "#1D3C77" }} />
            </span>
            <div>정산필요 행사</div>
          </div>
          <div>인도네시아</div>
          <div>필리핀</div>
          <div className="flex w-full justify-end text-[25px] font-bold">{Utils.formatNumber()}건</div>
        </div>

      </div>
    </div>
  );
};

export default StatisticBoard;
