import React, { useEffect, useState, } from "react";
import * as Utils from '../../features/Utils';
import { ROLES } from "../../features/Constant";
import { useNavigate } from "react-router-dom";
import { UserInfo, LanguageSet } from "../../recoil";
import { useRecoilValue } from "recoil";
import * as HTTPLogManager from "../../features/HTTPLogManager";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import LogList from "./LogList";
import "../styles/CommonStyle.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";

export default function LogWindow() {
  const { height, width } = useWindowDimensions();

  const navigate = useNavigate();
  const languageSet = useRecoilValue(LanguageSet);
  const userInfo = useRecoilValue(UserInfo);

  const [logList, setLogList] = useState([]);
  const [logFilterList, setLogFilterList] = useState([]);

  const [searchText, setSearchText] = useState("");

  async function fetchData() {
    let res = await HTTPLogManager.getLogList({
      accessToken: userInfo.accessToken
    });

    if (res !== undefined && res.data !== undefined) {
      let logList = res.data.result;
      // 최신순으로 정렬
      logList.sort((a, b) => {
        if (new Date(a.timestamp.replace(" ", "T")) > new Date(b.timestamp.replace(" ", "T")))
          return -1;
        if (new Date(a.timestamp.replace(" ", "T")) < new Date(b.timestamp.replace(" ", "T")))
          return 1;
      })

      setLogList(logList);
      setLogFilterList(logList);
    }
  }

  function searchList(findText) {
    let logFindList = logList.filter(item => (item.tourId === Number(findText) || item.event.includes(findText) || item.message.includes(findText)));
    setLogFilterList(logFindList);
  }

  useEffect(() => {
    Utils.allowByPosition(ROLES.ADMIN, userInfo.rolesId)
      .then(async (res) => {
        if (!res) {
          alert("접근 권한이 없습니다.");
          navigate("/", { replace: true });
        }
        else {
          fetchData();
        }
      })
      .catch((err) => {
        alert(err.message);
      });
  }, []);

  {/* <div>
        width: {width} ~ height: {height}
      </div> */}

  return (
    <div className="w-full h-full flex flex-col bg-[white] md:mt-14 px-[50px] md:px-[30px]" style={{
      height: height - (width >= 768 ? 160 : 0),
    }}>
      <div className="flex flex-col w-full">
        <div className="text-start mt-[50px] text-[#9299A1]">로그</div>
        <div className="text-start mt-[50px] text-[#333A40] font-bold text-[30px]">목록({logFilterList?.length})</div>
        <div className="flex flex-row items-center gap-3 my-[10px]">
          <FontAwesomeIcon icon={faCircle} style={{ color: "#000", width: 5 }} />
          <div className="text-[14px]">2024-01-03 이후 개발된 기능입니다. 이전 기간은 기록이 없습니다.</div>
        </div>

        <div className="h-[2px] bg-[#E7EAED] my-[30px] select-none" />

        <div className="w-full mt-[30px]" style={{ marginBottom: 10, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
          <input
            autoComplete="off"
            className="w-full placeholder:text-slate-400 block bg-white border border-slate-300 rounded-md py-2 pl-3 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1"
            placeholder={languageSet.roomingFindHint}
            type="text"
            name="search"
            onChange={(e) => {
              setSearchText(e.target.value);
              let findText = e.target.value;
              searchList(findText)
            }}
            value={searchText}
          />
        </div>
      </div>
      <div className="flex w-full h-full">
        <div className="flex w-full h-full">
          <LogList logs={logFilterList} />
        </div>
      </div>
    </div>
  );
}