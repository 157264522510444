import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import * as HTTPManager from "../../features/HTTPManager";
import { useRecoilValue } from "recoil";
import { UserInfo, LanguageSet } from "../../recoil";
import "../../fonts/Font.css"

const ChangePwDialog = ({ showModal, setShowModal }) => {
  const navigate = useNavigate();

  const [pw, setPw] = useState("");
  const [pwRe, setPwRe] = useState("");
  const [checkPwMsg, setcheckPwMsg] = useState("");
  const [checkPWTrigger, setCheckPwTrigger] = useState(false);

  const userInfo = useRecoilValue(UserInfo);
  const languageSet = useRecoilValue(LanguageSet);

  useEffect(() => {
    if (pw.length < 1 || pwRe.length < 1) {
      setcheckPwMsg("");
      setCheckPwTrigger(false);
      return;
    }

    if (pw === pwRe) {
      setcheckPwMsg(languageSet.pwChangePass);
      setCheckPwTrigger(true);
    } else {
      setcheckPwMsg(languageSet.pwChangeFail);
      setCheckPwTrigger(false);
    }
  }, [pw, pwRe]);

  //엔터키 동작
  const onEnterPress = (e) => {
    if (e.key === "Enter") {
      onChangePwButton();
    }
  };

  //버튼
  const onChangePwButton = async () => {
    let res = await HTTPManager.ChangePw({
      accessToken: userInfo.accessToken,
      account: userInfo.account,
      password: pw,
      passwordRe: pwRe
    });

    if (res !== undefined && res.data !== undefined) {
      setPw("");
      setPwRe("");
      alert("Password Change Success");
      setShowModal(false);
    }
  };

  return (
    <>
      {showModal ? (
        <>
          <div className="mx-6 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none font-medium select-none">
            <div className="relative w-full my-6 mx-auto max-w-lg">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-center p-5 rounded-t">
                  <h2 className="text-base">{languageSet.pwChangeTitle}</h2>
                </div>
                {/*body*/}
                <div className="relative p-3 flex-auto">
                  <input
                    className="w-full placeholder:text-slate-400 block bg-white border border-slate-300 rounded-md py-2 pl-9 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1"
                    placeholder={languageSet.pwChangeHint1}
                    type="password"
                    name="password"
                    onChange={(e) => {
                      setPw(e.target.value);
                    }}
                    value={pw}
                  />
                  <input
                    className="w-full mt-2 placeholder:text-slate-400 block bg-white border border-slate-300 rounded-md py-2 pl-9 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 mb-2"
                    placeholder={languageSet.pwChangeHint2}
                    type="password"
                    name="passwordRe"
                    onChange={(e) => {
                      setPwRe(e.target.value);
                    }}
                    value={pwRe}
                    onKeyDown={onEnterPress}
                  />
                  <p
                    className={
                      checkPWTrigger ? "ml-2 text-green-600" : "ml-2 text-red-600"
                    }
                  >
                    {checkPwMsg}
                  </p>
                </div>

                <div className="flex w-full items-center justify-center rounded-b border-t">
                  <button
                    className="flex w-full justify-center py-3 rounded-bl-lg focus:outline-none"
                    type="button"
                    onClick={() => {
                      setPw("");
                      setPwRe("");
                      setShowModal(false);
                    }}
                  >
                    {languageSet.pwChangeClose}
                  </button>
                  <button
                    className="flex w-full bg-[black] text-[white] justify-center py-3 rounded-br-lg focus:outline-none"
                    type="button"
                    onClick={() => onChangePwButton()}
                  >
                    {languageSet.pwChangeOk}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
};

export default ChangePwDialog;
