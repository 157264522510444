import axios from "axios";
import { ERRORCODE } from "../features/Constant";
import { useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";
import { UserInfo } from "../recoil";
import { useNavigate } from "react-router-dom";

const PRODUCTION = process.env.NODE_ENV === "development" ? false : true;

const SERVER_URL = PRODUCTION
  ? "https://ktravelstore.com:1225"
  : "http://localhost:1225";

const instance = axios.create({
  baseURL: SERVER_URL,
  withCredentials: true,
});

export const useAxiosInterceptor = () => {
  const setMyInfo = useSetRecoilState(UserInfo);
  const navigate = useNavigate();
  const [needToLogin, setNeedToLogin] = useState(false);

  const errorHandler = (error) => {
    //let msg = error.data.message;
    let code = error.data.code;

    switch (code) {
      // refresh 토큰까지 만료된 경우 > 재로그인 요청
      case ERRORCODE.NEED_TO_LOGIN.CODE:
        setNeedToLogin(true);
        setMyInfo({});
        break;
      default:
        break;
    }

    return error;
  };

  const responseHandler = (response) => {
    return response;
  };

  const responseInterceptor = instance.interceptors.response.use(
    (response) => responseHandler(response),
    (error) => errorHandler(error.response)
  );

  useEffect(() => {
    // 로그인 만료 처리
    if (needToLogin) {
      setNeedToLogin(false);
      navigate("/", { replace: true });
    }

    return () => {
      instance.interceptors.response.eject(responseInterceptor);
    };
  }, [responseInterceptor, needToLogin]);
};

export async function getRequestItem(Props) {
  return await instance
    .get("/request/item/" + Props.id, {
      headers: {
        Authorization: `Bearer ${Props.accessToken}`,
      },
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
}

export async function getRequestList(Props) {
  let searchText = Props.searchText.replaceAll("&", "%26").replaceAll("+", "%2B");
  return await instance
    .get("/request/" + Props.id + "?searchText=" + searchText + "&selectedState=" + Props.selectedState, {
      headers: {
        Authorization: `Bearer ${Props.accessToken}`,
      },
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
}

export async function insertRequest(Props) {
  return await instance
    .post("/request", {
      title: Props.title,
      content: Props.content,
      period: Props.period,
      agent: Props.agent,
      companyInfo: Props.companyInfo,
      flight: Props.flight,
      itinerary: Props.itinerary,
      pax: Props.pax,
      hotelStarRating: Props.hotelStarRating,
      schedule: Props.schedule,
    }, {
      headers: {
        Authorization: `Bearer ${Props.accessToken}`,
      },
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
}

export async function updateRequestList(Props) {
  // 매개변수 전달 순서 주의
  return await instance
    .delete("/request", {
      headers: {
        Authorization: `Bearer ${Props.accessToken}`,
      },
      data: {
        list: Props.list,
        state: Props.state,
      },
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
}

export async function getRequestCommentList(Props) {
  return await instance
    .get("/request/comment/" + Props.id, {
      headers: {
        Authorization: `Bearer ${Props.accessToken}`,
      },
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
}

export async function insertRequestComment(Props) {
  const formData = new FormData();

  // 파일정보
  if (Props.file !== undefined) {
    formData.append("requestId", Props.requestId);
    formData.append("file", Props.file);
  }
  else {
    formData.append("requestId", Props.requestId);
    formData.append("content", Props.content);
  }

  return await instance
    .post("/request/comment", formData, {
      headers: {
        Authorization: `Bearer ${Props.accessToken}`,
      },
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
}


export async function removeRequestComment(Props) {
  return await instance
    .delete("/request/comment/" + Props.id, {
      headers: {
        Authorization: `Bearer ${Props.accessToken}`,
      },
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
}

export async function updateRequestState(Props) {
  return await instance
    .put("/request", {
      requestId: Props.requestId,
      state: Props.state,
    }, {
      headers: {
        Authorization: `Bearer ${Props.accessToken}`,
      },
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
}

export async function getRequestHotelPriceList(Props) {
  return await instance
    .get("/request/hotel/" + Props.id, {
      headers: {
        Authorization: `Bearer ${Props.accessToken}`,
      },
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
}

export async function insertRequestHotelPrice(Props) {
  return await instance
    .post("/request/hotel", {
      requestId: Props.requestId,
      list: Props.list
    }, {
      headers: {
        Authorization: `Bearer ${Props.accessToken}`,
      },
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
}

export async function removeRequestHotelPrice(Props) {
  return await instance
    .delete("/request/hotel/" + Props.id, {
      headers: {
        Authorization: `Bearer ${Props.accessToken}`,
      },
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
}
