import React, { useState, useEffect, forwardRef } from "react";
import { useRecoilValue } from "recoil";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ko } from 'date-fns/esm/locale';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faMagnifyingGlass, faSquareMinus, faCaretDown } from "@fortawesome/free-solid-svg-icons";
import * as Utils from '../../features/Utils';
import * as HTTPManager from '../../features/HTTPManager';
import { ROLES } from "../../features/Constant"
import { UserInfo } from "../../recoil";
import ColorButton from "../ColorButton"

export default function ReservationGuideDialog(Props) {
  const body = document.querySelector('body');
  //const scrollPosition = window.pageYOffset;

  const userInfo = useRecoilValue(UserInfo);

  const [guideList, setGuideList] = useState([]);
  const [guideFilterList, setGuideFilterList] = useState([]);

  const [selectedList, setSelectedList] = useState([]);

  const [searchText, setSearchText] = useState("");
  const [needUpdate, setNeedUpdate] = useState(false);  // false : 예약 true : 변경
  const [isDirty, setIsDirty] = useState(false);        // 수정여부 확인

  // 가이드 추가
  const [name, setName] = useState("");

  async function handleKeyDown(event) {
    if (event.key === 'Enter') {
      registGuide();
    }
  }

  async function registGuide() {
    if (name.length < 1) {
      alert("이름 확인!");
      return;
    }

    var res = await HTTPManager.RegistGuide({
      accessToken: userInfo.accessToken,
      name: name,
    });

    if (res.data.result) {
      alert("등록 완료");
    } else {
      alert("등록 실패");
    }

    setName("");
    fetchData();
  }

  async function fetchData() {
    let response = await HTTPManager.GetUserList({
      accessToken: userInfo.accessToken
    });

    let guideList = [];
    if (response !== undefined && response.data?.result !== undefined) {
      guideList = [...response.data.result.filter(item => (item.rolesId === ROLES.GUIDE || item.rolesId === ROLES.LEADERGUIDE))];
      guideList.sort((a, b) => a.name < b.name ? -1 : a.name > b.name ? 1 : 0);
      setGuideList(guideList);
    }

    // 데이터 초기화
    if (Props.reservationInfo !== undefined && Props.reservationInfo.length > 0) {
      setNeedUpdate(true);
      setSelectedList(Props.reservationInfo.map(item => {
        let guideInfo = guideList.find(guide => guide.id === item.guideId);
        return ({
          id: item.guideId,
          name: guideInfo?.name,
          nameEN: guideInfo?.nameEN,
          startDate: Utils.convertLocalToUTCDate(item.startDate),
          endDate: Utils.convertLocalToUTCDate(item.endDate),
          phoneNumber: guideInfo?.phoneNumber,
          toursGuideId: item.id,
        })
      }));
    }
  }

  const selectedListHandler = (value, idx, type) => {
    setIsDirty(true);

    const updatedState = selectedList;
    const updatedFormElement = {
      ...updatedState[idx]
    }

    switch (type) {
      case 'startDate':
        updatedFormElement.startDate = value;
        break;
      case 'endDate':
        updatedFormElement.endDate = value;
        break;
      default:
        break;
    }

    updatedState[idx] = updatedFormElement;

    setSelectedList([...updatedState]);
  }

  useEffect(() => {
    // 데이터 수신
    fetchData();

    // 모달 팝업 시 호출
    // 스크롤 최상단 위치 후 배경 스크롤 되지 않도록 고정
    body.style.overflow = 'hidden';

    // 모달 종료 시 호출
    return () => {
      body.style.removeProperty('overflow');
    };
  }, []);

  const DateCustomInput = forwardRef(({ value, onClick }, ref) => (
    <button onClick={onClick} ref={ref}>{value}</button>
  ));

  return (
    <div className="fixed h-full w-full left-0 top-0 text-sm bg-[#0007] overflow-auto z-[999] font-medium">
      <div className="fixed h-full w-full bg-white overflow-auto">
        <div className="flex flex-row p-2 items-center">
          <div className="flex flex-1 font-bold">
            <div className="mr-1">[가이드예약]</div>
            <div className="text-[coral]">{Props.tourInfo.id}번</div>
          </div>
          <div style={{
            opacity: !needUpdate ? 1 : (!isDirty ? 0.5 : 1),
            pointerEvents: !needUpdate ? "all" : !isDirty && "none"
          }}>
            <ColorButton title={needUpdate ? "변경" : "예약"} color="deepskyblue" handler={async () => {
              // 경고 표시
              // callback(3번째 param) 사용 중복 제거
              let answer = window.confirm((needUpdate ? "변경" : "예약") + "하시겠습니까?");
              if (answer) {
                if (!needUpdate && selectedList.length === 0) {
                  alert("가이드 확인");
                  return;
                }

                let res = await HTTPManager.RegistToursGuide({
                  accessToken: userInfo.accessToken,
                  tourId: Props.tourInfo.id,
                  guideState: selectedList,
                })

                if (res.data.result) {
                  alert("요청 완료");
                  Props.setShow(false);
                  Props.refresh();
                } else {
                  alert("요청 실패");
                }
              }
            }} />
          </div>
          <div className="w-fit">
            <ColorButton title="취소" color="crimson" handler={async () => {
              Props.setShow(false);
            }} />
          </div>
        </div>
        <div className="relative flex w-full flex-col justify-items-center pb-1 pr-1 mt-1 bg-[white]">

          <div className="flex flex-row">
            <div className="text-[20px] absolute text-slate-400 top-[13px] left-5 origin-center rotate-90">
              <FontAwesomeIcon icon={faMagnifyingGlass} />
            </div>
            <div title="전체목록"
              className={guideFilterList.length > 0 ?
                "text-[20px] absolute text-slate-400 top-[13px] right-5 cursor-pointer origin-center rotate-180"
                : "text-[20px] absolute text-slate-400 top-[13px] right-5 cursor-pointer"
              }
              onClick={() => {
                if (guideFilterList.length > 0) {
                  setGuideFilterList([]);
                } else {
                  setGuideFilterList(guideList);
                }
              }}>
              <FontAwesomeIcon icon={faCaretDown} />
            </div>

            <input
              className="placeholder:text-slate-400 block bg-white border border-slate-300 py-2 pl-10 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 mb-2"
              placeholder="가이드 검색"
              type="text"
              name="search"
              style={{ flex: 1, marginTop: 5, marginLeft: 5 }}
              onChange={(e) => {
                if (e.target.value.length > 0) {
                  let searchList = [];

                  searchList = guideList.filter(guide => guide.name?.includes(e.target.value));

                  setGuideFilterList(searchList);
                } else {
                  setGuideFilterList([]);
                }

                setSearchText(e.target.value);
              }}
              value={searchText}
            />
          </div>

          <div style={{ marginBottom: 10, display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', }}>
            <input
              className="flex w-full ml-1 placeholder:text-slate-400 bg-white border border-slate-300 rounded-md py-2 pl-3 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1"
              placeholder="이름"
              type="text"
              name="name"
              onChange={(e) => { setName(e.target.value) }}
              value={name}
              onKeyDown={handleKeyDown}
            />
          </div>

          <div className="w-full">
            <ColorButton title="가이드 추가" color="crimson" handler={registGuide} />
          </div>

          {
            guideFilterList.length > 0 &&
            <div className="overflow-auto absolute h-80 w-full bg-[#e6e6e6] border-b mt-11 z-10">
              {
                guideFilterList.map(guide => {
                  return (
                    <div key={guide.id} className="flex flex-row hover:bg-[coral] hover:text-[white] cursor-pointer text-left m-2 pl-1"
                      onClick={(e) => {
                        setIsDirty(true);
                        setSearchText("");
                        setGuideFilterList([]);

                        setSelectedList([...selectedList, {
                          id: guide.id,
                          name: guide.name,
                          nameEN: guide.nameEN,
                          startDate: Utils.convertLocalToUTCDate(Props.tourInfo.startDate),
                          endDate: Utils.convertLocalToUTCDate(Props.tourInfo.endDate),
                          phoneNumber: guide.phoneNumber,
                        }])
                      }}
                    >
                      <div className="mr-1">{guide.name}</div>
                      <div className="mr-1">/ {guide.nameEN} /</div>
                      {
                        guide.phoneNumber !== null && <div>{guide.phoneNumber}</div>
                      }
                    </div>
                  )
                })
              }
            </div>
          }
        </div>

        <div className="text-left flex flex-row pl-1.5 mt-4 mb-2 font-bold">
          투어정보
        </div>

        <div className="flex flex-row w-full items-center bg-[#f5f5f5] border-t-2 border-[#3f4b5b] text-[#555555]">
          <div className="flex h-full w-fit mr-2 p-2 pl-4 pr-4">일정</div>
          <div className="flex flex-col flex-1 text-left bg-[white] p-2 pl-4 pr-4">
            <div>{Props.tourInfo.startDate.slice(0, 10)} {Props.tourInfo.eta}</div>
            <div>{Props.tourInfo.endDate.slice(0, 10)} {Props.tourInfo.etd}</div>
          </div>
        </div>

        {
          Props.tourInfo.name !== undefined && <div className="flex flex-row w-full items-center bg-[#f5f5f5] border-t text-[#555555]">
            <div className="flex h-full w-fit mr-2 p-2 pl-4 pr-4">국가</div>
            <div className="flex flex-col flex-1 text-left bg-[white] p-2 pl-4 pr-4">
              <div>{Props.tourInfo.name}</div>
            </div>
          </div>
        }

        {
          Props.tourInfo.itinerary !== undefined && <div className="flex flex-row w-full items-center bg-[#f5f5f5] border-t text-[#555555]">
            <div className="flex h-full w-fit mr-2 p-2 pl-4 pr-4">코스</div>
            <div className="flex flex-col flex-1 text-left bg-[white] p-2 pl-4 pr-4">
              <div>{Props.tourInfo.itinerary === null ? "미정" : Props.tourInfo.itinerary}</div>
            </div>
          </div>
        }

        {
          Props.tourInfo.pax !== undefined && <div className="flex flex-row w-full items-center bg-[#f5f5f5] border-t border-b text-[#555555]">
            <div className="flex h-full w-fit mr-2 p-2 pl-4 pr-4">인원</div>
            <div className="flex flex-col flex-1 text-left bg-[white] p-2 pl-4 pr-4">
              <div>{Props.tourInfo.pax}</div>
            </div>
          </div>
        }

        <div className="text-left flex flex-row pl-1.5 mt-4 mb-2 font-bold">
          가이드정보
        </div>

        {
          selectedList?.map((guideInfo, index) => {
            return (
              <div
                key={index + "/" + guideInfo.id}
                className="relative flex flex-row text-left text-[13px] pl-2 pr-2 md:pr-4 w-full mb-2 items-center ">
                <div className="flex flex-row mr-2">
                  <div className="text-[18px] text-[grey] mr-2 cursor-pointer" onClick={() => {
                    const tempArray = [...selectedList];
                    tempArray.splice(index, 1);
                    setSelectedList(tempArray);
                    setIsDirty(true);
                  }}>
                    <FontAwesomeIcon icon={faSquareMinus} />
                  </div>

                  <div className="flex text-left mr-1">
                    {guideInfo.name}
                  </div>
                  {
                    guideInfo.phoneNumber !== null && <div className="flex text-left">
                      ({guideInfo.phoneNumber})
                    </div>
                  }
                </div>

                <div className="mr-2">시작</div>
                <div className="mr-2">
                  <DatePicker onChange={date => {
                    selectedListHandler(date, index, "startDate");
                  }}
                    selected={Utils.convertLocalToUTCDate(guideInfo.startDate)}
                    locale={ko}
                    dateFormat="yyyy/MM/dd"
                    customInput={<DateCustomInput />}
                    showPopperArrow={false}
                  />
                </div>
                <div className="mr-2">종료</div>
                <div>
                  <DatePicker onChange={date => {
                    selectedListHandler(date, index, "endDate");
                  }}
                    selected={Utils.convertLocalToUTCDate(guideInfo.endDate)}
                    locale={ko}
                    dateFormat="yyyy/MM/dd"
                    customInput={<DateCustomInput />}
                    showPopperArrow={false}
                  />
                </div>
              </div>
            )
          })
        }
      </div>

    </div>
  );
}


