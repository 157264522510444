import React, { useState, useEffect } from "react";
import CurrentSchedule from "./home/myschedules/CurrentSchedule";
import { useRecoilValue } from "recoil";
import { UserInfo } from "../recoil";
import NoticeBoard from "./home/NoticeBoard";
import StatisticBoard from "./home/StatisticBoard";
import StatisticGraph from "./home/StatisticGraph";
import { ROLES } from "../features/Constant";

const Home = () => {
  const today = new Date();
  const userInfo = useRecoilValue(UserInfo);

  useEffect(() => {

  }, [userInfo]);


  return (
    <div className="flex p-4 bg-[white] flex-1 md:mt-14 flex-col md:flex-col md:gap-2 md:w-full">
      {userInfo.rolesId === ROLES.ADMIN || userInfo.rolesId === ROLES.GOD && <StatisticBoard />}
      {userInfo.rolesId === ROLES.ADMIN || userInfo.rolesId === ROLES.GOD && <StatisticGraph />}
      <div className="flex flex-row md:flex-col">
        <NoticeBoard />
        <CurrentSchedule />
      </div>
    </div>
  );
};

export default Home;
