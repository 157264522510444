import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import * as HTTPManager from "../../features/HTTPManager";

const SignUp = () => {
  const navigate = useNavigate();

  const [account, SetAccount] = useState("");
  const [pw, setPw] = useState("");
  const [pwRe, setPwRe] = useState("");
  const [name, setName] = useState("");
  const [birthDate, setBirthDate] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [passportNumber, setPassportNumber] = useState("");

  const [checkPwMsg, setcheckPwMsg] = useState("비밀번호 확인 검증");
  const [checkPWTrigger, setCheckPwTrigger] = useState(false);

  const onSignUpButton = async () => {
    const result = await HTTPManager.SignUp({
      account: account,
      password: pw,
      passwordRe: pwRe,
      name: name,
      birthDate: birthDate,
      phoneNumber: phone,
      email: email,
      passportNumber: passportNumber,
    });

    if (result === undefined) {
      return;
    }

    if (result === true) {
      SetAccount("");
      setPw("");
      setPwRe("");
      setName("");
      setBirthDate("");
      setPhone("");
      setEmail("");
      setPassportNumber("");

      navigate(-1);
    } else {
      alert(result.response.data.message);
    }
  };

  useEffect(() => {
    if (pw.length < 1 || pwRe.length < 1) {
      setcheckPwMsg("비밀번호 확인 검증.");
      setCheckPwTrigger(false);
      return;
    }

    if (pw === pwRe) {
      setcheckPwMsg("비밀번호가 같습니다.");
      setCheckPwTrigger(true);
    } else {
      setcheckPwMsg("확인 비밀번호가 같지 않습니다.");
      setCheckPwTrigger(false);
    }
  }, [pw, pwRe]);

  return (
    <div className="p-4 bg-gray-100 flex-1 md:mt-14">
      <div className="my-4 ml-8">
        <h3 className="text-3xl">KTS 시스템 회원가입</h3>
      </div>
      <div className="flex flex-col justify-center">
        <input
          className="mb-4 w-80 md:w-full placeholder:italic placeholder:text-slate-400 bg-white border border-slate-300 rounded-md py-2 pl-9 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 text-xl"
          placeholder="* 아이디"
          type="text"
          name="userId"
          onChange={(e) => {
            SetAccount(e.target.value);
          }}
          value={account}
        />
        <input
          className="mb-2 w-80 md:w-full placeholder:italic placeholder:text-slate-400 bg-white border border-slate-300 rounded-md py-2 pl-9 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 text-xl"
          placeholder="* 비밀번호"
          type="password"
          name="password"
          onChange={(e) => {
            setPw(e.target.value);
          }}
          value={pw}
        />
        <p
          className={
            checkPWTrigger ? "ml-2 text-green-600" : "ml-2 text-red-600"
          }
        >
          {checkPwMsg}
        </p>
        <input
          className="mb-4 w-80 md:w-full placeholder:italic placeholder:text-slate-400 bg-white border border-slate-300 rounded-md py-2 pl-9 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 text-xl"
          placeholder="* 비밀번호 확인"
          type="password"
          name="passwordRe"
          onChange={(e) => {
            setPwRe(e.target.value);
          }}
          value={pwRe}
        />
        <input
          className="mb-4 w-80 md:w-full placeholder:italic placeholder:text-slate-400 bg-white border border-slate-300 rounded-md py-2 pl-9 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 text-xl"
          placeholder="* 이름"
          type="text"
          name="name"
          onChange={(e) => {
            setName(e.target.value);
          }}
          value={name}
        />
        <input
          className="mb-4 w-80 md:w-full placeholder:italic placeholder:text-slate-400 bg-white border border-slate-300 rounded-md py-2 pl-9 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 text-xl"
          placeholder="* 생년월일"
          type="date"
          name="birthDate"
          max="9999-12-31"
          onChange={(e) => {
            setBirthDate(e.target.value);
          }}
          value={birthDate}
        />
        <input
          className="mb-4 w-80 md:w-full placeholder:italic placeholder:text-slate-400 bg-white border border-slate-300 rounded-md py-2 pl-9 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 text-xl"
          placeholder="* 연락처 휴대전화 '-'없이 기입"
          type="text"
          name="phoneNumber"
          onChange={(e) => {
            setPhone(e.target.value);
          }}
          value={phone}
        />
        <input
          className="mb-4 w-80 md:w-full placeholder:italic placeholder:text-slate-400 bg-white border border-slate-300 rounded-md py-2 pl-9 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 text-xl"
          placeholder="* 이메일"
          type="email"
          name="email"
          onChange={(e) => {
            setEmail(e.target.value);
          }}
          value={email}
        />
        <input
          className="uppercase mb-4 w-80 md:w-full placeholder:italic placeholder:text-slate-400 bg-white border border-slate-300 rounded-md py-2 pl-9 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 text-xl"
          placeholder="여권번호"
          type="text"
          name="passportNumber"
          onChange={(e) => {
            setPassportNumber(e.target.value);
          }}
          value={passportNumber}
        />
        <button
          className="mb-4 w-80 md:w-full py-2 bg-blue-300 font-bold text-xl rounded"
          onClick={() => {
            onSignUpButton();
          }}
        >
          회원가입
        </button>
        <button
          className="mb-4 w-80 md:w-full py-2 bg-buttonCancel font-bold text-xl rounded"
          onClick={() => {
            navigate(-1);
          }}
        >
          취소
        </button>
      </div>
    </div>
  );
};

export default SignUp;
