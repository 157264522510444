import { useRecoilValue } from "recoil";
import { UserInfo } from "../../../recoil";
import React, { useState, useEffect } from "react";
import * as HTTPManager from '../../../features/HTTPManager';
import { ROLES, TOURINFO_COLOR_SET_IN } from "../../../features/Constant";

const CurrentSchedule = () => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const userInfo = useRecoilValue(UserInfo);
  const [tourList, setTourList] = useState([]);
  const [toursGuide, setToursGuide] = useState([]);
  const [userList, setUserList] = useState([]);

  // 함수
  function setColor(country) {
    switch (country) {
      case "인도네시아":
        return TOURINFO_COLOR_SET_IN.IN;
      case "필리핀":
        return TOURINFO_COLOR_SET_IN.PH;
      default:
        return TOURINFO_COLOR_SET_IN.CANCEL;
    }
  }

  function checkExistTour(startDate, endDate) {
    if (today >= new Date(startDate.replace(" ", "T")) && today <= new Date(endDate.replace(" ", "T")))
      return true;
    else
      return false;
  }

  async function fetchData() {
    let response = await HTTPManager.GetUserList({
      accessToken: userInfo.accessToken,
    });

    if (response !== undefined && response.data !== undefined) {
      setUserList(response.data.result);
    }

    response = await HTTPManager.GetTourReservation({
      accessToken: userInfo.accessToken,
    });

    if (response !== undefined && response.data !== undefined) {
      setToursGuide(response.data.toursGuide);
    }

    response = await HTTPManager.GetTourList({
      accessToken: userInfo.accessToken,
    });

    if (response !== undefined) {
      setTourList(response.data.result);
    }
  }

  useEffect(() => {
    if (userInfo.rolesId === ROLES.ADMIN || userInfo.rolesId === ROLES.GOD) {
      // 관리자만
      if (userInfo.accessToken !== undefined)
        fetchData();
      else
        setTourList([]);
    } else {
      setTourList([]);
    }
  }, [userInfo]);

  return (
    <div className="w-[50%] md:w-full"
      style={{
        fontFamily: "Noto Sans KR"
      }}
    >
      {
        userInfo.rolesId === ROLES.ADMIN || userInfo.rolesId === ROLES.GOD &&
        <div className="w-full text-center font-bold mb-2">{today.toLocaleDateString()} 진행 중인 행사목록({tourList?.filter(tourInfo => checkExistTour(tourInfo.startDate, tourInfo.endDate) && tourInfo.cancel === 0)?.length})</div>
      }
      {
        tourList?.filter(tourInfo => checkExistTour(tourInfo.startDate, tourInfo.endDate)).length > 0 &&
        tourList?.filter(tourInfo => checkExistTour(tourInfo.startDate, tourInfo.endDate) && tourInfo.cancel === 0).map(tourInfo => {
          let colorSet = tourInfo.cancel === 1 ? setColor("cancel") : setColor(tourInfo.name);
          let guideList = toursGuide?.filter(item => item.tourId === tourInfo.id).map(item => {
            return userList?.find(user => user.id === item.guideId)?.name;
          });

          return (
            <div key={tourInfo.id}
              className="font-medium"
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                borderWidth: 1,
                borderRadius: 5,
                borderStyle: "solid",
                borderColor: colorSet.horizontalLine,
                background: colorSet.background,
                margin: 5,
                padding: 10,
                fontSize: 13,
              }}>
              <div style={{ marginRight: 5 }} onClick={() => { }}>{tourInfo.startDate.slice(2, 10).replaceAll("-", ".")} ~ {tourInfo.endDate.slice(2, 10).replaceAll("-", ".")}</div>
              <div style={{ marginRight: 5 }}>{tourInfo.name}</div>
              <div style={{ marginRight: 5, }}>{"(" + tourInfo.pax + ")"}</div>
              <div style={{ marginRight: 5, }}>{guideList?.toString()}</div>
            </div>
          )
        })
      }
    </div>);
};

export default CurrentSchedule;
