import React, { useState, } from "react";
import * as HTTPManager from "../../features/HTTPManager";
import { UserInfo, LanguageSet } from "../../recoil";
import { useRecoilValue } from "recoil";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark, } from "@fortawesome/free-solid-svg-icons";
import ColorButton from "../ColorButton"
import DragDropFile from "../DragDropFile";

export default function UploadDialog(Props) {
  const languageSet = useRecoilValue(LanguageSet);
  const userInfo = useRecoilValue(UserInfo);

  const [fileInfo, setFileInfo] = useState("");
  let waitForResponse = false;

  return (
    <div className="fixed h-full w-full top-0 left-60 md:left-0 md:top-10 text-sm bg-[#0007] overflow-auto z-[100] pt-44 md:pt-10 pl-20 pr-80 md:px-5">
      <div className="w-full border-t border-l border-r-2 border-b-2 rounded-md bg-[white] p-2 overflow-auto">
        <div className="flex justify-between items-center mb-2">
          <div>{Props.title}</div>
          <span
            className={"cursor-pointer py-1 rounded text-[20px]"}
            onClick={() => {
              Props.setShow(false);
            }}>
            <FontAwesomeIcon icon={faCircleXmark} style={{ color: "black" }} />
          </span>
        </div>
        <div className="flex flex-col">
          <div className="mb-4">
            <DragDropFile setFile={setFileInfo} />
          </div>
          <span>
            <ColorButton
              title={languageSet.roomingAddListDialogConfirmButton}
              color="slateblue"
              handler={async () => {
                if (waitForResponse === false) {
                  waitForResponse = true;

                  let uploadFileList = [{
                    type: Props.type,
                    file: fileInfo,
                  }];

                  let res = await HTTPManager.ModifyFileTourInfo({
                    accessToken: userInfo.accessToken,
                    tourId: Props.data.id,
                    fileList: uploadFileList,
                  });

                  if (res?.data?.result) {
                    Props.setShow(false);
                    Props.refresh();
                  } else {
                    alert("Upload Failed");
                    Props.setShow(false);
                  }
                  waitForResponse = false;
                } else {
                  alert('Wait For Upload!!');
                }
              }}
            />
          </span>
        </div>
      </div>
    </div >
  )
}