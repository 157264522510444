import React, { useEffect, useState } from "react";
import * as Utils from '../../features/Utils';
import { ROLES } from "../../features/Constant";
import { useNavigate } from "react-router-dom";
import { UserInfo, LanguageSet } from "../../recoil";
import { useRecoilValue } from "recoil";
import * as HTTPHanmaruManager from "../../features/HTTPHanmaruManager";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import FinishItem from "./FinishItem";
import CalendarMovingBox from "./CalendarMovingBox";

export default function FinishPage() {
  const { height, width } = useWindowDimensions();

  const navigate = useNavigate();
  const languageSet = useRecoilValue(LanguageSet);
  const userInfo = useRecoilValue(UserInfo);

  const [finishList, setFinishList] = useState([]);
  const [finishFilterList, setFinishFilterList] = useState([]);

  const [searchText, setSearchText] = useState("");

  const [selectedYear, setSelectedYear] = useState(0);
  const [selectedMonth, setSelectedMonth] = useState(0);

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const [showInputDialog, setShowInputDialog] = useState(false);
  const [finishTime, setFinishTime] = useState("");
  const [lunch, setLunch] = useState("");
  const [lunchNormal, setLunchNormal] = useState("");
  const [dinner, setDinner] = useState("");
  const [dinnerNormal, setDinnerNormal] = useState("");

  // 데이터 초기화
  async function initalize() {
    setSelectedYear(today.getFullYear());
    setSelectedMonth(today.getMonth() + 1);
  }

  const redTag = (msg) => {
    return <span className="text-[15px] inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10 mr-[5px]">{msg}</span>
  }

  const yellowTag = (msg) => {
    return <span className="text-[15px] inline-flex items-center rounded-md bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-800 ring-1 ring-inset ring-yellow-600/20 mr-[5px]">{msg}</span>
  }

  const greenTag = (msg) => {
    return <span className="text-[15px] inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20 mr-[5px]">{msg}</span>
  }

  const blueTag = (msg) => {
    return <span className="text-[15px] inline-flex items-center rounded-md bg-blue-50 px-2 py-1 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10 mr-[5px]">{msg}</span>
  }

  async function fetchData() {
    let res = await HTTPHanmaruManager.GetFinishList({
      accessToken: userInfo.accessToken
    });

    if (res !== undefined && res.data !== undefined) {
      let list = res.data.result;
      list.sort((a, b) => {
        if (new Date(a.finishTime.replace(" ", "T")) < new Date(b.finishTime.replace(" ", "T")))
          return -1;
        if (new Date(a.finishTime.replace(" ", "T")) > new Date(b.finishTime.replace(" ", "T")))
          return 1;
      });

      setFinishList(list);
      setFinishFilterList(list);
    }
  }

  function searchList(findText) {
    if (findText.length === 0) {
      setFinishFilterList(finishList);
      return;
    }

    let findList = finishList.filter(item => (item.id === Number(findText) || item.lunchTour === Number(findText) || item.lunchNormal === Number(findText) || item.dinnerTour === Number(findText) || item.dinnerNormal === Number(findText) || item.finishTime.includes(findText)));
    setFinishFilterList(findList);
  }

  useEffect(() => {
    Utils.allowByPosition(ROLES.ADMIN, userInfo.rolesId)
      .then(async (res) => {
        if (!res) {
          alert("접근 권한이 없습니다.");
          navigate("/", { replace: true });
        }
        else {
          fetchData();
          await initalize();
        }
      })
      .catch((err) => {
        alert(err.message);
      });
  }, []);

  return (
    <div className="w-full h-full flex flex-col bg-[white] mt-0 md:mt-14 px-[50px] md:px-[5px]">
      <div className="text-start mt-[50px] text-[#9299A1]">한마루</div>
      <div className="text-start mt-[50px] text-[#333A40] font-bold text-[30px]">마감</div>

      <div className="w-full h-[1px] bg-[#DCDCDC] my-[25px]" />

      <button className="bg-[black] text-[white] rounded px-2 py-1 font-bold text-[14px]" onClick={async () => {
        setShowInputDialog(true);
      }}>
        마감입력
      </button>
      <CalendarMovingBox year={selectedYear} setYear={setSelectedYear} month={selectedMonth} setMonth={setSelectedMonth} />
      <div className="mb-5">
        <div className="w-full flex-row gap-8 md:gap-2 scrollRemoveBox flex cursor-pointer p-1">
          <div className="md:flex-none flex flex-col gap-2 rounded-lg shadow-lg px-5 py-6 w-1/3 md:w-full mb-5" onClick={() => {

          }}>
            <div className="flex flex-row gap-3 font-bold">
              <span className={"cursor-pointer"}>
                <FontAwesomeIcon icon={faUser} style={{ color: "#1D3C77" }} />
              </span>
              <div>총인원</div>
            </div>
            <div className="flex w-full justify-end text-[25px] font-bold text-green-700">
              {
                Utils.formatNumber(finishFilterList.filter(item => new Date(item.finishTime.replace(" ", "T")) <= new Date(selectedYear, selectedMonth - 1, 25)
                  && new Date(item.finishTime.replace(" ", "T")) >= new Date(selectedYear, selectedMonth - 2, 25)).reduce((a, b) => a = a + Number(b.lunchTour + b.lunchNormal + b.dinnerTour + b.dinnerNormal), 0))
              }
            </div>
          </div>

          <div className="md:flex-none flex flex-col gap-2 rounded-lg shadow-lg px-5 py-6 w-1/3 md:w-full mb-5" onClick={() => {

          }}>
            <div className="flex flex-row gap-3 font-bold">
              <span className={"cursor-pointer"}>
                <FontAwesomeIcon icon={faUser} style={{ color: "#1D3C77" }} />
              </span>
              <div>점심</div>
            </div>
            <div className="flex w-full justify-end text-[25px] font-bold text-yellow-400">
              {
                Utils.formatNumber(finishFilterList.filter(item => new Date(item.finishTime.replace(" ", "T")) <= new Date(selectedYear, selectedMonth - 1, 25)
                  && new Date(item.finishTime.replace(" ", "T")) >= new Date(selectedYear, selectedMonth - 2, 25)).reduce((a, b) => a = a + Number(b.lunchTour + b.lunchNormal), 0))
              }
            </div>
          </div>

          <div className="md:flex-none flex flex-col gap-2 rounded-lg shadow-lg px-5 py-6 w-1/3 md:w-full mb-5" onClick={() => {

          }}>
            <div className="flex flex-row gap-3 font-bold">
              <span className={"cursor-pointer"}>
                <FontAwesomeIcon icon={faUser} style={{ color: "#1D3C77" }} />
              </span>
              <div>저녁</div>
            </div>
            <div className="flex w-full justify-end text-[25px] font-bold text-blue-700">
              {
                Utils.formatNumber(finishFilterList.filter(item => new Date(item.finishTime.replace(" ", "T")) <= new Date(selectedYear, selectedMonth - 1, 25)
                  && new Date(item.finishTime.replace(" ", "T")) >= new Date(selectedYear, selectedMonth - 2, 25)).reduce((a, b) => a = a + Number(b.dinnerTour + b.dinnerNormal), 0))
              }
            </div>
          </div>
        </div>
      </div>

      <div className="scrollRemoveBox flex flex-row md:flex-col gap-2 mb-5 items-center justify-center">
        <div className="flex flex-row items-center gap-1">
          <div className="h-4 w-4 rounded-full bg-[#B7F2FF]" />
          <div>~100</div>
          <div className="h-4 w-4 rounded-full bg-[#83DFE9]" />
          <div>~200</div>
          <div className="h-4 w-4 rounded-full bg-[#5ED1DF]" />
          <div>~300</div>
          <div className="h-4 w-4 rounded-full bg-[#33C7D8]" />
          <div>~400</div>
        </div>
        <div className="flex flex-row items-center gap-1">
          <div className="h-4 w-4 rounded-full bg-[#1FBDD2]" />
          <div>~500</div>
          <div className="h-4 w-4 rounded-full bg-[#1DADC0]" />
          <div>~600</div>
          <div className="h-4 w-4 rounded-full bg-[#1A98A6]" />
          <div>~700</div>
          <div className="h-4 w-4 rounded-full bg-[#18848E]" />
          <div>~800</div>
        </div>
      </div>


      <div className="w-full h-[1px] bg-[#DCDCDC] my-[25px]" />

      <div className="grid grid-cols-7 md:grid-cols-4 items-center justify-center pb-5">
        {
          finishFilterList.filter(item => new Date(item.finishTime.replace(" ", "T")) <= new Date(selectedYear, selectedMonth - 1, 25)
            && new Date(item.finishTime.replace(" ", "T")) >= new Date(selectedYear, selectedMonth - 2, 25)).map(item => {
              return (
                <FinishItem data={item} key={item.id} />
              );
            })
        }
      </div>

      {
        showInputDialog && (
          <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-[100] outline-none focus:outline-none font-medium">
              <div className="relative w-full my-6 mx-auto max-w-3xl">
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col bg-white outline-none focus:outline-none">
                  <div className="flex items-start justify-center mt-5 rounded-t">
                    <h2 className="text-base font-bold">마감정보</h2>
                  </div>
                  <div className="flex flex-col relative p-3 flex-auto gap-1">
                    <input
                      type="datetime-local"
                      className="w-full placeholder:text-slate-400 block bg-white border border-slate-300 rounded-md py-2 px-3 shadow-sm focus:outline-none"
                      placeholder={"마감시간"}
                      onChange={(e) => {
                        setFinishTime(e.target.value);
                      }}
                    />
                    <input
                      type="number"
                      className="w-full placeholder:text-slate-400 block bg-white border border-slate-300 rounded-md py-2 px-3 shadow-sm focus:outline-none"
                      placeholder={"점심"}
                      onChange={(e) => {
                        setLunch(e.target.value);
                      }}
                    />
                    <input
                      type="number"
                      className="w-full placeholder:text-slate-400 block bg-white border border-slate-300 rounded-md py-2 px-3 shadow-sm focus:outline-none"
                      placeholder={"점심(일반손님)"}
                      onChange={(e) => {
                        setLunchNormal(e.target.value);
                      }}
                    />
                    <input
                      type="number"
                      className="w-full placeholder:text-slate-400 block bg-white border border-slate-300 rounded-md py-2 px-3 shadow-sm focus:outline-none"
                      placeholder={"저녁"}
                      onChange={(e) => {
                        setDinner(e.target.value);
                      }}
                    />
                    <input
                      type="number"
                      className="w-full placeholder:text-slate-400 block bg-white border border-slate-300 rounded-md py-2 px-3 shadow-sm focus:outline-none"
                      placeholder={"저녁(일반손님)"}
                      onChange={(e) => {
                        setDinnerNormal(e.target.value);
                      }}
                    />
                  </div>

                  <div className="flex w-full items-center justify-center rounded-b border-t">
                    <button
                      className="flex w-full justify-center py-3 rounded-bl-lg focus:outline-none"
                      type="button"
                      onClick={() => {
                        setShowInputDialog(false);
                      }}
                    >
                      취소
                    </button>
                    <button
                      className="flex w-full bg-[black] text-[white] justify-center py-3 rounded-br-lg focus:outline-none"
                      type="button"
                      onClick={async () => {
                        let res = await HTTPHanmaruManager.InsertFinsishList({
                          accessToken: userInfo.accessToken,
                          param: {
                            finishTime: finishTime,
                            lunch: lunch,
                            lunchNormal: lunchNormal,
                            dinner: dinner,
                            dinnerNormal: dinnerNormal,
                          }
                        });

                        if (res.data !== undefined) {
                          if (res.data.result === true) {
                            await fetchData();
                            setShowInputDialog(false);
                            setFinishTime("");
                            setLunch("");
                            setLunchNormal("");
                            setDinner("");
                            setDinnerNormal("");
                          }
                        }
                      }}
                    >
                      기록
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-[99] bg-black"></div>
          </>
        )
      }
    </div>
  );
}