import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";
import LocaleKR from "../features/LocaleKR";
import LocaleEN from "../features/LocaleEN";

const locale = navigator.language;

const { persistAtom } = recoilPersist();

const today = new Date();

export const UserInfo = atom({
  key: "UserInfo",
  default: {},
  effects_UNSTABLE: [persistAtom],
});

export const ScheduleListScroll = atom({
  key: "ScheduleListScroll",
  default: {},
  effects_UNSTABLE: [persistAtom],
})

export const PageLocale = atom({
  key: "Locale",
  default: ((locale === "ko-KR") || (locale === "ko")) ? "ko" : "en",
  effects_UNSTABLE: [persistAtom],
})

export const LanguageSet = atom({
  key: "LanguageSet",
  default: ((locale === "ko-KR") || (locale === "ko")) ? LocaleKR : LocaleEN,
  effects_UNSTABLE: [persistAtom],
})

export const ShowInfo = atom({
  key: "ShowInfo",
  default: false,
  effects_UNSTABLE: [persistAtom],
})

export const RequestPage = atom({
  key: "RequestPage",
  default: 0,
  effects_UNSTABLE: [persistAtom],
});