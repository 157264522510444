import React, { useState, useEffect, useMemo } from "react";
import { useRecoilValue } from "recoil";
import { UserInfo } from "../../recoil";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import * as Utils from '../../features/Utils';
import * as HTTPManager from '../../features/HTTPManager';
import { ROLES } from "../../features/Constant";
import LineChart from "./LineChart";

const StatisticGraph = () => {
  const [today, setToday] = useState(new Date());
  today.setHours(0, 0, 0, 0);

  const [indonesiaData, setIndonesiaData] = useState([]);
  const [philippineData, setPilippineData] = useState([]);

  const userInfo = useRecoilValue(UserInfo);

  const [tourList, setTourList] = useState([]);
  const data = useMemo(() => tourList, [tourList]);

  function initGraphData(tourList) {
    let idDataList = [];
    let phDataList = [];

    // 오늘 기준 전 후 3일
    for (let i = -3; i < 4; i++) {
      let tmpData = new Date(today);
      let date = tmpData.setDate(tmpData.getDate() + i);
      let newDate = new Date(date);

      // 인도네시아 진행중인 행사
      let idDoingList = tourList?.filter(tour => {
        if (tour.countryId === 2 && tour.cancel !== 1) {
          let startDate = new Date(tour.startDate.replace(" ", "T"));
          let endDate = new Date(tour.endDate.replace(" ", "T"));
          if (startDate <= newDate && newDate <= endDate)
            return 1;
          else
            return 0;
        }
        else
          return 0;
      });

      // 인도네시아 진행중인 행사
      let phDoingList = tourList?.filter(tour => {
        if (tour.countryId === 3 && tour.cancel !== 1) {
          let startDate = new Date(tour.startDate.replace(" ", "T"));
          let endDate = new Date(tour.endDate.replace(" ", "T"));
          if (startDate <= newDate && newDate <= endDate)
            return 1;
          else
            return 0;
        }
        else
          return 0;
      });

      let dateStr = newDate.getDate().toString().padStart(2, 0) + "(" + getDayStr(newDate.getDay()) + ")";
      //let dateStr = (newDate.getMonth() + 1).toString().padStart(2, 0) + "-" + newDate.getDate().toString().padStart(2, 0) + "(" + getDayStr(newDate.getDay()) + ")";

      idDataList.push({
        date: dateStr,
        count: idDoingList?.length,
      });

      phDataList.push({
        date: dateStr,
        count: phDoingList?.length,
      })
    }

    setIndonesiaData({
      data: [{
        id: "인도네시아",
        color: "#4171D6",
        data: idDataList.map(item => {
          return {
            x: item.date,
            y: item.count,
          }
        }),
      }]
    });

    setPilippineData({
      data: [{
        id: "필리핀",
        color: "#D31A45",
        data: phDataList.map(item => {
          return {
            x: item.date,
            y: item.count,
          }
        }),
      }]
    });
  }

  async function fetchData() {
    let response = await HTTPManager.GetTourList({
      accessToken: userInfo.accessToken,
    });

    let tourList = [];

    if (response !== undefined) {
      tourList = response.data.result;
      setTourList(tourList);
    }

    initGraphData(tourList);
  }

  useEffect(() => {
    if (userInfo.rolesId === ROLES.ADMIN || userInfo.rolesId === ROLES.GOD) {
      // 관리자만
      if (userInfo.accessToken !== undefined) {
        fetchData();

      }
      else {
        setTourList([]);
        setIndonesiaData([]);
      }
    } else {
      setTourList([]);
      setIndonesiaData([])
    }
  }, [userInfo]);

  function getDayStr(day) {
    var result = "";
    switch (day) {
      case 0:
        result = "일";
        break;
      case 1:
        result = "월";
        break;
      case 2:
        result = "화";
        break;
      case 3:
        result = "수";
        break;
      case 4:
        result = "목";
        break;
      case 5:
        result = "금";
        break;
      case 6:
        result = "토";
        break;
    }
    return result;
  }

  return (
    <div className="flex bg-[white] flex-col gap-2">
      <div className="w-full flex flex-row md:flex-col gap-2 mt-[10px]">
        <div className="flex flex-col gap-2 rounded-lg shadow-lg px-5 py-6 w-1/2 md:w-full h-[400px] mb-5">
          <div className="flex flex-row gap-3 font-bold mb-5  items-center">
            <span className={"cursor-pointer bg-[lightgray] p-[1px]"}>
              <img src="https://i.namu.wiki/i/cxa8bmV7lRjs9McBqhbcj0jbDqCXSuugoJJvVHKKFN4hdjmENF3BRe4Df5i5G4GC3FqgXSOBC3l4y7Ty3YAR9vBWIqBMJ1fF26iXNI0LhNNP9c1yRWVhqpWGeB-KyE3So6khCcqglUpLseQIJQsI4g.svg"
                width={45} />
            </span>
            <div>인도네시아</div>
            <div>{today.getMonth() + 1}월</div>
          </div>
          <LineChart data={indonesiaData?.data} />
        </div>
        <div className="flex flex-col gap-2 rounded-lg shadow-lg px-5 py-6 w-1/2 md:w-full h-[400px] mb-5">
          <div className="flex flex-row gap-3 font-bold mb-5  items-center">
            <span className={"cursor-pointer bg-[lightgray] p-[1px]"}>
              <img src="https://i.namu.wiki/i/Qi5Cepm_qbprCO5f-lZgXq4KMksU4Rcbd2E6PYboqisMMksLzBkvNOS-uWNflVvcJQSX_QKrbw58CFz-nTLOB-OxYgX997cc-b4spaeX-yE8z1YtJD1chtpItod_ubjAqqboMZAAraxd1nf9vCIFyQ.svg"
                width={45} />
            </span>
            <div>필리핀</div>
            <div>{today.getMonth() + 1}월</div>
          </div>
          <LineChart data={philippineData?.data} />
        </div>
      </div>
      <div className="flex flex-row gap-1 w-full justify-center mb-20">
        <div className="cursor-pointer select-none bg-[#F6F7F8] text-[#93989E] rounded-lg px-3 py-1" onClick={() => {
          today.setDate(today.getDate() - 1);
          initGraphData(tourList);
        }}>{`<`}</div>
        <div className="cursor-pointer select-none bg-[#F6F7F8] text-[#93989E] rounded-lg px-3 py-1" onClick={() => {
          let resetToday = new Date();
          resetToday.setHours(0, 0, 0, 0);
          today.setTime(resetToday.getTime());
          initGraphData(tourList);
        }}>오늘</div>
        <div className="cursor-pointer select-none bg-[#F6F7F8] text-[#93989E] rounded-lg px-3 py-1" onClick={() => {
          today.setDate(today.getDate() + 1);
          initGraphData(tourList);
        }}>{`>`}</div>
      </div>
    </div>
  );
};

export default StatisticGraph;
