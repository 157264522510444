import React, { useEffect, useState } from "react";
import { useTable, useGlobalFilter, useSortBy } from "react-table";
import { UserInfo } from "../../recoil";
import { useRecoilValue } from "recoil";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import * as HTTPManager from '../../features/HTTPManager';
import ColorButton from "../ColorButton"

export default function ReservationBusListTable({ columns, data, }) {
  useEffect(() => {
    fetchData();
  }, [])

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
    setGlobalFilter,
  } = useTable({ columns, data }, useGlobalFilter, useSortBy);

  const userInfo = useRecoilValue(UserInfo);

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const [memoBusList, setMemoBusList] = useState([]);

  const [showModifyDialog, setShowModifyDialog] = useState(false);
  const [selectedTourId, setSelectedTourId] = useState(0);
  const [inputValue, setInputValue] = useState("");

  async function fetchData() {
    let response = await HTTPManager.GetMemoBus({
      accessToken: userInfo.accessToken
    });

    if (response !== undefined && response.data?.result?.length > 0) {
      let sortList = response.data.result.sort((a, b) => {
        if (new Date(a.createdAt) > new Date(b.createdAt)) {
          return -1;
        } else {
          return 1;
        }
      });

      setMemoBusList(sortList);
    }
  }

  async function handleKeyDown(event) {
    if (event.key === 'Enter') {
      await changeValue();
    } else if (event.keyCode === 27) {
      setShowModifyDialog(false);
    }
  }

  async function changeValue() {
    if (inputValue.length === 0) {
      alert("빈칸확인");
      return;
    }

    let result = await HTTPManager.InsertMemoBus({
      accessToken: userInfo.accessToken,
      param: {
        contents: inputValue,
        tourId: selectedTourId
      }
    });

    if (result?.data?.result) {
      setInputValue("");
      setShowModifyDialog(false);
      fetchData();
    }
  }

  return (
    <>
      <table {...getTableProps()} style={{ borderCollapse: "collapse" }}>
        <thead>
          {
            headerGroups.map((headerGroup) => {
              return (
                <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th key={column.id} {...column.getHeaderProps(column.getSortByToggleProps())} style={{
                      minWidth: column.minWidth,
                      width: column.width,
                      border: "1px solid rgb(0, 0, 0)",
                      fontSize: 13,
                      position: "sticky",
                      top: 0,
                      background: "#ccc",
                    }}>
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              )
            })
          }
        </thead>
        <tbody {...getTableBodyProps()}>
          {
            rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} key={row.index}>
                  {row.cells.map((cell) => {
                    return (
                      <td key={cell.row.id + " " + cell.column.index}
                        className="normal-case"
                        {...cell.getCellProps({
                          style: cell.column.style,
                        })}
                        onClick={async (e) => {
                          // 메모 열이벤트
                          if (cell.column.index === 14) {
                            setSelectedTourId(row.cells[0].value);
                            setShowModifyDialog(true);
                          }
                        }}
                      >
                        {
                          cell.column.index !== 14 ? cell.render("Cell") : memoBusList?.find(item => item.tourId === row.cells[0].value)?.contents
                        }
                      </td>
                    )
                  })}
                </tr>
              );
            })
          }
        </tbody>
      </table>
      {
        showModifyDialog &&
        <div className="left-20 md:left-0 top-20" style={{ position: "fixed", display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: 500, }}>
          <div style={{ display: "flex", flexDirection: "column", width: "80%", background: "#000a", padding: 20, borderRadius: 10 }}>
            <div style={{ display: "flex", width: "100%", justifyContent: "flex-end" }}>
              <div
                style={{
                  width: 25,
                  height: 25,
                  fontSize: 15,
                  color: "white",
                  cursor: "pointer",
                  textAlign: "center"
                }}
                onClick={() => { setShowModifyDialog(false) }}
              >
                <FontAwesomeIcon icon={faXmark} />
              </div>
            </div>
            <div style={{ marginBottom: 10, display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', }}>
              <div className="font-bold text-[20px] text-white mb-3">{selectedTourId}번 메모</div>
              <input
                className="placeholder:text-slate-400 block bg-white border border-slate-300 rounded-md py-2 pl-3 pr-3 shadow-sm focus:outline-none "
                placeholder={""}
                type="text"
                name="input"
                style={{ width: "100%", marginBottom: 10, }}
                onChange={(e) => setInputValue(e.target.value)}
                value={inputValue}
                onKeyDown={(e) => handleKeyDown(e)}
              />
              <ColorButton title={"확인"} color={"black"} width={"100%"} handler={async () => {
                changeValue();
              }} />
            </div>
          </div>
        </div>
      }
    </>
  );
}