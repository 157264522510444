import React, { useState, } from "react";
import * as Utils from '../../features/Utils';
import { LanguageSet, ShowInfo } from "../../recoil";
import { useRecoilValue } from "recoil";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudArrowUp } from "@fortawesome/free-solid-svg-icons";
import { Tooltip } from "react-tooltip";

export default function FinishItem(Props) {
  const languageSet = useRecoilValue(LanguageSet);

  const data = Props.data;

  // 삭제 redTag
  const redTag = (msg) => {
    return <span className="inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10 mr-[5px]">{msg}</span>
  }

  // 파일 yellowTag
  const yellowTag = (msg) => {
    return <span className="inline-flex items-center rounded-md bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-800 ring-1 ring-inset ring-yellow-600/20 mr-[5px]">{msg}</span>
  }

  // 생성 greenTag 
  const greenTag = (msg) => {
    return <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20 mr-[5px]">{msg}</span>
  }

  // 수정 blueTag
  const blueTag = (msg) => {
    return <span className="inline-flex items-center rounded-md bg-blue-50 px-2 py-1 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10 mr-[5px]">{msg}</span>
  }

  const date = data.finishTime.slice(5, 16);
  const lunchTour = data.lunchTour;
  const lunchNormal = data.lunchNormal;
  const dinnerTour = data.dinnerTour;
  const dinnerNormal = data.dinnerNormal;
  const totalCount = lunchTour + lunchNormal + dinnerTour + dinnerNormal;

  function getBgColor(count) {
    if (count < 100) {
      return "bg-[#B7F2FF]";
    } else if (count < 200) {
      return "bg-[#83DFE9]";
    } else if (count < 300) {
      return "bg-[#5ED1DF]";
    } else if (count < 400) {
      return "bg-[#33C7D8]";
    } else if (count < 500) {
      return "bg-[#1FBDD2]";
    } else if (count < 600) {
      return "bg-[#1DADC0]";
    } else if (count < 700) {
      return "bg-[#1A98A6]";
    } else if (count < 800) {
      return "bg-[#18848E]";
    } else {
      return "bg-[gray]";
    }
  }

  return (
    <div className={`tooltip${data.id} flex flex-col w-full h-[150px] md:h-[100px] normal-case border border-1 ${getBgColor(totalCount)} justify-center items-center`}>
      <div className="font-bold text-[#00000077]">{totalCount}명</div>
      <span
        className="flex-none items-center rounded-md px-2 py-1 ml-2 text-xs font-medium cursor-pointer select-none"
        style={{
          color: "#FFFFFF",
        }}>
        <Tooltip anchorSelect={`.tooltip${data.id}`} place="top">
          마감 {date} 점심 {lunchTour + lunchNormal} 저녁 {dinnerNormal + dinnerTour}
        </Tooltip>
      </span>
    </div>
  )
}