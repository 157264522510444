const Locale = {
  // TopBar
  signin: "로그인",
  signout: "로그아웃",
  changePw: "비밀번호 변경",
  changeENName: "영어이름 변경",

  // SideBar
  menuInfo: "정보",
  menuInfoSchedule: "일정",
  menuInfoGuideSettlement: "정산",
  menuInfoGuideAttraction: "관광지",

  menuManagement: "정산",
  menuManagementIncome: "매출",
  menuManagementTotalSettlement: "목록",
  menuManagementSettlement: "가이드 전도금 정산",
  menuManagementProfitAndLoss: "손익",
  menuManagementInvoice: "인보이스",
  menuManagementAgencyAccount: "에이전시별 손익",

  menuHanmaru: "한마루",
  menuHanmaruAccount: "매입 매출",
  menuHanmaruFinish: "마감",

  menuTour: "투어",
  menuTourCalendar: "달력",
  menuTourList: "목록",
  menuTourListEx: "목록(표)",
  menuTourCreate: "투어 생성",
  menuTourRooming: "루밍",
  menuTourRequest: "요청 게시판",
  menuTourFund: "지원금",
  menuTourStatistics: "통계",

  menuUser: "사용자",
  menuGuide: "가이드",
  menuManager: "관리자",
  menuGuideRegistNList: "등록 및 목록",

  menuBus: "버스",
  menuHotel: "호텔",
  menuBusRegistNList: "등록 및 목록",
  menuBusCalendar: "달력",
  menuHotelRegistNList: "등록 및 목록",

  menuAttraction: "관광지",
  menuAttractionList: "등록 및 목록",

  menuCommunity: "커뮤니티",
  menuCommunityMiniGame: "미니게임",
  menuCommunityLunch: "오늘뭐먹지?",

  menuManagerSetting: "관리자설정",

  menuLog: "로그",

  // 로그인 창
  signinTitle: "로그인",
  signinIdHint: "아이디",
  signinPwHint: "비밀번호",
  signinAuto: "자동로그인",
  signinCancel: "닫기",
  signinFindAccount: "아이디 찾기",
  signinPwAccount: "비밀번호 찾기",

  // 비밀번호 변경
  pwChangeTitle: "비밀번호 변경",
  pwChangeHint1: "비밀번호",
  pwChangeHint2: "비밀번호 확인",
  pwChangePass: "일치",
  pwChangeFail: "불일치",
  pwChangeClose: "취소",
  pwChangeOk: "변경",

  // 루밍 페이지
  roomingTitle: "루밍 정보",
  roomingAddListButton: "목록 추가",
  roomingFindHint: "검색",

  // 루밍 페이지 목록 추가 팝업
  roomingAddListDialogDescription: "입력창 (Enter로 구분)",
  roomingAddListDialogInputHint:
    "ex) 27jan sorak seoul 5d4n 15+1 asiana avenir NO",
  roomingAddListDialogConfirmButton: "등록",

  // 루밍 목록 아이템
  roomingItemCreateDate: "생성일",
  roomingItemModifyDate: "수정일",
  roomingItemButton: "루밍",
  roomingItemfinalDocumentButton: "파이널(KTS)",
  roomingItemfinalDocumentITSButton: "파이널(ITS)",
  roomingItemInvoiceButton: "인보이스",
  roomingItemLogoButton: "로고",

  // 요청사항 게시판
  requestBoardTitle: "요청사항",
  requestBoardWriter: "요청사항 작성",
  requestBoardHeaderNumber: "번호",
  requestBoardHeaderTitle: "제목",
  requestBoardHeaderTitleHint: "* 제목을 입력해 주세요.",
  requestBoardHeaderContent: "내용",
  requestBoardHeaderContentHint: "내용을 입력해 주세요.",
  requestBoardHeaderAccount: "아이디",
  requestBoardHeaderDate: "날짜",
  requestBoardAllButton: "전체목록",
  requestBoardWriteButton: "글쓰기",
  requestBoardRegisterButton: "등록",
  requestBoardFindHint: "검색어를 입력해 주세요.",
  requestBoardDeleteButton: "삭제",
  requestBoardCommentHint: "댓글을 입력해 주세요.",
  requestBoardFileUploadButton: "업로드",
  requestBoardPeriod: "* Period ex) 01-07 MAR",
  requestBoardAgent: "* Agent ex) ITS, WIDARIN, AVENIR",
  requestBoardCompanyInfo: "Company Information",
  requestBoardFlightSchedule: "Flight Schedule ex) OZ, GA, BI, SQ",
  requestBoardItinerary: "* Itinerary ex) SORAK-SEOUL",
  requestBoardPax: "* Pax ex) 25+1",
  requestBoardHotelStarRating: "* Hotel Star Rating ex) SEOUL 3 star, BUSAN 4 star, JEJU 3 star",
  requestBoardSchedule: "* Schedule ex) GYEONGBOK PALACE & NATIONAL MUSEUM, NAMI ISLAND",

  // 투어생성 Dialog
  createTourTitle: "투어 생성",
  createTourHint: "dd-dd MM document Infomation\ndd-dd MM document Infomation",
  createTourOk: "생성",

  // 공통
  strCancel: "취소",
  strUpdate: "수정",
};

export default Locale;
