import React, { useState, useEffect } from "react";
import * as Utils from '../features/Utils';
import * as HTTPManager from '../features/HTTPManager';
import { UserInfo } from "../recoil";
import { useRecoilValue } from "recoil";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ColorButton from "./ColorButton"
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { faCar } from "@fortawesome/free-solid-svg-icons";
import { faUser } from "@fortawesome/free-solid-svg-icons";

import { faEllipsisVertical } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { ROLES } from "../features/Constant";

export default function RegistGuide() {
  const navigate = useNavigate();
  const userInfo = useRecoilValue(UserInfo);

  const [name, setName] = useState("");
  const [userList, setUserList] = useState([]);

  const [selectedUserId, setSelectedUserId] = useState(0);

  const [showModifyPopup, setShowModifyPopup] = useState(false);
  const [modifyType, setModifyType] = useState("");
  const [modifyValue, setModifyValue] = useState("");

  const liCommonCSSUserMenu = "py-1 hover:bg-gray-500 hover:text-[white] select-none";
  const btnCommonCSSUserMenu = "hover:bg-grey-200 w-full h-full py-2 px-2 text-center focus:outline-none";
  const [showMenu, setShowMenu] = useState(false);
  const menuList = ["가이드 삭제", "비밀번호 초기화", "권한변경(ITS)", "취소"];

  async function fetchData() {
    var response = await HTTPManager.GetUserList({
      accessToken: userInfo.accessToken,
    });

    if (response !== undefined && response.data?.result !== undefined) {
      var sortList = [...response.data.result];
      sortList.sort((a, b) => a.name < b.name ? -1 : a.name > b.name ? 1 : 0);
      setUserList(sortList);
    }
  }

  useEffect(() => {
    Utils.allowByPosition(ROLES.ADMIN, userInfo.rolesId)
      .then(async (res) => {
        if (!res) {
          alert("접근 권한이 없습니다.");
          navigate("/", { replace: true });
        }
        else {
          await fetchData();
        }
      })
      .catch((err) => {
        console.log(err);
        alert(err.message);
      });
  }, [])

  async function handleKeyDown(event) {
    if (event.key === 'Enter') {
      registGuide();
    }
  }

  async function registGuide() {
    if (name.length < 1) {
      alert("이름 확인!");
      return;
    }

    var res = await HTTPManager.RegistGuide({
      accessToken: userInfo.accessToken,
      name: name,
    });

    if (res.data.result) {
      alert("등록 완료");
    } else {
      alert("등록 실패");
    }

    fetchData();
  }

  const [selectedFilterList, setSelectedFilterList] = useState([]);

  return (
    <div className="w-full h-full flex flex-col bg-[white] mt-0 md:mt-14 px-[50px] md:px-[30px]">
      <div className="text-start mt-[50px] text-[#9299A1]">사용자</div>
      <div className="text-start mt-[50px] text-[#333A40] font-bold text-[30px]">가이드</div>
      <div className="w-full h-full flex flex-col items-start">
        <div className="w-full h-[1px] bg-[#DCDCDC] my-[25px]" />
        <div style={{ marginBottom: 10, display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', }}>
          <div style={{ width: 80, color: '#9ca3af' }}>이름</div>
          <input
            className="placeholder:text-slate-400 block bg-white border border-slate-300 rounded-md py-2 pl-3 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1"
            placeholder="XXX"
            type="text"
            name="name"
            style={{ flex: 1 }}
            onChange={(e) => { setName(e.target.value) }}
            value={name}
            onKeyDown={handleKeyDown}
          />
        </div>

        <span style={{ width: "100%", marginBottom: 10 }}>
          <ColorButton title="등록" color="slateblue" handler={registGuide} />
        </span>

        <div className="w-full flex flex-row gap-2 mt-[25px] scrollRemoveBox px-1">
          <div className="md:flex-none flex flex-col gap-2 rounded-lg shadow-lg px-5 py-6 w-1/5 md:w-full mb-5">
            <div className="flex flex-row gap-3 font-bold">
              <span className={"cursor-pointer"}>
                <FontAwesomeIcon icon={faUser} style={{ color: "#1D3C77" }} />
              </span>
              <div>총원</div>
            </div>
            <div className="flex w-full justify-end text-[25px] font-bold">{Utils.formatNumber(userList?.filter(user => user.name !== "없음" && (user.rolesId === 2 || user.rolesId === 4)).length)}명</div>
          </div>

          <div className="md:flex-none flex flex-col gap-2 rounded-lg shadow-lg px-5 py-6 w-1/5 md:w-full mb-5 cursor-pointer"
            style={{
              background: (selectedFilterList.filter(name => name === "in")?.length > 0) ? "lightgray" : "#FFFFFF"
            }}
            onClick={() => {
              if (selectedFilterList.find(filter => filter === "in") !== undefined) {
                setSelectedFilterList(selectedFilterList.filter(code => code !== "in"));
              } else {
                setSelectedFilterList(["in"]);
              }
            }}>
            <div className="flex flex-row gap-3 font-bold">
              <span className={"cursor-pointer"}>
                <FontAwesomeIcon icon={faUser} style={{ color: "#1D3C77" }} />
              </span>
              <div>인도네시아</div>
            </div>
            <div className="flex w-full justify-end text-[25px] font-bold">{userList?.filter(user => (user.rolesId === 2 || user.rolesId === 4) && user.majorCountry === 2).length}명</div>
          </div>

          <div className="md:flex-none flex flex-col gap-2 rounded-lg shadow-lg px-5 py-6 w-1/5 md:w-full mb-5 cursor-pointer"
            style={{
              background: (selectedFilterList.filter(name => name === "ph")?.length > 0) ? "lightgray" : "#FFFFFF"
            }}
            onClick={() => {
              if (selectedFilterList.find(filter => filter === "ph") !== undefined) {
                setSelectedFilterList(selectedFilterList.filter(code => code !== "ph"));
              } else {
                setSelectedFilterList(["ph"]);
              }
            }}>
            <div className="flex flex-row gap-3 font-bold">
              <span className={"cursor-pointer"}>
                <FontAwesomeIcon icon={faUser} style={{ color: "#1D3C77" }} />
              </span>
              <div>필리핀</div>
            </div>
            <div className="flex w-full justify-end text-[25px] font-bold">{userList?.filter(user => (user.rolesId === 2 || user.rolesId === 4) && user.majorCountry === 3).length}명</div>
          </div>

          <div className="md:flex-none flex flex-col gap-2 rounded-lg shadow-lg px-5 py-6 w-1/5 md:w-full mb-5 cursor-pointer"
            style={{
              background: (selectedFilterList.filter(name => name === "none")?.length > 0) ? "lightgray" : "#FFFFFF"
            }}
            onClick={() => {
              if (selectedFilterList.find(filter => filter === "none") !== undefined) {
                setSelectedFilterList(selectedFilterList.filter(code => code !== "none"));
              } else {
                setSelectedFilterList(["none"]);
              }
            }}>
            <div className="flex flex-row gap-3 font-bold">
              <span className={"cursor-pointer"}>
                <FontAwesomeIcon icon={faUser} style={{ color: "#1D3C77" }} />
              </span>
              <div>미분류</div>
            </div>
            <div className="flex w-full justify-end text-[25px] font-bold">{userList?.filter(user => user.name !== "없음" && (user.rolesId === 2 || user.rolesId === 4) && (user.majorCountry === null || user.majorCountry === undefined)).length}명</div>
          </div>

          <div className="md:flex-none flex flex-col gap-2 rounded-lg shadow-lg px-5 py-6 w-1/5 md:w-full mb-5 cursor-pointer"
            style={{
              background: (selectedFilterList.filter(name => name === "driving")?.length > 0) ? "lightgray" : "#FFFFFF"
            }}
            onClick={() => {
              if (selectedFilterList.find(filter => filter === "driving") !== undefined) {
                setSelectedFilterList(selectedFilterList.filter(code => code !== "driving"));
              } else {
                setSelectedFilterList(["driving"]);
              }
            }}>
            <div className="flex flex-row gap-3 font-bold">
              <span className={"cursor-pointer"}>
                <FontAwesomeIcon icon={faCar} style={{ color: "#1D3C77" }} />
              </span>
              <div style={{
              }}>드라이빙</div>
            </div>
            <div className="flex w-full justify-end text-[25px] font-bold text-[#ABABAB]">{Utils.formatNumber(userList?.filter(user => user.driving === 1)?.length)}명</div>
          </div>
        </div>

        <div className="w-full h-[1px] bg-[#DCDCDC] my-[25px]" />

        <div className="grid grid-cols-4 md:grid-cols-1 gap-3 w-full pb-5">
          {
            userList?.length > 0 &&
            userList?.map(user => {
              if (user.rolesId === 2 || user.rolesId === 4) {
                if (user.name === "없음")
                  return null;

                if (selectedFilterList.length > 0) {
                  // 드라이빙 선택
                  if (selectedFilterList.find(item => item === "driving") !== undefined && user.driving !== 1)
                    return null;

                  // 인도네시아 선택
                  if (selectedFilterList.find(item => item === "in") !== undefined && user.majorCountry !== 2)
                    return null;

                  // 필리핀 선택
                  if (selectedFilterList.find(item => item === "ph") !== undefined && user.majorCountry !== 3)
                    return null;

                  // 미분류 선택
                  if (selectedFilterList.find(item => item === "none") !== undefined && user.majorCountry !== null)
                    return null;
                }

                if (user.name !== "없음")
                  return (
                    <div key={user.id} className="flex w-full flex-row border border-[black] hover:border-[slateblue] rounded py-2 px-4 items-center">
                      <div className="flex flex-row w-full ">
                        <div className="flex min-w-0 gap-x-4">
                          <div className="min-w-0 flex-auto">
                            <div className="text-sm font-semibold leading-6 text-[black] text-left normal-case cursor-pointer hover:text-blue-600" onClick={() => {
                              setSelectedUserId(user.id);
                              setModifyType("name");
                              setModifyValue(user.name);
                              setShowModifyPopup(true);
                            }}>{user.name}</div>

                            <div className="mt-1 truncate text-xs leading-5 text-gray-500 text-left normal-case cursor-pointer" onClick={() => {
                              setSelectedUserId(user.id);
                              setModifyType("account");
                              setModifyValue(user.account);
                              setShowModifyPopup(true);
                            }}>
                              {
                                user.account === null ? <div className="text-[crimson]">[계정 설정]</div> :
                                  <div
                                    onClick={() => {
                                      setSelectedUserId(user.id);
                                      setModifyType("account");
                                      setModifyValue(user.account);
                                      setShowModifyPopup(true);
                                    }}>
                                    계정 : {user.account}
                                  </div>
                              }
                            </div>

                            <div className="mt-1 truncate text-xs leading-5 text-gray-500 text-left normal-case">
                              {
                                user.randomPw === null ? "비밀번호 : 변경완료" : <div
                                  className="cursor-pointer hover:text-blue-600"
                                  onClick={() => {
                                    navigator.clipboard.writeText(user.randomPw);
                                    alert("복사 완료!");
                                  }}>비밀번호 : {user.randomPw} (임시)
                                </div>
                              }
                            </div>

                            <div className="mt-1 truncate text-xs leading-5 text-gray-500 text-left normal-case cursor-pointer hover:text-blue-600">
                              {
                                user.phoneNumber !== null ?
                                  <div className="flex flex-row">
                                    <div onClick={() => {
                                      setSelectedUserId(user.id);
                                      setModifyType("phoneNumber");
                                      setModifyValue(user.phoneNumber);
                                      setShowModifyPopup(true);
                                    }}>
                                      전화번호 : {user.phoneNumber}
                                    </div>
                                  </div> :
                                  <div className="text-[crimson]" onClick={() => {
                                    setSelectedUserId(user.id);
                                    setModifyValue("");
                                    setModifyType("phoneNumber");
                                    setShowModifyPopup(true);
                                  }}>
                                    [전화번호 설정]
                                  </div>
                              }
                            </div>

                            <div className={`mt-1 truncate text-xs leading-5 text-gray-500 text-left normal-case cursor-pointer hover:text-blue-600 ${user.nameEN !== null ? "" : "bg-[gray]"}`}>
                              {
                                user.nameEN !== null ?
                                  <div className="flex flex-row">
                                    <div onClick={() => {
                                      setSelectedUserId(user.id);
                                      setModifyType("nameEN");
                                      setModifyValue(user.nameEN);
                                      setShowModifyPopup(true);
                                    }}>
                                      영어이름 : {user.nameEN}
                                    </div>
                                  </div> :
                                  <div className="text-[white]" onClick={() => {
                                    setSelectedUserId(user.id);
                                    setModifyValue("");
                                    setModifyType("nameEN");
                                    setShowModifyPopup(true);
                                  }}>
                                    [영어이름 설정]
                                  </div>
                              }
                            </div>

                            <div className={`mt-1 truncate text-xs leading-5 text-gray-500 text-left normal-case cursor-pointer hover:text-blue-600 ${user.birthDate !== null ? "" : "bg-[gray]"}`}>
                              {
                                user.birthDate !== null ?
                                  <div className="flex flex-row">
                                    <div onClick={() => {
                                      setSelectedUserId(user.id);
                                      setModifyType("birthDate");
                                      setModifyValue(user.birthDate);
                                      setShowModifyPopup(true);
                                    }}>
                                      생년월일 : {user.birthDate}
                                    </div>
                                  </div> :
                                  <div className="text-[white]" onClick={() => {
                                    setSelectedUserId(user.id);
                                    setModifyValue("");
                                    setModifyType("birthDate");
                                    setShowModifyPopup(true);
                                  }}>
                                    [생년월일 설정]
                                  </div>
                              }
                            </div>

                            <div className="mt-1 truncate text-xs leading-5 text-gray-500 text-left normal-case cursor-pointer">
                              {
                                <div className="flex flex-row items-center">
                                  <div className="mr-1 h-[20px]">드라이빙</div>
                                  <div className="flex pt-[1px] items-center">
                                    <input
                                      className="bg-[red] h-[15px] w-[15px]"
                                      type="checkbox"
                                      checked={(user.driving === null || user.driving === 0) ? false : true}
                                      onChange={async () => {
                                        let res = await HTTPManager.ChangeGuideInfo({
                                          accessToken: userInfo.accessToken,
                                          guideId: user.id,
                                          type: "driving",
                                          value: (user.driving === null || user.driving === 0) ? "1" : "0",
                                        })

                                        if (res?.data?.result) {
                                          fetchData();
                                        } else {
                                          alert("변경 실패!");
                                        }
                                      }} />
                                  </div>
                                </div>
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="top-0 h-full hover:text-[slateblue]">
                        <div
                          style={{
                            fontSize: 15,
                            cursor: "pointer",
                            textAlign: "center"
                          }}
                          onClick={() => {
                            setSelectedUserId(user.id);
                            setShowMenu(true);
                          }}
                        >
                          <FontAwesomeIcon icon={faEllipsisVertical} />
                        </div>
                      </div>
                    </div>
                  )
              }
              else
                return null;
            })
          }
        </div>
      </div>
      {
        // 메뉴
        showMenu &&
        <>
          <div className="md:top-14 mx-6 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none font-medium">
            <div className="relative w-full m-auto max-w-lg">
              <div className="border-0 shadow-lg relative flex flex-col bg-white outline-none focus:outline-none">
                {
                  menuList.map((menu, index) => {
                    return (
                      <div key={index}
                        className={index !== menuList.length - 1
                          ? `border-b ${liCommonCSSUserMenu}`
                          : `${liCommonCSSUserMenu}`
                        }>
                        <button
                          className={btnCommonCSSUserMenu}
                          onClick={async () => {
                            let msg = `${userList?.find(user => user.id === selectedUserId)?.name}`;
                            switch (menu) {
                              case "가이드 삭제":
                                msg += ` 가이드를 삭제 하시겠습니까?`;
                                break;
                              case "비밀번호 초기화":
                                msg += ` 가이드 비밀번호를 초기화 하시겠습니까?`;
                                break;
                              case "권한변경(ITS)":
                                msg += ` ITS 멤버로 변경하시겠습니까?`
                                break;
                              case "취소":
                                break;
                            }

                            if (menu !== "취소") {
                              let answer = window.confirm(msg);
                              if (answer) {
                                if (menu === "가이드 삭제") {
                                  var res = await HTTPManager.DeleteGuide({
                                    accessToken: userInfo.accessToken,
                                    guideId: selectedUserId,
                                  });

                                  if (res.data.result) {
                                    fetchData();
                                  } else {
                                    alert("삭제 실패");
                                  }
                                } else if (menu === "비밀번호 초기화") {
                                  let res = await HTTPManager.ChangeGuideInfo({
                                    accessToken: userInfo.accessToken,
                                    guideId: selectedUserId,
                                    type: "init",
                                  })

                                  if (res?.data?.result) {
                                    fetchData();
                                  } else {
                                    alert("초기화 실패!");
                                  }
                                } else if (menu === "권한변경(ITS)") {
                                  let res = await HTTPManager.ChangeGuideInfo({
                                    accessToken: userInfo.accessToken,
                                    guideId: selectedUserId,
                                    type: "rolesId",
                                    value: "5",
                                  });

                                  if (res?.data?.result) {
                                    fetchData();
                                  } else {
                                    alert("변경 실패!");
                                  }
                                }
                              }
                            }

                            setShowMenu(false);
                          }}
                        >
                          {menu}
                        </button>
                      </div>
                    )
                  })
                }
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black" />
        </>
      }
      {
        // 수정 다이얼로그
        showModifyPopup &&
        <div className="left-20 md:left-0 top-20" style={{ position: "fixed", display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: 500, }}>
          <div style={{ display: "flex", flexDirection: "column", background: "#000c", padding: 20, paddingTop: 20, borderRadius: 10 }}>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <div
                style={{
                  width: 25,
                  height: 25,
                  marginBottom: 5,
                  fontSize: 15,
                  color: "white",
                  cursor: "pointer",
                  textAlign: "center"
                }}
                onClick={() => { setShowModifyPopup(false) }}
              >
                <FontAwesomeIcon icon={faXmark} />
              </div>
            </div>
            <div className="text-white mb-4 text-sm font-bold">
              변경하실 내용을 입력해주세요.
            </div>
            <div style={{ marginBottom: 10, display: 'flex', flexDirection: 'row', alignItems: 'center', }}>
              <div style={{ width: 80, color: '#9ca3af', marginRight: 5, }}>{modifyType}</div>
              <input
                className="placeholder:text-slate-400 block bg-white border border-slate-300 rounded-md py-2 pl-3 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1"
                type="text"
                onChange={(e) => {
                  setModifyValue(e.target.value);
                }}
                value={modifyValue}
              />
            </div>
            <div className="flex flex-row text-sm">
              <div className="mr-2 w-full">
                <ColorButton title={"취소"} color={"crimson"} width={"100%"} handler={async () => {
                  setShowModifyPopup(false);
                }} />
              </div>
              <div className="w-full">
                <ColorButton title={"확인"} color={"deepskyblue"} width={"100%"} handler={async () => {
                  let res = await HTTPManager.ChangeGuideInfo({
                    accessToken: userInfo.accessToken,
                    guideId: selectedUserId,
                    type: modifyType,
                    value: modifyValue,
                  })

                  if (res?.data?.result) {
                    setShowModifyPopup(false);
                    alert("변경 완료");
                    fetchData();
                  } else {
                    alert("변경 실패!");
                  }
                }} />
              </div>
            </div>
          </div>
        </div>
      }
    </div >
  );
}