import React, { } from "react";
import { LanguageSet, } from "../../recoil";
import { useRecoilValue } from "recoil";

export default function LogItem(Props) {
  const languageSet = useRecoilValue(LanguageSet);

  const logInfo = Props.data;

  // 삭제 redTag
  const redTag = (msg) => {
    return <span className="inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10 mr-[5px]">{msg}</span>
  }

  // 파일 yellowTag
  const yellowTag = (msg) => {
    return <span className="inline-flex items-center rounded-md bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-800 ring-1 ring-inset ring-yellow-600/20 mr-[5px]">{msg}</span>
  }

  // 생성 greenTag 
  const greenTag = (msg) => {
    return <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20 mr-[5px]">{msg}</span>
  }

  // 수정 blueTag
  const blueTag = (msg) => {
    return <span className="inline-flex items-center rounded-md bg-blue-50 px-2 py-1 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10 mr-[5px]">{msg}</span>
  }

  // tour 관련 API  
  // 생성 => post : /
  // 복사 => post : /:id

  // 수정 => put : /:id
  // 상태변경 => put : /state/:id
  // 파일업로드 => put : /file/:id

  // 파일삭제 => delete : /file/:id
  // 삭제 => delete : /:id  

  return (
    <div className="flex flex-row normal-case items-center text-sm font-medium" style={Props.style}>
      {
        logInfo.event.includes("[POST]") ? greenTag("생성") : ""
      }

      {
        logInfo.event.includes("[PUT]") ? blueTag("수정") : ""
      }

      {
        logInfo.event.includes("[DELETE]") ? redTag("삭제") : ""
      }

      {
        logInfo.event.includes("/file/") ? yellowTag("파일") : ""
      }
      <div className="mr-[10px] text-[#A2A7B0]">{logInfo.timestamp}</div>
      <div className="mr-[10px] text-[#B3261E]">{logInfo.account}</div>
      <div className="mr-[10px] text-[#2A313E]">{logInfo.tourId}번 {logInfo.message}</div>
      {/* <div className="mr-[10px]">{logInfo.event}</div> */}
    </div>
  )
}