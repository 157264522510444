import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import * as HTTPManager from "../../features/HTTPManager";
import { useRecoilValue } from "recoil";
import { UserInfo, LanguageSet } from "../../recoil";
import "../../fonts/Font.css"

const ChangeNameDialog = ({ showModal, setShowModal }) => {
  const navigate = useNavigate();

  const [englishName, setEnglishName] = useState("");

  const userInfo = useRecoilValue(UserInfo);
  const languageSet = useRecoilValue(LanguageSet);

  useEffect(() => {
  }, []);

  //엔터키 동작
  const onEnterPress = (e) => {
    if (e.key === "Enter") {
      onChangePwButton();
    }
  };

  //버튼
  const onChangePwButton = async () => {
    let res = await HTTPManager.ChangeGuideInfoForGuide({
      accessToken: userInfo.accessToken,
      guideId: userInfo.id,
      type: "nameEN",
      value: englishName,
    });

    if (res?.data?.result) {
      alert("변경 완료, 재로그인 시 적용")
      setEnglishName("");
      setShowModal(false);
    } else {
      alert("변경 실패!");
    }
  };

  return (
    <>
      {showModal ? (
        <>
          <div className="mx-6 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none font-medium select-none">
            <div className="relative w-full mx-auto max-w-lg">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-center p-5 rounded-t">
                  <h2 className="text-base">Change English Name</h2>
                </div>
                {/*body*/}
                <div className="relative p-5 flex-auto">
                  <input
                    className="w-full placeholder:text-slate-400 block bg-white border border-slate-300 rounded-md py-2 pl-9 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1"
                    placeholder={"ex) John"}
                    type="text"
                    name="text"
                    onChange={(e) => {
                      setEnglishName(e.target.value);
                    }}
                    value={englishName}
                  />
                </div>

                <div className="flex w-full items-center justify-center rounded-b border-t">
                  <button
                    className="flex w-full justify-center py-0 rounded-bl-lg focus:outline-none"
                    type="button"
                    onClick={() => {
                      setEnglishName("");
                      setShowModal(false);
                    }}
                  >
                    {languageSet.pwChangeClose}
                  </button>
                  <button
                    className="flex w-full bg-[black] text-[white] justify-center py-3 rounded-br-lg focus:outline-none"
                    type="button"
                    onClick={() => onChangePwButton()}
                  >
                    {languageSet.pwChangeOk}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
};

export default ChangeNameDialog;
