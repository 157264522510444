import { useState, } from "react";
import BodyBox from "./components/BodyBox";
import TopBar from "./components/TopBar";
import { RecoilRoot } from "recoil";



function App() {

  const [menuToggle, setMenuToggle] = useState(false);

  return (
    <RecoilRoot>
      <div className="flex flex-col min-h-screen">
        <TopBar menuToggle={menuToggle} setMenuToggle={setMenuToggle} />
        <BodyBox menuToggle={menuToggle} setMenuToggle={setMenuToggle} />
      </div>
    </RecoilRoot>
  );
}

export default App;
