

import React, { useEffect, useState, useMemo } from "react";
import * as Utils from '../../features/Utils';
import { ROLES } from "../../features/Constant";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { UserInfo, LanguageSet } from "../../recoil";
import * as HTTPManager from "../../features/HTTPManager";
import "../../fonts/Font.css"
import ktsLogo from '../../assets/final_logo.png';
import taylor from '../../assets/taylor.png';
import allen from '../../assets/allen.png';
import AutoCompleteInput from "../AutoCompleteInput";
import { Tooltip } from 'react-tooltip'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisVertical } from "@fortawesome/free-solid-svg-icons";
import html2canvas from 'html2canvas';

const InvoiceDetail = ({ showModal, setShowModal, info, refresh }) => {
  const navigate = useNavigate();

  const userInfo = useRecoilValue(UserInfo);
  const languageSet = useRecoilValue(LanguageSet);
  const [invoiceBank, setInvoiceBank] = useState([]);
  const [invoiceCompany, setInvoiceCompany] = useState([]);
  const signList = [{ "id": 1, "name": "taylor", }, { "id": 2, "name": "allen", }];

  const [showModify, setShowModify] = useState(false);
  const [detailTypeHint, setDetailTypeHint] = useState("");
  const [detailType, setDetailType] = useState("");
  const [detailValue, setDetailValue] = useState("");

  const [selectedInvoicePriceId, setSelectedInvoicePriceId] = useState(0);
  const [showRequest, setShowRequest] = useState(false);
  const [requestTitle, setRequestTitle] = useState("");
  const [requestHint, setRequestHint] = useState("");
  const [requestType, setRequestType] = useState("");
  const [requestValue, setRequestValue] = useState("");

  const [showMenu, setShowMenu] = useState(false);

  const outlineStyled = {
    "textTransform": "none",
    "width": "100%",
    "--md-outlined-text-field-container-shape": "0px",
    "--md-outlined-text-field-focus-outline-width": "2px",
    "--md-outlined-text-field-focus-outline-color": "#B3261E",
    "--md-outlined-text-field-outline-color": "#000000",
    "--md-outlined-text-field-label-text-color": "#ababab",
    "--md-outlined-text-field-label-text-size": "13px",
    "--md-outlined-text-field-focus-label-text-color": "#B3261E",
  }

  useEffect(() => {
    Utils.allowByPosition(ROLES.ADMIN, userInfo.rolesId)
      .then(async (res) => {
        if (!res) {
          alert("접근 권한이 없습니다.");
          navigate("/", { replace: true });
        }
        else {
          fetchData();
        }
      })
      .catch((err) => {
        alert(err.message);
      });
  }, [showModal]);

  async function fetchData() {
    let res = await HTTPManager.GetInvoiceInfo({
      accessToken: userInfo.accessToken,
    });

    if (res.data !== undefined) {
      setInvoiceBank(res.data?.invoiceBank);
      setInvoiceCompany(res.data?.invoiceCompany);
    }
  }

  function getSchedule(startDate, endDate) {
    let result = "";
    let startStr = "";
    let endStr = "";

    let start = new Date(startDate);
    let end = new Date(endDate);

    endStr = `${end.getDate() < 10 ? "0" + end.getDate() : end.getDate()} ${Utils.getMonthToStr(end.getMonth())} ${end.getFullYear()}`;

    if (start.getMonth() === end.getMonth())
      startStr = `${start.getDate() < 10 ? "0" + start.getDate() : start.getDate()}`;
    else
      startStr = `${start.getDate() < 10 ? "0" + start.getDate() : start.getDate()} ${Utils.getMonthToStr(start.getMonth())}`;

    if (start.getFullYear() !== end.getFullYear())
      startStr += ` ${start.getFullYear()}`;

    result = `${startStr} - ${endStr}`;

    return result;
  }

  function getSelectedList(type) {
    switch (type) {
      case "SELLER":
      case "BUYER":
        return invoiceCompany;
      case "BANK":
        return invoiceBank;
      case "SIGN":
        return signList;
    }
  }

  function getSelectedDetail(type) {
    if (detailValue === "") return '';

    switch (type) {
      case "SELLER":
      case "BUYER":
        let companyInfo = invoiceCompany.find(item => item.id === detailValue);
        return `${companyInfo?.name} ${companyInfo?.location} ${companyInfo?.ceo} ${companyInfo?.phoneNumber}`;
      case "BANK":
        let bankInfo = invoiceBank.find(item => item.id === detailValue);
        return `${bankInfo?.swiftcode} ${bankInfo?.name} ${bankInfo?.address} ${bankInfo?.accountNumber} ${bankInfo?.accountHolder}`;
      case "SIGN":
        let signInfo = signList.find(item => item.id === detailValue);
        return `${signInfo?.name}`;
    }
  }

  const liCommonCSSUserMenu = "py-1 hover:bg-gray-500 hover:text-[white] select-none";
  const btnCommonCSSUserMenu = "hover:bg-grey-200 w-full h-full py-2 px-2 text-center focus:outline-none";
  const menuList = ["발행하기", "발행취소", "닫기", "캡쳐"];

  const priceListSortbyCreatedAt = info.invoicePrice?.sort((a, b) => a.createdAt > b.createdAt ? -1 : 1);
  const showModifiedAt = priceListSortbyCreatedAt[0]?.createdAt !== null && priceListSortbyCreatedAt[0]?.createdAt !== undefined;
  const modifiedAt = priceListSortbyCreatedAt[0]?.createdAt;

  return (
    <>
      {showModal ? (
        <>
          <div>
            <div className="fixed inset-0 flex w-full h-full z-[400] pb-4 md:pb-8 normal-case" id={`tour${info.tourInfo[0]?.id}`}>
              <div className="flex flex-col w-5/12 md:w-full mx-auto z-[400] bg-[white] mt-24 md:mt-14 rounded">
                <div className="flex flex-col w-full h-full pt-3">
                  <div className="flex flex-row w-full mb-4 px-5">
                    <img src={ktsLogo} alt="not found" />
                    <div className="flex flex-col w-full text-end">
                      <div
                        style={{
                          fontSize: 15,
                          cursor: "pointer",
                          textAlign: "end",
                          color: "#F64C47",
                        }}
                        onClick={() => {
                          setShowMenu(true);
                        }}
                      >
                        <FontAwesomeIcon icon={faEllipsisVertical} />
                      </div>
                      <div className="font-bold text-[35px] mb-4">Commercial Invoice</div>
                      <div className="font-medium text-[13px]">Invoice No. KTS-[{info.tourInfo[0]?.id}]</div>
                      {
                        info.invoiceDetail[0]?.publishAt !== undefined && info.invoiceDetail[0]?.publishAt !== null ?
                          <div className="font-medium text-[13px]">Created. {info.invoiceDetail[0]?.publishAt}</div> :
                          <div className="font-medium text-[13px] text-[crimson]">NEED TO PUBLISHED BY KTS</div>
                      }
                      {
                        showModifiedAt ? <div className="font-medium text-[13px]">Modified. {modifiedAt}</div> : ""
                      }
                    </div>
                  </div>
                  <div className="w-full h-full overflow-y-scroll p-4">
                    <div className={`flex flex-row w-full mb-5 hover:bg-[#00000022] hover:rounded hover:cursor-pointer p-1`} onClick={() => {
                      setDetailTypeHint("SELLER");
                      setDetailType("sellerId");
                      setShowModify(true);
                    }}>
                      <div className="font-bold mr-10 w-[90px]">Seller</div>
                      <div className="flex flex-col w-full">
                        <div>{invoiceCompany?.find(item => item.id === info.invoiceDetail[0]?.sellerId)?.name}</div>
                        <div>{invoiceCompany?.find(item => item.id === info.invoiceDetail[0]?.sellerId)?.location}</div>
                        <div>{"Mob : " && invoiceCompany?.find(item => item.id === info.invoiceDetail[0]?.sellerId)?.phoneNumber} {invoiceCompany?.find(item => item.id === info.invoiceDetail[0]?.sellerId)?.ceo}</div>
                      </div>
                    </div>
                    <div className={`flex flex-row w-full mb-5 hover:bg-[#00000022] hover:rounded hover:cursor-pointer p-1`} onClick={() => {
                      setDetailTypeHint("BUYER");
                      setDetailType("buyerId");
                      setShowModify(true);
                    }}>
                      <div className="font-bold mr-10 w-[90px]">Buyer</div>
                      <div className="flex flex-col w-full">
                        <div>{invoiceCompany?.find(item => item.id === info.invoiceDetail[0]?.buyerId)?.name}</div>
                        <div>{invoiceCompany?.find(item => item.id === info.invoiceDetail[0]?.buyerId)?.location}</div>
                        <div>{"Mob : " && invoiceCompany?.find(item => item.id === info.invoiceDetail[0]?.buyerId)?.phoneNumber} {invoiceCompany?.find(item => item.id === info.invoiceDetail[0]?.buyerId)?.ceo}</div>
                      </div>
                    </div>
                    <div className="flex-col mb-10 p-1">
                      <div className="font-bold mb-1">Terms of delivery & payment : Cash or T/T</div>
                      <div className="flex flex-col w-full border-[1px] border-[black] divide-y divide-black ">
                        {
                          info.invoicePrice?.length > 0 &&
                          <div className="flex flex-row w-full divide-x divide-[black] font-bold">
                            <div className="w-full text-center">Item</div>
                            <div className="w-full text-center">Tour agency</div>
                            <div className="w-full text-center">PAX</div>
                            <div className="w-full text-center">Land Fee</div>
                            <div className="w-full text-center">Amount</div>
                          </div>
                        }
                        {
                          info.invoicePrice?.sort((a, b) => a.typeId < b.typeId ? -1 : a.typeId > b.typeId ? 1 : 0).map((item, index) => {
                            let requestCountCss = item.requestCount !== null ? "bg-[crimson] text-[white]" : "";
                            let reuqetPriceCss = item.requestPrice !== null ? "bg-[crimson] text-[white]" : "";

                            return (<div key={item.id} className="flex flex-row w-full divide-x divide-[black]">
                              <div className="w-full text-center">{index + 1}</div>
                              <div className="w-full text-center">{info.invoiceType?.find(type => type.id === item.typeId)?.name}</div>
                              <a className={`my-anchor-element${index} w-full text-center ${requestCountCss} hover:cursor-pointer`} onClick={() => {
                                setSelectedInvoicePriceId(item.id);
                                setRequestTitle("Request to modify");
                                setRequestHint(info.invoiceType?.find(type => type.id === item.typeId)?.name + " PAX");
                                setRequestType("requestCount");
                                setShowRequest(true);
                              }}>{Utils.formatNumber(item.count)}</a>
                              {
                                item.requestCount !== null &&
                                <Tooltip anchorSelect={`.my-anchor-element${index}`} place="top">
                                  {item.requestCount}
                                </Tooltip>
                              }
                              <a className={`land-fee${index} w-full text-center ${reuqetPriceCss} hover:cursor-pointer`} onClick={() => {
                                setSelectedInvoicePriceId(item.id);
                                setRequestTitle("Request to modify");
                                setRequestHint(info.invoiceType?.find(type => type.id === item.typeId)?.name + " Land Fee");
                                setRequestType("requestPrice");
                                setShowRequest(true);
                              }}>{Utils.formatNumber(item.price)}</a>
                              {
                                item.requestPrice !== null &&
                                <Tooltip anchorSelect={`.land-fee${index}`} place="top">
                                  {item.requestPrice}
                                </Tooltip>
                              }
                              {/* <div className="w-full text-center hover:cursor-pointer">{Utils.formatNumber(item.price)}</div> */}
                              <div className="w-full text-center">{Utils.formatNumber(item.count * item.price)}</div>
                            </div>);
                          })
                        }
                      </div>
                      <div className="text-end font-bold">
                        Amount Total USD $
                        {
                          info.invoicePrice !== undefined && info.invoicePrice.length > 0 && Utils.formatNumber(info.invoicePrice?.reduce((a, b) => a = a + (b.count * b.price), 0))
                        }
                      </div>
                    </div>
                    <div className={`flex flex-row w-full mb-10 hover:bg-[#00000022] hover:rounded hover:cursor-pointer p-1`} onClick={() => {
                      setDetailTypeHint("BANK");
                      setDetailType("bankId");
                      setShowModify(true);
                    }}>
                      <div className="font-bold mr-10">Bank Details</div>
                      <div className="flex flex-col w-full">
                        <div>SWIFT CODE : {invoiceBank?.find(item => item.id === info.invoiceDetail[0]?.bankId)?.swiftcode}</div>
                        <div>BANK NAME : {invoiceBank?.find(item => item.id === info.invoiceDetail[0]?.bankId)?.name}</div>
                        <div>BANK ADDRESS : {invoiceBank?.find(item => item.id === info.invoiceDetail[0]?.bankId)?.address}</div>
                        <div>ACCOUNT NUMBER : {invoiceBank?.find(item => item.id === info.invoiceDetail[0]?.bankId)?.accountNumber}</div>
                        <div>NAME : {invoiceBank?.find(item => item.id === info.invoiceDetail[0]?.bankId)?.accountHolder}</div>
                      </div>
                    </div>
                    <div className="flex flex-row w-full p-1">
                      <div className="font-bold mr-10 w-[90px]">Schedule</div>
                      <div className="flex flex-col w-full">
                        <div>{getSchedule(info.tourInfo[0]?.startDate, info.tourInfo[0]?.endDate)}</div>
                      </div>
                    </div>
                    <div className={`flex flex-row w-full mb-10 hover:bg-[#00000022] hover:rounded hover:cursor-pointer p-1`}>
                      <div className="font-bold mr-10  w-[90px]">Remark</div>
                      <div className="flex flex-col w-full">
                        <div>{info.tourInfo[0]?.pax}</div>
                      </div>
                    </div>
                    <div className="flex flex-row w-full mb-10 p-1">
                      <div className="font-bold mr-10 w-[90px]">Payment term</div>
                      <div className="flex flex-col w-full">
                        <div>The deposit is 50% of the total land fee at least 14 days before the tour date.</div>
                        <div>The balance is at least 7 days before the tour date.</div>
                      </div>
                    </div>
                    <div className="flex flex-row w-full mb-10 p-1">
                      <div className="font-bold mr-10 w-[90px]">Refund policy</div>
                      <div className="flex flex-col w-full">
                        <div>100% refund before 7 days before the tour date</div>
                        <div>50% refund before 5 days before the tour date</div>
                        <div>No refund before 4 days before the tour date.</div>
                      </div>
                    </div>
                    <div className={`hover:bg-[#00000022] hover:rounded hover:cursor-pointer p-1`} onClick={() => {
                      setDetailTypeHint("SIGN");
                      setDetailType("signId");
                      setShowModify(true);
                    }}>
                      <div className="mb-1">Faithfully yours, Koreatravelstore</div>
                      {
                        info.invoiceDetail[0] !== undefined && info.invoiceDetail[0]?.signId !== null &&
                        <img src={info.invoiceDetail[0]?.signId === 1 ? taylor : info.invoiceDetail[0]?.signId === 2 ? allen : ""} alt="not found" style={{
                          maxWidth: "100%",
                        }} />
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-[399] bg-black" />
          </div>

        </>
      ) : null}
      {showModify ? (
        <>
          <div className="mx-6 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 outline-none focus:outline-none font-medium z-[401]">
            <div className="relative w-full my-6 mx-auto max-w-2xl normal-case">
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col bg-white outline-none focus:outline-none">
                <div className="flex items-start justify-center p-5 rounded-t">
                  <h2 className="text-base font-bold">{info?.title}</h2>
                </div>
                <div className="relative p-3 whitespace-pre-wrap w-full flex break-all">
                  {detailTypeHint} : {getSelectedDetail(detailTypeHint)}
                </div>
                <div className="relative flex w-full flex-col justify-items-center pb-1 px-1 mt-1 bg-[white]">
                  <AutoCompleteInput label={`${detailTypeHint} 검색 (이동 : ↓↑키, 취소 : ESC, 추가 : Enter)`} list={getSelectedList(detailTypeHint)} selectedType={detailTypeHint} selectedList={detailValue} selectedFunc={setDetailValue} />
                </div>

                <div className="flex w-full items-center justify-center rounded-b border-t">
                  <button
                    className="flex w-full justify-center py-3 rounded-bl-lg focus:outline-none"
                    type="button"
                    onClick={() => {
                      setDetailType("");
                      setDetailValue("");
                      setShowModify(false);
                    }}
                  >
                    {languageSet.strCancel}
                  </button>
                  <button
                    className="flex w-full justify-center py-3 rounded-br-lg focus:outline-none bg-[black] text-[white] hover:bg-[#000000aa] focus:bg-[#000000aa]"
                    type="button"
                    onClick={async () => {
                      let res = await HTTPManager.AddInvoiceDetail({
                        accessToken: userInfo.accessToken,
                        data: {
                          "tourId": info.tourInfo[0]?.id,
                          "type": detailType,
                          "value": detailValue,
                        }
                      });

                      setDetailType("");
                      setDetailValue("");

                      if (res !== undefined && res.data?.result) {
                        refresh();
                        setShowModify(false);
                      }
                    }}
                  >
                    {languageSet.strUpdate}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-[400] bg-black"></div>
        </>
      ) : null}
      {showRequest ? (
        <>
          <div className="mx-6 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 outline-none focus:outline-none font-medium z-[401]">
            <div className="relative w-full my-6 mx-auto max-w-2xl normal-case">
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col bg-white outline-none focus:outline-none">
                <div className="flex items-start justify-center p-5 rounded-t">
                  <h2 className="text-base font-bold">{info?.title}</h2>
                </div>
                <div className="relative px-3 whitespace-pre-wrap w-full flex break-all mb-2">
                  {requestTitle}
                </div>
                <div className="relative flex w-full flex-col justify-items-center bg-[white] p-3 mb-2">
                  <md-outlined-text-field label={requestHint} type="text" style={outlineStyled} onKeyUp={(e) => {
                    setRequestValue(e.target.value);
                  }} />
                </div>

                <div className="flex w-full items-center justify-center rounded-b border-t">
                  <button
                    className="flex w-full justify-center py-3 rounded-bl-lg focus:outline-none"
                    type="button"
                    onClick={() => {
                      setRequestType("");
                      setRequestValue("");
                      setShowRequest(false);
                    }}
                  >
                    {languageSet.strCancel}
                  </button>
                  <button
                    className="flex w-full justify-center py-3 rounded-br-lg focus:outline-none bg-[black] text-[white]"
                    type="button"
                    onClick={async () => {
                      let res = await HTTPManager.ModifyInvoicePrice({
                        accessToken: userInfo.accessToken,
                        toursInvoicePriceId: selectedInvoicePriceId,
                        requestType: requestType,
                        requestValue: requestValue,
                      });

                      setRequestType("");
                      setRequestValue("");

                      if (res !== undefined && res.data?.result) {
                        refresh();
                        setShowRequest(false);
                      }
                    }}
                  >
                    {languageSet.strUpdate}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-[400] bg-black"></div>
        </>
      ) : null}
      {
        showMenu &&
        <>
          <div className="md:top-14 mx-6 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-[403] outline-none focus:outline-none font-medium">
            <div className="relative w-full m-auto max-w-lg">
              <div className="border-0 shadow-lg relative flex flex-col bg-white outline-none focus:outline-none">
                {
                  menuList.map((menu, index) => {
                    return (
                      <div key={index}
                        className={index !== menuList.length - 1
                          ? `border-b ${liCommonCSSUserMenu}`
                          : `${liCommonCSSUserMenu}`
                        }>
                        <button
                          className={btnCommonCSSUserMenu}
                          onClick={async () => {
                            switch (index) {
                              case 0: {
                                let res = await HTTPManager.UpdateInvoiceDetail({
                                  accessToken: userInfo.accessToken,
                                  data: {
                                    "tourId": info.tourInfo[0]?.id,
                                    "publish": true,
                                  }
                                });

                                if (res !== undefined && res.data?.result === true) {
                                  refresh();
                                  setShowMenu(false);
                                  setShowModal(false);
                                }
                                break;
                              }
                              case 1: {
                                let res = await HTTPManager.UpdateInvoiceDetail({
                                  accessToken: userInfo.accessToken,
                                  data: {
                                    "tourId": info.tourInfo[0]?.id,
                                    "publish": false,
                                  }
                                });

                                if (res !== undefined && res.data?.result === true) {
                                  refresh();
                                  setShowMenu(false);
                                  setShowModal(false);
                                }
                                break;
                              }
                              case 2:
                                setShowMenu(false);
                                setShowModal(false);
                                break;
                              case 3: {
                                // tailwind + html2canvas 조합 시 텍스트 아래로 내려가는 문제 해결 방법
                                const style = document.createElement('style');
                                document.head.appendChild(style);
                                style.sheet?.insertRule('body > div:last-child img { display: inline-block; }');

                                const target = document.getElementById(`tour${info.tourInfo[0]?.id}`);
                                await html2canvas(target).then(canvas => {
                                  style.remove();
                                  canvas.toBlob(async (blob) => {
                                    if (blob !== null) {
                                      const data = [new window.ClipboardItem({ ['image/png']: blob })];
                                      await navigator.clipboard.write(data);
                                      alert('캡쳐완료');
                                    }
                                  });
                                });
                                break;
                              }
                            }
                          }}
                        >
                          {menu}
                        </button>
                      </div>
                    );
                  })
                }
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-[402] bg-black" />
        </>
      }
    </>
  );
};

export default InvoiceDetail;
