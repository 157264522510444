import React, { useEffect, useState, useRef } from "react";
import PageCounter from "./PageCounter";
import RequestBoardItem from "./RequestBoardItem";
import { ROLES, REQUEST_STATE } from "../../features/Constant";
import { UserInfo, LanguageSet, RequestPage } from "../../recoil";
import { useRecoilValue, useRecoilState } from "recoil";
import { useNavigate } from "react-router-dom";
import * as HTTPRequestManager from "../../features/HTTPRequestManager";
import * as Utils from '../../features/Utils';
import "../styles/CommonStyle.css";

export default function RequestBoard(Props) {
  const languageSet = useRecoilValue(LanguageSet);
  const userInfo = useRecoilValue(UserInfo);

  const navigate = useNavigate();
  const [requestList, setRequestList] = useState([]);
  const [requestCount, setRequestCount] = useState(1);

  const [requestPage, setRequestPage] = useRecoilState(RequestPage);

  // 0 : need to check(default), 1 : processing(호텔 가격 확인 중), 2 : complete (완료), 3 : revise (재확인)
  // 4 : pause (대기중), 5 : reject (거절), 6 : deleted (삭제), 7 : need to check(ITS)
  const [selectedState, setSelectedState] = useState([]);

  let searchText = useRef("");
  let refSearchInput = useRef();

  // 체크박스 표시 여부
  const [checkedAll, setCheckedAll] = useState(false);
  const [checkedList, setCheckedList] = useState([]);

  async function fetchData() {
    var response = await HTTPRequestManager.getRequestList({
      accessToken: userInfo.accessToken,
      searchText: searchText.current,
      selectedState: selectedState.toString(),
      id: requestPage,
    });

    if (response.status === 200) {
      if (response.data !== null && response.data.result !== null) {
        response.data.result.filter(item => item.state !== 6).sort((a, b) => a.createdAt > b.createdAt ? -1 : 1);
        setRequestList(response.data.result);
      } else {
        setRequestList([]);
      }

      if (response.data !== null && response.data.pageCount !== null) {
        setRequestCount(response.data.requestCount);
      }
    }
  }

  useEffect(() => {
    Utils.allowByPosition(ROLES.ITS, userInfo.rolesId)
      .then(async (res) => {
        if (!res) {
          alert("접근 권한이 없습니다.");
          navigate("/", { replace: true });
        }
        else {
          await fetchData();
        }
      })
      .catch((err) => {

      });
  }, [requestPage, selectedState]);

  const redTag = (msg) => {
    return <span className="flex-none inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10 mr-2">{msg}</span>
  }

  const checkITSTag = (msg) => {
    return <span className="flex-none inline-flex items-center rounded-md bg-[red] px-2 py-1 text-xs font-medium text-white ring-1 ring-inset ring-red-600/10 mr-2">{msg}</span>
  }

  const yellowTag = (msg) => {
    return <span className="flex-none inline-flex items-center rounded-md bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-800 ring-1 ring-inset ring-yellow-600/20 mr-2">{msg}</span>
  }

  const greenTag = (msg) => {
    return <span className="flex-none inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20 mr-2">{msg}</span>
  }

  const blueTag = (msg) => {
    return <span className="flex-none inline-flex items-center rounded-md bg-blue-50 px-2 py-1 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10 mr-2">{msg}</span>
  }

  const purpleTag = (msg) => {
    return <span className="flex-none inline-flex items-center rounded-md bg-purple-50 px-2 py-1 text-xs font-medium text-purple-700 ring-1 ring-inset ring-purple-700/10 mr-2">{msg}</span>
  }

  const blackTag = (msg) => {
    return <span className="flex-none inline-flex items-center rounded-md bg-[black] px-2 py-1 text-xs font-medium text-[white] ring-1 ring-inset ring-[black] mr-2">{msg}</span>
  }

  const needToHotelPriceTag = (msg) => {
    return <span className="flex-none inline-flex items-center rounded-md bg-[#A1EEBD] px-2 py-1 text-xs font-medium text-[white] ring-1 ring-inset ring-[#A1EEBD] mr-2">{msg}</span>
  }

  const completeHotelPriceTag = (msg) => {
    return <span className="flex-none inline-flex items-center rounded-md bg-[#89A8B2] px-2 py-1 text-xs font-medium text-[white] ring-1 ring-inset ring-[#89A8B2] mr-2">{msg}</span>
  }

  const unselectedTag = (msg) => {
    return <span
      className="flex-none inline-flex items-center rounded-md px-2 py-1 text-xs font-medium cursor-pointer bg-[#ababab] text-[white] ring-1 ring-inset ring-[#ababab] mr-2">
      {msg}
    </span>
  }

  // 각 항목 업데이트
  function updateChecked(id, checked) {
    let tmpList = [];
    for (let i = 0; i < checkedList.length; i++) {
      tmpList.push(checkedList[i]);
    }

    if (checked) {
      tmpList.push(id);
    } else {
      tmpList = checkedList.filter(item => item !== id);
    }

    setCheckedList(tmpList);
    isSelectedAll(tmpList);
  }

  // 전체 선택 업데이트
  function updateCheckedAll(checked) {
    let tmpList = [];
    if (checked) {
      tmpList = requestList.map(item => { return item.id });
    }

    setCheckedList(tmpList);
    isSelectedAll(tmpList);
  }

  // 전체 선택 여부 확인
  function isSelectedAll(list) {
    let requestIdList = requestList.map(request => request.id);
    setCheckedAll(requestIdList.every(item => list.includes(item)));
  }

  const updateButton = (state) => {
    let title = "";
    let color = "#000";
    switch (state) {
      case 0:
        title = "Need To Check";
        color = "#C91C1C";
        break;
      case 1:
        title = "Processing";
        color = "#854D0E";
        break;
      case 2:
        title = "Complete";
        color = "#15803D";
        break;
      case 3:
        title = "Revise";
        color = "#1D4ED8";
        break;
      case 4:
        title = "Pause";
        color = "#7F23D7";
        break;
      case 5:
        title = "Reject";
        break;
      case 6:
        title = "삭제!!";
        color = "#f00";
        break;
      case 7:
        title = "Need To Check(ITS)";
        color = "orange";
        break;
      case 8:
        title = "Need to Hotel Price";
        color = "#A1EEBD";
        break;
      case 9:
        title = "Complete Hotel Price";
        color = "#89A8B2";
        break;
    }

    return <div
      className="flex select-none outline-none font-bold cursor-pointer py-[5px] px-[6px]"
      style={{
        color: checkedList.length > 0 ? color : "#B2B2B4",
      }}
      onClick={async () => {
        if (checkedList.length < 1) {
          return;
        }

        let deleteMsg = checkedList.toString();
        let answer = window.confirm("Update to \"" + title + "\"?\n" + deleteMsg);

        if (answer) {
          let response = await HTTPRequestManager.updateRequestList({
            accessToken: userInfo.accessToken,
            list: checkedList,
            state: state,
          });

          if (response !== undefined && response.data !== undefined) {
            if (response.data?.result) {
              fetchData();
              updateCheckedAll(false);
            }
          }
        }
      }}
    >
      {title}
    </div>
  }

  return (
    <div className="flex flex-col w-full">
      <div className="flex flex-row mb-2 justify-end">
        <div className="mr-1">Total</div>
        <div className="mr-1 font-bold">{requestCount}</div>
      </div>
      <div className="mb-4">
        <div className="scrollRemoveBox flex flex-row md:w-full overflow-auto select-none">
          <div onClick={() => {
            setRequestPage(0);

            if (selectedState.find(item => item === REQUEST_STATE.NEEDTOCHECK) !== undefined) {
              setSelectedState(selectedState.filter(item => item !== REQUEST_STATE.NEEDTOCHECK));
            } else {
              setSelectedState([...selectedState, REQUEST_STATE.NEEDTOCHECK]);
            }
          }}>
            {
              selectedState.find(item => item === REQUEST_STATE.NEEDTOCHECK) !== undefined ? redTag("Need to check") : unselectedTag("Need to check")
            }
          </div>
          <div onClick={() => {
            setRequestPage(0);

            if (selectedState.find(item => item === REQUEST_STATE.NEEDTOCHECKITS) !== undefined) {
              setSelectedState(selectedState.filter(item => item !== REQUEST_STATE.NEEDTOCHECKITS));
            } else {
              setSelectedState([...selectedState, REQUEST_STATE.NEEDTOCHECKITS]);
            }
          }}>
            {
              selectedState.find(item => item === REQUEST_STATE.NEEDTOCHECKITS) !== undefined ? checkITSTag("NEED TO CHECK(ITS)") : unselectedTag("NEED TO CHECK(ITS)")
            }
          </div>
          <div onClick={() => {
            setRequestPage(0);

            if (selectedState.find(item => item === REQUEST_STATE.PROCESSING) !== undefined) {
              setSelectedState(selectedState.filter(item => item !== REQUEST_STATE.PROCESSING));
            } else {
              setSelectedState([...selectedState, REQUEST_STATE.PROCESSING]);
            }
          }}>
            {
              selectedState.find(item => item === REQUEST_STATE.PROCESSING) !== undefined ? yellowTag("Processing") : unselectedTag("Processing")
            }
          </div>
          <div onClick={() => {
            setRequestPage(0);

            if (selectedState.find(item => item === REQUEST_STATE.COMPLETE) !== undefined) {
              setSelectedState(selectedState.filter(item => item !== 2));
            } else {
              setSelectedState([...selectedState, REQUEST_STATE.COMPLETE]);
            }
          }}>
            {
              selectedState.find(item => item === REQUEST_STATE.COMPLETE) !== undefined ? greenTag("Complete") : unselectedTag("Complete")
            }
          </div>
          <div onClick={() => {
            setRequestPage(0);

            if (selectedState.find(item => item === REQUEST_STATE.REVISE) !== undefined) {
              setSelectedState(selectedState.filter(item => item !== REQUEST_STATE.REVISE));
            } else {
              setSelectedState([...selectedState, REQUEST_STATE.REVISE]);
            }
          }}>
            {
              selectedState.find(item => item === REQUEST_STATE.REVISE) !== undefined ? blueTag("Revise") : unselectedTag("Revise")
            }
          </div>
          <div onClick={() => {
            setRequestPage(0);

            if (selectedState.find(item => item === REQUEST_STATE.PAUSE) !== undefined) {
              setSelectedState(selectedState.filter(item => item !== REQUEST_STATE.PAUSE));
            } else {
              setSelectedState([...selectedState, REQUEST_STATE.PAUSE]);
            }
          }}>
            {
              selectedState.find(item => item === REQUEST_STATE.PAUSE) !== undefined ? purpleTag("Pause") : unselectedTag("Pause")
            }
          </div>
          <div onClick={() => {
            setRequestPage(0);

            if (selectedState.find(item => item === REQUEST_STATE.REJECT) !== undefined) {
              setSelectedState(selectedState.filter(item => item !== REQUEST_STATE.REJECT));
            } else {
              setSelectedState([...selectedState, REQUEST_STATE.REJECT]);
            }
          }}>
            {
              selectedState.find(item => item === REQUEST_STATE.REJECT) !== undefined ? blackTag("Reject") : unselectedTag("Reject")
            }
          </div>
          <div onClick={() => {
            setRequestPage(0);

            if (selectedState.find(item => item === REQUEST_STATE.NEEDTOHOTELPRICE) !== undefined) {
              setSelectedState(selectedState.filter(item => item !== REQUEST_STATE.NEEDTOHOTELPRICE));
            } else {
              setSelectedState([...selectedState, REQUEST_STATE.NEEDTOHOTELPRICE]);
            }
          }}>
            {
              selectedState.find(item => item === REQUEST_STATE.NEEDTOHOTELPRICE) !== undefined ? needToHotelPriceTag("Need to Hotel Price") : unselectedTag("Need to Hotel Price")
            }
          </div>
          <div onClick={() => {
            setRequestPage(0);

            if (selectedState.find(item => item === REQUEST_STATE.COMPLETEHOTELPRICE) !== undefined) {
              setSelectedState(selectedState.filter(item => item !== REQUEST_STATE.COMPLETEHOTELPRICE));
            } else {
              setSelectedState([...selectedState, REQUEST_STATE.COMPLETEHOTELPRICE]);
            }
          }}>
            {
              selectedState.find(item => item === REQUEST_STATE.COMPLETEHOTELPRICE) !== undefined ? completeHotelPriceTag("Complete Hotel Price") : unselectedTag("Complete Hotel Price")
            }
          </div>
        </div>
      </div>
      <div className="flex w-full h-[2px] bg-[#000000]" />
      <div className="flex flex-row py-1" style={{
        display: userInfo.rolesId === ROLES.GOD ? "" : "none",
      }}>
        <label className="flex items-center select-none mr-5" >
          <input id={`allCheckBox`} className="cursor-pointer" type="checkbox" checked={checkedAll} onChange={() => {
            var checkbox = document.getElementById(`allCheckBox`);
            updateCheckedAll(checkbox.checked);
          }} />
        </label>
        {
          updateButton(0)
        }
        {
          updateButton(7)
        }
        {
          updateButton(1)
        }
        {
          updateButton(2)
        }
        {
          updateButton(3)
        }
        {
          updateButton(4)
        }
        {
          updateButton(5)
        }
        {
          updateButton(6)
        }
        {
          updateButton(8)
        }
        {
          updateButton(9)
        }
      </div>
      <div className="flex w-full h-[2px] bg-[#EDEDF0]" style={{ display: userInfo.rolesId === ROLES.GOD ? "" : "none" }} />
      <div className="h-[409px] md:h-full">
        {
          requestList.map(item => {
            return (
              <RequestBoardItem key={item.id} data={item} showCheckBox={userInfo.rolesId === ROLES.GOD} onCheck={(checked) => {
                updateChecked(item.id, checked);
              }} checked={checkedList.find(id => id === item.id) !== undefined} />
            )
          })
        }
      </div>
      <div className="flex w-full h-[1px] bg-[#000000]" />
      <div className="mb-2" />

      <div className="flex justify-between text-[13px]">
        <div>
          <button
            style={{
              outline: "none",
              background: "#000",
              color: "#fff",
              borderRadius: 5,
              paddingTop: 5,
              paddingBottom: 5,
              paddingRight: 7,
              paddingLeft: 7,
            }}
            onClick={async () => {
              searchText.current = "";
              refSearchInput.current.value = "";

              setRequestPage(0);
              setSelectedState([]);

              await fetchData();
            }}
          >
            {languageSet.requestBoardAllButton}
          </button>
        </div>
        <button
          style={{
            outline: "none",
            background: "#000",
            color: "#fff",
            borderRadius: 5,
            paddingTop: 5,
            paddingBottom: 5,
            paddingRight: 7,
            paddingLeft: 7,
          }}
          onClick={async () => {
            navigate("/requestBoardWriter", { state: {} });
          }}
        >
          {languageSet.requestBoardWriteButton}
        </button>
      </div>
      <div className="mb-5" />
      <div className="flex justify-center">
        <div className="flex w-[400px] mb-[15px] ">
          <input
            className="placeholder:text-slate-400 block bg-white border border-slate-300 py-2 pl-2 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 rounded"
            placeholder={languageSet.requestBoardFindHint}
            type="text"
            name="search"
            style={{ flex: 1, marginTop: 5, marginLeft: 5 }}
            onChange={(e) => {
              searchText.current = e.target.value;
            }}
            onKeyDown={async (e) => {
              if (e.key === 'Enter') {
                setRequestPage(0);
                await fetchData();
              }
            }}
            ref={refSearchInput}
          />
        </div>
      </div>
      <div className="flex justify-center">
        <PageCounter setSelectedIndex={setRequestPage} selectedIndex={requestPage} totalPageCount={requestCount % 10 === 0 ? requestCount / 10 : Math.floor(requestCount / 10) + 1} />
      </div>
    </div >
  )
}