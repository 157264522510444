import React, { useState } from "react";
import { useTable, useGlobalFilter, useSortBy } from "react-table";
import { UserInfo } from "../../recoil";
import { useRecoilValue } from "recoil";

export default function ReservationListTable({ columns, data, }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
    setGlobalFilter,
  } = useTable({ columns, data }, useGlobalFilter, useSortBy);

  const userInfo = useRecoilValue(UserInfo);

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  return (
    <>
      <table {...getTableProps()} style={{ borderCollapse: "collapse" }}>
        <thead>
          {
            headerGroups.map((headerGroup) => {
              return (
                <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th key={column.id} {...column.getHeaderProps(column.getSortByToggleProps())} style={{
                      minWidth: column.minWidth,
                      width: column.width,
                      border: "1px solid rgb(0, 0, 0)",
                      fontSize: 13,
                      position: "sticky",
                      top: 0,
                      background: "#ccc",
                    }}>
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              )
            })
          }
        </thead>
        <tbody {...getTableBodyProps()}>
          {
            rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} key={row.index}>
                  {row.cells.map((cell) => {
                    return (
                      <td key={cell.row.id + " " + cell.column.index}
                        {...cell.getCellProps({
                          style: cell.column.style,
                        })}
                      >
                        {
                          cell.render("Cell")
                        }
                      </td>
                    )
                  })}
                </tr>
              );
            })
          }
        </tbody>
      </table>
    </>
  );
}