import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretLeft } from "@fortawesome/free-solid-svg-icons";
import { faCaretRight } from "@fortawesome/free-solid-svg-icons";

export default function CalendarMovingBox({ year, setYear, month, setMonth, clear }) {
  const btnMoveMonthClass = "text-3xl pointerhover:hover:cursor-pointer px-4 py-1 pointerhover:hover:bg-gray-200 rounded-full focus:outline-none";

  return (
    <div style={{
      marginBottom: 5, display: "flex", width: "100%", justifyContent: "center", padding: 10,
    }}
    >
      <span className={btnMoveMonthClass} style={{ fontSize: 25, cursor: "pointer" }} onClick={() => {
        if (month - 1 < 1) {
          setYear(year - 1);
          month = 13;
          year = year - 1;
        }

        setMonth(month - 1);

        if (clear !== undefined && clear !== null)
          clear();
      }} >
        <FontAwesomeIcon icon={faCaretLeft} style={{ color: "#777" }} />
      </span>
      <div
        id="title"
        className="flex flex-row justify-center items-center text-[25px] select-none cursor-pointer">
        <div className="mr-[5px]" onClick={() => {
        }}>{year}년</div>
        <div onClick={() => {
        }}>{month}월</div>
      </div>
      <span className={btnMoveMonthClass} style={{ fontSize: 25, cursor: "pointer" }} onClick={() => {
        if (month + 1 > 12) {
          setYear(year + 1);
          month = 0;
          year = year + 1;
        }

        setMonth(month + 1);

        if (clear !== undefined && clear !== null)
          clear();
      }}>
        <FontAwesomeIcon icon={faCaretRight} style={{ color: "#777" }} />
      </span>
    </div>
  );
}