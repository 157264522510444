import React, { useState, } from "react";

export default function DragDropFile(Props) {
  // drag state
  const [dragActive, setDragActive] = useState(false);

  // select state
  const [isSelected, setIsSelected] = useState(false);

  // selected file
  const [selectedFile, setSelectedFile] = useState("");

  const inputRef = React.useRef(null);

  const handleFiles = function (files) {
    Props.setFile(files[0]);
    setSelectedFile(files[0]);
    setIsSelected(true);
  }

  function getColorFromExt(type) {
    let textColor = "bg-[#000000] text-[#ffffff]";
    switch (type) {
      case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
      case "application/vnd.ms-powerpoint":
        textColor = "bg-[#C43E1C] text-[#ffffff]";
        break;
      case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      case "application/haansoftdocx":
      case "application/msword":
      case "application/haansoftdoc":
        textColor = "bg-[#185ABD] text-[#ffffff]";
        break;
      case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
      case "application/haansoftxlsx":
      case "application/vnd.ms-excel":
      case "application/haansoftxls":
        textColor = "bg-[#107C41] text-[#ffffff]";
        break;
      case "application/pdf":
        textColor = "bg-[#B30C00] text-[#ffffff]";
        break;
      default:
        textColor = "bg-[#000000] text-[#ffffff]";
        break;
    }

    return textColor;
  }

  // handle drag events
  const handleDrag = function (e) {
    setIsSelected(false);
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  // triggers when file is dropped
  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleFiles(e.dataTransfer.files);
    } else {
      setSelectedFile("");
      Props.setFile("");
      setIsSelected(false);
    }
  };

  // triggers when file is selected with click
  const handleChange = function (e) {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      handleFiles(e.target.files);
    } else {
      setSelectedFile("");
      Props.setFile("");
      setIsSelected(false);
    }
  };

  return (
    <form id="form-file-upload" onDragEnter={handleDrag} onSubmit={(e) => e.preventDefault()} style={{
      height: "10rem",
      maxWidth: "100%",
      textAlign: "center",
      position: "relative"
    }}>
      <input ref={inputRef} type="file" id="input-file-upload" multiple={true} onChange={handleChange} style={{ display: "none" }} />
      <label id="label-file-upload" htmlFor="input-file-upload" className="flex flex-col" style={{
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderWidth: "2px",
        borderRadius: "1rem",
        borderStyle: "dashed",
        borderColor: "#cbd5e1",
        backgroundColor: dragActive ? "#ffffff" : "#f8fafc",
      }}>
        <div className="cusor-pointer mb-[3px]">
          <p className="normal-case">(Drag and drop) or (click)</p>
        </div>
        {
          isSelected &&
          <div className="flex flex-row normal-case ">
            <div className="p-1 mr-1">File :</div>
            <div className={`py-1 px-2 rounded ${getColorFromExt(selectedFile?.type)}`}>{selectedFile?.name}</div>
          </div>
        }
      </label>
      {dragActive && <div id="drag-file-element" onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop} style={{
        position: "absolute",
        width: "100%",
        height: "100%",
        borderRadius: "1rem",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      }}></div>}
    </form>
  );
};