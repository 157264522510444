import React, { useState, useEffect, forwardRef } from "react";
import * as Utils from "../features/Utils";
import * as HTTPManager from "../features/HTTPManager";
import { UserInfo } from "../recoil";
import { useRecoilValue } from "recoil";
import { useLocation, useNavigate } from "react-router-dom";
import ColorButton from "./ColorButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { ROLES } from "../features/Constant";

export default function DetailTour() {
  const location = useLocation();
  const navigate = useNavigate();
  const userInfo = useRecoilValue(UserInfo);
  const tourInfo = location.state.tourInfo;

  const [userList, setUserList] = useState([]);

  const [showModifyInput, setShowModifyInput] = useState(false);
  const [uploadFileList, setUploadFileList] = useState([]);

  async function fetchData() {
    Utils.allowByPosition(ROLES.GUIDE, userInfo.rolesId)
      .then(async (res) => {
        if (!res) {
          alert("접근 권한이 없습니다.");
          navigate("/", { replace: true });
        }
        else {
          if (userInfo.rolesId === ROLES.ADMIN) {
            let reseponse = await HTTPManager.GetUserList({
              accessToken: userInfo.accessToken,
            });

            if (reseponse !== undefined) {
              setUserList(reseponse.data.result);
            }
          }
        }
      })
      .catch((err) => {
        console.log(err);
        alert(err.message);
      });
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div
      style={{
        width: "100%",
        flex: 1,
        position: "relative",
      }}
    >
      <div
        className="mt-2 md:mt-14"
        style={{
          padding: 20,
          width: "100%",
          flex: 1,
          backgroundColor: "#fff",
          position: "relative",
          alignItems: "center",
        }}
      >
        {
          // 관리자만
          userInfo.rolesId === ROLES.ADMIN && (
            <div style={{ width: "100%", marginBottom: 10, display: "flex" }}>
              <ColorButton
                title="수정"
                color="slateblue"
                handler={() => setShowModifyInput(!showModifyInput)}
              />
              <ColorButton
                title={tourInfo.cancel === 0 ? "취소" : "예약"}
                color={tourInfo.cancel === 0 ? "crimson" : "deepskyblue"}
                handler={async () => {
                  let answer = window.confirm(
                    (tourInfo.cancel === 0 ? "취소" : "예약") + "하시겠습니까?"
                  );
                  if (answer) {
                    //some code
                    let result = await HTTPManager.UpdateTourState({
                      accessToken: userInfo.accessToken,
                      tourId: tourInfo.id,
                      cancel: tourInfo.cancel,
                    });

                    if (result?.data) {
                      navigate(-1);
                    }
                  } else {
                  }
                }}
              />
            </div>
          )
        }
        <div style={{ marginBottom: 10, position: "relative" }}>
          <details
            className="open:bg-white dark:open:bg-slate-900 open:ring-1 open:ring-black/5 dark:open:ring-white/10 open:shadow-lg p-6 rounded-lg"
            open
          >
            <summary className="text-sm leading-6 text-slate-900 dark:text-white font-semibold select-none normal-case">
              {userInfo.rolesId === ROLES.ADMIN
                ? tourInfo.id + "번"
                : tourInfo.nameCountry}{" "}
              행사 정보
            </summary>
            <div className="mt-1 text-sm leading-6 text-slate-600 dark:text-slate-400">
              기간 :{" "}
              {tourInfo.startDate.slice(0, 10) +
                " ~ " +
                tourInfo.endDate.slice(0, 10)}
            </div>
            <div className="mt-1 text-sm leading-6 text-slate-600 dark:text-slate-400">
              일정 : {tourInfo.itinerary}
            </div>
            <div className="mt-1 text-sm leading-6 text-slate-600 dark:text-slate-400">
              인원 : {tourInfo.pax}
            </div>
            <div className="mt-1 text-sm leading-6 text-slate-600 dark:text-slate-400">
              입국 : {tourInfo.eta}
            </div>
            <div className="mt-1 text-sm leading-6 text-slate-600 dark:text-slate-400">
              출국 : {tourInfo.etd}
            </div>
            <div className="mt-1 text-sm leading-6 text-slate-600 dark:text-slate-400">
              면세점 : {tourInfo.dutyFreeShop}{" "}
              {tourInfo.dutyFreeState === 0 ? "(예약완료)" : "(확인필요)"}
            </div>
            <div className="mt-1 text-sm leading-6 text-slate-600 normal-case dark:text-slate-400 flex flex-col">
              <div className="mr-1">남이섬 QRCode</div>
              {
                (tourInfo.nami === undefined || tourInfo.nami === null) ?
                  <div>(확인필요)</div>
                  :
                  <div className="normal-case cursor-pointer hover:text-blue-600" onClick={() => {
                    if (tourInfo.nami.includes("http://") || tourInfo.nami.includes("https://"))
                      window.open(tourInfo.nami, "_blank");
                  }}>
                    {tourInfo.nami}
                  </div>
              }
            </div>
            <div className="mt-1 text-sm leading-6 text-red-500 normal-case flex flex-row font-bold">
              ※ 실제 인원과 바우처 인원이 다른 경우 사무실에 연락 부탁드립니다.
            </div>
          </details>
        </div>

        <div
          style={{
            marginBottom: 10,
            position: "relative",
            display: "flex",
            width: "100%",
          }}
        >
          <details
            style={{ width: "100%" }}
            className="open:bg-white dark:open:bg-slate-900 open:ring-1 open:ring-black/5 dark:open:ring-white/10 open:shadow-lg p-6 rounded-lg"
            open
          >
            <summary className="text-sm leading-6 text-slate-900 dark:text-white font-semibold select-none">
              로고
            </summary>
            <div
              className="mt-3 text-sm leading-6 text-slate-600 dark:text-slate-400"
              style={{
                pointerEvents:
                  (tourInfo.logo === null || tourInfo.logo === undefined) &&
                  "none",
              }}
            >
              <ColorButton
                title={
                  tourInfo.logo === null || tourInfo.logo === undefined
                    ? "업로드 요청"
                    : "확인"
                }
                color="slateblue"
                handler={async () => {
                  Utils.showContent(tourInfo.logo);
                }}
              />
            </div>
          </details>
        </div>

        <div className="flex relative mb-2 w-full">
          <details
            className="w-full open:bg-white dark:open:bg-slate-900 open:ring-1 open:ring-black/5 dark:open:ring-white/10 open:shadow-lg p-6 rounded-lg"
            open
          >
            <summary className="text-sm leading-6 text-slate-900 dark:text-white font-semibold select-none mb-2">
              루밍리스트
            </summary>
            <div
              className="mt-3 text-sm leading-6 text-slate-600 dark:text-slate-400"
              style={{
                pointerEvents:
                  (tourInfo.rooming === null ||
                    tourInfo.rooming === undefined) &&
                  "none",
              }}
            >
              <ColorButton
                title={
                  tourInfo.rooming === null || tourInfo.rooming === undefined
                    ? "업로드요청"
                    : "확인"
                }
                color="slateblue"
                handler={async () => {
                  Utils.showContent(tourInfo.rooming);
                }}
              />
            </div>
          </details>
        </div>
        <div className="flex relative mb-2 w-full">
          <details
            className="w-full open:bg-white dark:open:bg-slate-900 open:ring-1 open:ring-black/5 dark:open:ring-white/10 open:shadow-lg p-6 rounded-lg"
            open
          >
            <summary className="text-sm leading-6 text-slate-900 dark:text-white font-semibold select-none mb-2">
              행사지시서
            </summary>
            <div className="flex flex-row text-sm">
              <div
                className="w-full"
                style={{
                  pointerEvents:
                    (tourInfo.scheduleEN === null ||
                      tourInfo.scheduleEN === undefined) &&
                    "none",
                }}
              >
                <ColorButton
                  title={
                    tourInfo.scheduleEN === null ||
                      tourInfo.scheduleEN === undefined
                      ? "업로드 요청"
                      : "영어"
                  }
                  color="deepskyblue"
                  handler={async () => {
                    Utils.showContent(tourInfo.scheduleEN);
                  }}
                />
              </div>
              <div
                className="w-full"
                style={{
                  pointerEvents:
                    (tourInfo.scheduleKO === null ||
                      tourInfo.scheduleKO === undefined) &&
                    "none",
                }}
              >
                <ColorButton
                  title={
                    tourInfo.scheduleKO === null ||
                      tourInfo.scheduleKO === undefined
                      ? "업로드 요청"
                      : "한글"
                  }
                  color="crimson"
                  handler={async () => {
                    Utils.showContent(tourInfo.scheduleKO);
                  }}
                />
              </div>
            </div>
          </details>
        </div>
      </div>
      {
        // 수정 팝업
        showModifyInput && (
          <div
            className="md:mt-14"
            style={{
              position: "absolute",
              width: "100%",
              background: "#000c",
              top: 0,
              left: 0,
              padding: 20,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <span
              style={{
                display: "flex",
                marginBottom: 15,
                alignItems: "center",
                justifyContent: "right",
              }}
            >
              <FontAwesomeIcon
                icon={faCircleXmark}
                onClick={() => setShowModifyInput(!showModifyInput)}
                color="white"
                size="2x"
              />
            </span>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <div
                style={{
                  marginBottom: 10,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <div style={{ width: 80, color: "#fff" }}>로고</div>
                <input
                  type="file"
                  name="image"
                  onChange={(event) => {
                    setUploadFileList([
                      ...uploadFileList,
                      {
                        type: "logo",
                        file: event.target.files[0],
                      },
                    ]);
                  }}
                  style={{
                    color: "#000",
                    background: "#fff",
                    flex: "100%",
                    borderRadius: 5,
                  }}
                />
              </div>

              <div
                style={{
                  marginBottom: 10,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <div style={{ width: 80, color: "#fff" }}>루밍</div>
                <input
                  type="file"
                  onChange={(event) => {
                    setUploadFileList([
                      ...uploadFileList,
                      {
                        type: "rooming",
                        file: event.target.files[0],
                      },
                    ]);
                  }}
                  style={{
                    color: "#000",
                    background: "#fff",
                    flex: "100%",
                    borderRadius: 5,
                  }}
                />
              </div>

              <div
                style={{
                  marginBottom: 10,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <div style={{ width: 80, color: "#fff" }}>한글행지</div>
                <input
                  type="file"
                  onChange={(event) => {
                    setUploadFileList([
                      ...uploadFileList,
                      {
                        type: "scheduleKO",
                        file: event.target.files[0],
                      },
                    ]);
                  }}
                  style={{
                    color: "#000",
                    background: "#fff",
                    flex: "100%",
                    borderRadius: 5,
                  }}
                />
              </div>

              <div
                style={{
                  marginBottom: 10,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <div style={{ width: 80, color: "#fff" }}>영어행지</div>
                <input
                  type="file"
                  onChange={(event) => {
                    setUploadFileList([
                      ...uploadFileList,
                      {
                        type: "scheduleEN",
                        file: event.target.files[0],
                      },
                    ]);
                  }}
                  style={{
                    color: "#000",
                    background: "#fff",
                    flex: "100%",
                    borderRadius: 5,
                  }}
                />
              </div>

              <span>
                <ColorButton
                  title="확인"
                  color="slateblue"
                  handler={async () => {
                    if (uploadFileList.length < 1) return;

                    var res = await HTTPManager.ModifyTourInfo({
                      accessToken: userInfo.accessToken,
                      tourId: location.state.tourId,
                      fileList: uploadFileList,
                    });

                    if (res?.data?.result) {
                      alert("업로드 완료!");
                      setShowModifyInput(false);
                      fetchData();
                      navigate(-1);
                    } else {
                      alert("업로드 실패!")
                      setShowModifyInput(false);
                    }

                    setUploadFileList([]);
                  }}
                />
              </span>
            </div>
          </div>
        )
      }
    </div>
  );
}
