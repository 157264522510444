import React, { useState, useEffect } from "react";
import * as Utils from '../features/Utils';
import * as HTTPManager from '../features/HTTPManager';
import { UserInfo } from "../recoil";
import { useRecoilValue } from "recoil";
import ModifyModal from "./ModifyModal";
import { useNavigate } from "react-router-dom";
import { ROLES } from "../features/Constant";
import UploadDialog from "./hotelList/UploadDialog";
import RegistHotel from "./RegistHotel";
import ColorButton from "./ColorButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHouse } from "@fortawesome/free-solid-svg-icons";
import { faCalendar } from "@fortawesome/free-regular-svg-icons";

export default function HotelList() {
  const navigate = useNavigate();

  const userInfo = useRecoilValue(UserInfo);
  const [hotelList, setHotelList] = useState([]);
  const [hotelFilterList, setHotelFilterList] = useState([]);
  const [priceList, setPriceList] = useState([]);
  const [priceFilterList, setPriceFilterList] = useState([]);

  const [showModify, setShowModify] = useState(false);
  const [modifyValue, setModifyValue] = useState("");
  const [modifyType, setModifyType] = useState("");
  const [modifyHotelId, setModifyHotelId] = useState(0);
  const [modifyHotelPriceId, setModifyHotelPriceId] = useState(0);

  const [selectedHotelInfo, setSelectedHotelInfo] = useState("");
  const [showUploadDialog, setShowUploadDialog] = useState(false);

  const [showAddHotel, setShowAddHotel] = useState(false);
  const [toursHotelInfo, setToursHotelInfo] = useState([]);

  async function fetchData() {
    var response = await HTTPManager.GetHotelList({
      accessToken: userInfo.accessToken
    });

    if (response !== undefined) {
      setHotelList(response.data.hotelList);
      setHotelFilterList(response.data.hotelList);

      setPriceList(response.data.priceList);
      setPriceFilterList(response.data.priceList);
    }

    response = await HTTPManager.GetTourReservation({
      accessToken: userInfo.accessToken,
    });

    if (response !== undefined && response.data !== undefined) {
      setToursHotelInfo(response.data.toursHotel);
    }
  }

  useEffect(() => {
    Utils.allowByPosition(ROLES.ADMIN, userInfo.rolesId)
      .then(async (res) => {
        if (!res) {
          alert("접근 권한이 없습니다.");
          navigate("/", { replace: true });
        }
        else {
          await fetchData();
        }
      })
      .catch((err) => {
        console.log(err);
        alert(err.message);
      });
  }, []);

  return (
    <div className="w-full h-full flex flex-col bg-[white] mt-0 md:mt-14 px-[50px] md:px-[30px] pb-5">
      <div className="text-start mt-[50px] text-[#9299A1]">호텔</div>
      <div className="text-start mt-[50px] text-[#333A40] font-bold text-[30px]">등록 및 목록</div>

      <div className="flex flex-col w-full h-full">
        <div className="w-full flex flex-row gap-8 mt-[25px] scrollRemoveBox px-1">
          <div className="md:flex-none flex flex-col gap-2 rounded-lg shadow-lg px-5 py-6 w-1/3 md:w-full mb-5">
            <div className="flex flex-row gap-3 font-bold">
              <span className={"cursor-pointer"}>
                <FontAwesomeIcon icon={faHouse} style={{ color: "#1D3C77" }} />
              </span>
              <div>등록</div>
            </div>
            <div className="flex w-full justify-end text-[25px] font-bold">{Utils.formatNumber(hotelFilterList?.length)}곳</div>
          </div>

          <div className="md:flex-none flex flex-col gap-2 rounded-lg shadow-lg px-5 py-6 w-1/3 md:w-full mb-5">
            <div className="flex flex-row gap-3 font-bold">
              <span className={"cursor-pointer"}>
                <FontAwesomeIcon icon={faHouse} style={{ color: "#1D3C77" }} />
              </span>
              <div>미사용</div>
            </div>
            <div className="flex w-full justify-end text-[25px] font-bold">
              {
                Utils.formatNumber(hotelList.filter(hotel => toursHotelInfo.find(toursHotel => toursHotel.hotelId === hotel.id) === undefined)?.length)
              }곳</div>
          </div>

          <div className="md:flex-none flex flex-col gap-2 rounded-lg shadow-lg px-5 py-6 w-1/3 md:w-full mb-5">
            <div className="flex flex-row gap-3 font-bold">
              <span className={"cursor-pointer"}>
                <FontAwesomeIcon icon={faCalendar} style={{ color: "#1D3C77" }} />
              </span>
              <div>총 예약수</div>
            </div>
            <div className="flex w-full justify-end text-[25px] font-bold">{Utils.formatNumber(hotelFilterList.reduce((a, b) => {
              a = a + toursHotelInfo.filter(item => item.hotelId === b.id)?.length;
              return a;
            }, 0))}건</div>
          </div>
        </div>

        <div className="flex flex-row justify-start my-[10px]">
          <input
            className="placeholder:text-slate-400 block bg-white border border-slate-300 rounded-md py-2 pl-3 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1"
            placeholder="검색"
            type="text"
            name="pax"
            style={{ flex: 1 }}
            onChange={(e) => {
              let searchList = [];
              searchList = hotelList.filter(item => item.address1?.includes(e.target.value) || item.address2?.includes(e.target.value)
                || item.breakfast?.includes(e.target.value) || item.memo?.includes(e.target.value) || item.name?.includes(e.target.value))
              setHotelFilterList(searchList);
            }}
          />
        </div>

        <div className="flex justify-end">
          <ColorButton title="등록" color="black" handler={() => setShowAddHotel(!showAddHotel)} />
        </div>

        <div className="w-full h-[1px] bg-[#DCDCDC] my-[25px]" />

        <div className="grid grid-cols-[5%_10%_10%_15%_15%_10%_30%_5%] sticky top-0 w-full text-[white] text-center text-[16px] font-bold bg-[black] py-3">
          <div>ID</div>
          <div>시도</div>
          <div>군구</div>
          <div>이름</div>
          <div>영문</div>
          <div>조식</div>
          <div>비고</div>
          <div>예약수</div>
        </div>
        <table>
          <tbody>
            {
              hotelFilterList.sort((a, b) => {
                if (toursHotelInfo.filter(item => item.hotelId === a.id)?.length > toursHotelInfo.filter(item => item.hotelId === b.id)?.length)
                  return -1;
                else
                  return 1;
              }).map((hotel, index) => {
                return (
                  <tr key={hotel.id} className="h-full grid grid-cols-[5%_10%_10%_15%_15%_10%_30%_5%] text-center border-[#E2E2E2] border-x-[1px] border-b-[1px] text-[#A8A9A8] font-semibold justify-center items-center">
                    <td className="flex py-5 items-center justify-center">{hotel.id}</td>
                    <td className="flex py-5 items-center justify-center text-[black]" onClick={() => {
                      setModifyHotelId(hotel.id);
                      setModifyType("address1");
                      setModifyValue(hotel.address1);
                      setShowModify(true);
                    }}>{hotel.address1}</td>
                    <td className="flex py-5 items-center justify-center text-[black]" onClick={() => {
                      setModifyHotelId(hotel.id);
                      setModifyType("address2");
                      setModifyValue(hotel.address2);
                      setShowModify(true);
                    }}>{hotel.address2}</td>
                    <td className="flex py-5 items-center justify-center text-[deepskyblue]" onClick={() => {
                      setModifyHotelId(hotel.id);
                      setModifyType("name");
                      setModifyValue(hotel.name);
                      setShowModify(true);
                    }}>{hotel.name}</td>
                    <td className="flex py-5 items-center justify-center text-[deepskyblue]" onClick={() => {
                      setModifyHotelId(hotel.id);
                      setModifyType("nameEN");
                      setModifyValue(hotel.nameEN);
                      setShowModify(true);
                    }}>{hotel.nameEN}</td>
                    <td className="flex py-5 items-center justify-center text-[black]" onClick={() => {
                      setModifyHotelId(hotel.id);
                      setModifyType("breakfast");
                      setModifyValue(hotel.breakfast);
                      setShowModify(true);
                    }}>{Utils.formatNumber(hotel.breakfast)}</td>
                    <td className="flex py-5 items-center justify-center text-[red]" onClick={() => {
                      setModifyHotelId(hotel.id);
                      setModifyType("memo");
                      setModifyValue(hotel.memo);
                      setShowModify(true);
                    }}>{hotel.memo}</td>
                    <td>{Utils.formatNumber(toursHotelInfo.filter(item => item.hotelId === hotel.id)?.length)}</td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>
      </div>
      <ModifyModal show={showModify} setShow={setShowModify} value={modifyValue} setValue={setModifyValue} onClick={async () => {
        let res = await HTTPManager.UpdateHotelInfo({
          accessToken: userInfo.accessToken,
          hotelId: modifyHotelId,
          hotelPriceId: modifyHotelPriceId,
          value: modifyValue,
          type: modifyType,
        })

        if (res?.data?.result) {
          await fetchData();
        } else {
          alert("변경실패!");
        }

        setShowModify(false);
      }} />
      {
        showUploadDialog &&
        <UploadDialog
          title={modifyValue}
          setShow={setShowUploadDialog}
          data={selectedHotelInfo}
          refresh={fetchData}
          type={"priceFile"}
        />
      }
      <div className="fixed flex flex-col w-full md:top-14">
        {
          showAddHotel &&
          <>
            <div className="flex w-full h-full text-center">
              <RegistHotel setShow={setShowAddHotel} refresh={fetchData} />
            </div>
          </>
        }
      </div>
    </div>
  );
}