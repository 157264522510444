import React, { useState, useEffect } from "react";
import { useRecoilValue } from "recoil";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquareMinus, faCaretDown } from "@fortawesome/free-solid-svg-icons";
import * as HTTPRequestManager from '../../features/HTTPRequestManager';
import { UserInfo } from "../../recoil";
import ColorButton from "../ColorButton"

export default function RequestBoardHotelPriceDialog(Props) {
  const body = document.querySelector('body');
  const scrollPosition = window.pageYOffset;

  const userInfo = useRecoilValue(UserInfo);

  const [selectedList, setSelectedList] = useState([]);

  const [needUpdate, setNeedUpdate] = useState(false);  // false : 예약 true : 변경
  const [isDirty, setIsDirty] = useState(false);        // 수정여부 확인

  async function fetchData() {

  }

  async function updateRequestHotelPrice() {
    let res = await HTTPRequestManager.insertRequestHotelPrice({
      requestId: Props.requestInfo?.id,
      list: selectedList,
    });

    if (res.data?.result) {
      Props.refresh();
      Props.setShow(false);
    }
  }

  const selectedListHandler = (value, idx, type) => {
    setIsDirty(true);

    const updatedState = selectedList;
    const updatedFormElement = {
      ...updatedState[idx]
    }

    switch (type) {
      case 'name':
        updatedFormElement.name = value;
        break;
      case 'content':
        updatedFormElement.content = value;
        break;
      default:
        break;
    }

    updatedState[idx] = updatedFormElement;

    setSelectedList([...updatedState]);
  }

  useEffect(() => {
    // 데이터 수신
    fetchData();

    // 이벤트 리스너 예제
    //window.addEventListener("resize", curScreenLocation, { capture: true })

    // 모달 팝업 시 호출
    // 스크롤 최상단 위치 후 배경 스크롤 되지 않도록 고정
    body.style.overflow = 'hidden';

    // 모달 종료 시 호출
    return () => {
      body.style.removeProperty('overflow');
      //window.removeEventListener("resize", curScreenLocation);
    };
  }, []);

  const curScreenLocation = () => {
    //console.log(window.innerHeight);
  };

  return (
    <div className="fixed h-full w-full left-0 top-0 text-sm bg-[#0007] overflow-auto z-[999] font-medium">
      <div className="fixed h-full w-full bg-white overflow-auto">
        <div className="flex flex-row p-2 items-center">
          <div className="flex flex-1 font-bold">
            <div>요청사항 {Props.requestInfo?.id}번</div>
          </div>

          <div style={{
            opacity: !needUpdate ? 1 : (!isDirty ? 0.5 : 1),
            pointerEvents: !needUpdate ? "all" : !isDirty && "none"
          }}>
            <ColorButton title={needUpdate ? "변경" : "등록"} color="deepskyblue" handler={async () => {
              var answer = window.confirm((needUpdate ? "변경" : "등록") + "하시겠습니까?");
              if (answer) {
                await updateRequestHotelPrice();
              }
            }} />
          </div>
          <div className="w-fit">
            <ColorButton title="취소" color="crimson" handler={async () => {
              Props.setShow(false);
            }} />
          </div>
        </div>

        <div className="justify-center flex py-1.5 mb-2 font-bold bg-[crimson] text-[white] selected-none cursor-pointer"
          onClick={() => {
            setSelectedList([...selectedList, {
              name: "",
              content: "",
            }]);
          }}>
          항목추가
        </div>
        {
          selectedList?.map((requestInfo, index) => {
            return (
              <div
                key={index}
                className="relative flex flex-col md:flex-col text-left pl-2 pr-2 md:pr-4 w-full mb-2 items-center md:items-start normal-case">
                <div className="flex flex-row w-full">
                  <div className="text-[18px] text-[grey] mr-2 cursor-pointer" onClick={() => {
                    const tempArray = [...selectedList];
                    tempArray.splice(index, 1);
                    setSelectedList(tempArray);
                    setIsDirty(true);
                  }}>
                    <FontAwesomeIcon icon={faSquareMinus} />
                  </div>

                  <div className="flex flex-row text-left gap-1">
                    <div className="font-bold">호텔명</div>
                    <div>({requestInfo.name})</div>
                    <div className="font-bold">가격정보</div>
                    <div>({requestInfo.content})</div>
                  </div>
                </div>
                <div className="flex flex-row md:flex-col w-full">
                  <div className="relative flex w-full md:p-1">
                    <input
                      className="rounded placeholder:text-slate-400 block bg-white border border-slate-300 py-2 pl-2 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 mr-2"
                      placeholder="호텔이름"
                      type="text"
                      name="search"
                      style={{ flex: 1, marginTop: 5, marginLeft: 5 }}
                      onChange={(e) => {
                        selectedListHandler(e.target.value, index, "name");
                      }}
                      value={requestInfo.name}
                    />
                  </div>
                  <div className="relative flex w-full md:p-1">
                    <input
                      className="rounded placeholder:text-slate-400 block bg-white border border-slate-300 py-2 pl-2 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 mr-2"
                      placeholder="내용"
                      type="text"
                      name="search"
                      style={{ flex: 1, marginTop: 5, marginLeft: 5 }}
                      onChange={(e) => {
                        selectedListHandler(e.target.value, index, "content");
                      }}
                      value={requestInfo.content}
                    />
                  </div>
                </div>
              </div>
            )
          })
        }
      </div>
    </div>
  );
}


