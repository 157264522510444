import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import * as HTTPManager from "../../features/HTTPManager";
import { useRecoilValue } from "recoil";
import { UserInfo, LanguageSet } from "../../recoil";
import "../../fonts/Font.css"

const CreateTourDialog = ({ showModal, setShowModal, refresh }) => {
  const navigate = useNavigate();

  const userInfo = useRecoilValue(UserInfo);
  const languageSet = useRecoilValue(LanguageSet);
  const [tourInfo, setTourInfo] = useState("");
  const monthList = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];

  useEffect(() => {

  }, []);

  function getTourInfo(docNum) {
    const regexp = /^\d{1,2}\s*[-]?\s*\d{1,2}\s*[a-zA-Z]{1,3}/i;

    if (regexp.exec(docNum) === null) {
      return null;
    }

    // 날짜 정보
    let dateInfo = regexp.exec(docNum)[0];
    dateInfo = dateInfo.replace(/ /g, "").toUpperCase();

    let month = monthList.find(item => dateInfo.includes(item));
    let startEnd = dateInfo.replace(month, "").split('-');

    let now = new Date();
    let startStr = now.getFullYear() + "-" + String(getMonth(month)).padStart(2, "0") + "-" + String(startEnd[0]).padStart(2, "0") + "T00:00:00";
    let endStr = now.getFullYear() + "-" + String(getMonth(month)).padStart(2, "0") + "-" + String(startEnd[1]).padStart(2, "0") + "T00:00:00";

    let result = {
      docNum: docNum,
      startDate: startStr,
      endDate: endStr,
    }

    return result;
  }

  function getMonth(month) {
    let result = 0;
    switch (month) {
      case "JAN":
        result = 1;
        break;
      case "FEB":
        result = 2;
        break;
      case "MAR":
        result = 3;
        break;
      case "APR":
        result = 4;
        break;
      case "MAY":
        result = 5;
        break;
      case "JUN":
        result = 6;
        break;
      case "JUL":
        result = 7;
        break;
      case "AUG":
        result = 8;
        break;
      case "SEP":
        result = 9;
        break;
      case "OCT":
        result = 10;
        break;
      case "NOV":
        result = 11;
        break;
      case "DEC":
        result = 12;
        break;
    }

    return result;
  }

  //엔터키 동작
  const onEnterPress = (e) => {
    if (e.key === "Enter") {
      buttonHandler();
    }
  };

  //버튼
  const buttonHandler = async () => {
    let tourInfoList = tourInfo.split("\n");
    let paramList = [];

    for (let i = 0; i < tourInfoList.length; i++) {
      let tourInfo = getTourInfo(tourInfoList[i]);
      if (tourInfo !== null)
        paramList.push(tourInfo);
    }

    if (paramList.length > 0) {
      let res = await HTTPManager.CreateMultipleTour({
        accessToken: userInfo.accessToken,
        list: paramList,
      });

      if (res !== undefined && res.data !== undefined) {
        setShowModal(false);
        refresh();
      }
    } else {
      alert('입력 형식이 잘못되었습니다.');
    }
  };

  return (
    <>
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none font-medium">
            <div className="relative w-full my-6 mx-auto max-w-3xl">
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col bg-white outline-none focus:outline-none">
                <div className="flex items-start justify-center mt-5 rounded-t">
                  <h2 className="text-base">{languageSet.createTourTitle}</h2>
                </div>
                <div className="relative p-3 flex-auto">
                  <textarea
                    rows="10"
                    className="w-full placeholder:text-slate-400 block bg-white border border-slate-300 rounded-md py-2 px-3 shadow-sm focus:outline-none"
                    placeholder={languageSet.createTourHint}
                    onChange={(e) => {
                      setTourInfo(e.target.value);
                    }}
                  //onKeyDown={onEnterPress}
                  />
                </div>

                <div className="flex w-full items-center justify-center rounded-b border-t">
                  <button
                    className="flex w-full justify-center py-3 rounded-bl-lg focus:outline-none"
                    type="button"
                    onClick={() => {
                      setShowModal(false);
                    }}
                  >
                    {languageSet.strCancel}
                  </button>
                  <button
                    className="flex w-full bg-[black] text-[white] justify-center py-3 rounded-br-lg focus:outline-none"
                    type="button"
                    onClick={() => {
                      let tourInfoList = tourInfo.split("\n").filter(item => item.length > 0);

                      if (tourInfoList.length > 0) {
                        let answer = window.confirm(
                          `투어 ${tourInfoList.length}개를 생성 하시겠습니까?`
                        );

                        if (answer) {
                          buttonHandler();
                        }
                      }
                    }}
                  >
                    {languageSet.createTourOk}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
};

export default CreateTourDialog;
