import React, { useState, useEffect, forwardRef, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretLeft } from "@fortawesome/free-solid-svg-icons";
import { faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { faMessage } from "@fortawesome/free-solid-svg-icons";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { UserInfo, ScheduleListScroll } from "../../recoil";
import { useRecoilValue, useRecoilState } from "recoil";

import { ROLES } from "../../features/Constant"
import * as HTTPManager from "../../features/HTTPManager";
import * as HTTPITSManager from "../../features/HTTPITSManager";
import * as Utils from "../../features/Utils";

import useScript from "../../hooks/useScript";
import "../ScheduleListStyle.css";
import { Tooltip } from "react-tooltip";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ko } from 'date-fns/esm/locale';

export default function MainPage() {
  useScript("https://t1.kakaocdn.net/kakao_js_sdk/2.1.0/kakao.min.js", "sha384-dpu02ieKC6NUeKFoGMOKz6102CLEWi9+5RQjWSV0ikYSFFd8M3Wp2reIcquJOemx");

  const navigate = useNavigate();
  const scrollPosition = window.pageYOffset;
  const [currentScroll, setCurrentScroll] = useRecoilState(ScheduleListScroll);

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  // 이전버전 컴포넌트 적용여부
  const [isNewComponent, setNewComponent] = useState(true);

  const [tourList, setTourList] = useState([]);
  const [tourFilterList, setTourFilterList] = useState([]);

  const [toursBusInfo, setToursBusInfo] = useState([]);
  const [toursHotelInfo, setToursHotelInfo] = useState([]);
  const [toursHotelLogInfo, setToursHotelLogInfo] = useState([]);
  const [toursGuideInfo, setToursGuideInfo] = useState([]);
  const [toursCheckListInfo, setToursCheckListInfo] = useState([]);
  const [toursFunds, setToursFunds] = useState([]);
  const [toursFiles, setToursFiles] = useState([]);

  const [selectedYear, setSelectedYear] = useState(0);
  const [selectedMonth, setSelectedMonth] = useState(0);

  const [hotelList, setHotelList] = useState([]);
  const [busList, setBusList] = useState([]);
  const [userList, setUserList] = useState([]);

  const [guideMemoList, setGuideMemoList] = useState([]);

  const userInfo = useRecoilValue(UserInfo);

  // 누락확인 버튼
  const [checkHotelBus, setCheckHotelBus] = useState(false);
  const [checkDutyFree, setCheckDutyFree] = useState(false);
  const [checkRooming, setCheckRooming] = useState(false);
  const [checkFund, setCheckFund] = useState(false);

  // 국가 버튼  
  const [phSelected, setPHSelected] = useState(true);
  const [inSelected, setINSelected] = useState(true);

  // 필터 관련 변수
  const [selectedFilterIndex, setSelectedFilterIndex] = useState(2);
  const [showFilter, setShowFilter] = useState(false);

  const filterYearList = [2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030, 2031, 2032, "닫기"];
  const [showYearList, setShowYearList] = useState(false);
  const filterMonthList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, "닫기"];
  const [showMonthList, setShowMonthList] = useState(false);

  let searchText = useRef("");
  let focusRef = useRef();

  // 투어수
  const [totalTourCount, setTotalTourCount] = useState(0);
  const [totalCancelCount, setTotalCancelCount] = useState(0);

  // 인원수
  const [totalInPax, setTotalInPax] = useState(0);
  const [totalPhPax, setTotalPhPax] = useState(0);

  const idColor = "#43A4F9";
  const phColor = "#FF3A3A";

  const [showCreateTourDialog, setShowCreateTourDialog] = useState(false);

  const liCommonCSSUserMenu = "py-1 hover:bg-gray-500 hover:text-[white] select-none";
  const btnCommonCSSUserMenu = "hover:bg-grey-200 w-full h-full py-2 px-2 text-center focus:outline-none";

  const [fromITSInfoList, setFromITSInfoList] = useState([]);


  // 데이터 초기화
  async function initalize() {
    setSelectedYear(today.getFullYear());
    setSelectedMonth(today.getMonth() + 1);
  }

  // 서버 데이터 수신
  async function fetchData() {
    var response = await HTTPManager.GetTourList({
      accessToken: userInfo.accessToken,
    });

    let initTourList = [];
    if (response !== undefined) {
      setTourList(response.data.result);

      let initYear = selectedYear === 0 ? today.getFullYear() : selectedYear;
      let initMonth = selectedMonth === 0 ? today.getMonth() : selectedMonth - 1;

      initTourList = response.data.result?.filter(tour => (new Date(tour.startDate.replace(" ", "T")).getFullYear() === initYear &&
        new Date(tour.startDate.replace(" ", "T")).getMonth() === initMonth));

      // 국가 선택 버튼 확인
      if (!phSelected) {
        initTourList = initTourList?.filter((item) => item.name !== "필리핀");
      }

      if (!inSelected) {
        initTourList = initTourList?.filter((item) => item.name !== "인도네시아");
      }

      filteringTour(initTourList, selectedFilterIndex);
    }

    response = await HTTPManager.GetTourReservation({
      accessToken: userInfo.accessToken,
    });

    if (response !== undefined && response.data !== undefined) {
      setToursBusInfo(response.data.toursBus);
      setToursHotelInfo(response.data.toursHotel);
      setToursHotelLogInfo(response.data.toursHotelLog);
      setToursGuideInfo(response.data.toursGuide);
      setToursCheckListInfo(response.data.toursCheckList);
      setToursFunds(response.data.toursFunds);
      setToursFiles(response.data.toursFiles);
    }

    response = await HTTPManager.GetHotelList({
      accessToken: userInfo.accessToken
    });

    if (response !== undefined) {
      setHotelList(response.data.hotelList);
    }

    response = await HTTPManager.GetBusList({
      accessToken: userInfo.accessToken
    });

    if (response !== undefined) {
      setBusList(response.data.busList);
    }

    response = await HTTPManager.GetGuideMemoForAdmin({
      accessToken: userInfo.accessToken
    });

    if (response !== undefined) {
      setGuideMemoList(response.data.guideMemoList);
    }

    response = await HTTPManager.GetUserList({
      accessToken: userInfo.accessToken,
    });

    if (response !== undefined && response.data?.result !== undefined) {
      var sortList = [...response.data.result];
      sortList.sort((a, b) => a.name < b.name ? -1 : a.name > b.name ? 1 : 0);
      setUserList(sortList);
    }

    response = await HTTPITSManager.getFromITS({
      accessToken: userInfo.accessToken,
    });

    if (response !== undefined && response.data !== undefined) {
      setFromITSInfoList(response.data.result);
    }
  }

  const [allowShow, setAllowShow] = useState(false);

  // useEffect (async () => {}, []) 형태 사용하지 말 것
  // 내부에서 하는 경우 async 함수를 정의해서 호출
  useEffect(() => {
    Utils.allowByPosition(ROLES.ADMIN, userInfo.rolesId)
      .then(async (res) => {
        if (!res) {
          alert("접근 권한이 없습니다.");
          navigate("/", { replace: true });
        }
        else {
          await initalize();
          await fetchData();
          setAllowShow(true);
        }
      })
      .catch((err) => {
        alert(err.message);
      });

  }, []);

  function checkCompleteTour(endDate) {
    if (today > new Date(endDate.replace(" ", "T")))
      return true;
    else
      return false;
  }

  function checkDoingTour(startDate, endDate) {
    if (today >= new Date(startDate.replace(" ", "T")) && today <= new Date(endDate.replace(" ", "T")))
      return true;
    else
      return false;
  }

  function checkRemainTour(startDate) {
    if (today < new Date(startDate.replace(" ", "T")))
      return true;
    else
      return false;
  }

  function sortList(list, direction) {
    // direction : 1, 빠른순
    // direction : -1, 느린순
    return list.sort((a, b) => {
      if (new Date(a.startDate.replace(" ", "T")) > new Date(b.startDate.replace(" ", "T")))
        return direction;
      if (new Date(a.startDate.replace(" ", "T")) < new Date(b.startDate.replace(" ", "T")))
        return -direction;

      if (new Date(a.endDate.replace(" ", "T")) > new Date(b.endDate.replace(" ", "T")))
        return direction;
      if (new Date(a.endDate.replace(" ", "T")) < new Date(b.endDate.replace(" ", "T")))
        return -direction;
    });
  }

  function changeYearOrMonth(year, month) {
    // 선택된 연도 or 달만 표시
    let selectList = tourList.filter(tour => (new Date(tour.startDate.replace(" ", "T")).getFullYear() === year &&
      new Date(tour.startDate.replace(" ", "T")).getMonth() === (month - 1)));

    // 필터 초기화
    filteringTour(selectList, 2);
    setPHSelected(true);
    setINSelected(true);

    setCheckHotelBus(false);
    setCheckRooming(false);
    setCheckDutyFree(false);
    setCheckFund(false);
  }

  function filteringTour(list, index) {
    if (list === undefined) return;

    let paxIn = 0;
    let paxPh = 0;

    list.map(tour => {
      if (tour.cancel === 1) return 0;

      // [, ( 기준 제거 숫자만 추출하기 위한 과정
      let firstItem = tour.pax?.split('[')[0];
      let secondItem = firstItem?.split('(')[0];
      let sum = secondItem?.split(/[\D]+/i)?.reduce((a, b) => a = a + Number(b), 0);

      if (sum !== undefined && tour.countryId === 2)
        paxIn += sum;

      if (sum !== undefined && tour.countryId === 3)
        paxPh += sum;
    });

    setTotalInPax(paxIn);
    setTotalPhPax(paxPh);

    let completeList = list.filter(item => checkCompleteTour(item.endDate) && item.cancel === 0);
    let doingList = list.filter(item => (checkDoingTour(item.startDate, item.endDate) && item.cancel === 0));
    let cancelList = list.filter(item => item.cancel === 1);
    let remainList = list.filter(item => (checkRemainTour(item.startDate) && item.cancel === 0));

    setTotalCancelCount(cancelList?.length);
    setTotalTourCount(cancelList?.length + completeList?.length + doingList?.length + remainList?.length);

    setSelectedFilterIndex(index);
    setShowFilter(false);
    // "번호 오름차순", "번호 내림차순", "시작일 빠른순", "시작일 느린순"
    switch (index) {
      case 0:
        setTourFilterList([].concat(list.sort((a, b) => a.id - b.id)));
        break;
      case 1:
        setTourFilterList([].concat(list.sort((a, b) => b.id - a.id)));
        break;
      case 2:
        setTourFilterList([].concat(sortList(list, 1)));
        break;
      case 3:
        setTourFilterList([].concat(sortList(list, -1)));
        break;
      default:
        break;
    }
  }

  const btnMoveMonthClass = "text-3xl pointerhover:hover:cursor-pointer px-4 py-1 pointerhover:hover:bg-gray-200 rounded-full focus:outline-none";

  function moveToRight() {
    let year = selectedYear;
    let month = selectedMonth;

    if (month + 1 > 12) {
      setSelectedYear(selectedYear + 1);
      month = 0;
      year = year + 1;
    }

    setSelectedMonth(month + 1);
    changeYearOrMonth(year, month + 1);

    if (searchText.current.length > 0)
      focusRef.current.focus();
  }

  function moveToLeft() {
    let year = selectedYear;
    let month = selectedMonth;

    if (month - 1 < 1) {
      setSelectedYear(selectedYear - 1);
      month = 13;
      year = year - 1;
    }

    setSelectedMonth(month - 1);
    changeYearOrMonth(year, month - 1);

    if (searchText.current.length > 0)
      focusRef.current.focus();
  }

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const DateCustomInput = forwardRef(({ value, onClick }, ref) => (
    <div>
      <button className="" onClick={onClick} ref={ref} style={{
        width: '250px',
        height: '46px',
        borderWidth: 1,
        padding: 10,
        borderRadius: 5,
        borderColor: "#E2E2E2",
        color: value?.length === 0 ? "#E2E2E2" : "#4B4C4D",
      }}>
        {value?.length === 0 ? "시작　　>　　끝" : value}
      </button>
    </div>
  ));

  return (
    <>
      <div
        className="mt-0 md:mt-14 overflow-y-auto outline-none px-[50px]"
        style={{
          width: "100%",
          backgroundColor: "#fff",
          textAlign: "center",
        }}
      >
        <div className="text-start mt-[50px] text-[#9299A1]">투어</div>
        <div className="text-start mt-[50px] text-[#333A40] font-bold text-[30px]">목록(표)</div>
        <div className="h-[2px] bg-[#E7EAED] my-[30px] select-none" />
        <div className="flex flex-row gap-1">
          <div className="flex flex-col w-[150px] mb-[30px] bg-[#F8F9FA] text-[#1B1E21] rounded-lg p-5">
            <div className="font-bold">인도네시아</div>
            <div>{Utils.formatNumber(totalInPax)}명</div>
          </div>
          <div className="flex flex-col w-[150px] mb-[30px] bg-[#F8F9FA] text-[#1B1E21] rounded-lg p-5">
            <div className="font-bold">필리핀</div>
            <div>{Utils.formatNumber(totalPhPax)}명</div>
          </div>
          <div className="flex flex-col w-[150px] mb-[30px] bg-[#F8F9FA] text-[#1B1E21] rounded-lg p-5">
            <div className="font-bold">총</div>
            <div>{Utils.formatNumber(totalInPax + totalPhPax)}명</div>
          </div>
        </div>
        <div className="flex w-full mb-[20px] items-center justify-between">
          <div className="flex">
            <DatePicker
              selected={startDate}
              onChange={onChange}
              startDate={startDate}
              endDate={endDate}
              shouldCloseOnSelect
              selectsRange
              locale={ko}
              dateFormat="yyyy/MM/dd"
              customInput={<DateCustomInput />}
            />
          </div>
          <div className="flex px-5 py-2 bg-[#51ABF3] rounded-lg text-white font-bold select-none cursor-pointer"
            onClick={() => {
              // 투어 시작 혹은 끝이 검색하고자 하는 기간 사이에 존재하면 포함              
              let tmpList = tourList.filter(tour => {
                // 시작일 포함
                let containStart = new Date(tour.startDate.replace(" ", "T")) >= startDate && new Date(tour.startDate.replace(" ", "T")) <= endDate;

                // 종료일 포함
                let containEnd = new Date(tour.endDate.replace(" ", "T")) >= startDate && new Date(tour.endDate.replace(" ", "T")) <= endDate;

                if (containStart || containEnd) return tour;
              }).filter(tour => tour.cancel !== 1);

              setTourFilterList(sortList(tmpList, 1));

              let paxIn = 0;
              let paxPh = 0;

              tmpList.map(tour => {
                if (tour.cancel === 1) return 0;

                // [, ( 기준 제거 숫자만 추출하기 위한 과정
                let firstItem = tour.pax?.split('[')[0];
                let secondItem = firstItem?.split('(')[0];
                let sum = secondItem?.split(/[\D]+/i)?.reduce((a, b) => a = a + Number(b), 0);

                if (sum !== undefined && tour.countryId === 2)
                  paxIn += sum;

                if (sum !== undefined && tour.countryId === 3)
                  paxPh += sum;
              });

              setTotalInPax(paxIn);
              setTotalPhPax(paxPh);
            }}
          >
            찾기
          </div>
        </div>

        <div className="grid grid-cols-12 w-full bg-[white] divide-x-[1px] divide-[#E7EAED] border-[#E7EAED] border-y-[1px] py-5 font-bold justify-center items-center">
          <div>행사번호</div>
          <div>날짜</div>
          <div className="col-span-6">문서번호</div>
          <div>입국</div>
          <div>출국</div>
          <div>입국(ITS)</div>
          <div>출국(ITS)</div>
        </div>
        {
          tourFilterList?.filter(tour => tour.cancel !== 1).map((tour, index) => {
            return (
              <div className="grid grid-cols-12 w-full border-[#E7EAED] border-b-[1px] py-2 items-center" key={tour.id}
                style={{
                  background: tour.cancel === 1 ? "#afafaf" : "#fff",
                  color: tour.cancel === 1 ? "#afafaf" : tour.name === "인도네시아" ? idColor : tour.name === "필리핀" ? phColor : "#000",
                }}
              >
                <div>{tour.id}</div>
                <div className="flex flex-col">
                  <div>{tour.startDate.slice(0, 10)}</div>
                  <div>{tour.endDate.slice(0, 10)}</div>
                </div>
                <div className="col-span-6 text-start pl-2">{tour.docNum}</div>
                <div>{tour.eta}</div>
                <div>{tour.etd}</div>
                <div>{fromITSInfoList?.find(info => info.tourId === tour.id)?.eta}</div>
                <div>{fromITSInfoList?.find(info => info.tourId === tour.id)?.etd}</div>
              </div>
            );
          })
        }
      </div>
    </>
  )
}