import React, { useEffect, useState } from "react";
import * as Utils from '../../features/Utils';
import { ROLES } from "../../features/Constant";
import { useNavigate, useLocation } from "react-router-dom";
import { UserInfo, LanguageSet } from "../../recoil";
import { useRecoilValue } from "recoil";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import '@material/web/button/filled-button.js';
import '@material/web/button/outlined-button.js';
import '@material/web/checkbox/checkbox.js';
import '@material/web/textfield/filled-text-field.js'
import '@material/web/textfield/outlined-text-field.js'
import '@material/web/select/outlined-select.js'
import '@material/web/select/internal/outlined-select-styles.css'
import '@material/web/select/select-option.js'
import '@material/web/select/filled-select.js'

import * as HTTPFinalManager from "../../features/HTTPFinalManager";
import headerLogo from '../../assets/final_logo.png';

import {
  Document,
  HeadingLevel,
  ImageRun,
  Packer,
  Paragraph,
  Table,
  TableCell,
  TableRow,
  VerticalAlign,
  ShadingType,
  AlignmentType,
  TextRun,
  WidthType,
  SectionType,
  HeightRule,
  Header,
  ExternalHyperlink,
  TextWrappingType,
  TextWrappingSide
} from "docx";

import { saveAs } from "file-saver";

export default function RequestFinalWindow() {
  const navigate = useNavigate();
  const location = useLocation();
  const languageSet = useRecoilValue(LanguageSet);
  const userInfo = useRecoilValue(UserInfo);

  // 투어 정보
  const [agent, setAgent] = useState("");
  const [flightSchedule, setFlightSchedule] = useState("");
  const [period, setPeriod] = useState("");
  const [transportation, setTransportation] = useState("");
  const [tourRegion, setTourRegion] = useState("");
  const [remark, setRemark] = useState("");
  const [expireDate, setExpireDate] = useState("");
  const [documentNo, setDocumentNo] = useState("");
  const [hotel, setHotel] = useState("");
  const [pax, setPax] = useState("");
  const [landFee, setLandFee] = useState("");
  const [sglSppl, setSglSppl] = useState("");

  const [programList, setProgramList] = useState([]);
  const [selectedMap, setSelectedMap] = React.useState(new Map());

  // NOTIFICATION
  const [included, setIncluded] = useState("");
  const [excluded, setExcluded] = useState("");
  const [note, setNote] = useState("");

  var dateDiff = (new Date(location.state.tourInfo.endDate.replace(" ", "T")) - new Date(location.state.tourInfo.startDate.replace(" ", "T")));
  var totalDays = dateDiff / 1000 / 3600 / 24 + 1

  const addMap = (key, value) => {
    setSelectedMap((prev) => new Map([...prev, [key, value]]));
  }

  const updateMap = (key, value) => {
    setSelectedMap((prev) => new Map(prev).set(key, value));
  }

  function getRow(title1, title2, value1, value2) {
    return new TableRow({
      height: {
        value: 500,
        rule: HeightRule.ATLEAST,
      },
      children: [
        new TableCell({
          children: [
            new Paragraph({
              children: [new TextRun({
                text: title1,
                bold: true,
                color: "000000",
                font: "Calibri",
              })],
              alignment: AlignmentType.CENTER,
            }),
          ],
          verticalAlign: VerticalAlign.CENTER,
          shading: {
            fill: "FFF2CC",
            type: ShadingType.CLEAR,
            color: "auto",
          },
          width: {
            size: 3505,
            type: WidthType.DXA,
          },
        }),
        new TableCell({
          children: [
            new Paragraph({
              children: [new TextRun({
                text: value1,
                color: "000000",
                font: "Calibri",
              })],
              alignment: AlignmentType.CENTER,
            }),
          ],
          verticalAlign: VerticalAlign.CENTER,
          shading: {
            fill: "FFFFFF",
            type: ShadingType.CLEAR,
            color: "auto",
          },
          width: {
            size: 3505,
            type: WidthType.DXA,
          },
        }),
        new TableCell({
          children: [
            new Paragraph({
              children: [new TextRun({
                text: title2,
                bold: true,
                color: "000000",
                font: "Calibri",
              })],
              alignment: AlignmentType.CENTER,
            }),
          ],
          verticalAlign: VerticalAlign.CENTER,
          shading: {
            fill: "FFF2CC",
            type: ShadingType.CLEAR,
            color: "auto",
          },
          width: {
            size: 3505,
            type: WidthType.DXA,
          },
        }),
        new TableCell({
          children: [
            new Paragraph({
              children: [new TextRun({
                text: value2,
                color: "000000",
                font: "Calibri",
              })],
              alignment: AlignmentType.CENTER,
            }),
          ],
          verticalAlign: VerticalAlign.CENTER,
          shading: {
            fill: "FFFFFF",
            type: ShadingType.CLEAR,
            color: "auto",
          },
          width: {
            size: 3505,
            type: WidthType.DXA,
          },
        }),
      ],
    });
  }

  const infoTable = new Table({
    rows: [
      getRow("AGENT", "FLIGHT SCHEDULE", agent, flightSchedule),
      getRow("PERIOD", "TRANSPORTATION", period, transportation),
      getRow("TOUR REGION", "REMARK", tourRegion, remark),
      getRow("EXPIRE DATE", "DOCUMENT NO", expireDate, documentNo),
    ],
  });

  const infoTable2 = new Table({
    rows: [
      getRow("HOTEL", "PAX", hotel, pax),
      getRow("LAND FEE", "SGL SPPL", landFee, sglSppl),
    ],
  });

  function getRowNotification(title, value) {
    return new TableRow({
      height: {
        value: 1000,
        rule: HeightRule.ATLEAST,
      },
      children: [
        new TableCell({
          children: [
            new Paragraph({
              children: [new TextRun({
                text: title,
                bold: true,
                color: "000000",
                font: "Calibri",
                underline: true,
              })],
              alignment: AlignmentType.CENTER,
            }),
          ],
          verticalAlign: VerticalAlign.CENTER,
          shading: {
            fill: "FFF2CC",
            type: ShadingType.CLEAR,
            color: "auto",
          },
          width: {
            size: 3400,
            type: WidthType.DXA,
          },
        }),
        new TableCell({
          children: [
            new Paragraph({
              children: [new TextRun({
                text: value,
                color: "000000",
                font: "Calibri",
              })],
              alignment: AlignmentType.CENTER,
            }),
          ],
          verticalAlign: VerticalAlign.CENTER,
          shading: {
            fill: "FFFFFF",
            type: ShadingType.CLEAR,
            color: "auto",
          },
          width: {
            size: 14020,
            type: WidthType.DXA,
          },
        }),
      ],
    });
  }

  function getRowProgramHeader(title1, title2, title3, height, bg1, bg2, bg3) {
    return new TableRow({
      height: {
        value: height,
        rule: HeightRule.ATLEAST,
      },
      children: [
        new TableCell({
          children: [
            new Paragraph({
              children: [new TextRun({
                text: title1,
                bold: true,
                color: "000000",
                font: "Calibri",
              })],
              alignment: AlignmentType.CENTER,
            }),
          ],
          verticalAlign: VerticalAlign.CENTER,
          shading: {
            fill: bg1,
            type: ShadingType.CLEAR,
            color: "auto",
          },
          width: {
            size: 3300,
            type: WidthType.DXA,
          },
        }),
        new TableCell({
          children: [
            new Paragraph({
              children: [new TextRun({
                text: title2,
                bold: true,
                color: "000000",
                font: "Calibri",
              })],
              alignment: AlignmentType.CENTER,
            }),
          ],
          verticalAlign: VerticalAlign.CENTER,
          shading: {
            fill: bg2,
            type: ShadingType.CLEAR,
            color: "auto",
          },
          width: {
            size: 7000,
            type: WidthType.DXA,
          },
        }),
        new TableCell({
          children: [
            new Paragraph({
              children: [new TextRun({
                text: title3,
                bold: true,
                color: "000000",
                font: "Calibri",
              })],
              alignment: AlignmentType.CENTER,
            }),
          ],
          verticalAlign: VerticalAlign.CENTER,
          shading: {
            fill: bg3,
            type: ShadingType.CLEAR,
            color: "auto",
          },
          width: {
            size: 3515,
            type: WidthType.DXA,
          },
        }),
      ],
    });
  }

  function getRowProgram(title1, height, bg1, bg2, bg3, index) {
    return new TableRow({
      height: {
        value: height,
        rule: HeightRule.ATLEAST,
      },
      children: [
        new TableCell({
          children: [
            new Paragraph({
              children: [new TextRun({
                text: title1,
                bold: true,
                color: "000000",
                font: "Calibri",
              })],
              alignment: AlignmentType.CENTER,
            }),
          ],
          verticalAlign: VerticalAlign.CENTER,
          shading: {
            fill: bg1,
            type: ShadingType.CLEAR,
            color: "auto",
          },
          width: {
            size: 3300,
            type: WidthType.DXA,
          },
        }),
        new TableCell({
          children: makeProgramParagraph(selectedMap.get(index)),
          verticalAlign: VerticalAlign.CENTER,
          shading: {
            fill: bg2,
            type: ShadingType.CLEAR,
            color: "auto",
          },
          width: {
            size: 7000,
            type: WidthType.DXA,
          },
        }),
        new TableCell({
          children: [
            new Paragraph({
              children: [new TextRun({
                text: "",
                bold: true,
                color: "000000",
                font: "Calibri",
              })],
              alignment: AlignmentType.CENTER,
            }),
          ],
          verticalAlign: VerticalAlign.CENTER,
          shading: {
            fill: bg3,
            type: ShadingType.CLEAR,
            color: "auto",
          },
          width: {
            size: 3515,
            type: WidthType.DXA,
          },
        }),
      ],
    });
  }

  function makeProgramParagraph(list) {
    let result = [];

    if (list === undefined || list.length < 1) return result;

    for (let i = 0; i < list.length; i++) {
      result.push(
        new Paragraph({
          children: [new TextRun({
            text: list[i],
            color: "000000",
            font: "Calibri",
          })],
          alignment: AlignmentType.START,
        }),
      );
    }

    return result;
  }

  function programRow() {
    let result = [];

    result.push(getRowProgramHeader("DATE", "PROGRAM", "REMARK", 350, "FFF2CC", "FFF2CC", "FFF2CC"));

    for (let i = 1; i <= totalDays; i++) {
      result.push(getRowProgram("DAY" + i, 1500, "FFF2CC", "FFFFFF", "FFFFFF", i))
    }

    return result;
  }

  const programTable = new Table({
    rows: programRow(),
  })

  const notificationTable = new Table({
    rows: [
      new TableRow({
        height: {
          value: 350,
          rule: HeightRule.ATLEAST,
        },
        children: [
          new TableCell({
            columnSpan: 2,
            children: [
              new Paragraph({
                children: [new TextRun({
                  text: "NOTIFICATION",
                  bold: true,
                  color: "000000",
                  font: "Calibri",
                })],
                alignment: AlignmentType.CENTER,
              }),
            ],
            verticalAlign: VerticalAlign.CENTER,
            shading: {
              fill: "FFF2CC",
              type: ShadingType.CLEAR,
              color: "auto",
            },
            width: {
              size: 14020,
              type: WidthType.DXA,
            },
          }),
        ],
      }),
      getRowNotification("INCLUDED", included),
      getRowNotification("EXCLUDED", excluded),
      getRowNotification("NOTE", note),
    ],
  });

  const doc = new Document({
    sections: [
      {
        properties: {
          page: {
            margin: {
              top: 565,
              right: 565,
              bottom: 565,
              left: 565,
              header: 145,
            },
          },
        },
        headers: {
          default: new Header({
            children: [
              new Paragraph({
                children: [new TextRun({
                  text: "",
                  bold: true,
                  color: "000000",
                  size: 22,
                  font: "Calibri",
                }),],
                alignment: AlignmentType.START,
              }),
              new Paragraph({
                children: [new TextRun({
                  text: "Korea Travel Store",
                  bold: true,
                  color: "000000",
                  size: 22,
                  font: "Calibri",
                }),],
                alignment: AlignmentType.START,
              }),
              new Paragraph({
                children: [new ExternalHyperlink({
                  children: [
                    new TextRun({
                      text: "korea@indotravelstore.com",
                      style: "Hyperlink",
                      size: 22,
                      font: "Calibri",
                    }),
                  ],
                  link: "korea@indotravelstore.com",
                })],
                alignment: AlignmentType.START,
              }),
              new Paragraph({
                children: [
                  new ImageRun({
                    data: headerLogo,
                    transformation: {
                      width: 78,
                      height: 115,
                    },
                    floating: {
                      horizontalPosition: {
                        offset: 6414368,
                      },
                      // 17.8, 0.53
                      // 2014400 : 5.59 = x : 17.8
                      verticalPosition: {
                        offset: 190990,
                      },
                      wrap: {
                        type: TextWrappingType.SQUARE,
                        side: TextWrappingSide.BOTH_SIDES,
                      },
                    },
                  })
                ],
              }),
            ],
          }),
        },
        children: [
          new Paragraph({
            children: [new TextRun({
              text: "FINAL CNFIRMATION KOREA",
              bold: true,
              color: "000000",
              underline: true,
              size: 32,
              font: "Calibri",
            })],
            alignment: AlignmentType.CENTER,
          }),
          infoTable,
          new Paragraph({
            children: [new TextRun({
              text: "",
              bold: true,
              color: "000000",
              font: "Calibri",
            })],
            alignment: AlignmentType.START,
          }),
          infoTable2,
          new Paragraph({
            children: [new TextRun({
              text: "",
              bold: true,
              color: "000000",
              font: "Calibri",
            })],
            alignment: AlignmentType.START,
          }),
          programTable,
          new Paragraph({
            children: [new TextRun({
              text: "",
              bold: true,
              color: "000000",
              font: "Calibri",
            })],
            alignment: AlignmentType.START,
          }),
          notificationTable,
        ],
      }
    ],
  });

  function documentGenerateTest() {
    Packer.toBlob(doc).then(blob => {
      saveAs(blob, "example.docx");
    });
  }

  async function fetchData() {
    var response = await HTTPFinalManager.getProgramList({
      accessToken: userInfo.accessToken,
    });

    if (response.status === 200) {
      if (response.data !== null && response.data.result !== null) {
        response.data.result.sort((a, b) => a.name < b.name ? -1 : a.name > b.name ? 1 : 0)
        setProgramList(response.data.result);
      } else {
        setProgramList([]);
      }
    }
  }

  async function handleKeyDown(event) {
    if (event.key === 'Enter' || event.key === 'Tab') {
      switch (event.target.label) {
        case "AGENT":
          setAgent(event.target.value);
          break;
        case "FLIGHTSCHEDULE":
          setFlightSchedule(event.target.value);
          break;
        case "PERIOD":
          setPeriod(event.target.value);
          break;
        case "TRANSPORTATION":
          setTransportation(event.target.value);
          break;
        case "TOURREGION":
          setTourRegion(event.target.value);
          break;
        case "REMARK":
          setRemark(event.target.value);
          break;
        case "EXPIREDATE":
          setExpireDate(event.target.value);
          break;
        case "DOCUMENTNO":
          setDocumentNo(event.target.value);
          break;
        case "HOTEL":
          setHotel(event.target.value);
          break;
        case "PAX":
          setPax(event.target.value);
          break;
        case "LANDFEE":
          setLandFee(event.target.value);
          break;
        case "SGLSPPL":
          setSglSppl(event.target.value);
          break;
        case "INCLUDED":
          setIncluded(event.target.value);
          break;
        case "EXCLUDED":
          setExcluded(event.target.value);
          break;
        case "NOTE":
          setNote(event.target.value);
          break;
        default:

          break;
      }
    }
  }

  const outlineStyled = {
    "textTransform": "none",
    "width": "100%",
    "--md-outlined-text-field-container-shape": "0px",
    "--md-outlined-text-field-focus-outline-width": "2px",
    "--md-outlined-text-field-focus-outline-color": "#B3261E",
    "--md-outlined-text-field-outline-color": "#000000",
    "--md-outlined-text-field-label-text-color": "#ababab",
    "--md-outlined-text-field-label-text-size": "13px",
    "--md-outlined-text-field-focus-label-text-color": "#B3261E",
  }

  const outlineSelectStyled = {
    // 너비 설정    
    "minWidth": "100%",

    // 상단 라벨
    "--md-outlined-select-text-field-label-text-color": "#000",  // 포커스 없을 때
    "--md-outlined-select-text-field-focus-label-text-color": "#B3261E",  // 포커스 없을 때

    // 박스
    "--md-outlined-select-text-field-outline-color": "#000",
    "--md-outlined-select-text-field-focus-outline-color": "#B3261E",
    "--md-outlined-select-text-field-container-shape": "0px",
    "--md-outlined-select-text-field-input-text-color": "#000",

    "--md-outlined-select-text-field-focus-trailing-icon-color": "#B3261E",
    "--md-outlined-select-text-field-container-color": "#B3261E",
    "--md-outlined-select-text-field-focus-label-text-color'": "#B3261E",

    // 글자 크기
    "--md-outlined-select-text-field-input-text-size": "13px",
    "--md-menu-item-label-text-size": "13px",

    // 메뉴 옵션 영역
    "--md-menu-item-one-line-container-height": "30px",
    "--md-menu-item-top-space": "0px",
    "--md-menu-item-bottom-space": "0px",

    "--md-menu-container-color": "#B3261E05",
    "--md-menu-container-shape": "0px",

    "--md-sys-color-secondary-container": "#B3261E20",

    "marginBottom": "10px",
  }

  const buttonStyle = {
    "--md-outlined-button-label-text-color": "#B3261E",
    "--md-sys-color-primary": "#B3261E",
    "--md-sys-color-outline": "#B3261E",
  }

  useEffect(() => {
    Utils.allowByPosition(ROLES.ITS, userInfo.rolesId)
      .then(async (res) => {
        if (!res) {
          alert("접근 권한이 없습니다.");
          navigate("/", { replace: true });
        }
        else {
          fetchData();
        }
      })
      .catch((err) => {
        alert(err.message);
      });

    for (let i = 1; i <= totalDays; i++) {
      addMap(i, [])
    }
  }, []);

  const getDateProgramInput = () => {
    let arr = [];

    for (let i = 1; i <= totalDays; i++) {
      var dayStr = "DAY" + i;

      arr.push(<md-outlined-select key={i} style={outlineSelectStyled} label={dayStr}>
        {
          programList.map(item => {
            return <md-select-option style={{ background: "#fff" }} value={item.name} key={item.id} onClick={(e) => {
              updateMap(i, [...selectedMap.get(i), e.target.textContent]);
            }}>
              <div slot="headline">{item.name}</div>
            </md-select-option>
          })
        }
      </md-outlined-select>);
    }

    return arr;
  }

  const getDateProgramTable = () => {
    let arr = [];

    for (let i = 1; i <= totalDays; i++) {
      var dayStr = "DAY" + i;
      arr.push(
        <tr key={i} style={{ width: "100%", height: "100px", fontSize: 12, position: "sticky", top: 0, borderCollapse: "collapse" }}>
          <td style={{ width: "20%", border: "1px solid rgb(0, 0, 0)", background: "#FFF2CC", borderCollapse: "collapse", fontWeight: "bold" }}>{dayStr}</td>
          <td style={{ width: "50%", border: "1px solid rgb(0, 0, 0)", textAlign: "start", padding: 10 }}>
            {
              selectedMap.get(i) !== undefined && selectedMap.get(i).map((item, index) => {
                var rootStyled = (selectedMap.get(i).length - 1) === (index) ? "flex flex-row items-center mb-[0px]" : "flex flex-row items-center mb-[10px]";

                return <div key={index} className={rootStyled}>
                  <div className="mr-1 text-center">
                    {item}
                  </div>
                  <div className="normal-case text-center rounded-full bg-[#ededed] text-[#B3261E] px-[15px] py-[1px] cursor-pointer select-none"
                    onClick={(e) => {
                      var filterResult = selectedMap.get(i).filter((item, idx) => idx !== index);
                      if (filterResult.length === 0) {
                        addMap(i, [])
                      } else {
                        updateMap(i, selectedMap.get(i).filter((item, idx) => idx !== index));
                      }
                    }}
                  >DEL</div>
                </div>
              })
            }
          </td>
          <td style={{ width: "30%", border: "1px solid rgb(0, 0, 0)", }}></td>
        </tr>
      );
    }

    return arr;
  }


  const getNotificationTable = () => {
    let arr = [];

    arr.push(
      <table key="notitable" style={{ width: "100%", borderCollapse: "collapse", marginBottom: "5px", textAlign: "center" }} className="normal-case">
        <thead className="select-none">
          <tr style={{ width: "100%", fontSize: 12, position: "sticky", top: 0, borderCollapse: "collapse" }}>
            <th style={{ width: "100%", border: "1px solid rgb(0, 0, 0)", background: "#FFF2CC", borderCollapse: "collapse", fontWeight: "bold" }} colSpan="2">NOTIFICATION</th>
          </tr>
        </thead>
        <tbody>
          <tr style={{ width: "100%", height: "56px", fontSize: 12, position: "sticky", top: 0, borderCollapse: "collapse" }}>
            <td style={{ width: "20%", border: "1px solid rgb(0, 0, 0)", background: "#FFF2CC", borderCollapse: "collapse", fontWeight: "bold" }}><u>INCLUDED</u></td>
            <td style={{ width: "80%", border: "1px solid rgb(0, 0, 0)", }}>{included}</td>
          </tr>
          <tr style={{ width: "100%", height: "56px", fontSize: 12, position: "sticky", top: 0, borderCollapse: "collapse" }}>
            <td style={{ width: "20%", border: "1px solid rgb(0, 0, 0)", background: "#FFF2CC", borderCollapse: "collapse", fontWeight: "bold" }}><u>EXCLUDED</u></td>
            <td style={{ width: "80%", border: "1px solid rgb(0, 0, 0)", }}>{excluded}</td>
          </tr>
          <tr style={{ width: "100%", height: "56px", fontSize: 12, position: "sticky", top: 0, borderCollapse: "collapse" }}>
            <td style={{ width: "20%", border: "1px solid rgb(0, 0, 0)", background: "#FFF2CC", borderCollapse: "collapse", fontWeight: "bold" }}><u>NOTE</u></td>
            <td style={{ width: "80%", border: "1px solid rgb(0, 0, 0)", }}>{note}</td>
          </tr>
        </tbody>
      </table>
    )

    return arr;
  }

  return (
    <div className="w-full bg-[white] md:mt-14 p-3 h-[500px]">
      <div className="flex flex-col w-full justify-center">
        <div className="flex w-full justify-center font-bold text-[20px] mb-[20px]" id="title">
          FINAL CONFIRMATION
        </div>

        <md-outlined-button style={buttonStyle} onClick={(e) => {
          documentGenerateTest();
        }}>Generate Final Document</md-outlined-button>

        <div className="mb-5"></div>

        {
          // filled text input 사용방법
          //<md-filled-text-field label="AGENT" type="text" />
        }

        <div className="flex flex-row h-full w-full">
          <div className="flex flex-col w-full">
            <div className="flex flex-row w-full">
              <md-outlined-text-field label="AGENT" type="text" style={outlineStyled} onKeyDown={(e) => handleKeyDown(e)} />
              <div className="mr-[10px]" />

              <md-outlined-text-field label="FLIGHTSCHEDULE" type="text" style={outlineStyled} onKeyDown={(e) => handleKeyDown(e)} />
            </div>
            <div className="mb-[10px]" />
            <div className="flex flex-row w-full">
              <md-outlined-text-field label="PERIOD" type="text" style={outlineStyled} onKeyDown={(e) => handleKeyDown(e)} />
              <div className="mr-[10px]" />

              <md-outlined-text-field label="TRANSPORTATION" type="text" style={outlineStyled} onKeyDown={(e) => handleKeyDown(e)} />
            </div>
            <div className="mb-[10px]" />
            <div className="flex flex-row w-full">
              <md-outlined-text-field label="TOURREGION" type="text" style={outlineStyled} onKeyDown={(e) => handleKeyDown(e)} />
              <div className="mr-[10px]" />

              <md-outlined-text-field label="REMARK" type="text" style={outlineStyled} onKeyDown={(e) => handleKeyDown(e)} />
            </div>
            <div className="mb-[10px]" />
            <div className="flex flex-row w-full">
              <md-outlined-text-field label="EXPIREDATE" type="text" style={outlineStyled} onKeyDown={(e) => handleKeyDown(e)} />
              <div className="mr-[10px]" />

              <md-outlined-text-field label="DOCUMENTNO" type="text" style={outlineStyled} onKeyDown={(e) => handleKeyDown(e)} />
            </div>
            <div className="mb-[40px]" />
            <div className="flex flex-row w-full">
              <md-outlined-text-field label="HOTEL" type="text" style={outlineStyled} onKeyDown={(e) => handleKeyDown(e)} />
              <div className="mr-[10px]" />

              <md-outlined-text-field label="PAX" type="text" style={outlineStyled} onKeyDown={(e) => handleKeyDown(e)} />
            </div>
            <div className="mb-[10px]" />
            <div className="flex flex-row w-full">
              <md-outlined-text-field label="LANDFEE" type="text" style={outlineStyled} onKeyDown={(e) => handleKeyDown(e)} />
              <div className="mr-[10px]" />

              <md-outlined-text-field label="SGLSPPL" type="text" style={outlineStyled} onKeyDown={(e) => handleKeyDown(e)} />
            </div>
            <div className="mb-[40px]" />
            {
              getDateProgramInput()
            }
            <div className="mb-[10px]" />

            <div className="mb-[10px]" >NOTIFICATION</div>
            <div className="flex flex-row w-full">
              <md-outlined-text-field label="INCLUDED" type="textarea" style={outlineStyled} onKeyDown={(e) => handleKeyDown(e)} />
            </div>

            <div className="mb-[10px]" />
            <div className="flex flex-row w-full">
              <md-outlined-text-field label="EXCLUDED" type="textarea" style={outlineStyled} onKeyDown={(e) => handleKeyDown(e)} />
            </div>

            <div className="mb-[10px]" />
            <div className="flex flex-row w-full">
              <md-outlined-text-field label="NOTE" type="textarea" style={outlineStyled} onKeyDown={(e) => handleKeyDown(e)} />
            </div>
          </div>

          <div className="w-[3px] mx-4 flex bg-[#ededed]" />

          <div className="flex flex-col w-full normal-case">
            <table style={{ width: "100%", borderCollapse: "collapse", marginBottom: "5px", textAlign: "center" }}>
              <tbody>
                <tr style={{ width: "100%", height: "56px", fontSize: 12, position: "sticky", top: 0, borderCollapse: "collapse" }}>
                  <td style={{ width: "20%", border: "1px solid rgb(0, 0, 0)", background: "#FFF2CC", borderCollapse: "collapse", fontWeight: "bold" }}>AGENT</td>
                  <td style={{ width: "30%", border: "1px solid rgb(0, 0, 0)", }}>{agent}</td>
                  <td style={{ width: "20%", border: "1px solid rgb(0, 0, 0)", background: "#FFF2CC", fontWeight: "bold" }}>FLIGHT SCHEDULE</td>
                  <td style={{ width: "30%", border: "1px solid rgb(0, 0, 0)", }}>{flightSchedule}</td>
                </tr>
                <tr style={{ width: "100%", height: "56px", fontSize: 12, position: "sticky", top: 0, borderCollapse: "collapse" }}>
                  <td style={{ width: "20%", border: "1px solid rgb(0, 0, 0)", background: "#FFF2CC", borderCollapse: "collapse", fontWeight: "bold" }}>PERIOD</td>
                  <td style={{ width: "30%", border: "1px solid rgb(0, 0, 0)", }}>{period}</td>
                  <td style={{ width: "20%", border: "1px solid rgb(0, 0, 0)", background: "#FFF2CC", fontWeight: "bold" }}>TRANSPORTATION</td>
                  <td style={{ width: "30%", border: "1px solid rgb(0, 0, 0)", }}>{transportation}</td>
                </tr>
                <tr style={{ width: "100%", height: "56px", fontSize: 12, position: "sticky", top: 0, borderCollapse: "collapse" }}>
                  <td style={{ width: "20%", border: "1px solid rgb(0, 0, 0)", background: "#FFF2CC", borderCollapse: "collapse", fontWeight: "bold" }}>TOUR REGION</td>
                  <td style={{ width: "30%", border: "1px solid rgb(0, 0, 0)", }}>{tourRegion}</td>
                  <td style={{ width: "20%", border: "1px solid rgb(0, 0, 0)", background: "#FFF2CC", fontWeight: "bold" }}>REMARK</td>
                  <td style={{ width: "30%", border: "1px solid rgb(0, 0, 0)", }}>{remark}</td>
                </tr>
                <tr style={{ width: "100%", height: "56px", fontSize: 12, position: "sticky", top: 0, borderCollapse: "collapse" }}>
                  <td style={{ width: "20%", border: "1px solid rgb(0, 0, 0)", background: "#FFF2CC", borderCollapse: "collapse", fontWeight: "bold" }}>EXPIRE DATE</td>
                  <td style={{ width: "30%", border: "1px solid rgb(0, 0, 0)", }}>{expireDate}</td>
                  <td style={{ width: "20%", border: "1px solid rgb(0, 0, 0)", background: "#FFF2CC", fontWeight: "bold" }}>DOCUMENT NO</td>
                  <td style={{ width: "30%", border: "1px solid rgb(0, 0, 0)", }}>{documentNo}</td>
                </tr>
              </tbody>
            </table>
            <div className="flex w-full h-5" />

            <table style={{ width: "100%", borderCollapse: "collapse", marginBottom: "5px", textAlign: "center" }}>
              <tbody>
                <tr style={{ width: "100%", height: "56px", fontSize: 12, position: "sticky", top: 0, borderCollapse: "collapse" }}>
                  <td style={{ width: "20%", border: "1px solid rgb(0, 0, 0)", background: "#FFF2CC", borderCollapse: "collapse", fontWeight: "bold" }}>HOTEL</td>
                  <td style={{ width: "30%", border: "1px solid rgb(0, 0, 0)", }}>{hotel}</td>
                  <td style={{ width: "20%", border: "1px solid rgb(0, 0, 0)", background: "#FFF2CC", fontWeight: "bold" }}>PAX</td>
                  <td style={{ width: "30%", border: "1px solid rgb(0, 0, 0)", }}>{pax}</td>
                </tr>
                <tr style={{ width: "100%", height: "56px", fontSize: 12, position: "sticky", top: 0, borderCollapse: "collapse" }}>
                  <td style={{ width: "20%", border: "1px solid rgb(0, 0, 0)", background: "#FFF2CC", borderCollapse: "collapse", fontWeight: "bold" }}>LAND FEE</td>
                  <td style={{ width: "30%", border: "1px solid rgb(0, 0, 0)", }}>{landFee}</td>
                  <td style={{ width: "20%", border: "1px solid rgb(0, 0, 0)", background: "#FFF2CC", fontWeight: "bold" }}>SGL SPPL</td>
                  <td style={{ width: "30%", border: "1px solid rgb(0, 0, 0)", }}>{sglSppl}</td>
                </tr>
              </tbody>
            </table>

            <div className="flex w-full h-5" />
            <table style={{ width: "100%", borderCollapse: "collapse", marginBottom: "5px", textAlign: "center" }}>
              <thead className="select-none">
                <tr style={{ width: "100%", fontSize: 12, position: "sticky", top: 0, borderCollapse: "collapse" }}>
                  <th style={{ width: "20%", border: "1px solid rgb(0, 0, 0)", background: "#FFF2CC", borderCollapse: "collapse", fontWeight: "bold" }}>DATE</th>
                  <th style={{ width: "50%", border: "1px solid rgb(0, 0, 0)", background: "#FFF2CC", borderCollapse: "collapse", }}>PROGRAM</th>
                  <th style={{ width: "30%", border: "1px solid rgb(0, 0, 0)", background: "#FFF2CC", borderCollapse: "collapse", }}>REMARK</th>
                </tr>
              </thead>
              <tbody>
                {
                  getDateProgramTable()
                }
              </tbody>
            </table>

            <div className="flex w-full h-10" />
            {
              getNotificationTable()
            }
          </div>
        </div>
      </div>
    </div >
  );
}