import React, { useCallback, useEffect, useRef, useState } from "react";
import { read, utils, writeFileXLSX } from 'xlsx';
import { useLocation, } from "react-router-dom";
import { UserInfo } from "../recoil";
import { useRecoilValue } from "recoil";
import * as Utils from "../features/Utils";
import * as HTTPManager from "../features/HTTPManager";

// html 형식으로 xlsx open 하는 컴포넌트
export default function SheetJSReactHTML(props) {
  const body = document.querySelector('body');
  const scrollPosition = window.pageYOffset;

  const [__html, setHtml] = useState("");
  const tbl = useRef(null);
  const [sheetList, setSheetList] = useState([]);
  const [wsData, setWsData] = useState("");

  const [selectedIndex, setSelectedIndex] = useState(0);

  const userInfo = useRecoilValue(UserInfo);

  const location = useLocation();

  const [tourInfo, setTourInfo] = useState("");
  const [type, setType] = useState("");
  const [url, setURL] = useState("");

  async function fetchData(tourId) {
    var response = await HTTPManager.GetTourByID({
      accessToken: userInfo.accessToken,
      tourId: tourId,
    });

    if (response !== undefined) {
      setTourInfo(response.data.result[0]);
    }

    return response.data.result[0];
  }

  useEffect(() => {
    (async () => {
      try {
        let searchList = location.search.split("?");
        let tourId = searchList.find(item => item.includes("tourId")).replace("tourId=", "");
        let tourInfo = await fetchData(tourId);
        let type = searchList.find(item => item.includes("type")).replace("type=", "");
        setType(type);

        let url = "";

        switch (type) {
          case "invoice":
            url = tourInfo.invoice;
            break;
        }

        setURL(url);

        const f = await (await fetch(url)).arrayBuffer();
        const wb = read(f); // parse the array buffer
        setWsData(wb);
        const ws = wb.Sheets[wb.SheetNames[0]]; // get the first worksheet
        setSheetList(wb.SheetNames);
        const data = utils.sheet_to_html(ws); // generate HTML
        setHtml(data); // update state
      } catch (e) {
        console.log(e);
      }
    })();
  }, [location]);

  return (
    <div className="absolute top-0 left-0 flex flex-col w-full h-full px-5 pt-2 z-[999] bg-[white]">
      <div className="absolute flex flex-row gap-1 right-5 top-2 md:right-3 items-center">
        <div className="bg-[crimson] hover:bg-[crimson] rounded-lg text-[white] py-1 px-2 cursor-pointer text-sm font-bold" onClick={() => {
          Utils.handleDownloadFile(url, type, tourInfo, userInfo.accessToken);
        }}>다운로드</div>
        <div className="bg-[crimson] hover:bg-[crimson] rounded-lg text-[white] py-1 px-2 cursor-pointer text-sm font-bold" onClick={() => {
          window.close();
        }}>닫기</div>
      </div>
      <div className="text-[20px] md:text-[20px] mb-[10px]">{ }</div>
      <div className="flex flex-row gap-2 normal-case">
        {
          sheetList.map((name, index) => {
            return (
              <div key={index} className="px-2 py-1 rounded-lg text-[white] cursor-pointer"
                style={{
                  background: selectedIndex === index ? "#000000" : "#BABABA",
                }}
                onClick={() => {
                  try {
                    setSelectedIndex(index);
                    if (wsData !== undefined && wsData !== "") {
                      const ws = wsData.Sheets[name];
                      const data = utils.sheet_to_html(ws, { editable: true });
                      setHtml(data);
                    }
                  } catch (e) {
                    setHtml("");
                  }
                }}>
                {name}
              </div>);
          })
        }
      </div>
      <div className="flex w-full h-full" ref={tbl} dangerouslySetInnerHTML={{ __html }} />
    </div>
  );
}