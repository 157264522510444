import React, { useCallback, useRef } from 'react';
import { WindowScroller, CellMeasurer, CellMeasurerCache, AutoSizer, List, ListRowProps } from 'react-virtualized';
import LogItem from "./LogItem";

const LogList = ({ logs }) => {
  const listRef = useRef(null);

  const cache = new CellMeasurerCache({
    defaultWidth: 100,
    fixedWidth: true,
    minHeight: 35
  });

  const rowRenderer = useCallback(
    ({ index, key, parent, style }) => {
      const log = logs[index];
      return (
        <CellMeasurer cache={cache} parent={parent} key={key} columnIndex={0} rowIndex={index}>
          <LogItem data={log} style={style} />
        </CellMeasurer>
      );
    },
    [logs],
  );

  return (
    <WindowScroller>
      {({ height, scrollTop, isScrolling, onChildScroll }) => (
        <AutoSizer>
          {({ width }) => (
            <List
              ref={listRef}
              autoHeight
              height={height}
              width={width}
              isScrolling={isScrolling}
              overscanRowCount={0}
              onScroll={onChildScroll}
              scrollTop={scrollTop}
              rowCount={logs.length}
              rowHeight={cache.rowHeight}
              rowRenderer={rowRenderer}
              deferredMeasurementCache={cache}
            />
          )}
        </AutoSizer>
      )}
    </WindowScroller>
  );
};

export default React.memo(LogList);