import React, { } from "react";
import { useNavigate } from "react-router-dom";
import { REQUEST_STATE } from "../../features/Constant";
import * as Utils from '../../features/Utils';

export default function AgencyAccountListItem(Props) {
  const data = Props.data;
  const navigate = useNavigate();

  return (
    <div className="flex flex-col justify-items-center items-center text-[13px] p-2 normal-case cursor-pointer hover:bg-[#eee] hover:rounded-lg" onClick={() => Props.onClick()}>
      <div className="flex flex-row w-full justify-between mb-[10px]">
        <div className="flex flex-row items-center">
          <div className="w-[50px] h-[50px] bg-[#ddd] mr-[10px] rounded-full items-center justify-center">
            <div className="flex items-center justify-center w-full h-full">{Props.rank + 1}</div>
          </div>
          <div className="">{Props.agency}</div>
        </div>
        <div className="flex flex-row items-center font-bold" style={{
          color: Props.amount < 0 && "#f00",
        }}>
          {Utils.formatNumber(Props.amount)}
        </div>
      </div>
      <div className="flex w-full h-[1px] bg-[#F5F5F7]" />
    </div>
  );
}