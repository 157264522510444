import React, { useState, useEffect, forwardRef } from "react";
import { useRecoilValue } from "recoil";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ko } from 'date-fns/esm/locale';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquareH, faMagnifyingGlass, faSquareMinus, faCaretDown } from "@fortawesome/free-solid-svg-icons";
import * as Utils from '../../features/Utils';
import * as HTTPManager from '../../features/HTTPManager';
import { UserInfo } from "../../recoil";
import ColorButton from "../ColorButton"
import AutoCompleteInput from "../AutoCompleteInput";

export default function ReservationHotelDialog(Props) {
  const body = document.querySelector('body');
  const scrollPosition = window.pageYOffset;

  const userInfo = useRecoilValue(UserInfo);

  const [hotelList, setHotelList] = useState([]);
  const [hotelFilterList, setHotelFilterList] = useState([]);

  const [selectedList, setSelectedList] = useState([]);

  const [searchText, setSearchText] = useState("");
  const [needUpdate, setNeedUpdate] = useState(false);  // false : 예약 true : 변경
  const [isDirty, setIsDirty] = useState(false);        // 수정여부 확인

  async function fetchData() {
    var response = await HTTPManager.GetHotelList({
      accessToken: userInfo.accessToken
    });

    if (response !== undefined) {
      setHotelList(response.data.hotelList);
    }

    // 데이터 초기화
    if (Props.reservationInfo !== undefined && Props.reservationInfo.length > 0) {
      setNeedUpdate(true);

      setSelectedList(Props.reservationInfo.map(item => {
        let hotelInfo = response.data.hotelList.find(hotel => hotel.id === item.hotelId);
        return ({
          id: item.hotelId,
          address1: hotelInfo?.address1,
          address2: hotelInfo?.address2,
          name: hotelInfo?.name,
          startDate: Utils.convertLocalToUTCDate(item.startDate),
          endDate: Utils.convertLocalToUTCDate(item.endDate),
          roomType: item.roomType,
          toursHotelId: item.id,
        })
      }));
    }
  }

  const selectedHotelListHandler = (value, idx, type) => {
    setIsDirty(true);

    const updatedState = selectedList;
    const updatedFormElement = {
      ...updatedState[idx]
    }

    switch (type) {
      case 'roomType':
        updatedFormElement.roomType = value;
        break;
      case 'startDate':
        updatedFormElement.startDate = value;
        break;
      case 'endDate':
        updatedFormElement.endDate = value;
        break;
    }

    updatedState[idx] = updatedFormElement;

    setSelectedList([...updatedState]);
  }

  useEffect(() => {
    // 데이터 수신
    fetchData();

    // 이벤트 리스너 예제
    //window.addEventListener("resize", curScreenLocation, { capture: true })

    // 모달 팝업 시 호출
    // 스크롤 최상단 위치 후 배경 스크롤 되지 않도록 고정
    body.style.overflow = 'hidden';

    // 모달 종료 시 호출
    return () => {
      body.style.removeProperty('overflow');
      //window.removeEventListener("resize", curScreenLocation);
    };
  }, []);

  const curScreenLocation = () => {
    //console.log(window.innerHeight);
  };

  const DateCustomInput = forwardRef(({ value, onClick }, ref) => (
    <button onClick={onClick} ref={ref}>{value}</button>
  ));

  return (
    <div className="fixed h-full w-full left-0 top-0 text-sm bg-[#0007] overflow-auto z-[999] font-medium">
      <div className="fixed h-full w-full bg-white overflow-auto">
        <div className="flex flex-row p-2 items-center">
          <div className="flex flex-1 font-bold">
            <div className="mr-1">[호텔예약]</div>
            <div className="text-[deepskyblue]">{Props.tourInfo.id}번</div>
          </div>

          <div style={{
            opacity: !needUpdate ? 1 : (!isDirty ? 0.5 : 1),
            pointerEvents: !needUpdate ? "all" : !isDirty && "none"
          }}>
            <ColorButton title={needUpdate ? "변경" : "예약"} color="deepskyblue" handler={async () => {
              // 경고 표시
              // callback(3번째 param) 사용 중복 제거
              var answer = window.confirm((needUpdate ? "변경" : "예약") + "하시겠습니까?");
              if (answer) {
                if (!needUpdate && selectedList.length === 0) {
                  alert("호텔 확인");
                  return;
                }

                if (!isDirty) {
                  alert("타입 작성 확인");
                  return;
                }

                if (needUpdate) {
                  let res = await HTTPManager.UpdateToursHotel({
                    accessToken: userInfo.accessToken,
                    tourId: Props.tourInfo.id,
                    hotelState: selectedList,
                  })

                  if (res.data.result) {
                    alert("변경 완료");
                    Props.setShow(false);
                    Props.refresh();
                  } else {
                    alert("변경 실패");
                  }
                } else {
                  let res = await HTTPManager.RegistToursHotel({
                    accessToken: userInfo.accessToken,
                    tourId: Props.tourInfo.id,
                    hotelState: selectedList,
                  })

                  if (res.data.result) {
                    alert("예약 완료");
                    Props.setShow(false);
                    Props.refresh();
                  } else {
                    alert("예약 실패");
                  }
                }
              }
            }} />
          </div>
          <div className="w-fit">
            <ColorButton title="취소" color="crimson" handler={async () => {
              Props.setShow(false);
            }} />
          </div>
        </div>
        <div className="relative flex w-full flex-col justify-items-center pb-1 px-1 mt-1 bg-[white]">
          <AutoCompleteInput label="호텔 검색 (이동 : ↓↑키, 취소 : ESC, 추가 : Enter)" list={hotelList} selectedType="reservationHotel" selectedList={selectedList} selectedFunc={setSelectedList} startDate={Utils.convertLocalToUTCDate(Props.tourInfo.startDate)} endDate={Utils.convertLocalToUTCDate(Props.tourInfo.endDate)} />
        </div>

        <div className="text-left flex flex-row pl-1.5 mt-4 mb-2 font-bold">
          투어정보
        </div>

        <div className="flex flex-row w-full items-center bg-[#f5f5f5] border-t-2 border-[#3f4b5b] text-[#555555]">
          <div className="flex h-full w-fit mr-2 p-2 pl-4 pr-4">일정</div>
          <div className="flex flex-col flex-1 text-left bg-[white] p-2 pl-4 pr-4">
            <div>{Props.tourInfo.startDate.slice(0, 10)} {Props.tourInfo.eta}</div>
            <div>{Props.tourInfo.endDate.slice(0, 10)} {Props.tourInfo.etd}</div>
          </div>
        </div>

        {
          Props.tourInfo.name !== undefined && <div className="flex flex-row w-full items-center bg-[#f5f5f5] border-t text-[#555555]">
            <div className="flex h-full w-fit mr-2 p-2 pl-4 pr-4">국가</div>
            <div className="flex flex-col flex-1 text-left bg-[white] p-2 pl-4 pr-4">
              <div>{Props.tourInfo.name}</div>
            </div>
          </div>
        }

        {
          Props.tourInfo.itinerary !== undefined && <div className="flex flex-row w-full items-center bg-[#f5f5f5] border-t text-[#555555]">
            <div className="flex h-full w-fit mr-2 p-2 pl-4 pr-4">코스</div>
            <div className="flex flex-col flex-1 text-left bg-[white] p-2 pl-4 pr-4">
              <div>{Props.tourInfo.itinerary === null ? "미정" : Props.tourInfo.itinerary}</div>
            </div>
          </div>
        }

        {
          Props.tourInfo.pax !== undefined && <div className="flex flex-row w-full items-center bg-[#f5f5f5] border-t border-b text-[#555555]">
            <div className="flex h-full w-fit mr-2 p-2 pl-4 pr-4">인원</div>
            <div className="flex flex-col flex-1 text-left bg-[white] p-2 pl-4 pr-4">
              <div>{Props.tourInfo.pax}</div>
            </div>
          </div>
        }

        <div className="text-left flex flex-row pl-1.5 mt-4 mb-2 font-bold">
          호텔정보
        </div>

        {
          selectedList?.map((hotelInfo, index) => {
            return (
              <div
                key={index + "/" + hotelInfo.id}
                className="relative flex flex-row md:flex-col text-left pl-2 pr-2 md:pr-4 w-full mb-2 items-center md:items-start">
                <div className="flex flex-row">
                  <div className="text-[18px] text-[grey] mr-2 cursor-pointer" onClick={() => {
                    const tempArray = [...selectedList];
                    tempArray.splice(index, 1);
                    setSelectedList(tempArray);
                    setIsDirty(true);
                  }}>
                    <FontAwesomeIcon icon={faSquareMinus} />
                  </div>

                  <div className="flex text-left mr-2">
                    {hotelInfo.id} {hotelInfo.address1} {hotelInfo.address2} {hotelInfo.name}
                  </div>
                </div>
                <div className="relative flex flex-1 md:w-full md:p-1">
                  <div className="flex flex-row items-center text-[12px]">
                    <div className="flex flex-col mr-2 items-center">
                      체크인
                      <DatePicker onChange={date => {
                        selectedHotelListHandler(date, index, "startDate");
                      }}
                        selected={Utils.convertLocalToUTCDate(hotelInfo.startDate)}
                        locale={ko}
                        dateFormat="yyyy/MM/dd"
                        customInput={<DateCustomInput />}
                        showPopperArrow={false}
                      />
                    </div>
                    <div className="flex flex-col items-center">
                      체크아웃
                      <DatePicker onChange={date => {
                        selectedHotelListHandler(date, index, "endDate");
                      }}
                        selected={Utils.convertLocalToUTCDate(hotelInfo.endDate)}
                        locale={ko}
                        dateFormat="yyyy/MM/dd"
                        customInput={<DateCustomInput />}
                        showPopperArrow={false}
                      />
                    </div>
                  </div>
                  <input
                    className="rounded placeholder:text-slate-400 block bg-white border border-slate-300 py-2 pl-2 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 mr-2"
                    placeholder="타입) 더블 1, 트윈 2, 트리플 3 .."
                    type="text"
                    name="search"
                    style={{ flex: 1, marginTop: 5, marginLeft: 5 }}
                    onChange={(e) => {
                      selectedHotelListHandler(e.target.value, index, "roomType");
                    }}
                    value={hotelInfo.roomType}
                  />
                </div>
              </div>
            )
          })
        }
      </div>

    </div>
  );
}


