import React from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { UserInfo, LanguageSet } from "../../recoil";
import "../../fonts/Font.css"

const NoticeContentDialog = ({ showModal, setShowModal, info }) => {
  const navigate = useNavigate();

  const userInfo = useRecoilValue(UserInfo);
  const languageSet = useRecoilValue(LanguageSet);

  return (
    <>
      {showModal ? (
        <>
          <div className="mx-6 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 outline-none focus:outline-none font-medium z-[400]">
            <div className="relative w-full my-6 mx-auto max-w-2xl normal-case">
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col bg-white outline-none focus:outline-none">
                <div className="flex items-start justify-center p-5 rounded-t">
                  <h2 className="text-base font-bold">{info?.title}</h2>
                </div>
                <div className="relative p-3 whitespace-pre-wrap w-full flex break-all">
                  {
                    info?.content?.replaceAll("<br>", '\n')
                  }
                </div>

                <div className="flex w-full items-center justify-center rounded-b border-t">
                  <button
                    className="flex w-full justify-center py-3 rounded-bl-lg focus:outline-none"
                    type="button"
                    onClick={() => {
                      setShowModal(false);
                    }}
                  >
                    {languageSet.signinCancel}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-[399] bg-black"></div>
        </>
      ) : null}
    </>
  );
};

export default NoticeContentDialog;
