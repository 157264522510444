import React, { useState, useEffect } from "react";
import * as Utils from '../../features/Utils';
import * as HTTPManager from '../../features/HTTPManager';
import { UserInfo } from "../../recoil";
import { useRecoilValue } from "recoil";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ColorButton from "../ColorButton"
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { ROLES } from "../../features/Constant";

export default function RegisterUser() {
  const navigate = useNavigate();
  const userInfo = useRecoilValue(UserInfo);

  const [account, setAccount] = useState("");
  const [userList, setUserList] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState(0);

  const [showModifyPopup, setShowModifyPopup] = useState(false);
  const [modifyType, setModifyType] = useState("");
  const [modifyValue, setModifyValue] = useState("");

  async function fetchData() {
    var response = await HTTPManager.GetUserList({
      accessToken: userInfo.accessToken,
    });

    if (response !== undefined && response.data?.result !== undefined) {
      var sortList = [...response.data.result];
      sortList = sortList.filter(user => user.rolesId == 3 || user.rolesId == 5);
      sortList.sort((a, b) => a.account < b.account ? -1 : a.account > b.account ? 1 : 0);

      setUserList(sortList);
    }
  }

  useEffect(() => {
    Utils.allowByPosition(ROLES.ADMIN, userInfo.rolesId)
      .then(async (res) => {
        if (!res) {
          alert("접근 권한이 없습니다.");
          navigate("/", { replace: true });
        }
        else {
          await fetchData();
        }
      })
      .catch((err) => {
        console.log(err);
        alert(err.message);
      });
  }, [])

  async function handleKeyDown(event) {
    if (event.key === 'Enter') {
      registManager();
    }
  }

  async function registManager() {
    if (account.length < 1) {
      alert("계정 확인!");
      return;
    }

    var res = await HTTPManager.RegistManager({
      accessToken: userInfo.accessToken,
      account: account,
    });

    if (res.data.result) {
      alert("등록 완료");
      setAccount("");
    } else {
      alert("등록 실패");
    }

    fetchData();
  }

  return (
    <div
      className="mt-2 md:mt-14"
      style={{
        padding: 20,
        width: "100%",
        flex: 1,
        backgroundColor: "#fff",
        textAlign: "center",
        justifyContent: 'center',
        fontSize: 13,
      }}
    >
      <div className="defaultInfoArea" style={{ width: '100%', height: '100%', alignItems: 'flex-start', display: 'flex', flexDirection: 'column' }}>
        {
          userList?.length > 0 &&
          userList?.map(user => {
            if (user.rolesId === 3 || user.rolesId === 5) {
              if (user.name !== "없음")
                return (
                  <div key={user.id} className="flex w-full flex-row border hover:border-indigo-600 rounded mb-2 p-2 items-center">
                    <div className="flex flex-row w-full ">

                      <div className="flex justify-between py-5 w-full">
                        <div className="flex min-w-0 gap-x-4">
                          <div className="h-12 w-12 flex-none rounded-full bg-gray-200" src="" alt="" />
                          <div className="min-w-0 flex-auto">
                            <p className="text-sm font-semibold leading-6 text-gray-900 text-left normal-case cursor-pointer hover:text-blue-600" onClick={() => {
                              setSelectedUserId(user.id);
                              setModifyType("account");
                              setModifyValue(user.account);
                              setShowModifyPopup(true);
                            }}>{user.account}</p>
                            <p className="mt-1 truncate text-xs leading-5 text-gray-500 text-left normal-case cursor-pointer hover:text-blue-600" onClick={() => {
                              setSelectedUserId(user.id);
                              setModifyType("name");
                              setModifyValue(user.name);
                              setShowModifyPopup(true);
                            }}>이름 : {user.name}</p>
                          </div>
                        </div>

                        <div className="flex flex-col justify-end mr-5">
                          <p className="text-sm leading-6 text-gray-900 text-right">{user.rolesId === 3 ? "KTS" : "ITS"}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                )
            }
            else
              return null;
          })
        }
      </div>
      {
        // 수정 다이얼로그
        showModifyPopup &&
        <div className="left-20 md:left-0 top-20" style={{ position: "fixed", display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: 500, }}>
          <div style={{ display: "flex", flexDirection: "column", background: "#000c", padding: 20, paddingTop: 20, borderRadius: 10 }}>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <div
                style={{
                  width: 25,
                  height: 25,
                  marginBottom: 5,
                  fontSize: 15,
                  color: "white",
                  cursor: "pointer",
                  textAlign: "center"
                }}
                onClick={() => { setShowModifyPopup(false) }}
              >
                <FontAwesomeIcon icon={faXmark} />
              </div>
            </div>
            <div className="text-white mb-4 text-sm font-bold">
              변경하실 내용을 입력해주세요.
            </div>
            <div style={{ marginBottom: 10, display: 'flex', flexDirection: 'row', alignItems: 'center', }}>
              <div style={{ width: 80, color: '#9ca3af', marginRight: 5, }}>{modifyType}</div>
              <input
                className="placeholder:text-slate-400 block bg-white border border-slate-300 rounded-md py-2 pl-3 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1"
                type="text"
                onChange={(e) => {
                  setModifyValue(e.target.value);
                }}
                value={modifyValue}
              />
            </div>
            <div className="flex flex-row text-sm">
              <div className="mr-2 w-full">
                <ColorButton title={"취소"} color={"crimson"} width={"100%"} handler={async () => {
                  setShowModifyPopup(false);
                }} />
              </div>
              <div className="w-full">
                <ColorButton title={"확인"} color={"deepskyblue"} width={"100%"} handler={async () => {
                  let res = await HTTPManager.ChangeGuideInfo({
                    accessToken: userInfo.accessToken,
                    guideId: selectedUserId,
                    type: modifyType,
                    value: modifyValue,
                  })

                  if (res?.data?.result) {
                    setShowModifyPopup(false);
                    alert("변경 완료");
                    fetchData();
                  } else {
                    alert("변경 실패!");
                  }
                }} />
              </div>
            </div>
          </div>
        </div>
      }
    </div >
  );
}