import React, { useState, useEffect, forwardRef } from "react";
import { useRecoilValue } from "recoil";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ko } from 'date-fns/esm/locale';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear } from "@fortawesome/free-solid-svg-icons";
import * as Utils from '../../features/Utils';
import * as HTTPManager from '../../features/HTTPManager';
//import { ROLES } from "../../features/Constant"
import { UserInfo } from "../../recoil";
import ColorButton from "../ColorButton"
import Dropdown from "../Dropdown";
import AutoCompleteInput from "../AutoCompleteInput";

export default function ModifyDialog(Props) {
  const tourInfo = Props.tourInfo;
  const body = document.querySelector('body');
  const scrollPosition = window.pageYOffset;

  // 서버정보
  const userInfo = useRecoilValue(UserInfo);
  const [countryList, setCountryList] = useState([]);

  // 수정항목
  const [startDate, setStartDate] = useState(Utils.convertLocalToUTCDate(tourInfo.startDate));
  const [endDate, setEndDate] = useState(Utils.convertLocalToUTCDate(tourInfo.endDate));

  const [countryId, setCountryId] = useState(tourInfo.countryId);
  const [pax, setPax] = useState(tourInfo.pax === null ? "" : tourInfo.pax);
  const [itinerary, setItinerary] = useState(tourInfo.itinerary === null ? "" : tourInfo.itinerary);
  const [docNum, setDocNum] = useState(tourInfo.docNum === null ? "" : tourInfo.docNum);
  const [eta, setEta] = useState(tourInfo.eta === null ? "" : tourInfo.eta);
  const [etd, setEtd] = useState(tourInfo.etd === null ? "" : tourInfo.etd);
  const [memo, setMemo] = useState(tourInfo.memo === null ? "" : tourInfo.memo);
  const [payment, setPyament] = useState(tourInfo.payment === null ? "" : tourInfo.payment);
  const [nami, setNami] = useState(tourInfo.nami === null ? "" : tourInfo.nami);
  const [dutyFreeShop, setDutyFreeShop] = useState(tourInfo.dutyFreeShop === null ? "" : tourInfo.dutyFreeShop);
  const [airplaneSchedule, setAirplaneSchedule] = useState([]);

  // 입출국 결합
  let etaStr = tourInfo.eta === null ? "" : tourInfo.eta
  let etdStr = tourInfo.etd === null ? "" : " / " + tourInfo.etd
  const [etad, setEtad] = useState(etaStr + etdStr);

  // 수정여부 확인
  const [isDirty, setIsDirty] = useState(false);

  async function fetchData() {
    let res = await HTTPManager.GetCountryList();
    setCountryList(res.data.result);

    res = await HTTPManager.GetAirplaneScheduleList({
      accessToken: userInfo.accessToken,
    });

    setAirplaneSchedule(res.result);
  }

  async function modifyInfo() {
    let res = await HTTPManager.ModifyTourInfo({
      accessToken: userInfo.accessToken,
      tourId: tourInfo.id,
      tourInfo: {
        startDate: startDate,
        endDate: endDate,
        countryId: countryId,
        pax: pax,
        itinerary: itinerary,
        docNum: docNum,
        eta: eta,
        etd: etd,
        memo: memo,
        payment: payment,
        nami: nami,
        dutyFreeShop: dutyFreeShop,
      }
    });

    if (res?.data?.result) {
      Props.setShow(false);
      Props.refresh();
    } else {
      alert("수정 실패!");
    }
  }

  async function handleKeyDown(event) {
    if (event.key === 'Enter') {
      await modifyInfo();
    } else if (event.key === 'Escape') {
      Props.setShow(false);
    }
  }

  function handleAirplaneSchedule(value) {
    setEtad(value);
    let inputList = value.split('/');

    if (inputList[0] !== undefined && inputList[0].length > 0) {
      setEta(inputList[0].trimEnd());
    } else {
      setEta("");
    }

    if (inputList[1] !== undefined && inputList[1].length > 0) {
      setEtd(inputList[1].trimEnd());
    } else {
      setEtd("");
    }

    setIsDirty(true);
  }

  useEffect(() => {
    // 데이터 수신
    fetchData();

    // 모달 팝업 시 호출
    // 스크롤 최상단 위치 후 배경 스크롤 되지 않도록 고정 -> 최상단 이동 삭제    
    body.style.overflow = 'hidden';

    // 모달 종료 시 호출
    return () => {
      body.style.removeProperty('overflow');
      window.scrollTo(0, scrollPosition);
    };
  }, []);

  const DateCustomInput = forwardRef(({ value, onClick }, ref) => (
    <button onClick={onClick} ref={ref}>{value}</button>
  ));

  const courseData = ["서울", "남이섬", "설악", "부산", "제주", "전주", "경주"];

  function getCourse(title) {
    let itinerary = tourInfo.itin;

    let cssStyleOn = "flex-none items-center rounded-md bg-[red] px-2 py-1 text-sm font-bold text-[white] ring-1 ring-inset ring-[red] ml-2 cursor-pointer hover:bg-red-400 hover:ring-red-400";
    let cssStyleOff = "flex-none items-center rounded-md bg-[#cfcfcf] px-2 py-1 text-sm font-bold text-[white] ring-1 ring-inset ring-[#cfcfcf] ml-2 cursor-pointer hover:bg-red-400 hover:ring-red-400";
    let applyStyle = cssStyleOff;

    let findIdx = courseData.findIndex(item => item === title);
    let value = Math.pow(2, findIdx);

    applyStyle = (itinerary & value) > 0 ? cssStyleOn : cssStyleOff;

    return <span
      title={title}
      className={applyStyle}
      onClick={async () => {
        let res = await HTTPManager.ModifyTourItineraryInfo({
          accessToken: userInfo.accessToken,
          tourId: Props.tourInfo.id,
          param: {
            itin: findIdx,
          },
        });

        if (res?.data?.result) {
          Props.refresh();
        } else {
          alert("수정 실패!");
        }
      }}>
      {title}
    </span>
  }

  return (
    <div className="fixed h-full w-full top-0 left-0 text-sm bg-[#0007] overflow-auto z-[999]">
      <div className="fixed h-full w-full bg-white overflow-auto">
        <div className="flex flex-row items-center border-b p-2">
          <div className="flex flex-1 font-bold text-[20px]">
            <div className="text-[#34414d]">{tourInfo.id}번</div>
          </div>
          <div style={{
            opacity: !isDirty ? 0.5 : 1,
            pointerEvents: !isDirty && "none"
          }}>
            <ColorButton title={"변경"} color="deepskyblue" handler={async () => {
              await modifyInfo();
            }} />
          </div>
          <div className="w-fit">
            <ColorButton title="취소" color="crimson" handler={async () => {
              Props.setShow(false);
            }} />
          </div>
        </div>

        <div className="text-left flex pl-1.5 mt-4 mb-2 font-bold">
          수정항목
        </div>
        <div className="flex flex-row w-full items-center bg-[#f5f5f5] border-t-2 border-[#3f4b5b] text-[#555555]"></div>

        <div className="text-left flex flex-col px-1.5 mt-2 mb-2">
          <div className="mb-[5px] font-medium">시작일</div>
          <div className="font-bold">
            <DatePicker onChange={date => {
              setStartDate(date);
              setIsDirty(true);
            }}
              selected={startDate}
              locale={ko}
              dateFormat="yyyy/MM/dd"
              customInput={<DateCustomInput />}
              showPopperArrow={false}
            />
          </div>
        </div>

        <div className="text-left flex flex-col px-1.5 mb-3">
          <div className="mb-[5px] font-medium">종료일</div>
          <div className="font-bold">
            <DatePicker onChange={date => {
              setEndDate(date);
              setIsDirty(true);
            }}
              selected={endDate}
              locale={ko}
              dateFormat="yyyy/MM/dd"
              customInput={<DateCustomInput />}
              showPopperArrow={false}
            />
          </div>
        </div>

        <div className="text-left flex flex-col w-full px-1.5 mb-3 items-center">
          <div className="flex w-full mb-[5px] font-medium">국가</div>
          <div className="flex w-full items-center font-bold">
            <Dropdown title="목록" data={countryList?.map((item) => {
              return {
                id: item.id,
                name: item.nameCountry,
              }
            })}
              setFunc={setCountryId}
              initId={tourInfo.countryId}
              onClick={() => { setIsDirty(true) }}
            />
          </div>
        </div>

        <div className="text-left flex flex-col w-full px-1.5 mb-3 items-center">
          <div className="flex w-full mb-[5px] font-medium">인원</div>
          <div className="relative flex w-full font-bold">
            <input
              className="placeholder:text-slate-400 block bg-white border border-slate-300 py-2 pl-2 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 mr-[5px] rounded"
              placeholder="ex) 15+1명"
              type="text"
              name="search"
              style={{ flex: 1, marginTop: 5, marginLeft: 5 }}
              onChange={(e) => {
                setPax(e.target.value);
                setIsDirty(true);
              }}
              onKeyDown={(e) => handleKeyDown(e)}
              value={pax}
            />
          </div>
        </div>

        <div className="text-left flex flex-col w-full px-1.5 mb-3 items-center">
          <div className="flex w-full mb-[5px] font-medium">코스</div>
          <div className="relative flex w-full font-bold">
            <div className="scrollRemoveBox flex flex-row mt-2 md:w-full overflow-auto select-none">
              {getCourse("서울")}
              {getCourse("남이섬")}
              {getCourse("설악")}
              {getCourse("부산")}
              {getCourse("제주")}
              {getCourse("전주")}
              {getCourse("경주")}
            </div>
          </div>
        </div>

        <div className="text-left flex flex-col w-full px-1.5 mb-3 items-center">
          <div className="flex w-full mb-[5px] font-medium">문서번호</div>
          <div className="relative flex w-full font-bold">
            <input
              className="placeholder:text-slate-400 block bg-white border border-slate-300 py-2 pl-2 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 mr-[5px] rounded"
              placeholder="ex) ITS_xxx, PH_xxx"
              type="text"
              name="search"
              style={{ flex: 1, marginTop: 5, marginLeft: 5 }}
              onChange={(e) => {
                setDocNum(e.target.value);
                setIsDirty(true);
              }}
              onKeyDown={(e) => handleKeyDown(e)}
              value={docNum}
            />
          </div>
        </div>

        <div className="text-left flex flex-col w-full px-1.5 mb-3 items-center">
          {/* <div className="flex w-full mb-[5px]">입국</div>
          <div className="relative flex w-full font-bold mb-[5px]">
            <input
              className="placeholder:text-slate-400 block bg-white border border-slate-300 py-2 pl-2 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 mr-[5px] rounded"
              placeholder="ex) SQ123"
              type="text"
              name="search"
              style={{ flex: 1, marginTop: 5, marginLeft: 5 }}
              onChange={(e) => {
                setEta(e.target.value);
                setIsDirty(true);
              }}
              onKeyDown={(e) => handleKeyDown(e)}
              value={eta}
            />
          </div>
          <div className="flex w-full mb-[5px]">출국</div>
          <div className="relative flex w-full font-bold mb-[5px]">
            <input
              className="placeholder:text-slate-400 block bg-white border border-slate-300 py-2 pl-2 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 mr-[5px] rounded"
              placeholder="ex) SQ456"
              type="text"
              name="search"
              style={{ flex: 1, marginTop: 5, marginLeft: 5 }}
              onChange={(e) => {
                setEtd(e.target.value);
                setIsDirty(true);
              }}
              onKeyDown={(e) => handleKeyDown(e)}
              value={etd}
            />
          </div> */}

          <div className="flex w-full mb-[5px] font-medium">입국, 출국 (New)</div>
          <div className="relative flex w-full font-bold">
            <input
              className="placeholder:text-slate-400 block bg-white border border-slate-300 py-2 pl-2 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 mr-[5px] rounded"
              placeholder="ex) SQ123 / SQ456 반드시 '/' 사용 구분해서 입력!"
              type="text"
              name="search"
              style={{ flex: 1, marginTop: 5, marginLeft: 5 }}
              onChange={(e) => {
                handleAirplaneSchedule(e.target.value);
              }}
              value={etad}
            />
          </div>
        </div>

        <div className="relative flex w-full flex-col justify-items-center pb-1 px-3 bg-[white] mb-2 font-medium">
          <AutoCompleteInput label="입출국 검색 (이동 : ↓↑키, 취소 : ESC, 추가 : Enter)" list={airplaneSchedule} selectedType="reservationEtaEtd" selectedList={etad} selectedFunc={setEtad} callback={handleAirplaneSchedule} />
        </div>

        <div className="text-left flex flex-col w-full px-1.5 mb-3 items-center">
          <div className="flex w-full mb-[5px] font-medium">메모</div>
          <div className="relative flex w-full font-bold">
            <input
              className="placeholder:text-slate-400 block bg-white border border-slate-300 py-2 pl-2 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 mr-[5px] rounded"
              placeholder="ex) 특이사항"
              type="text"
              name="search"
              style={{ flex: 1, marginTop: 5, marginLeft: 5 }}
              onChange={(e) => {
                setMemo(e.target.value);
                setIsDirty(true);
              }}
              onKeyDown={(e) => handleKeyDown(e)}
              value={memo}
            />
          </div>
        </div>

        {/* <div className="text-left flex flex-col w-full px-1.5 mb-3 items-center">
          <div className="flex w-full mb-[5px]">남이섬</div>
          <div className="relative flex w-full font-bold">
            <input
              className="placeholder:text-slate-400 block bg-white border border-slate-300 py-2 pl-2 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 mr-[5px] rounded"
              placeholder="ex) Link, 없음, 티켓전달 등"
              type="text"
              name="search"
              style={{ flex: 1, marginTop: 5, marginLeft: 5 }}
              onChange={(e) => {
                setNami(e.target.value);
                setIsDirty(true);
              }}
              onKeyDown={(e) => handleKeyDown(e)}
              value={nami}
            />
          </div>
        </div> */}

        <div className="text-left flex flex-col w-full px-1.5 mb-3 items-center">
          <div className="flex w-full mb-[5px] font-medium">면세점</div>
          <div className="relative flex w-full font-bold">
            <input
              className="placeholder:text-slate-400 block bg-white border border-slate-300 py-2 pl-2 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 mr-[5px] rounded"
              placeholder="ex) 업체 + 등록번호"
              type="text"
              name="search"
              style={{ flex: 1, marginTop: 5, marginLeft: 5 }}
              onChange={(e) => {
                setDutyFreeShop(e.target.value);
                setIsDirty(true);
              }}
              onKeyDown={(e) => handleKeyDown(e)}
              value={dutyFreeShop}
            />
          </div>
        </div>

        {
          tourInfo.name === "필리핀" && <div className="text-left flex flex-col w-full px-1.5 mb-3 items-center">
            <div className="flex w-full mb-[5px] font-medium">페이먼트</div>
            <div className="relative flex w-full font-bold">
              <input
                className="placeholder:text-slate-400 block bg-white border border-slate-300 py-2 pl-2 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 mr-[5px] rounded"
                placeholder="ex) $7,000 입금"
                type="text"
                name="search"
                style={{ flex: 1, marginTop: 5, marginLeft: 5 }}
                onChange={(e) => {
                  setPyament(e.target.value);
                  setIsDirty(true);
                }}
                onKeyDown={(e) => handleKeyDown(e)}
                value={payment}
              />
            </div>
          </div>
        }

      </div>

    </div>
  )
}