

import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { REQUEST_STATE } from "../../features/Constant";
import "../styles/CommonStyle.css";
import { ResponsiveBar } from '@nivo/bar'
import { ResponsiveLineCanvas } from "@nivo/line";
import { ResponsiveLine } from "@nivo/line";
import * as Utils from "../../features/Utils";

export default function AgencyAccount(Props) {
  const navigate = useNavigate();

  const Bar = () => {
    return (
      <ResponsiveLine
        colors={Props.data !== undefined && Props.data[0] !== undefined ? Props.data[0].color : "#fff"}
        curve="monotoneX"
        yFormat="> ,"
        data={Props.data !== undefined ? Props.data : []}
        margin={{
          bottom: 80,
          left: 80,
          right: 40,
          top: 80
        }}
        yScale={{
          type: 'linear',
          min: 'auto',
          max: 'auto',
          stacked: true,
        }}
        axisBottom={null}
        pointLabelYOffset={12}
        useMesh={true}
        enableTouchCrosshair={true}
        pointLabel="data.yFormatted"
        enableSlices="x"
        enableGridX={false}
        sliceTooltip={(_) => {
          return <div className="bg-[white] shadow-2xl px-10 py-5 flex flex-col">
            <div>{_.slice.points[0].data.x}</div>
            <div className="font-bold" style={{ color: _.slice.points[0].data.y < 0 ? "#f00" : "#000" }}>{_.slice.points[0].data.yFormatted}원</div>
          </div>
        }}
      />
    );
  };

  return (
    <div className="scrollRemoveBox flex flex-col w-full h-full justify-items-center items-center text-[13px] normal-case">
      {
        Bar()
      }
      {
        (Props.list !== undefined || Props.list?.length === 0) && <div className="flex w-full h-full justify-center items-center text-[20px]">Chart</div>
      }
    </div>
  );
}