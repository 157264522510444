import { useRecoilValue } from "recoil";
import { UserInfo } from "../../recoil";
import React, { useState, useEffect } from "react";
import * as HTTPManager from '../../features/HTTPManager';
import NoticeContentDialog from "./NoticeContentDialog";
import { ROLES } from "../../features/Constant";

const NoticeBoard = () => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const userInfo = useRecoilValue(UserInfo);
  const [noticeList, setNoticeList] = useState([]);
  const [showContent, setShowContent] = useState(false);
  const [selectedId, setSelectedId] = useState(0);

  async function fetchData() {
    let response = await HTTPManager.GetNoticeList({
      accessToken: userInfo.accessToken,
    });

    if (response !== undefined && response.data !== undefined) {
      setNoticeList(response.data.result);
    }
  }

  useEffect(() => {
    if (userInfo.rolesId === ROLES.ADMIN || userInfo.rolesId === ROLES.GOD) {
      // 관리자만
      if (userInfo.accessToken !== undefined)
        fetchData();
      else
        setNoticeList([]);
    } else {
      setNoticeList([]);
    }
  }, [userInfo]);

  return (
    <div className="w-[50%] md:w-full"
      style={{
        fontFamily: "Noto Sans KR"
      }}>
      {
        userInfo.rolesId === ROLES.ADMIN || userInfo.rolesId === ROLES.GOD &&
        <div className="w-full text-center font-bold mb-2">공지사항({noticeList?.length})</div>
      }
      {
        noticeList?.length > 0 &&
        noticeList?.map((notice, index) => {
          return (
            <div key={index} className={`cursor-pointer select-none font-medium text-[15px] p-[10px] rounded mb-1 ${index % 2 === 0 ? 'bg-[#efefef]' : 'bg-[#fefefe]'}`}
              onClick={() => {
                setSelectedId(notice.id);
                setShowContent(true);
              }}
            >
              [{notice.createdAt?.slice(0, 10)}] {notice.title}
            </div>
          );
        })
      }
      <NoticeContentDialog showModal={showContent} setShowModal={setShowContent} info={noticeList?.find(item => item.id === selectedId)} />
    </div>);
};

export default NoticeBoard;
