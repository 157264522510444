import React, { useState, useEffect } from "react";
import { useRecoilState, } from "recoil";
import { ROLES } from "../../features/Constant";
import * as HTTPManager from "../../features/HTTPManager";
import * as HTTPSettlementManager from "../../features/HTTPSettlementManager";
import * as Utils from "../../features/Utils";
import { UserInfo } from "../../recoil";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { Tooltip } from "react-tooltip";

const GuideSettlementPage = () => {
  const [userInfo, setUserInfo] = useRecoilState(UserInfo);
  const [myTourList, setMyTourList] = useState([]);

  // 이슈 : ToursCardList에 현재 달과 다른 달의 투어 정보가 표시됨.
  // 재현경로 : 다른 달로 이동한 상태 > 다른 메뉴 페이지 확인 > 이 페이지로 복귀
  // 수정 : 전역 변수 > 지역변수
  // Calendar 컴포넌트에서 set함수 받아서 처리
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const [year, setYear] = useState(today.getFullYear());
  const [month, setMonth] = useState(today.getMonth());

  const [userList, setUserList] = useState([]);
  const [toursGuideInfo, setToursGuideInfo] = useState([]);
  const [toursGuideIncentive, setToursGuideIncentive] = useState([]);

  async function fecthData() {
    if (userInfo.accessToken === undefined) return;

    let res = "";

    if (userInfo.rolesId === ROLES.LEADERGUIDE || userInfo.rolesId === ROLES.ADMIN) {
      res = await HTTPManager.GetUserList({
        accessToken: userInfo.accessToken,
      });

      setUserList(res.data.result);
    }

    res = await HTTPManager.GetMyToursList({
      accessToken: userInfo.accessToken,
      year,
      month,
    });

    if (res.data.accessToken) {
      setUserInfo((prev) => {
        const returnValue = { ...prev };
        returnValue.accessToken = res.data.accessToken;

        return { ...returnValue };
      });
    }

    // [확인필요] 같은 행사 중복 수신
    // 취소 행사 제외    
    setMyTourList(res.data.result.filter(item => item.cancel !== 1));
    if (res.data !== undefined) {
      setToursGuideInfo(res.data.toursGuide.filter(item => item.cancel !== 1).sort((a, b) => {
        // 시작일 빠른순 정렬
        if (new Date(a.startDate.replace(" ", "T")) > new Date(b.startDate.replace(" ", "T")))
          return 1;
        if (new Date(a.startDate.replace(" ", "T")) < new Date(b.startDate.replace(" ", "T")))
          return -1;

        // 종료일 빠른순 정렬
        if (new Date(a.endDate.replace(" ", "T")) > new Date(b.endDate.replace(" ", "T")))
          return 1;
        if (new Date(a.endDate.replace(" ", "T")) < new Date(b.endDate.replace(" ", "T")))
          return -1;
      }));
    }

    res = await HTTPSettlementManager.getAdditionalIncentiveList({
      accessToken: userInfo.accessToken,
    });

    if (res !== undefined) {
      setToursGuideIncentive(res.data.result);
    }
  }

  useEffect(() => {
    fecthData();
  }, [userInfo, year, month, setUserInfo]);

  // 상태 정리
  const stateSymbol = (receiptCheck, guideCheck, accountFinish, index) => {
    // 영수증 확인 > 가이드 확인 > 정산완료    
    if (accountFinish === 1) {
      return <div className={`tooltip${index} w-4 h-4 cursor-pointer bg-[#4ED964] rounded-full`} >
        <Tooltip anchorSelect={`.tooltip${index}`} place="top">
          정산완료
        </Tooltip>
      </div>
    }

    if (guideCheck === 1) {
      return <div className={`tooltip${index} w-4 h-4 cursor-pointer bg-[#4E64D9] rounded-full`} >
        <Tooltip anchorSelect={`.tooltip${index}`} place="top">
          가이드확인완료
        </Tooltip>
      </div>
    }

    if (receiptCheck === 1) {
      return <div className={`tooltip${index} w-4 h-4 cursor-pointer bg-[#FFEA00] rounded-full`}>
        <Tooltip anchorSelect={`.tooltip${index}`} place="top">
          영수증확인완료
        </Tooltip>
      </div>
    }
  }

  return (
    <div className="flex flex-col w-full h-full pt-[50px] pb-[37px] px-[30px]">
      <div className="flex flex-col">
        <div className="text-[32px] font-extrabold mb-[25px] md:mt-[10px]">정산</div>
        <div className="text-[18px] p-[0.7px] font-bold mb-[25px]">내역 조회</div>
        <div className="text-[#485763] font-bold normal-case mb-[25px] flex flex-col gap-1">
          <div className="flex flex-row items-center gap-3">
            <FontAwesomeIcon icon={faCircle} style={{ color: "#000", width: 5 }} />
            <div className="text-[14px]">값이 없는 이전 행사는 데이터 입력 중입니다. 데이터 입력이 완료되면 확인 하실 수 있습니다.</div>
          </div>
          <div className="flex flex-row items-center gap-3">
            <FontAwesomeIcon icon={faCircle} style={{ color: "#000", width: 5 }} />
            <div className="text-[14px]">정산담당자가 영수증을 확인한 경우 금액이 표시됩니다.</div>
          </div>
        </div>
      </div>

      {/* <div className="w-full h-[1px] bg-[#DCDCDC] my-[25px]" /> */}

      <div className="w-full h-[1px] bg-[#DCDCDC] my-[25px]" />

      <div className="grid grid-cols-[5%_10%_10%_10%_10%_10%_10%_10%_10%_10%_5%] sticky top-0 w-full text-[white] text-center text-[16px] font-bold bg-[black] z-[100]">
        <div>번호</div>
        <div>날짜</div>
        <div>전도금</div>
        <div>일비합계</div>
        <div>쇼핑(인삼+적송)</div>
        <div>인센티브</div>
        <div>원천징수(3.3%)</div>
        <div>합계</div>
        <div>전도금잔액</div>
        <div>총합계</div>
        <div>상태</div>
      </div>
      <table>
        <tbody>
          {
            toursGuideInfo?.map((item, index) => {
              // 인센티브
              let shopIncentive = Number(item.shopIncentive);

              // 추가인센티브 계산
              let additionalIncentiveList = toursGuideIncentive?.filter(item => item.toursGuideId === item.id);
              let additionalIncentive = additionalIncentiveList.reduce((a, b) => a = a + Number(b.amount), 0);
              let additionalIncentiveTax = additionalIncentiveList.reduce((a, b) => a = a + (Utils.roundDown(Number(b.amount) * 0.03, -1) + Utils.roundDown(Number(b.amount) * 0.003, -1)), 0);

              let guideFeeTax = Utils.roundDown(item.guideFee * 0.03, -1) + Utils.roundDown(item.guideFee * 0.003, -1);

              // 원천징수
              let tax = guideFeeTax + (Utils.roundDown(shopIncentive * 0.03, -1) + Utils.roundDown(shopIncentive * 0.003, -1)) + additionalIncentiveTax;

              let sum = Number(item.guideFee) + shopIncentive + additionalIncentive - tax;
              let totalSum = sum - Number(item.preChargeChange);

              // 상태
              let receiptCheck = item.receiptCheck;   // 영수증확인완료
              let guideCheck = item.guideCheck;       // 가이드확인완료
              let accountFinish = item.accountFinish; // 정산완료

              return (
                <tr key={item.id} className="grid grid-cols-[5%_10%_10%_10%_10%_10%_10%_10%_10%_10%_5%] text-center border-[#E2E2E2] border-x-[1px] border-b-[1px] py-5 text-[#A8A9A8] font-semibold">
                  <td>{index + 1}</td>
                  <td>{item.startDate?.slice(2, 10)?.replaceAll('-', '.')}~{item.endDate?.slice(2, 10)?.replaceAll('-', '.')}</td>
                  <td>{receiptCheck === 1 ? Utils.formatNumber(item.preCharge) : ""}</td>
                  <td>{receiptCheck === 1 ? Utils.formatNumber(item.guideFee) : ""}</td>
                  <td>{receiptCheck === 1 ? Utils.formatNumber(item.shopCount) : ""}</td>
                  <td>{receiptCheck === 1 ? Utils.formatNumber(shopIncentive) : ""}</td>
                  <td>{receiptCheck === 1 ? Utils.formatNumber(tax) : ""}</td>
                  <td>{receiptCheck === 1 ? Utils.formatNumber(sum) : ""}</td>
                  <td>{receiptCheck === 1 ? Utils.formatNumber(item.preChargeChange) : ""}</td>
                  <td className={`${totalSum < 0 ? "text-[red]" : "text-[black]"} font-bold`}>{receiptCheck === 1 ? Utils.formatNumber(totalSum) : ""}</td>
                  <td className="flex justify-center items-center">
                    {
                      stateSymbol(receiptCheck, guideCheck, accountFinish, index)
                    }
                  </td>
                </tr>
              )
            })
          }
        </tbody>
      </table>
    </div>
  );
};

export default GuideSettlementPage;
