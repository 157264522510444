import { useEffect } from 'react';

// url script 삽입하는 hook
const useScript = (url, integrity, async = true, crossOrigin = "anonymous") => {
  useEffect(() => {
    const script = document.createElement('script');

    script.src = url;
    script.async = true;

    if (integrity) {
      script.integrity = integrity
    }

    script.crossOrigin = crossOrigin

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    }
  }, [url, integrity, async, crossOrigin]);
};

export default useScript;