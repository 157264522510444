import React, { } from "react";
import { useNavigate } from "react-router-dom";
import { REQUEST_STATE } from "../../features/Constant";

export default function RequestBoardItem(Props) {
  const data = Props.data;
  const navigate = useNavigate();

  const redTag = (msg) => {
    return <span className="inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10 mr-[10px]">{msg}</span>
  }

  const checkITSTag = (msg) => {
    return <span className="inline-flex items-center rounded-md bg-[red] px-2 py-1 text-xs font-medium text-white ring-1 ring-inset ring-red-600/10 mr-2 animate-bounce">{msg}</span>
  }

  const yellowTag = (msg) => {
    return <span className="inline-flex items-center rounded-md bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-800 ring-1 ring-inset ring-yellow-600/20 mr-[5px]">{msg}</span>
  }

  const greenTag = (msg) => {
    return <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20 mr-[10px]">{msg}</span>
  }

  const blueTag = (msg) => {
    return <span className="inline-flex items-center rounded-md bg-blue-50 px-2 py-1 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10 mr-[5px]">{msg}</span>
  }

  const purpleTag = (msg) => {
    return <span className="flex-none inline-flex items-center rounded-md bg-purple-50 px-2 py-1 text-xs font-medium text-purple-700 ring-1 ring-inset ring-purple-700/10 mr-[5px]">{msg}</span>
  }

  const blackTag = (msg) => {
    return <span className="flex-none inline-flex items-center rounded-md bg-[black] px-2 py-1 text-xs font-medium text-[white] ring-1 ring-inset ring-[black] mr-[5px]">{msg}</span>
  }

  const needToHotelPriceTag = (msg) => {
    return <span className="flex-none inline-flex items-center rounded-md bg-[#A1EEBD] px-2 py-1 text-xs font-medium text-[white] ring-1 ring-inset ring-[#A1EEBD] mr-2">{msg}</span>
  }

  const completeHotelPriceTag = (msg) => {
    return <span className="flex-none inline-flex items-center rounded-md bg-[#89A8B2] px-2 py-1 text-xs font-medium text-[white] ring-1 ring-inset ring-[#89A8B2] mr-2">{msg}</span>
  }

  function getComponentState(state) {
    switch (state) {
      case REQUEST_STATE.NEEDTOCHECK:
        return redTag("Need to Check");
      case REQUEST_STATE.NEEDTOCHECKITS:
        return checkITSTag("NEED TO CHECK(ITS)");
      case REQUEST_STATE.PROCESSING:
        return yellowTag("Processing");
      case REQUEST_STATE.COMPLETE:
        return greenTag("Complete");
      case REQUEST_STATE.REVISE:
        return blueTag("Revise");
      case REQUEST_STATE.PAUSE:
        return purpleTag("Pause");
      case REQUEST_STATE.REJECT:
        return blackTag("Reject");
      case REQUEST_STATE.NEEDTOHOTELPRICE:
        return needToHotelPriceTag("Need to Hotel Price");
      case REQUEST_STATE.COMPLETEHOTELPRICE:
        return completeHotelPriceTag("Complete Hotel Price");
    }
  }

  return (
    <div className="flex flex-col justify-items-center items-center text-[13px] normal-case">
      <div className="flex w-full">
        <label className="flex items-center select-none" style={{ display: Props.showCheckBox ? "" : "none" }}>
          <input id={`checkBox${data.id}`} className="cursor-pointer" type="checkbox" onChange={() => {
            var checkbox = document.getElementById(`checkBox${data.id}`);
            Props.onCheck(checkbox.checked);
          }} checked={Props.checked} />
        </label>
        <div className="flex w-[150px] md:w-[100px] text-center py-2 justify-center items-center">{data.id}</div>
        <div className="flex flex-row w-full text-start py-2 cursor-pointer"
          onClick={async () => {
            navigate("/requestBoardContent", { state: { id: data.id, title: data.title, userName: data.name, createdAt: data.createdAt.slice(2, 19) } });
          }}
        >
          {
            getComponentState(data.state)
            // 0 : check, 1 : complete
            //data.state === 0 ? redTag("Need to check") : data.state === 1 ? yellowTag("Processing") : data.state === 2 ? greenTag("Complete") : blueTag("Revise")
          }
          <div>{data.title}</div>
          {
            data.commentCount > 0 ? <div className="ml-[5px] text-[crimson]">[{data.commentCount}]</div> : null
          }
        </div>
        <div className="w-[300px] md:w-[200px] text-center py-2 ">{data.name}</div>
        <div className="w-[400px] md:w-[200px] text-center py-2">{data.createdAt.slice(2, 19)}</div>
      </div>
      <div className="flex w-full h-[1px] bg-[#F5F5F7]" />
    </div>
  );
}