import React, { useState, useEffect, useMemo, useRef } from "react";
import ReactDOM from "react-dom";
import { useRecoilValue } from "recoil";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import * as HTTPManager from '../../features/HTTPManager';
import { UserInfo } from "../../recoil";
import ColorButton from "../ColorButton"
import { useLocation, useNavigate } from "react-router-dom";
import ReservationListTable from "./ReservationListTable";

export default function ReservationTourList(Props) {
  const location = useLocation();
  const navigate = useNavigate();
  const userInfo = useRecoilValue(UserInfo);

  const [reservationInfo, setReservationInfo] = useState([]);
  const [reservationFilterInfo, setReservationFilterInfo] = useState([]);

  const [receiver, setReceiver] = useState("담당자 입력");
  const [title, setTitle] = useState("행사 목록");
  const [sender, setSender] = useState("대표 김종주");
  const [team, setTeam] = useState("국내사업부");
  const [phone, setPhone] = useState("+82-10-2326-2825");
  const [email, setEmail] = useState("james@ktravelstore.com");
  const [address, setAddress] = useState("서울시 마포구 연희로 11, 5층 코리아 트래블 스토어");

  const [showInput, setShowInput] = useState(false);
  const [inputHint, setInputHint] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [inputType, setInputType] = useState("");

  const [sortCountry, setSortCountry] = useState(false);

  async function handleKeyDown(event) {
    if (event.key === 'Enter') {
      changeValue();
    } else if (event.keyCode === 27) {
      setShowInput(false);
    }
  }

  function changeValue() {
    if (inputValue.length === 0) {
      alert("빈칸확인");
      return;
    }

    switch (inputType) {
      case "manager":
        setReceiver(inputValue);
        break;
      case "title":
        setTitle(inputValue);
        break;
      case "team":
        setTeam(inputValue);
        break;
      case "sender":
        setSender(inputValue);
        break;
      case "phone":
        setPhone(inputValue);
        break;
      case "email":
        setEmail(inputValue);
        break;
      default:
        break;
    }

    setShowInput(false);
  }

  async function fetchData() {
    let userList = [];
    let response = await HTTPManager.GetUserList({
      accessToken: userInfo.accessToken
    });

    if (response !== undefined) {
      userList = response.data.result;
    }

    let list = location.state.reservationTour;
    let toursGuideInfo = location.state.toursGuideInfo;
    let reservationList = list.map(reservation => {

      let guideList = toursGuideInfo.filter(item => item.tourId === reservation.id).map(item => {
        let guideInfo = userList.find(user => user.id === item.guideId);
        let guideName = guideInfo.name + "(" + guideInfo.nameEN + ")";

        return guideName;
      });

      return ({
        id: reservation.id,
        startDate: reservation.startDate.slice(0, 10),
        endDate: reservation.endDate.slice(0, 10),
        guide: guideList.toString(),
        pax: reservation.pax.replace("명", ""),
        country: reservation.name,
        payment: reservation.payment,
      });
    });

    reservationList.sort((a, b) => {
      if (sortCountry) {
        if (a.country > b.country)
          return 1;
        if (a.country < b.country)
          return -1;
      }

      // 시작일 빠른순 정렬
      if (new Date(a.startDate.replace(" ", "T")) > new Date(b.startDate.replace(" ", "T")))
        return 1;
      if (new Date(a.startDate.replace(" ", "T")) < new Date(b.startDate.replace(" ", "T")))
        return -1;

      // 종료일 빠른순 정렬
      if (new Date(a.endDate.replace(" ", "T")) > new Date(b.endDate.replace(" ", "T")))
        return 1;
      if (new Date(a.endDate.replace(" ", "T")) < new Date(b.endDate.replace(" ", "T")))
        return -1;
    })

    setReservationInfo(reservationList);
    setReservationFilterInfo(reservationList);
  }

  useEffect(() => {
    fetchData();
  }, [])

  const minWidth = 100;
  const columns = useMemo(
    () => [
      {
        accessor: "id",
        Header: "행사번호",
        minWidth: minWidth,
        style: {
          border: "1px solid rgb(0, 0, 0)",
          textAlign: "center",
          fontSize: 13,
        },
        index: 5,
      },
      {
        accessor: "startDate",
        Header: "시작일",
        minWidth: minWidth,
        style: {
          border: "1px solid rgb(0, 0, 0)",
          textAlign: "center",
          fontSize: 13,
        },
        index: 0,
      },
      {
        accessor: "endDate",
        Header: "종료일",
        minWidth: minWidth,
        style: {
          border: "1px solid rgb(0, 0, 0)",
          textAlign: "center",
          fontSize: 13,
        },
        index: 1,
      },
      {
        accessor: "guide",
        Header: "가이드",
        minWidth: minWidth + 60,
        style: {
          border: "1px solid rgb(0, 0, 0)",
          textAlign: "center",
          fontSize: 13,
        },
        index: 2,
      },
      {
        accessor: "pax",
        Header: "인원",
        minWidth: minWidth + 60,
        style: {
          border: "1px solid rgb(0, 0, 0)",
          textAlign: "center",
          fontSize: 13,
        },
        index: 3,
      },
      {
        accessor: "country",
        Header: "국가",
        minWidth: minWidth + 30,
        style: {
          border: "1px solid rgb(0, 0, 0)",
          textAlign: "center",
          fontSize: 13,
        },
        index: 4,
      },
      {
        accessor: "payment",
        Header: "페이먼트",
        minWidth: minWidth + 100,
        style: {
          border: "1px solid rgb(0, 0, 0)",
          textAlign: "center",
          fontSize: 13,
        },
        index: 5,
      },
    ]);

  const data = useMemo(() => reservationFilterInfo, [reservationFilterInfo]);
  const [searchText, setSearchText] = useState("");

  const a4Notice = useRef();

  // 인쇄 기능 Handler
  const onPrintHandler = () => {
    let printContents = ReactDOM.findDOMNode(a4Notice.current).innerHTML;
    let windowObject = window.open('', "PrintWindow", "width=1000, height=800, top=100, left=300, toolbars=no, scrollbars=no, status=no, resizale=no");

    windowObject.document.writeln(printContents);
    windowObject.document.close();
    windowObject.focus();
    windowObject.print();
    //windowObject.close();
  }

  return (
    <div className="flex w-full flex-col mt-2 md:mt-14" style={{ padding: 10, width: "100%" }}>

      <div className="w-full px-2">
        <ColorButton color={"slateblue"} title={"인쇄"} handler={() => {
          onPrintHandler();
        }} />
      </div>
      <div>
        <span
          title="국가정렬"
          className="mx-2 flex-none items-center rounded-md px-2 py-1 text-xs font-medium cursor-pointer text-[white]"
          style={{ background: !sortCountry ? "grey" : "crimson", }}
          onClick={() => {
            setReservationFilterInfo(reservationInfo.sort((a, b) => {
              if (!sortCountry) {
                if (a.country > b.country)
                  return 1;
                if (a.country < b.country)
                  return -1;
              }

              // 시작일 빠른순 정렬
              if (new Date(a.startDate.replace(" ", "T")) > new Date(b.startDate.replace(" ", "T")))
                return 1;
              if (new Date(a.startDate.replace(" ", "T")) < new Date(b.startDate.replace(" ", "T")))
                return -1;

              // 종료일 빠른순 정렬
              if (new Date(a.endDate.replace(" ", "T")) > new Date(b.endDate.replace(" ", "T")))
                return 1;
              if (new Date(a.endDate.replace(" ", "T")) < new Date(b.endDate.replace(" ", "T")))
                return -1;
            }));

            setSortCountry(!sortCountry);
          }}>
          국가정렬
        </span>
      </div>
      <div ref={a4Notice} style={{ padding: 10, width: "100%", display: "flex", flexDirection: "column", fontSize: 13, }}>
        <div style={{ marginTop: 5, borderWidth: "0px 0px 3px 0px", borderBottomStyle: "double", borderBottomColor: "black", }}>
          <div style={{ position: "absolute", right: 5, }}>
            <img src="https://www.ktravelstore.com/static/media/logo_kts.d464f76115f5ea7366ec.png" alt="not found" width={120} />
          </div>
          <div style={{ marginBottom: 20, textAlign: "center", fontWeight: "bold", fontSize: 20, }}>코리아 트래블 스토어(KTS)</div>
          <div style={{ padding: 10, display: "flex", flexDirection: "row" }}>
            <div style={{ marginRight: 10 }}>수 신</div>
            <div style={{ color: "deepskyblue", cursor: "pointer", textTransform: "none", }} onClick={() => {
              setInputType("manager");
              setInputHint("담당자");
              setInputValue(receiver);
              setShowInput(true);
            }}>{receiver}</div>
          </div>
          <div style={{ padding: 10, display: "flex", flexDirection: "row", marginBottom: 5 }}>
            <div style={{ marginRight: 10, }}>제 목</div>
            <div style={{ color: "deepskyblue", fontWeight: "bold", textTransform: "none", cursor: "pointer", }} onClick={() => {
              setInputType("title");
              setInputHint("제목");
              setInputValue(title);
              setShowInput(true);
            }}>{title}</div>
          </div>
        </div>
        <div style={{ display: "flex", flexDirection: "column", flex: "100%", justifyContent: "center", marginTop: 10 }}>
          {reservationFilterInfo?.length > 0 && <ReservationListTable columns={columns} data={data} />}
        </div>
        <div style={{ display: "flex", marginTop: 50, marginBottom: 20, borderWidth: "0px 0px 10px 0px", borderBottomStyle: "solid", borderBottomColor: "#DDD9C3", }} />
        <div style={{ display: "flex", flexDirection: "row", marginBottom: 10, }}>
          <div style={{ marginRight: 20, cursor: "pointer", textTransform: "none", }} onClick={() => {
            setInputType("team");
            setInputHint("부서명");
            setInputValue(team);
            setShowInput(true);
          }}>
            {team}
          </div>
          <div style={{ cursor: "pointer", textTransform: "none", }} onClick={() => {
            setInputType("sender");
            setInputHint("발신자");
            setInputValue(sender);
            setShowInput(true);
          }}>
            {sender}
          </div>
        </div>
        <div style={{ display: "flex", flexDirection: "row", marginBottom: 10, cursor: "pointer", textTransform: "none", }} onClick={() => {
          setInputType("phone");
          setInputHint("전화번호");
          setInputValue(phone);
          setShowInput(true);
        }}>
          {"전화 " + phone}
        </div>
        <div style={{ display: "flex", flexDirection: "row", marginBottom: 10, cursor: "pointer", textTransform: "none", }} onClick={() => {
          setInputType("email");
          setInputHint("이메일");
          setInputValue(email);
          setShowInput(true);
        }}>
          {"메일 " + email}
        </div>
      </div>
      {
        // 수정 입력창 다이얼로그
        showInput &&
        <div className="left-20 md:left-0 top-20" style={{ position: "fixed", display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: 500, }}>
          <div style={{ display: "flex", flexDirection: "column", width: "80%", background: "#000a", padding: 20, borderRadius: 10 }}>
            <div style={{ display: "flex", width: "100%", justifyContent: "flex-end" }}>
              <div
                style={{
                  width: 25,
                  height: 25,
                  fontSize: 15,
                  color: "white",
                  cursor: "pointer",
                  textAlign: "center"
                }}
                onClick={() => { setShowInput(false) }}
              >
                <FontAwesomeIcon icon={faXmark} />
              </div>
            </div>
            <div style={{ marginBottom: 10, display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', }}>
              <input
                className="placeholder:text-slate-400 block bg-white border border-slate-300 rounded-md py-2 pl-3 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1"
                placeholder={inputHint}
                type="text"
                name="input"
                style={{ width: "100%", marginBottom: 10, }}
                onChange={(e) => setInputValue(e.target.value)}
                value={inputValue}
                onKeyDown={(e) => handleKeyDown(e)}
              />
              <ColorButton title={"변경"} color={"black"} width={"100%"} handler={() => {
                changeValue();
              }} />
            </div>
          </div>
        </div>
      }
    </div >
  )
}