
// 버스, 호텔, 가이드, 면세점 상태
export const reservationStateList = [
  {
    id: 0,
    name: "[완]",
    color: "blue",
  },
  {
    id: 1,
    name: "[가]",
    color: "slateblue",
  },
  {
    id: 2,
    name: "[미]",
    color: "red",
  },
];

// 지원금 신청 상태
export const fundStateList = [
  {
    id: 0,
    name: "불필요",
    color: "crimson",
  },
  {
    id: 1,
    name: "사전등록필요",
    color: "coral",
  },
  {
    id: 2,
    name: "사전등록완료",
    color: "goldenrod",
  },
  {
    id: 3,
    name: "중간접수필요",
    color: "lawngreen",
  },
  {
    id: 4,
    name: "중간접수완료",
    color: "aqua",
  },
  {
    id: 5,
    name: "영수증발송",
    color: "#a0c4ff",
  },
  {
    id: 6,
    name: "청구완료",
    color: "slateblue",
  },
];

// 페이먼트 지불상태
export const paymentStateList = [
  {
    id: 0,
    name: "[완]",
    color: "blue",
  },
  {
    id: 1,
    name: "[미]",
    color: "red",
  },
];