import React, { useState, } from "react";
import * as Utils from '../../features/Utils';
import { LanguageSet, ShowInfo } from "../../recoil";
import { useRecoilValue } from "recoil";
import UploadDialog from "./UploadDialog";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudArrowUp } from "@fortawesome/free-solid-svg-icons";
import "../../components/styles/CommonStyle.css";
import * as HTTPITSManager from "../../features/HTTPITSManager";
import { UserInfo } from "../../recoil";

export default function RoomingInfoItem(Props) {
  const languageSet = useRecoilValue(LanguageSet);
  const userInfo = useRecoilValue(UserInfo);

  const [showUploadDialog, setShowUploadDialog] = useState(false);

  const tourInfo = Props.data;

  const docNum = tourInfo.docNum;
  const rooming = tourInfo.rooming;
  const finalDoc = tourInfo.finalDoc;
  const finalDocIN = tourInfo.finalDocIN;
  const invoice = tourInfo.invoice;
  const logo = tourInfo.logo;

  const linkOnBackground = "#4171D6";
  const linkOffBackground = "#B3CDFF";

  //const userInfo = useRecoilValue(UserInfo);
  const showInfo = useRecoilValue(ShowInfo);

  const [uploadType, setUploadType] = useState("");

  const reservationHotel = Props.toursHotelInfo.filter(item => item.tourId === tourInfo.id);
  const reservationGuide = Props.toursGuideInfo.filter(item => item.tourId === tourInfo.id);
  const userList = Props.userList;
  const hotelList = Props.hotelList;

  const fromITSInfoList = Props.fromITSInfoList?.filter(item => item.tourId === tourInfo.id).sort((a, b) => {
    // 생성시간 최근순 정렬
    if (new Date(a.createdAt.replace(" ", "T")) > new Date(b.createdAt.replace(" ", "T")))
      return -1;
    else
      return 1;
  });

  const etaFromITS = fromITSInfoList !== undefined && fromITSInfoList.length > 0 ? fromITSInfoList[0].eta : undefined;
  const etdFromITS = fromITSInfoList !== undefined && fromITSInfoList.length > 0 ? fromITSInfoList[0].etd : undefined;

  const [dataType, setDataType] = useState("");   // eta, etd
  const [dataValue, setDataValue] = useState("");
  const [showModifyDialog, setShowModifyDialog] = useState(false);

  async function handleKeyDown(event) {
    if (event.key === 'Enter') {
      await insertFromITSData();
    }
  }

  async function insertFromITSData() {
    let res = await HTTPITSManager.insertFromITS({
      accessToken: userInfo.accessToken,
      param: {
        tourId: tourInfo.id,
        dataType: dataType,
        dataValue: dataValue,
      }
    });

    if (res?.data?.result) {
      setShowModifyDialog(false);
      setDataType("");
      setDataValue("");
      Props.refresh();
    } else {
      alert("업데이트 실패!");
    }
  }

  return (
    <div className="flex flex-col w-full py-4" style={{
      background: Props.index % 2 === 0 ? "#cfcfcf" : "#efefef"
    }}>
      <div className="grid grid-cols-[3%_4%_4%_50%_15%_15%_9%] w-full border-[#E7EAED] py-2 text-center gap-1 items-center">
        <div className="">{Props.index + 1}</div>
        <div className="">{tourInfo.id}</div>
        <div className="">{tourInfo.pax}</div>
        <div className="h-full flex font-bold bg-red-50 rounded-lg p-2 items-center justify-center">{docNum}</div>
        <div className="h-full flex cursor-pointer normal-case font-bold items-center justify-center" onClick={() => {
          setShowModifyDialog(true);
          setDataType("eta");
        }}>
          {
            etaFromITS !== undefined && etaFromITS !== null ?
              <div className="h-full flex rounded-lg bg-green-50 items-center justify-center p-2"> {etaFromITS} </div>
              : <div className="mx-6 md:mx-0 px-3 py-2 rounded-lg text-white font-bold bg-[crimson] hover:bg-red-400" style={{
              }}>INPUT</div>
          }
        </div>
        <div className="h-full flex cursor-pointer normal-case font-bold items-center justify-center" onClick={() => {
          setShowModifyDialog(true);
          setDataType("etd");
        }}>
          {
            etdFromITS !== undefined && etdFromITS !== null ?
              <div className="h-full flex px-2 rounded-lg bg-blue-50 items-center justify-center p-2"> {etdFromITS}</div>
              : <div className="mx-6 md:mx-0 px-3 py-2 rounded-lg text-white font-bold  bg-[crimson] hover:bg-red-400" style={{
              }}>INPUT</div>
          }
        </div>
        {
          reservationGuide.length > 0 ?
            <div className="relative text-[#4171D6] uppercase font-bold">
              {
                reservationGuide.map(item => {
                  return userList.find(user => user.id === item.guideId)?.nameEN;
                }).toString()
              }
            </div> :
            <div className="font-bold text-[crimson]">TBA</div>
        }

        <div className="flex flex-col justify-between mb-1">
          {/* <div className="relative flex flex-col mb-2">
          {
            reservationHotel.length > 0 &&
            <div>
              {
                reservationHotel.map(item => {
                  return (
                    <div key={item.id}>
                      {hotelList.find(hotel => hotel.id === item.hotelId)?.nameEN} {item.startDate.slice(2, 10)}~{item.endDate.slice(2, 10)}
                    </div>
                  )
                })
              }
            </div>
          }
          {
            showInfo && <div className="absolute top-[0px] left-0 text-[white] font-bold z-[101]">Reservation Hotel</div>
          }
        </div> */}
        </div>
        {
          showUploadDialog &&
          <UploadDialog
            title={docNum}
            setShow={setShowUploadDialog}
            data={Props.data}
            refresh={Props.refresh}
            type={uploadType}
          />
        }
        {
          showModifyDialog && <>
            <div className="scrollRemoveBox mx-6 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-[150] outline-none focus:outline-none font-medium">
              <div className="relative w-full mx-auto max-w-lg">
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col bg-[#efefef] outline-none focus:outline-none px-5 pt-5">
                  <div className="mb-1">{tourInfo.id}</div>
                  <div className="mb-5">{docNum}</div>
                  <input
                    className="rounded placeholder:text-slate-400 block bg-white border border-slate-300 py-4 pl-10 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 mb-2"
                    type="text"
                    placeholder={dataType === "eta" ? "ARRIVAL" : "DEPATURE"}
                    onChange={(e) => {
                      setDataValue(e.target.value);
                    }}
                    onKeyDown={(e) => {
                      handleKeyDown(e);
                    }}
                  />
                  <div className="flex flex-row">
                    <span className={"flex w-full cursor-pointer px-2 py-3 justify-center"}>
                      <div className={"select-none text-[deepskyblue] font-bold"}
                        onClick={async () => {
                          insertFromITSData();
                        }}>MODIFY</div>
                    </span>
                    <span className={"flex w-full  cursor-pointer px-2 py-3 justify-center"}>
                      <div className={"select-none text-[red] font-bold"} onClick={() => {
                        setShowModifyDialog(false);
                        setDataType("");
                        setDataValue("");
                      }}>CANCEL</div>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-[99] bg-black"></div>
          </>
        }
      </div>
      <div className="flex flex-row gap-2 pr-3 md:p-0 scrollRemoveBox overflow-auto justify-end md:justify-start md:px-2">
        <div className="md:flex-none flex flex-row justify-end ">
          <div className="flex text-[white] items-center justify-center p-1 px-2 rounded cursor-pointer text-[14px] select-none font-bold mr-2"
            style={{ background: (rooming !== undefined && rooming !== null) ? linkOnBackground : linkOffBackground }}
            onClick={() => {
              if (rooming !== undefined && rooming !== null)
                Utils.showContent(rooming);
            }}>
            {languageSet.roomingItemButton}
          </div>
          <span
            title="Upload"
            className={"cursor-pointer px-2 py-1 rounded"}
            style={{ background: "#34414d" }}
            onClick={() => {
              setShowUploadDialog(true);
              setUploadType("rooming");
            }}>
            <FontAwesomeIcon icon={faCloudArrowUp} style={{ color: "white" }} />
          </span>
        </div>

        <div className="md:flex-none flex flex-row justify-end">
          <div className="flex text-[white] items-center justify-center p-1 px-2 rounded cursor-pointer text-[14px] select-none font-bold mr-2"
            style={{ background: (logo !== undefined && logo !== null) ? linkOnBackground : linkOffBackground }}
            onClick={() => {
              if (logo !== undefined && logo !== null)
                Utils.showContent(logo);
            }}>
            {languageSet.roomingItemLogoButton}
          </div>
          <span
            title="Upload"
            className={"cursor-pointer px-2 py-1 rounded"}
            style={{ background: "#34414d" }}
            onClick={() => {
              setShowUploadDialog(true);
              setUploadType("logo");
            }}>
            <FontAwesomeIcon icon={faCloudArrowUp} style={{ color: "white" }} />
          </span>
        </div>

        <div className="md:flex-none flex flex-row justify-end">
          <div className="flex text-[white] items-center justify-center p-1 px-2 rounded cursor-pointer text-[14px] select-none font-bold mr-2"
            style={{ background: (finalDocIN !== undefined && finalDocIN !== null) ? linkOnBackground : linkOffBackground }}
            onClick={() => {
              if (finalDocIN !== undefined && finalDocIN !== null)
                Utils.showContent(finalDocIN);
            }}>
            {languageSet.roomingItemfinalDocumentITSButton}
          </div>
          <span
            title="Upload"
            className={"cursor-pointer px-2 py-1 rounded"}
            style={{ background: "#34414d" }}
            onClick={() => {
              setShowUploadDialog(true);
              setUploadType("finalDocIN");
            }}>
            <FontAwesomeIcon icon={faCloudArrowUp} style={{ color: "white" }} />
          </span>
        </div>

        <div className="md:flex-none flex flex-row justify-end">
          <div className="flex text-[white] items-center justify-center p-1 px-2 rounded cursor-pointer text-[14px] select-none font-bold"
            style={{ background: (finalDoc !== undefined && finalDoc !== null) ? linkOnBackground : linkOffBackground }}
            onClick={() => {
              if (finalDoc !== undefined && finalDoc !== null)
                Utils.showContent(finalDoc);
            }}>
            {languageSet.roomingItemfinalDocumentButton}
          </div>
        </div>

        <div className="md:flex-none flex flex-row justify-end">
          <div className="flex text-[white] items-center justify-center p-1 px-2 rounded cursor-pointer text-[14px] select-none font-bold"
            style={{ background: (invoice !== undefined && invoice !== null) ? linkOnBackground : linkOffBackground }}
            onClick={() => {
              if (invoice !== undefined && invoice !== null)
                Utils.showContent(invoice);
            }}>
            {languageSet.roomingItemInvoiceButton}
          </div>
        </div>
      </div>
    </div >
  )
}