import axios from "axios";
import { ERRORCODE } from "../features/Constant";
import { useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";
import { UserInfo } from "../recoil";
import { useNavigate } from "react-router-dom";

const PRODUCTION = process.env.NODE_ENV === "development" ? false : true;

const SERVER_URL = PRODUCTION
  ? "https://ktravelstore.com:1225"
  : "http://localhost:1225";

const instance = axios.create({
  baseURL: SERVER_URL,
  withCredentials: true,
});

export const useAxiosInterceptor = () => {
  const setMyInfo = useSetRecoilState(UserInfo);
  const navigate = useNavigate();
  const [needToLogin, setNeedToLogin] = useState(false);

  const errorHandler = (error) => {
    //let msg = error.data.message;
    let code = error.data.code;

    switch (code) {
      // refresh 토큰까지 만료된 경우 > 재로그인 요청
      case ERRORCODE.NEED_TO_LOGIN.CODE:
        setNeedToLogin(true);
        setMyInfo({});
        break;
      default:
        break;
    }

    return error;
  };

  const responseHandler = (response) => {
    return response;
  };

  const responseInterceptor = instance.interceptors.response.use(
    (response) => responseHandler(response),
    (error) => errorHandler(error.response)
  );

  useEffect(() => {
    // 로그인 만료 처리
    if (needToLogin) {
      setNeedToLogin(false);
      navigate("/", { replace: true });
    }

    return () => {
      instance.interceptors.response.eject(responseInterceptor);
    };
  }, [responseInterceptor, needToLogin]);
};

export async function SignUp(Props) {
  return await instance
    .post("/api/auth/signup", {
      account: Props.account,
      password: Props.password,
      passwordRe: Props.passwordRe,
      name: Props.name,
      birthDate: Props.birthDate,
      phoneNumber: Props.phoneNumber,
      email: Props.email,
      passportNumber: Props.passportNumber,
    })
    .then(function (response) {
      return response.data.result;
    })
    .catch(function (error) {
      console.log(error);
      return error;
    });
}

export async function SignIn(Props) {
  return await instance
    .post(
      "/api/auth/signin",
      {
        account: Props.account,
        password: Props.password,
        autoLogin: Props.autoLogin,
      },
      { withCredentials: true }
    )
    .then(function (response) {
      // login response, accessToken, email, id, point, etc..
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${response.data.accessToken}`;
      if (Props.autoLogin) {
        localStorage.setItem("kts_autoLogin", response.data.refreshToken);
      }
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
    });
}

export async function Logout(Props) {
  return await instance
    .delete("/api/auth/logout")
    .then((res) => {
      localStorage.removeItem("kts_autoLogin");
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
}

//국가 목록 가져오기
export async function GetCountryList(Props) {
  return await instance
    .get("/admin/country/list")
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
}

//입출국 목록 가져오기
export async function GetAirplaneScheduleList(Props) {
  return await instance
    .get("/tours/airplane", {
      headers: {
        Authorization: `Bearer ${Props.accessToken}`,
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err);
    });
}

export async function InsertNewCountry(Props) {
  return await instance
    .post(
      "/admin/country",
      { nameCountry: Props.newCountry },
      {
        headers: {
          Authorization: `Bearer ${Props.accessToken}`,
        },
      }
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
      alert(err.response.data.message);
    });
}

export async function GetTourList(Props) {
  return await instance
    .get("/tours/list", {
      headers: {
        Authorization: `Bearer ${Props.accessToken}`,
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
}

export async function GetTourByID(Props) {
  return await instance
    .get("/tours/" + Props.tourId, {
      headers: {
        Authorization: `Bearer ${Props.accessToken}`,
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
}

export async function InsertTour(Props) {
  return await instance
    .post(
      "/tours/",
      {
        startDate: Props.startDate,
        endDate: Props.endDate,
        countryId: Props.countryId,
        pax: Props.pax,
        countGuide: Props.countGuide,
        countHotel: Props.countHotel,
        countBus: Props.countBus,
        eta: Props.eta,
        etd: Props.etd,
        docNum: Props.docNum,
        memo: Props.memo,
        guideList: Props.guideList,
        hotelList: Props.hotelList,
      },
      {
        headers: {
          Authorization: `Bearer ${Props.accessToken}`,
        },
      }
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
}

export async function UpdateTourState(Props) {
  return await instance
    .put(
      "/tours/state/" + Props.tourId,
      {
        cancel: Props.cancel,
        stateType: Props.stateType,
        stateValue: Props.stateValue
      },
      {
        headers: {
          Authorization: `Bearer ${Props.accessToken}`,
          "Content-Type": "multipart/form-data",
        },
      }
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
}

export async function InsertAdditionTourInfo(Props) {
  return await instance
    .post(
      "/tours/" + Props.tourId,
      {
        info: Props.info,
        requestList: Props.requestList,
      },
      {
        headers: {
          Authorization: `Bearer ${Props.accessToken}`,
        },
      }
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
}

export async function GetMyToursList(Props) {
  return await instance
    .get(`/tours/mylist/${Props.year}/${Props.month}`, {
      headers: {
        Authorization: `Bearer ${Props.accessToken}`,
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
}

// 투어정보 텍스트수정
export async function ModifyTourInfo(Props) {
  return await instance
    .put("/tours/" + Props.tourId, Props.tourInfo, {
      headers: {
        Authorization: `Bearer ${Props.accessToken}`,
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
}

// 투어정보 파일수정
export async function ModifyFileTourInfo(Props) {
  const formData = new FormData();

  // 파일 리스트 업로드
  for (let i = 0; i < Props.fileList?.length; i++) {
    let item = Props.fileList[i];
    formData.append(item.type, item.file);
  }

  return await instance
    .put("/tours/file/" + Props.tourId, formData, {
      headers: {
        Authorization: `Bearer ${Props.accessToken}`,
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
}

// 투어 정보 복사
export async function CopyTour(Props) {
  return await instance
    .post(
      "/tours/" + Props.tourId,
      {},
      {
        headers: { Authorization: `Bearer ${Props.accessToken}` },
      }
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
}

// 투어 정보 삭제
export async function DeleteTour(Props) {
  return await instance
    .delete("/tours/" + Props.tourId, {
      headers: { Authorization: `Bearer ${Props.accessToken}` },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
}

// 투어 예약 정보 확인
export async function GetTourReservation(Props) {
  return await instance
    .get("/tours/reservation", {
      headers: {
        Authorization: `Bearer ${Props.accessToken}`,
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
}

// 호텔 등록
export async function RegistHotelInfo(Props) {
  return await instance
    .post(
      "/hotel",
      {
        info: Props.info,
        price: Props.price,
      },
      {
        headers: {
          Authorization: `Bearer ${Props.accessToken}`,
        },
      }
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
}

// 호텔 정보 변경
export async function UpdateHotelInfo(Props) {
  return await instance
    .put(
      "/hotel",
      {
        hotelId: Props.hotelId,
        hotelPriceId: Props.hotelPriceId,
        type: Props.type,
        value: Props.value,
      },
      {
        headers: {
          Authorization: `Bearer ${Props.accessToken}`,
        },
      }
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
}

// 호텔 가격 파일 추가
export async function UploadFileHotelPrice(Props) {
  const formData = new FormData();

  // 파일 리스트 업로드
  for (let i = 0; i < Props.fileList?.length; i++) {
    let item = Props.fileList[i];
    formData.append("file", item.file, item.type);
  }

  return await instance
    .put("/hotel/" + Props.id, formData, {
      headers: {
        Authorization: `Bearer ${Props.accessToken}`,
        "Content-Type": "multipart/form-data",
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
}

// rooming 파일 삭제
export async function DeleteFileHotelPrice(Props) {
  return await instance
    .delete(
      "/hotel/file/" + Props.id,
      {
        headers: {
          Authorization: `Bearer ${Props.accessToken}`,
        },
        data: {
          type: Props.type,
        }
      }
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
}

// 호텔 리스트
export async function GetHotelList(Props) {
  return await instance
    .get("/hotel", {
      headers: {
        Authorization: `Bearer ${Props.accessToken}`,
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
}

// 호텔 예약 등록
export async function RegistToursHotel(Props) {
  return await instance
    .post(
      "/hotel/tour",
      {
        tourId: Props.tourId,
        hotelState: Props.hotelState,
      },
      {
        headers: {
          Authorization: `Bearer ${Props.accessToken}`,
        },
      }
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
}

// 호텔 예약 변경
export async function UpdateToursHotel(Props) {
  return await instance
    .put(
      "/hotel/tour",
      {
        tourId: Props.tourId,
        hotelState: Props.hotelState,
      },
      {
        headers: {
          Authorization: `Bearer ${Props.accessToken}`,
        },
      }
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
}

// 호텔 결제 금액 등록
export async function UpdateToursHotelPay(Props) {
  return await instance
    .put(
      "/hotel/tour",
      {
        id: Props.id,
        pay: Props.pay,
      },
      {
        headers: {
          Authorization: `Bearer ${Props.accessToken}`,
        },
      }
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
}

// 버스 등록
export async function RegistBusInfo(Props) {
  return await instance
    .post(
      "/bus",
      {
        info: Props.info,
        //price: Props.price,
      },
      {
        headers: {
          Authorization: `Bearer ${Props.accessToken}`,
        },
      }
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
}

// 버스 리스트
export async function GetBusList(Props) {
  return await instance
    .get("/bus", {
      headers: {
        Authorization: `Bearer ${Props.accessToken}`,
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
}

// 버스 예약 등록
export async function RegistToursBus(Props) {
  return await instance
    .post(
      "/bus/tour",
      {
        tourId: Props.tourId,
        busState: Props.busState,
      },
      {
        headers: {
          Authorization: `Bearer ${Props.accessToken}`,
        },
      }
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
}

// 버스 예약 변경
export async function UpdateToursBus(Props) {
  return await instance
    .put(
      "/bus/tour",
      {
        tourId: Props.tourId,
        busState: Props.busState,
      },
      {
        headers: {
          Authorization: `Bearer ${Props.accessToken}`,
        },
      }
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
}

// 버스 결제 금액 등록
export async function UpdateToursBusPay(Props) {
  return await instance
    .put(
      "/bus/tour",
      {
        id: Props.id,
        pay: Props.pay,
      },
      {
        headers: {
          Authorization: `Bearer ${Props.accessToken}`,
        },
      }
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
}

// 관광지 등록
export async function RegistAttractionInfo(Props) {
  return await instance
    .post(
      "/attraction",
      {
        info: Props.info,
      },
      {
        headers: {
          Authorization: `Bearer ${Props.accessToken}`,
        },
      }
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
}

// 관광지 리스트
export async function GetAttractionList(Props) {
  return await instance
    .get("/attraction", {
      headers: {
        Authorization: `Bearer ${Props.accessToken}`,
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
}

// 관광지 예약 등록
export async function RegistToursAttraction(Props) {
  return await instance
    .post(
      "/attraction/tour",
      {
        tourId: Props.tourId,
        attractionId: Props.attractionId,
      },
      {
        headers: {
          Authorization: `Bearer ${Props.accessToken}`,
        },
      }
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
}

// 가이드 등록
export async function RegistGuide(Props) {
  return await instance
    .post(
      "/user",
      {
        name: Props.name,
      },
      {
        headers: {
          Authorization: `Bearer ${Props.accessToken}`,
        },
      }
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
}

// 관리자 등록
export async function RegistManager(Props) {
  return await instance
    .post(
      "/user",
      {
        account: Props.account,
      },
      {
        headers: {
          Authorization: `Bearer ${Props.accessToken}`,
        },
      }
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
}

// 가이드 삭제
export async function DeleteGuide(Props) {
  return await instance
    .delete("/user", {
      headers: {
        Authorization: `Bearer ${Props.accessToken}`,
      },
      data: {
        guideId: Props.guideId,
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
}

// 가이드 예약
export async function RegistToursGuide(Props) {
  return await instance
    .post(
      "/user/tour",
      {
        tourId: Props.tourId,
        guideState: Props.guideState,
        guideId: Props.guideId,
      },
      {
        headers: {
          Authorization: `Bearer ${Props.accessToken}`,
        },
      }
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
}

// 가이드 예약정보 변경
export async function ModifyToursGuide(Props) {
  return await instance
    .put(
      "/user/tour",
      {
        toursGuideInfo: Props.toursGuideInfo,
        id: Props.id,
        tipDate: Props.tipDate,
        updateList: Props.updateList,
      },
      {
        headers: {
          Authorization: `Bearer ${Props.accessToken}`,
        },
      }
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
}

// 유저 정보
export async function GetUserList(Props) {
  return await instance
    .get("/user", {
      headers: {
        Authorization: `Bearer ${Props.accessToken}`,
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
}

// 가이드 예약 변경
export async function ChangeToursGuide(Props) {
  return await instance
    .put(
      "/user/tour/",
      {
        tourId: Props.tourId,
        currentGuideId: Props.currentGuideId,
        nextGuideId: Props.nextGuideId,
      },
      {
        headers: {
          Authorization: `Bearer ${Props.accessToken}`,
        },
      }
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
}

// 가이드 예약 상태 변경
export async function ChangeStateToursGuide(Props) {
  return await instance
    .post(
      "/user/tour/state",
      {
        tourId: Props.tourId,
        guideId: Props.guideId,
        guideState: Props.guideState,
      },
      {
        headers: {
          Authorization: `Bearer ${Props.accessToken}`,
        },
      }
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
}

// 가이드 예약 삭제
export async function DeleteToursGuide(Props) {
  return await instance
    .delete("/user/tour/", {
      headers: {
        Authorization: `Bearer ${Props.accessToken}`,
      },
      data: {
        tourId: Props.tourId,
        guideId: Props.guideId,
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
}

// 호텔 예약 상태 변경
export async function ChangeStateToursHotel(Props) {
  return await instance
    .post(
      "/hotel/tour/state",
      {
        tourId: Props.tourId,
        hotelId: Props.hotelId,
        type: Props.type,
        state: Props.state,
      },
      {
        headers: {
          Authorization: `Bearer ${Props.accessToken}`,
        },
      }
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
}

// 버스 예약 상태 변경
export async function ChangeStateToursBus(Props) {
  return await instance
    .post(
      "/bus/tour/state",
      {
        tourId: Props.tourId,
        busId: Props.busId,
        type: Props.type,
        state: Props.state,
      },
      {
        headers: {
          Authorization: `Bearer ${Props.accessToken}`,
        },
      }
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
}

// 정산 정보 변경
export async function ChangeShopIncome(Props) {
  return await instance
    .post(
      "/tours/income",
      {
        tourId: Props.tourId,
        type: Props.type,
        value: Props.value,
      },
      {
        headers: {
          Authorization: `Bearer ${Props.accessToken}`,
        },
      }
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
}

// 비밀번호 변경
export async function ChangePw(Props) {
  return await instance
    .post(
      "/api/auth/change",
      {
        password: Props.password,
        passwordRe: Props.passwordRe,
      },
      {
        headers: {
          Authorization: `Bearer ${Props.accessToken}`,
        },
      }
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
}

export const DownloadXLSXFile = async (url) => {
  return await instance
    .get(url, {
      headers: {
        "Content-Type": "blob",
      },
      responseType: "arraybuffer",
    })
    .then((res) => {
      console.log(res);
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
};

// 가이드 정보 변경
export async function ChangeGuideInfo(Props) {
  return await instance
    .put(
      "/user/",
      {
        guideId: Props.guideId,
        type: Props.type,
        value: Props.value,
      },
      {
        headers: {
          Authorization: `Bearer ${Props.accessToken}`,
        },
      }
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
}

// 가이드 정보 변경
export async function ChangeGuideInfoForGuide(Props) {
  return await instance
    .put(
      "/user/guide",
      {
        guideId: Props.guideId,
        type: Props.type,
        value: Props.value,
      },
      {
        headers: {
          Authorization: `Bearer ${Props.accessToken}`,
        },
      }
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
}

// 행사별정산에서 수입지출 목록
export async function GetLogStatementByTour(Props) {
  return await instance
    .get("/tours/logstatement/" + Props.tourId, {
      headers: {
        Authorization: `Bearer ${Props.accessToken}`,
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
}

// 매입 조회
export async function GetPurchaseList(Props) {
  return await instance
    .get("/settlement/purchase/" + Props.tourId,
      {
        headers: {
          Authorization: `Bearer ${Props.accessToken}`,
        },
      })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
}

// 매입 입력
export async function AddPurchaseList(Props) {
  return await instance
    .post("/settlement/purchase/" + Props.tourId,
      {
        purchaseList: Props.purchaseList,
      },
      {
        headers: {
          Authorization: `Bearer ${Props.accessToken}`,
        },
      })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
}

// 매입 삭제
export async function DeletePurchaseList(Props) {
  return await instance
    .delete("/settlement/purchase/" + Props.tourId,
      {
        headers: {
          Authorization: `Bearer ${Props.accessToken}`,
        },
        data: {
          tourId: Props.tourId,
          id: Props.id,
        },
      })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
}

// 매출 조회
export async function GetSalesList(Props) {
  return await instance
    .get("/settlement/sales/" + Props.tourId,
      {
        headers: {
          Authorization: `Bearer ${Props.accessToken}`,
        },
      })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
}

// 매출 입력
export async function AddSalesList(Props) {
  return await instance
    .post("/settlement/sales/" + Props.tourId,
      {
        salesList: Props.salesList,
      },
      {
        headers: {
          Authorization: `Bearer ${Props.accessToken}`,
        },
      })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
}

// 매출 삭제
export async function DeleteSalesList(Props) {
  return await instance
    .delete("/settlement/sales/" + Props.tourId,
      {
        headers: {
          Authorization: `Bearer ${Props.accessToken}`,
        },
        data: {
          id: Props.id,
        },
      })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
}

// 매출 수정
export async function UpdateSales(Props) {
  return await instance
    .put("/settlement/sales/" + Props.id,
      {
        type: Props.type,
        value: Props.value,
      },
      {
        headers: {
          Authorization: `Bearer ${Props.accessToken}`,
        },
      })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
}

// 지출 타입 정보 조회
export async function GetPuchaseTypeList(Props) {
  return await instance
    .get("/settlement/purchase/type",
      {
        headers: {
          Authorization: `Bearer ${Props.accessToken}`,
        },
      })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
}

// 매출 회사 정보 조회
export async function GetSalesCompanyList(Props) {
  return await instance
    .get("/settlement/sales/company",
      {
        headers: {
          Authorization: `Bearer ${Props.accessToken}`,
        },
      })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
}

// todoList 조회
export async function GetTodoList(Props) {
  return await instance
    .get(
      "/user/todo",
      {
        headers: {
          Authorization: `Bearer ${Props.accessToken}`,
        },
      }
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
}

// todoList 등록
export async function InsertTodoList(Props) {
  return await instance
    .post(
      "/user/todo",
      {
        receiver: Props.receiver,
        content: Props.content,
        maker: Props.maker,
      },
      {
        headers: {
          Authorization: `Bearer ${Props.accessToken}`,
        },
      }
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
}

// todoListComment 등록
export async function InsertTodoListComment(Props) {
  return await instance
    .post(
      "/user/todoComment",
      {
        id: Props.id,
        content: Props.content,
      },
      {
        headers: {
          Authorization: `Bearer ${Props.accessToken}`,
        },
      }
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
}

// todoListComment 삭제
export async function RemoveTodoListComment(Props) {
  return await instance
    .delete(
      "/user/todoComment",
      {
        headers: {
          Authorization: `Bearer ${Props.accessToken}`,
        },
        data: {
          id: Props.id,
        }
      }
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
}

// todoList 수정
export async function ModifyTodoList(Props) {
  return await instance
    .put(
      "/user/todo",
      {
        id: Props.id,
        complete: Props.complete,
      },
      {
        headers: {
          Authorization: `Bearer ${Props.accessToken}`,
        },
      }
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
}

// todolist 파일 추가
export async function UploadFileTodoList(Props) {
  const formData = new FormData();

  // 파일 리스트 업로드
  for (let i = 0; i < Props.fileList?.length; i++) {
    let item = Props.fileList[i];
    formData.append("file", item.file, item.type);
  }

  return await instance
    .put("/user/todo/" + Props.id, formData, {
      headers: {
        Authorization: `Bearer ${Props.accessToken}`,
        "Content-Type": "multipart/form-data",
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
}

// todoList 삭제
export async function RemoveTodoList(Props) {
  return await instance
    .delete(
      "/user/todo",
      {
        headers: {
          Authorization: `Bearer ${Props.accessToken}`,
        },
        data: {
          id: Props.id,
        }
      }
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
}

export async function RemoveTourFile(Props) {
  return await instance
    .delete("/tours/file/" + Props.id,
      {
        headers: {
          Authorization: `Bearer ${Props.accessToken}`,
        },
        data: {
          type: Props.type,
        }
      }
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
}

// 원본파일명 조회
export async function GetOriginalFileName(Props) {
  return await instance
    .get("/file/" + Props.tourId + "&" + Props.type,
      {
        headers: {
          Authorization: `Bearer ${Props.accessToken}`,
        },
      })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
}

// 투어 다수 생성
export async function CreateMultipleTour(Props) {
  return await instance
    .post(
      "/tours/multiple",
      {
        list: Props.list,
      },
      {
        headers: {
          Authorization: `Bearer ${Props.accessToken}`,
        },
      }
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
}

// 공지사항 목록
export async function GetNoticeList(Props) {

  return await instance
    .get("/notice", {
      headers: {
        Authorization: `Bearer ${Props.accessToken}`,
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
}

// invoice type 목록
export async function GetInvoiceType(Props) {
  return await instance
    .get("/invoice/type", {
      headers: {
        Authorization: `Bearer ${Props.accessToken}`,
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
}

// invoice type 추가
export async function AddInvoiceType(Props) {
  return await instance
    .post(
      "/invoice/type",
      {
        typeName: Props.typeName,
      },
      {
        headers: {
          Authorization: `Bearer ${Props.accessToken}`,
        },
      }
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
}

// invoice 목록
export async function GetInvoice(Props) {
  return await instance
    .get("/invoice", {
      headers: {
        Authorization: `Bearer ${Props.accessToken}`,
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
}

// invoice price 추가
export async function AddInvoice(Props) {
  return await instance
    .post(
      "/invoice",
      {
        invoiceData: Props.list
      },
      {
        headers: {
          Authorization: `Bearer ${Props.accessToken}`,
        },
      }
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
}

// invoice price 추가
export async function ModifyInvoicePrice(Props) {
  return await instance
    .put(
      "/invoice",
      {
        toursInvoicePriceId: Props.toursInvoicePriceId,
        requestType: Props.requestType,
        requestValue: Props.requestValue,
      },
      {
        headers: {
          Authorization: `Bearer ${Props.accessToken}`,
        },
      }
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
}

// invoice detail 추가
export async function AddInvoiceDetail(Props) {
  return await instance
    .post(
      "/invoice/detail",
      {
        invoiceData: Props.data
      },
      {
        headers: {
          Authorization: `Bearer ${Props.accessToken}`,
        },
      }
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
}


// invoice detail 수정
export async function UpdateInvoiceDetail(Props) {
  return await instance
    .put(
      "/invoice/detail",
      {
        invoiceData: Props.data
      },
      {
        headers: {
          Authorization: `Bearer ${Props.accessToken}`,
        },
      }
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
}

// invoice 정보 조회
export async function GetInvoiceInfo(Props) {
  return await instance
    .get("/invoice/info", {
      headers: {
        Authorization: `Bearer ${Props.accessToken}`,
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
}

// 입국인원 입력
export async function ModifyTourEnterPaxInfo(Props) {
  return await instance
    .put("/tours/enterPax/" + Props.tourId, Props.param, {
      headers: {
        Authorization: `Bearer ${Props.accessToken}`,
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
}

// 프리데이 입력
export async function ModifyTourFreeDayInfo(Props) {
  return await instance
    .put("/tours/freeDay/" + Props.tourId, Props.param, {
      headers: {
        Authorization: `Bearer ${Props.accessToken}`,
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
}

// 대형여부 수정
export async function ModifyTourIsBigInfo(Props) {
  return await instance
    .put("/tours/isBig/" + Props.tourId, Props.param, {
      headers: {
        Authorization: `Bearer ${Props.accessToken}`,
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
}


// 코스 수정
export async function ModifyTourItineraryInfo(Props) {
  return await instance
    .put("/tours/itinerary/" + Props.tourId, Props.param, {
      headers: {
        Authorization: `Bearer ${Props.accessToken}`,
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
}

// 가이드용 메모 조회(가이드)
export async function GetGuideMemoForGuide(Props) {
  return await instance
    .get("/tours/guide/memo/" + Props.guideId, {
      headers: {
        Authorization: `Bearer ${Props.accessToken}`,
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
}

// 가이드용 메모 조회(관리자)
export async function GetGuideMemoForAdmin(Props) {
  return await instance
    .get("/tours/guide/memo", {
      headers: {
        Authorization: `Bearer ${Props.accessToken}`,
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
}

// 가이드용 메모 입력
export async function InsertGuideMemo(Props) {
  return await instance
    .post("/tours/guide/memo", Props.param, {
      headers: {
        Authorization: `Bearer ${Props.accessToken}`,
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
}

// 가이드용 메모 삭제
export async function RemoveGuideMemo(Props) {
  return await instance
    .delete("/tours/guide/memo/" + Props.guideMemoId, {
      headers: {
        Authorization: `Bearer ${Props.accessToken}`,
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
}

// 월별 메모 조회
export async function GetMemoMonth(Props) {
  return await instance
    .get("/tours/month/memo", {
      headers: {
        Authorization: `Bearer ${Props.accessToken}`,
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
}

// 월별 메모 입력
export async function InsertMemoMonth(Props) {
  return await instance
    .post("/tours/month/memo", Props.param, {
      headers: {
        Authorization: `Bearer ${Props.accessToken}`,
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
}

// 월별 메모 입력
export async function UpdateMemoMonth(Props) {
  return await instance
    .put("/tours/month/memo", Props.param, {
      headers: {
        Authorization: `Bearer ${Props.accessToken}`,
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
}

// 버스 메모 조회
export async function GetMemoBus(Props) {
  return await instance
    .get("/tours/bus/memo", {
      headers: {
        Authorization: `Bearer ${Props.accessToken}`,
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
}

// 버스 메모 입력
export async function InsertMemoBus(Props) {
  return await instance
    .post("/tours/bus/memo", Props.param, {
      headers: {
        Authorization: `Bearer ${Props.accessToken}`,
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
}

// 버스 메모 입력
export async function UpdateMemoBus(Props) {
  return await instance
    .put("/tours/bus/memo", Props.param, {
      headers: {
        Authorization: `Bearer ${Props.accessToken}`,
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
}