import React, { useState, useEffect, useMemo, useRef } from "react";
import ReactDOM from "react-dom";
import { useRecoilValue } from "recoil";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import * as HTTPManager from '../../features/HTTPManager';
import { UserInfo } from "../../recoil";
import ColorButton from "../ColorButton"
import { useLocation, useNavigate } from "react-router-dom";
import ReservationBusListTable from "./ReservationBusListTable";

export default function ReservationBusList(Props) {
  const location = useLocation();
  const navigate = useNavigate();
  const userInfo = useRecoilValue(UserInfo);
  const [buslist, setBusList] = useState([]);

  const [reservationInfo, setReservationInfo] = useState([]);
  const [reservationFilterInfo, setReservationFilterInfo] = useState([]);

  const [receiver, setReceiver] = useState("담당자 입력");
  const [title, setTitle] = useState("버스 예약 요청서");
  const [sender, setSender] = useState("대표 김종주");
  const [team, setTeam] = useState("국내사업부");
  const [phone, setPhone] = useState("+82-10-2326-2825");
  const [email, setEmail] = useState("james@ktravelstore.com");
  const [address, setAddress] = useState("서울시 마포구 연희로 11, 5층 코리아 트래블 스토어");

  const [showInput, setShowInput] = useState(false);
  const [inputHint, setInputHint] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [inputType, setInputType] = useState("");

  const courseData = ["서울", "남이섬", "설악", "부산", "제주", "전주", "경주"];

  async function handleKeyDown(event) {
    if (event.key === 'Enter') {
      changeValue();
    } else if (event.keyCode === 27) {
      setShowInput(false);
    }
  }


  function changeValue() {
    if (inputValue.length === 0) {
      alert("빈칸확인");
      return;
    }

    switch (inputType) {
      case "manager":
        setReceiver(inputValue);
        break;
      case "title":
        setTitle(inputValue);
        break;
      case "team":
        setTeam(inputValue);
        break;
      case "sender":
        setSender(inputValue);
        break;
      case "phone":
        setPhone(inputValue);
        break;
      case "email":
        setEmail(inputValue);
        break;
      default:
        break;
    }

    setShowInput(false);
  }

  function getCourse(itinerary) {
    let result = [];

    courseData.map((item, idx) => {
      let powValue = Math.pow(2, idx);
      if ((itinerary & powValue) > 0)
        result.push(item);
    });

    return result.toString();
  }

  function getFreeDay(startDate, endDate, freeDay) {
    let result = [];

    let start = new Date(startDate.replace(" ", "T"));
    let end = new Date(endDate.replace(" ", "T"));
    let dayCount = (end - start) / 86400000 + 1;

    let shiftValue = 1;
    for (let i = 0; i < dayCount; i++) {
      let start = new Date(startDate.replace(" ", "T"));

      if ((freeDay & shiftValue) > 0) {
        start.setDate(start.getDate() + i + 1)
        result.push(start.toISOString().slice(8, 10));
      }

      shiftValue <<= 1;
    }

    return result.toString();
  }

  async function fetchData() {
    let response = await HTTPManager.GetBusList({
      accessToken: userInfo.accessToken
    });

    if (response !== undefined) {
      setBusList(response.data.busList);
    }

    // 버스 예약 없는 경우도 표시
    let reservationList = location.state.tourList.map(tourInfo => {
      let guideId = location.state.toursGuideInfo.find(guide => guide.tourId === tourInfo.id)?.guideId;
      let guideInfo = location.state.userList.find(user => user.id === guideId);

      let busInfo = location.state.reservationBus.filter(item => item.tourId === tourInfo.id);
      let busName = busInfo.map(item => { return response.data.busList.find(bus => bus.id === item.busId)?.name });
      let busType = busInfo.map(item => { return item.busType });

      let hotelInfo = location.state.reservationHotel.filter(item => item.tourId === tourInfo.id).map(item => {
        return item.hotelId
      });

      let uniqueHotelList = [...new Set(hotelInfo)];
      let hotelNameList = uniqueHotelList.map(item => {
        let hotelInfo = location.state.hotelList.find(hotel => hotel.id === item);
        return <div key={hotelInfo.id}>{hotelInfo.name}[{hotelInfo.address2}]</div>;
      });

      // 여러 개 구분자 사용
      // | 구분기호 사용하여 구분자 추가
      let paxStr = tourInfo.pax.split(/\[|\(/);
      let checkSales = tourInfo.memo?.includes("판매중");
      let checkCancel = tourInfo.cancel === 1;
      let memoStr = "";

      if (checkSales)
        memoStr = "판매중";

      if (checkCancel)
        memoStr = "캔슬";

      return ({
        tourId: tourInfo.id,
        startDate: tourInfo.startDate.slice(2, 10),
        endDate: tourInfo.endDate.slice(2, 10),
        pax: paxStr[0],
        eta: tourInfo.eta,
        etd: tourInfo.etd,
        itinerary: getCourse(tourInfo.itin),
        bus: busName.toString(),
        busType: busType.toString(),
        isBig: tourInfo.isBig === 1 ? "O" : "",
        guide: guideInfo !== undefined && (guideInfo.name + "(" + guideInfo.phoneNumber + ")"),
        hotel: hotelNameList,
        freeDay: getFreeDay(tourInfo.startDate, tourInfo.endDate, tourInfo.freeDay),
        state: memoStr,
      });
    });

    reservationList.sort((a, b) => {
      if (a.bus > b.bus)
        return 1;
      if (a.bus < b.bus)
        return -1;

      // 시작일 빠른순 정렬
      if (new Date(a.startDate.replace(" ", "T")) > new Date(b.startDate.replace(" ", "T")))
        return 1;
      if (new Date(a.startDate.replace(" ", "T")) < new Date(b.startDate.replace(" ", "T")))
        return -1;

      // 종료일 빠른순 정렬
      if (new Date(a.endDate.replace(" ", "T")) > new Date(b.endDate.replace(" ", "T")))
        return 1;
      if (new Date(a.endDate.replace(" ", "T")) < new Date(b.endDate.replace(" ", "T")))
        return -1;
    })

    setReservationInfo(reservationList);
    setReservationFilterInfo(reservationList);


  }

  useEffect(() => {
    fetchData();
  }, [])

  const minWidth = 50;
  const columns = useMemo(
    () => [
      {
        accessor: "tourId",
        Header: "번호",
        width: 70,
        style: {
          border: "1px solid rgb(0, 0, 0)",
          textAlign: "center",
          fontSize: 13,
        },
        index: 0,
      },
      {
        accessor: "startDate",
        Header: "시작",
        width: 70,
        style: {
          border: "1px solid rgb(0, 0, 0)",
          textAlign: "center",
          fontSize: 13,
        },
        index: 1,
      },
      {
        accessor: "endDate",
        Header: "종료",
        width: 70,
        style: {
          border: "1px solid rgb(0, 0, 0)",
          textAlign: "center",
          fontSize: 13,
        },
        index: 2,
      },
      {
        accessor: "freeDay",
        Header: "자유일정",
        minWidth: minWidth + 60,
        style: {
          border: "1px solid rgb(0, 0, 0)",
          textAlign: "center",
          fontSize: 13,
        },
        index: 7,
      },
      {
        accessor: "pax",
        Header: "인원",
        width: 70,
        style: {
          border: "1px solid rgb(0, 0, 0)",
          textAlign: "center",
          fontSize: 13,
        },
        index: 3,
      },
      {
        accessor: "eta",
        Header: "입국",
        minWidth: minWidth + 60,
        style: {
          border: "1px solid rgb(0, 0, 0)",
          textAlign: "center",
          fontSize: 13,
        },
        index: 4,
      },
      {
        accessor: "etd",
        Header: "출국",
        minWidth: minWidth + 60,
        style: {
          border: "1px solid rgb(0, 0, 0)",
          textAlign: "center",
          fontSize: 13,
        },
        index: 5,
      },
      {
        accessor: "hotel",
        Header: "호텔",
        minWidth: minWidth * 4,
        style: {
          border: "1px solid rgb(0, 0, 0)",
          textAlign: "center",
          fontSize: 13,
        },
        index: 8,
      },
      {
        accessor: "guide",
        Header: "가이드",
        minWidth: minWidth + 120,
        style: {
          border: "1px solid rgb(0, 0, 0)",
          textAlign: "center",
          fontSize: 13,
        },
        index: 10,
      },
      {
        accessor: "bus",
        Header: "버스",
        minWidth: minWidth + 60,
        style: {
          border: "1px solid rgb(0, 0, 0)",
          textAlign: "center",
          fontSize: 13,
        },
        index: 11,
      },
      {
        accessor: "isBig",
        Header: "대형",
        minWidth: 10,
        width: 80,
        style: {
          border: "1px solid rgb(0, 0, 0)",
          textAlign: "center",
          fontSize: 13,
        },
        index: 12,
      },
      {
        accessor: "state",
        Header: "상태",
        minWidth: 10,
        width: 80,
        style: {
          border: "1px solid rgb(0, 0, 0)",
          textAlign: "center",
          fontSize: 13,
        },
        index: 13,
      },
      {
        accessor: "memoBus",
        Header: "메모",
        minWidth: 80,
        width: 80,
        style: {
          border: "1px solid rgb(0, 0, 0)",
          textAlign: "center",
          fontSize: 13,
          cursor: "pointer",
        },
        index: 14,
      },
    ]);

  const data = useMemo(() => reservationFilterInfo, [reservationFilterInfo]);
  const [searchText, setSearchText] = useState("");

  const a4Notice = useRef();

  // 인쇄 기능 Handler
  const onPrintHandler = () => {
    let printContents = ReactDOM.findDOMNode(a4Notice.current).innerHTML;
    let windowObject = window.open('', "PrintWindow", "width=1000, height=800, top=100, left=300, toolbars=no, scrollbars=no, status=no, resizale=no");

    windowObject.document.writeln(printContents);
    windowObject.document.close();
    windowObject.focus();
    windowObject.print();
    //windowObject.close();
  }

  return (
    <div className="flex w-full flex-col mt-2 md:mt-14" style={{ padding: 10, width: "100%" }}>
      <div className="w-full pr-5 pl-5" style={{ marginBottom: 10, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', }}>
        <input
          className="placeholder:text-slate-400 block bg-white border border-slate-300 rounded-md py-2 pl-3 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1"
          placeholder="검색"
          type="text"
          name="search"
          style={{ flex: 1, marginTop: 5, marginLeft: 5 }}
          onChange={(e) => {
            if (e.target.value.length === 0) {
              setReservationFilterInfo(reservationInfo);
              setSearchText(e.target.value);
              return;
            }
            let searchList = [];
            searchList = reservationInfo.filter(reservation => reservation.bus === e.target.value);
            setReservationFilterInfo(searchList);
            setSearchText(e.target.value);
          }}
          value={searchText}
        />
      </div>
      <div className="w-full pr-5 pl-6">
        <ColorButton color={"slateblue"} title={"인쇄"} handler={() => {
          onPrintHandler();
        }} />
      </div>
      <div ref={a4Notice} style={{ padding: 10, width: "100%", display: "flex", flexDirection: "column", fontSize: 13, }}>
        <div style={{ marginTop: 5, borderWidth: "0px 0px 3px 0px", borderBottomStyle: "double", borderBottomColor: "black", }}>
          <div style={{ position: "absolute", right: 5, }}>
            <img src="https://www.ktravelstore.com/static/media/logo_kts.d464f76115f5ea7366ec.png" alt="not found" width={120} />
          </div>
          <div style={{ marginBottom: 20, textAlign: "center", fontWeight: "bold", fontSize: 20, }}>코리아 트래블 스토어(KTS)</div>
          <div style={{ padding: 10, display: "flex", flexDirection: "row" }}>
            <div style={{ marginRight: 10 }}>수 신</div>
            <div style={{ color: "deepskyblue", cursor: "pointer", textTransform: "none", }} onClick={() => {
              setInputType("manager");
              setInputHint("담당자");
              setInputValue(receiver);
              setShowInput(true);
            }}>{receiver}</div>
          </div>
          <div style={{ padding: 10, display: "flex", flexDirection: "row", marginBottom: 5 }}>
            <div style={{ marginRight: 10, }}>제 목</div>
            <div style={{ color: "deepskyblue", fontWeight: "bold", textTransform: "none", cursor: "pointer", }} onClick={() => {
              setInputType("title");
              setInputHint("제목");
              setInputValue(title);
              setShowInput(true);
            }}>{title}</div>
          </div>
        </div>
        <div style={{ display: "flex", flexDirection: "column", flex: "100%", justifyContent: "center", marginTop: 10 }}>
          {reservationFilterInfo?.length > 0 && <ReservationBusListTable columns={columns} data={data} />}
        </div>
        <div style={{ display: "flex", marginTop: 50, marginBottom: 20, borderWidth: "0px 0px 10px 0px", borderBottomStyle: "solid", borderBottomColor: "#DDD9C3", }} />
        <div style={{ display: "flex", flexDirection: "row", marginBottom: 10, }}>
          <div style={{ marginRight: 20, cursor: "pointer", textTransform: "none", }} onClick={() => {
            setInputType("team");
            setInputHint("부서명");
            setInputValue(team);
            setShowInput(true);
          }}>
            {team}
          </div>
          <div style={{ cursor: "pointer", textTransform: "none", }} onClick={() => {
            setInputType("sender");
            setInputHint("발신자");
            setInputValue(sender);
            setShowInput(true);
          }}>
            {sender}
          </div>
        </div>
        <div style={{ display: "flex", flexDirection: "row", marginBottom: 10, cursor: "pointer", textTransform: "none", }} onClick={() => {
          setInputType("phone");
          setInputHint("전화번호");
          setInputValue(phone);
          setShowInput(true);
        }}>
          {"전화 " + phone}
        </div>
        <div style={{ display: "flex", flexDirection: "row", marginBottom: 10, cursor: "pointer", textTransform: "none", }} onClick={() => {
          setInputType("email");
          setInputHint("이메일");
          setInputValue(email);
          setShowInput(true);
        }}>
          {"메일 " + email}
        </div>
      </div>
      {
        // 수정 입력창 다이얼로그
        showInput &&
        <div className="left-20 md:left-0 top-20" style={{ position: "fixed", display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: 500, }}>
          <div style={{ display: "flex", flexDirection: "column", width: "80%", background: "#000a", padding: 20, borderRadius: 10 }}>
            <div style={{ display: "flex", width: "100%", justifyContent: "flex-end" }}>
              <div
                style={{
                  width: 25,
                  height: 25,
                  fontSize: 15,
                  color: "white",
                  cursor: "pointer",
                  textAlign: "center"
                }}
                onClick={() => { setShowInput(false) }}
              >
                <FontAwesomeIcon icon={faXmark} />
              </div>
            </div>
            <div style={{ marginBottom: 10, display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', }}>
              <input
                className="placeholder:text-slate-400 block bg-white border border-slate-300 rounded-md py-2 pl-3 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1"
                placeholder={inputHint}
                type="text"
                name="input"
                style={{ width: "100%", marginBottom: 10, }}
                onChange={(e) => setInputValue(e.target.value)}
                value={inputValue}
                onKeyDown={(e) => handleKeyDown(e)}
              />
              <ColorButton title={"변경"} color={"black"} width={"100%"} handler={() => {
                changeValue();
              }} />
            </div>
          </div>
        </div>
      }
    </div >
  )
}