import React, { useState } from 'react';

export default function (Props) {

  const [opacity, SetOpacity] = useState(1);

  return (
    <div className="select-none normal-case"
      style={{
        width: Props.width,
        background: '#fff',
        borderLeftWidth: 1,
        borderRightWidth: 2,
        borderBottomWidth: 3,
        borderRadius: 10,
        paddingTop: 7,
        paddingBottom: 7,
        paddingRight: 10,
        paddingLeft: 10,
        backgroundColor: Props.color,
        opacity: opacity,
        color: '#fff',
        cursor: 'pointer',
        fontWeight: 'bold',
        textAlign: 'center'
      }} onClick={Props.handler}
      onMouseEnter={() => { SetOpacity(0.4) }} onMouseLeave={() => { SetOpacity(1) }} onMouseUp={() => { SetOpacity(1) }}
    >{Props.title}
    </div>
  );
}