import React, { useEffect, useState, useMemo, useRef } from "react";
import * as Utils from '../../features/Utils';
import { ROLES } from "../../features/Constant";
import { useNavigate } from "react-router-dom";
import { UserInfo, LanguageSet } from "../../recoil";
import { useRecoilValue } from "recoil";
import * as HTTPManager from "../../features/HTTPManager";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import "../styles/CommonStyle.css";
import InvoiceDetail from "./InvoiceDetail";
import { InvoiceTextField } from "./InvoiceTextField";

export default function InvoiceWindow() {
  const { height, width } = useWindowDimensions();

  const navigate = useNavigate();
  const languageSet = useRecoilValue(LanguageSet);
  const userInfo = useRecoilValue(UserInfo);

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const [tourList, setTourList] = useState([]);
  const [tourFilterList, setTourFilterList] = useState([]);
  const [invoiceType, setInvoiceType] = useState([]);
  const [invoicePrice, setInvoicePrice] = useState([]);
  const [invoiceDetail, setInvoiceDetail] = useState([]);

  // type 추가 관련 변수
  const [showModal, setShowModal] = useState(false);
  const [typeName, setTypeName] = useState("");

  // 인보이스 값 설정 관련 변수
  const [selectedTourId, setSelectedTourId] = useState(0);
  const [showModify, setShowModify] = useState(false);
  const [changeList, setChangeList] = useState([]);

  // invoice 확인 창
  const [showInvoice, setShowInvoice] = useState(false);

  // filter 관련
  const [showRequest, setShowRequest] = useState(false);

  const invoiceListHandler = (value, tourId, type, isCount) => {
    value = value;
    let findItem = changeList.find(item => item.tourId === tourId);
    let initData;

    if (findItem === undefined && value !== "") {
      initData = { "tourId": tourId, "list": [] };

      if (!isCount) {
        initData.list.push({ "key": type, "price": value, });
      } else {
        initData.list.push({ "key": type, "count": value, });
      }

      setChangeList([...changeList, initData]);
    } else {
      if (findItem === undefined) {

      } else {
        if (findItem.list.find(item => item.key === type) !== undefined) {
          // 이미 key 존재하는 경우
          if (!isCount) { findItem.list.find(item => item.key === type)["price"] = value; }
          else { findItem.list.find(item => item.key === type)["count"] = value; }
        } else {
          // key 없는 경우
          if (!isCount) {
            findItem.list.push({ "key": type, "price": value, });
          } else {
            findItem.list.push({ "key": type, "count": value, });
          }
        }

        if (value === "" || value === null) {
          if (!isCount) {
            delete findItem.list?.find(item => item.key === type).price;
          } else {
            delete findItem.list?.find(item => item.key === type).count;
          }

          // // 나머지 항목 확인, id만 남아있다면 삭제.
          if (findItem?.list?.length === 0) {
            changeList = changeList.filter(item => item.tourId !== findItem.tourId);
          }
        }

        setChangeList([...changeList]);
      }
    }
  }

  async function fetchData() {
    let res = await HTTPManager.GetUserList({
      accessToken: userInfo.accessToken,
    });

    let userList = [];
    let toursGuideInfo = [];

    if (res !== undefined) {
      userList = res.data.result;
    }

    res = await HTTPManager.GetTourReservation({
      accessToken: userInfo.accessToken,
    });

    if (res !== undefined && res.data !== undefined) {
      toursGuideInfo = res.data.toursGuide;
    }

    res = await HTTPManager.GetInvoiceType({
      accessToken: userInfo.accessToken,
    });

    if (res !== undefined) {
      let typeList = res.data.result;
      typeList.map(type => {
        return ({
          id: type.id,
          name: type.name,
        });
      });

      setInvoiceType(typeList);
    }

    res = await HTTPManager.GetInvoice({
      accessToken: userInfo.accessToken,
    });

    if (res !== undefined && res.data !== undefined) {
      setInvoicePrice(res.data?.invoicePrice);
      setInvoiceDetail(res.data?.invoiceDetail);
    }

    res = await HTTPManager.GetTourList({
      accessToken: userInfo.accessToken,
    });

    if (res !== undefined) {
      let tmpList = res.data.result.sort((a, b) => new Date(a.startDate.replace(" ", "T")) - new Date(b.startDate.replace(" ", "T")))
      tmpList = tmpList.map(tour => {
        let guideId = toursGuideInfo.find(tourGuide => tourGuide.tourId === tour.id)?.guideId;
        let guideName = userList.find(user => user.id === guideId)?.name?.split('/')[0];

        return ({
          id: tour.id,
          country: getCountrName(tour.countryId),
          pax: tour.pax,
          docNum: tour.docNum,
          startDate: tour.startDate.slice(0, 10),
          endDate: tour.endDate.slice(0, 10),
          guide: guideName,
          cancel: tour.cancel,
        });
      });

      setTourList(tmpList);
      setTourFilterList(tmpList);
    }
  }

  useEffect(() => {
    Utils.allowByPosition(ROLES.ADMIN, userInfo.rolesId)
      .then(async (res) => {
        if (!res) {
          alert("접근 권한이 없습니다.");
          navigate("/", { replace: true });
        }
        else {
          fetchData();
        }
      })
      .catch((err) => {
        alert(err.message);
      });
  }, []);

  function getCountrName(value) {
    switch (value) {
      case 2:
        return "인도네시아";
      case 3:
        return "필리핀";
    }
  }

  function getTextFieldLabel(value) {
    switch (value) {
      default:
        return value;
    }
  }

  const redTag = (msg) => {
    return <span
      className="inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10 mr-[5px] cursor-pointer"
    > {msg}</span >
  }

  const greenTag = (msg) => {
    return <span
      className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20 mr-[5px] cursor-pointer"
    >{msg}</span>
  }

  const yellowTag = (msg) => {
    return <span className="inline-flex items-center rounded-md bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-800 ring-1 ring-inset ring-yellow-600/20 mr-[5px]">{msg}</span>
  }

  function getInvoiceTable() {
    let resultArray = [];

    tourFilterList.map((tour, index) => {
      let bgColor = index % 2 === 0 ? "bg-[#ededed]" : "bg-[#fefefe]"
      let hoverColor = "hover:bg-[#0005]";

      resultArray.push(
        <div key={tour.id} className={`flex flex-row w-auto h-full text-[black] text-[14px] font-medium cursor-pointer normal-case ${bgColor} ${hoverColor}`}
          onClick={() => {
            setSelectedTourId(tour.id);
            setShowModify(true);
          }}>
          <div className={`flex flex-none h-full w-[70px] text-[white] justify-center items-center p-1`}>
            <div className="relative bg-[black] rounded py-1 px-2" onClick={(e) => {
              setSelectedTourId(tour.id);
              setShowInvoice(true);
              e.stopPropagation();
            }}>
              확인
            </div>
          </div>
          <div className={`flex flex-none h-full w-[70px] text-[white] justify-center items-center p-1`}>
            {
              invoiceDetail.filter(item => item.tourId === tour.id)[0]?.publishAt !== null && invoiceDetail.filter(item => item.tourId === tour.id)[0]?.publishAt !== undefined
                ? invoicePrice.filter(item => (item.requestCount !== null || item.requestPrice !== null) && item.modified === null && item.tourId === tour.id)?.length > 0 ? yellowTag('요청') : greenTag('발행') : redTag('미발행')
            }
          </div>
          <div className={`flex flex-none h-full w-[70px] justify-center items-center p-1`}>{tour.id}</div>
          <div className={`flex flex-none h-full w-[100px] justify-center items-center p-1`}>{tour.country}</div>
          <div className={`flex flex-none h-full w-[130px] justify-center items-center p-1`}>{tour.startDate}</div>
          <div className={`flex flex-none h-full w-[130px] justify-center items-center p-1`}>{tour.endDate}</div>
          <div className={`flex flex-none h-full w-[150px] justify-center items-center p-1`}>{tour.guide}</div>
          <div className={`flex flex-none h-full w-[300px] justify-center items-center p-1`}>{tour.docNum}</div>
          {
            invoiceType.map(type => {
              if (type.showing === 0) return null

              let invoiceFromServer = invoicePrice.find(invoice => invoice.tourId === tour.id && invoice.typeId === type.id);

              let cellContent = "";
              let price = invoiceFromServer?.price;
              let count = invoiceFromServer?.count;

              if (invoiceFromServer !== undefined) {
                cellContent = `${count} * ${price} = ${Utils.formatNumber((price * count).toFixed(1))}`
              }

              if (type.id === 1) {
                cellContent = price?.toString();
              }

              return (
                <div key={type.id} className={`flex flex-none h-full w-[130px] text-[black] justify-center items-center p-1`}>
                  {cellContent || ""}
                </div>
              )
            })
          }
        </div>
      );
    });

    return resultArray;
  }

  const invoiceTable = useMemo(() => getInvoiceTable(), [tourFilterList]);
  const outlineStyled = {
    "textTransform": "none",
    "width": "100%",
    "--md-outlined-text-field-container-shape": "0px",
    "--md-outlined-text-field-focus-outline-width": "2px",
    "--md-outlined-text-field-focus-outline-color": "#B3261E",
    "--md-outlined-text-field-outline-color": "#000000",
    "--md-outlined-text-field-label-text-color": "#ababab",
    "--md-outlined-text-field-label-text-size": "13px",
    "--md-outlined-text-field-focus-label-text-color": "#B3261E",
  }

  const buttonRef = useRef(null)

  return (
    <div className={`fixed w-full h-full md:mt-0 pr-60 md:pr-0 ml-60 md:ml-0 pb-[112px] md:pb-[90px]`}>
      <div className="w-full h-full md:mt-14">
        <div className="scrollRemoveBox flex p-1">
          <span className="flex-none items-center rounded-md px-2 py-1 text-xs font-medium cursor-pointer select-none bg-[#acacac] hover:bg-[crimson] text-[white] mr-1"
            onClick={() => {
              setShowModal(true);
            }}
          >
            INVOICE 종류 추가
          </span>
          <span className={`flex-none items-center rounded-md px-2 py-1 text-xs font-medium cursor-pointer select-none ${showRequest ? "bg-[crimson]" : "bg-[#acacac]"} text-[white]`}
            onClick={() => {
              setShowRequest(!showRequest);

              if (!showRequest) {
                let modifyList = invoicePrice.filter(item => (item.requestCount !== null || item.requestPrice !== null) && item.modified === null);
                setTourFilterList(tourList.filter(tour => modifyList.find(modify => modify.tourId === tour.id)));
              } else {
                setTourFilterList(tourList);
              }
            }}
          >
            수정요청 ({invoicePrice.filter(item => (item.requestCount !== null || item.requestPrice !== null) && item.modified === null)?.length})
          </span>
        </div>
        <div className="flex flex-col w-full h-full">
          <div className="flex flex-col w-full overflow-auto">
            {
              <div className="flex flex-row sticky top-0 w-full text-[white] text-center text-[16px] font-bold bg-[black] z-[100]">
                <div className={`flex-none w-[70px] bg-[black] p-1`}></div>
                <div className={`flex-none w-[70px] bg-[black] p-1`}>상태</div>
                <div className={`flex-none w-[70px] bg-[black] p-1`}>번호</div>
                <div className={`flex-none w-[100px] bg-[black] p-1`}>국가</div>
                <div className={`flex-none w-[130px] bg-[black] p-1`}>시작일</div>
                <div className={`flex-none w-[130px] bg-[black] p-1`}>종료일</div>
                <div className={`flex-none w-[150px] bg-[black] p-1`}>가이드</div>
                <div className={`flex-none w-[300px] bg-[black] p-1`}>문서번호</div>
                {
                  invoiceType.map(type => {
                    if (type.showing === 0) return null

                    return (
                      <div key={type.id} className={`flex-none w-[130px] bg-[black] p-1`}>
                        {type.name}
                      </div>
                    )
                  })
                }
              </div>
            }
            {
              invoiceTable
            }
          </div>
        </div>
      </div>
      {
        <InvoiceDetail showModal={showInvoice} setShowModal={setShowInvoice} info={{
          "tourInfo": tourList?.filter(item => item.id === selectedTourId),
          "invoiceType": invoiceType,
          "invoicePrice": invoicePrice?.filter(item => item.tourId === selectedTourId),
          "invoiceDetail": invoiceDetail?.filter(item => item.tourId === selectedTourId)
        }} refresh={fetchData} />
      }
      {
        showModal ? (
          <>
            <div className="mx-6 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none font-medium">
              <div className="relative w-full my-6 mx-auto max-w-lg">
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col bg-white outline-none focus:outline-none">
                  <input
                    autoComplete="off"
                    className="mx-4 my-4 placeholder:text-slate-400 block bg-white border border-slate-300 rounded-md py-2 pl-3 pr-3 shadow-sm focus:outline-none"
                    placeholder="이름"
                    type="text"
                    name="name"
                    style={{ flex: "100%" }}
                    onChange={(e) => {
                      setTypeName(e.target.value);
                    }}
                    value={typeName}
                  />

                  <div className="flex flex-row text-[white]">
                    <button
                      className="flex w-full cursor-pointer justify-center rounded-bl-lg bg-[#cfcfcf] py-2 focus:bg-[#ababab] outline-none focus:outline-none"
                      type="button"
                      onClick={() => {
                        setShowModal(false);
                      }}
                    >
                      {languageSet.strCancel}
                    </button>

                    <button
                      className="flex w-full cursor-pointer justify-center rounded-br-lg bg-[black] py-2 focus:bg-[#000000aa] outline-none focus:outline-none"
                      type="button"
                      ref={buttonRef}
                      onClick={async () => {
                        if (typeName.length === 0) {
                          alert('이름 확인');
                          buttonRef.current.blur();
                          return;
                        }

                        let res = await HTTPManager.AddInvoiceType({
                          accessToken: userInfo.accessToken,
                          typeName: typeName,
                        });

                        if (res.data !== undefined && res.data.result === true) {
                          setShowModal(false);
                          fetchData();
                          alert('추가 성공');
                        } else {
                          alert('추가 실패');
                        }

                      }}
                    >
                      추가
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black" />
          </>
        ) : null
      }
      {
        showModify ? (
          <>
            <div className="mx-6 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none font-medium">
              <div className="relative w-full my-6 mx-auto max-w-lg">
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col bg-white outline-none focus:outline-none">
                  <div className="flex flex-row mx-4 mt-4 gap-1">
                    <div>[{tourList.find(tour => tour.id === selectedTourId).id}]</div>
                    <div>{tourList.find(tour => tour.id === selectedTourId).country}</div>
                    <div>{tourList.find(tour => tour.id === selectedTourId).startDate}</div>
                    <div>~</div>
                    <div>{tourList.find(tour => tour.id === selectedTourId).endDate}</div>
                    <div>{tourList.find(tour => tour.id === selectedTourId).guide}</div>
                  </div>
                  <div className="flex flex-col gap-2 m-4">
                    {
                      invoiceType.map(type => {
                        if (type.showing === 0) return null

                        // 서버 데이터 현재 변경 데이터 비교 어떤 항목을 표시할 지 결정
                        let invoiceFromServer = invoicePrice.find(invoice => invoice.tourId === selectedTourId && invoice.typeId === type.id);

                        let price = invoiceFromServer?.price === undefined ? "" : invoiceFromServer?.price;
                        let count = invoiceFromServer?.count === undefined ? "" : invoiceFromServer?.count;

                        let changeListPrice = changeList.find(item => item.tourId === selectedTourId)?.list.find(item => item.key === type.name)?.price;
                        if (changeListPrice !== undefined)
                          price = changeListPrice;

                        let changeListCount = changeList.find(item => item.tourId === selectedTourId)?.list.find(item => item.key === type.name)?.count;
                        if (changeListCount !== undefined)
                          count = changeListCount;

                        return (
                          <div key={type.id} className="flex w-full gap-4">
                            <div className={`flex w-full`}>
                              <InvoiceTextField label={getTextFieldLabel(type.name) + " (총인원)"} type="tel" style={outlineStyled}
                                autoComplete="off"
                                value={count}
                                onChange={(e) => {
                                  invoiceListHandler(e.target.value, selectedTourId, type.name, true);
                                }} />
                            </div>
                            <div className={`flex w-full`}>
                              <InvoiceTextField label={getTextFieldLabel(type.name) + " (가격)"} type="tel" style={outlineStyled}
                                autoComplete="off"
                                value={price}
                                onChange={(e) => {
                                  invoiceListHandler(e.target.value, selectedTourId, type.name, false);
                                }} />
                            </div>
                          </div>
                        );
                      })
                    }
                  </div>
                  <div className="flex flex-row text-[white]">
                    <button
                      className="flex w-full cursor-pointer justify-center rounded-bl-lg bg-[#cfcfcf] py-2 focus:bg-[#ababab] outline-none focus:outline-none"
                      type="button"
                      onClick={() => {
                        setShowModify(false);
                      }}
                    >
                      {languageSet.strCancel}
                    </button>
                    <button
                      className="flex w-full cursor-pointer justify-center rounded-br-lg bg-[black] py-2 focus:bg-[#000000aa] outline-none focus:outline-none"
                      type="button"
                      ref={buttonRef}
                      onClick={async () => {
                        setShowModify(false);

                        await HTTPManager.AddInvoice({
                          accessToken: userInfo.accessToken,
                          list: changeList,
                        });

                        setChangeList([]);
                        fetchData();
                      }}
                    >
                      수정
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black" />
          </>
        ) : null
      }
    </div >
  );
}