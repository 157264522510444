import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretLeft } from "@fortawesome/free-solid-svg-icons";
import { faCaretRight } from "@fortawesome/free-solid-svg-icons";

const Calendar = (Props) => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const [year, setYear] = useState(today.getFullYear());
  const [month, setMonth] = useState(today.getMonth());

  var rows = [0, 1, 2, 3, 4, 5];
  var cols = [0, 1, 2, 3, 4, 5, 6];

  // 날짜 : getDate() 1 - 31
  // 요일 : getDay() 0 - 6
  var startDate = new Date(year, month, 1);
  var endDate = new Date(year, month + 1, 0);

  function movingMonth(value) {
    if (value > 11) {
      setMonth(0);
      setYear(year + 1);
      Props.setMonth(0);
      Props.setYear(year + 1);
    } else if (value < 0) {
      setMonth(11);
      setYear(year - 1);
      Props.setMonth(11);
      Props.setYear(year - 1);
    } else {
      setMonth(value);
      Props.setMonth(value);
    }
  }

  function checkToday(value) {
    var result = true;
    // year
    if (year !== today.getFullYear()) result = false;

    //month
    if (month !== today.getMonth()) result = false;

    // day
    if (today.getDate() !== value - startDate.getDay() + 1) result = false;

    return result;
  }

  function checkExistDay(value) {
    var result = true;

    if (
      startDate.getDay() > value ||
      endDate.getDate() < value - startDate.getDay() + 1
    )
      result = false;

    return result;
  }

  function todayDate(day) {
    var currDate = new Date();
    currDate.setFullYear(year);
    currDate.setMonth(month);
    currDate.setDate(day);
    currDate.setHours(0);
    currDate.setMinutes(0);
    currDate.setSeconds(0);
    currDate.setMilliseconds(0);
    return currDate;
  }

  // 해당 날짜 투어 존재 여부 확인
  function isExistTour(start, end, day) {
    var result = false;

    // 2022-xx-xxT00:00:00 형태로 생성   
    // 위와 같은 형태로 생성하지 않으면 아이폰에서 제대로 나오지 않는다.
    var dateStr = year + "-" + String((month + 1)).padStart(2, "0") + "-" + String(day).padStart(2, "0") + "T00:00:00";
    var currDate = new Date(dateStr);

    if (currDate >= new Date(start.replace(" ", "T")) && currDate <= new Date(end.replace(" ", "T"))) {
      result = true;
    }

    return result;
  }


  function getRoundedType(start, end, day) {
    let result = ``;

    var dateStr = year + "-" + String((month + 1)).padStart(2, "0") + "-" + String(day).padStart(2, "0") + "T00:00:00";
    var currDate = new Date(dateStr);

    if (new Date(start.replace(" ", "T")).getDate() === currDate.getDate()) {
      result = "rounded-l-full";
    } else if (new Date(end.replace(" ", "T")).getDate() === currDate.getDate()) {
      result = "rounded-r-full";
    } else {
      result = "rounded-full";
    }

    return "rounded-full";
  }

  let red = "";
  let green = "";
  let blue = "";

  function getBgColor(start, day) {
    var dateStr = year + "-" + String((month + 1)).padStart(2, "0") + "-" + String(day).padStart(2, "0") + "T00:00:00";
    var currDate = new Date(dateStr);

    if (new Date(start.replace(" ", "T")).getDate() === currDate.getDate()) {
      red = (Math.floor(Math.random() * 156) + 100).toString(16).padStart(2, "0");
      green = (Math.floor(Math.random() * 156) + 100).toString(16).padStart(2, "0");
      blue = (Math.floor(Math.random() * 156) + 100).toString(16).padStart(2, "0");
    }

    return `#${red}${green}${blue}`;
  }

  // useEffect (async () => {}, []) 형태 사용하지 말 것
  // 내부에서 async 함수를 정의해서 호출
  useEffect(() => { }, []);

  // 월 이동 버튼 css
  const btnMoveMonthClass = "text-3xl pointerhover:hover:cursor-pointer px-4 py-1 pointerhover:hover:bg-gray-200 rounded-full focus:outline-none";

  return (
    <div className="md:mt-14 p-4 md:py-4 md:px-1 flex flex-col flex-1 bg-white text-center justify-center">
      <div className="flex justify-evenly mb-4">
        <div className="flex justify-center align-middle md:ml-0 -ml-5">
          <span
            className={btnMoveMonthClass}
            onClick={() => {
              movingMonth(month - 1);
            }}
          >
            <FontAwesomeIcon icon={faCaretLeft} className="text-gray-500" />
          </span>
          <div id="title" className="text-2xl mx-2 py-1">
            {year}년 {month + 1}월
          </div>
          <span
            className={btnMoveMonthClass}
            onClick={() => {
              movingMonth(month + 1); // 부모 컴포넌트로 전달할 리코일 변수
            }}
          >
            <FontAwesomeIcon icon={faCaretRight} className="text-gray-500" />
          </span>
        </div>
      </div>
      <div className="flex mb-4">
        <div className="bg-black text-white border-l-1 border-r-2 rounded-lg py-1 px-3 hover:cursor-pointer font-bold hover:opacity-60 select-none"
          onClick={() => {
            setYear(today.getFullYear());
            setMonth(today.getMonth());
            Props.setMonth(today.getMonth());
            Props.setYear(today.getFullYear());
          }}>
          오늘
        </div>
      </div>

      <div id="calendarHeader" className="flex">
        <div className="bg-red-500 flex-1 text-white font-extrabold">일</div>
        <div className="bg-[black] flex-1 text-white font-extrabold">월</div>
        <div className="bg-[black] flex-1 text-white font-extrabold">화</div>
        <div className="bg-[black] flex-1 text-white font-extrabold">
          수
        </div>
        <div className="bg-[black] flex-1 text-white font-extrabold">
          목
        </div>
        <div className="bg-[black] flex-1 text-white font-extrabold">
          금
        </div>
        <div className="bg-blue-500 flex-1 text-white font-extrabold">토</div>
      </div>
      <div id="calendarBody" className="bg-gray-200 mb-2">
        {
          //map 중첩되는 경우 중첩되는 모든 곳에서 return 처리 해야 함
          rows.map((row) => {
            if ((startDate.getDay() + endDate.getDate()) / 7 > row) {
              return (
                <div key={"row" + row} className="flex flex-1 bg-white w-full">
                  {cols.map((col) => {
                    return (
                      <div
                        key={"col" + (row * 7 + col)}
                        // display, alignItems, justifyContent 같이 써야 가로, 세로 가운데 정렬 가능
                        className="flex flex-1 justify-center border h-24 md:h-20 pt-1"
                      >
                        {
                          // 오늘 표시
                          checkToday(row * 7 + col) ? (
                            <span className="bg-[crimson] w-10 h-6 rounded-full absolute z-10" />
                          ) : null
                        }
                        <span
                          className={
                            checkToday(row * 7 + col)
                              ? `text-white font-bold z-20 w-full`
                              : `z-20 w-full`
                          }
                        >
                          <div className="flex justify-center mb-1">
                            {
                              // 날짜 표시 : 시작 요일, 마지막 날짜 고려하여
                              checkExistDay(row * 7 + col)
                                ? row * 7 + col - startDate.getDay() + 1
                                : ""
                            }
                          </div>
                          {
                            // 행사 유무 표시
                            checkExistDay(row * 7 + col) ? (
                              <div className="flex justify-center gap-1">
                                {
                                  Props.myTourList?.map((tour) => {
                                    let day = row * 7 + col - startDate.getDay() + 1;
                                    if (isExistTour(tour.startDate, tour.endDate, day)) {
                                      return (
                                        <div key={tour.tourId + "/" + tour.guideId + "/" + (row * 7 + col)}
                                          className={`w-2 h-2 cursor-pointer ${getRoundedType(tour.startDate, tour.endDate, day)}`}
                                          style={{
                                            background: `${getBgColor(tour.startDate, day)}`,
                                          }}
                                        />
                                      );
                                    }
                                  })
                                }
                              </div>
                            ) : null
                          }
                        </span>
                      </div>
                    );
                  })}
                </div>
              );
            }
            return null;
          })
        }
      </div>
    </div>
  );
};

export default Calendar;
