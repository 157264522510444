import React from 'react';
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ColorButton from "./ColorButton";

// 수정 입력창 다이얼로그
export default function ModifyModal(Props) {
  return (
    Props.show &&
    <div className="left-20 md:left-0 top-20" style={{ position: "fixed", display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: 500, }}>
      <div style={{ display: "flex", flexDirection: "column", width: "80%", background: "#000a", padding: 20, paddingTop: 20, borderRadius: 10 }}>
        <div style={{ display: "flex", width: "100%", justifyContent: "flex-end" }}>
          <div
            style={{
              width: 25,
              height: 25,
              fontSize: 15,
              color: "white",
              cursor: "pointer",
              textAlign: "center"
            }}
            onClick={() => { Props.setShow(false) }}
          >
            <FontAwesomeIcon icon={faXmark} />
          </div>
        </div>
        <div style={{ marginBottom: 10, display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', }}>
          <input
            className="placeholder:text-slate-400 block bg-white border border-slate-300 rounded-md py-2 pl-3 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1"
            type="text"
            name="input"
            style={{ width: "100%", marginBottom: 10, }}
            onChange={(e) => Props.setValue(e.target.value)}
            value={Props.value}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                Props.onClick();
              }
            }}
          />
          <ColorButton title={"변경"} color={"black"} width={"100%"} handler={Props.onClick} />
        </div>
      </div>
    </div>
  )
}
