import React, { useState, useEffect, forwardRef } from "react";
import * as Utils from "../../features/Utils";
import * as HTTPManager from "../../features/HTTPManager";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear, faX, faPen } from "@fortawesome/free-solid-svg-icons";
import ModifyToursGuideDialog from "./ModifyToursGuideDialog";
import 'react-toastify/dist/ReactToastify.css';
import { UserInfo, } from "../../recoil";
import { useRecoilValue, useRecoilState } from "recoil";
import ColorButton from "../ColorButton";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ko } from 'date-fns/esm/locale';

// 메뉴 4가지 : 호텔, 버스, 가이드, 관광지
export default function TourInfoItemAddOn(Props) {
  const userInfo = useRecoilValue(UserInfo);
  const today = Utils.convertLocalToUTCDate(new Date());

  const colorSet = Props.colorSet;
  const menuList = [{ id: 0, name: "호텔" }, { id: 1, name: "버스" }, { id: 2, name: "가이드" }, { id: 3, name: "관광지" }]

  const [selectedIndex, setSelectedIndex] = useState(0);
  const [showModifyGuideInfo, setShowModifyGuideInfo] = useState(false);
  const [showAddHotelPay, setShowAddHotelPay] = useState(false);
  const [showAddBusPay, setShowAddBusPay] = useState(false);
  const [userList, setUserList] = useState([]);

  const toursHotel = Props.tourHotelInfo;
  const toursBus = Props.tourBusInfo;
  const hotelList = Props.hotelList;
  const busList = Props.busList;

  const [selectedToursHotelInfo, setSelectedToursHotelInfo] = useState("");
  const [selectedToursBusInfo, setSelectedToursBusInfo] = useState("");
  const [inputPay, setInputPay] = useState("");

  const [showAddTipDate, setShowAddTipDate] = useState(false);
  const [tipDate, setTipDate] = useState(today);
  const [selectedToursGuideInfo, setSelectedToursGuideInfo] = useState("");

  const DateCustomInput = forwardRef(({ value, onClick }, ref) => (
    <button className="flex w-full justify-end mb-2" onClick={onClick} ref={ref}>{value}</button>
  ));

  function selectedBodyBox() {
    switch (selectedIndex) {
      case 0:
        return (
          <div className="flex flex-col w-full items-start">
            <div className="flex flex-row w-full items-center justify-between px-2 my-2">
              <div className="flex w-full select-none font-bold" style={{ color: colorSet.name }}>결제정보</div>
              <span
                title="정보수정"
                className={"cursor-pointer px-2 py-1 rounded"}
                style={{ background: "#34414d" }}
                onClick={() => {

                }}>
                <FontAwesomeIcon icon={faGear} style={{ color: "white" }} />
              </span>
            </div>
            <div className="flex w-full select-none border-b-2" style={{ borderBottomColor: colorSet.addLine }}></div>
            <div className="flex w-full flex-row p-2">
              <div className="flex w-full flex-col items-start">
                {
                  toursHotel.map(item => {
                    return (
                      <div key={item.id} className="flex w-full justify-between items-center p-2">
                        <div>
                          {hotelList.find(hotel => hotel.id === item.hotelId).name} ({item.startDate.slice(2, 10)}~{item.endDate.slice(2, 10)})
                        </div>
                        <div>
                          {
                            (item.pay === undefined || item.pay === null) ?
                              <div className="text-[white] p-1 rounded cursor-pointer select-none" style={{
                                background: colorSet.name,
                              }} onClick={() => {
                                setShowAddHotelPay(true);
                                setSelectedToursHotelInfo(item);
                              }}>
                                등록
                              </div> :
                              <div className="flex flex-row items-center">
                                <div className="mr-1">
                                  {
                                    Utils.formatNumber(item.pay)
                                  }
                                </div>
                                <span
                                  title="수정"
                                  className={"cursor-pointer px-2 py-1 rounded"}
                                  style={{ background: "#34414d" }}
                                  onClick={() => {
                                    setShowAddHotelPay(true);
                                    setSelectedToursHotelInfo(item);
                                  }}>
                                  <FontAwesomeIcon icon={faPen} style={{ color: "white" }} />
                                </span>
                              </div>
                          }
                        </div>
                      </div>
                    )
                  })
                }
              </div>
            </div>
          </div>
        )
      case 1:
        return (
          <div className="flex flex-col w-full items-start">
            <div className="flex flex-row w-full items-center justify-between px-2 my-2">
              <div className="flex w-full select-none font-bold" style={{ color: colorSet.name }}>결제정보</div>
              <span
                title="정보수정"
                className={"cursor-pointer px-2 py-1 rounded"}
                style={{ background: "#34414d" }}
                onClick={() => {

                }}>
                <FontAwesomeIcon icon={faGear} style={{ color: "white" }} />
              </span>
            </div>
            <div className="flex w-full select-none border-b-2" style={{ borderBottomColor: colorSet.addLine }}></div>
            <div className="flex w-full flex-row p-2">
              <div className="flex w-full flex-col items-start">
                {
                  toursBus.map(item => {
                    return (
                      <div key={item.id} className="flex w-full justify-between items-center p-2">
                        <div>
                          {busList.find(bus => bus.id === item.busId).name}
                        </div>
                        <div>
                          {
                            (item.pay === undefined || item.pay === null) ?
                              <div className="text-[white] p-1 rounded cursor-pointer select-none" style={{
                                background: colorSet.name,
                              }} onClick={() => {
                                setShowAddBusPay(true);
                                setSelectedToursBusInfo(item);
                              }}>
                                등록
                              </div> :
                              <div className="flex flex-row items-center">
                                <div className="mr-1">
                                  {
                                    Utils.formatNumber(item.pay)
                                  }
                                </div>
                                <span
                                  title="수정"
                                  className={"cursor-pointer px-2 py-1 rounded"}
                                  style={{ background: "#34414d" }}
                                  onClick={() => {
                                    setShowAddBusPay(true);
                                    setSelectedToursBusInfo(item);
                                  }}>
                                  <FontAwesomeIcon icon={faPen} style={{ color: "white" }} />
                                </span>
                              </div>
                          }
                        </div>
                      </div>
                    )
                  })
                }
              </div>
            </div>
          </div >
        )
      case 2:
        return (
          <div className="flex flex-col w-full items-start">
            <div className="flex flex-row w-full items-center justify-between px-2 my-2">
              <div className="flex w-full select-none font-bold" style={{ color: colorSet.name }}>추가정보</div>
              <span
                title="정보수정"
                className={"cursor-pointer px-2 py-1 rounded"}
                style={{ background: "#34414d" }}
                onClick={() => {
                  setShowModifyGuideInfo(true);
                }}>
                <FontAwesomeIcon icon={faGear} style={{ color: "white" }} />
              </span>
            </div>
            <div className="flex w-full select-none border-b-2" style={{ borderBottomColor: colorSet.addLine }}></div>
            {
              Props.tourGuideInfo?.map(item => {
                return (
                  <div key={item.id}
                    className="flex flex-col w-full items-start select-none">
                    <div className="flex w-full justify-between p-2">
                      <div>
                        가이드
                      </div>
                      <div>
                        {userList?.find(user => user.id === item.guideId)?.name}
                      </div>
                    </div>
                    <div className="flex w-full justify-between p-2">
                      <div>
                        전도금
                      </div>
                      <div>
                        {Utils.formatNumber(item.preCharge)}원
                      </div>
                    </div>
                    <div className="flex w-full justify-between p-2">
                      <div>
                        팁
                      </div>
                      <div>
                        ${Utils.formatNumber(item.tipKTS)}
                      </div>
                    </div>
                    <div className="flex w-full justify-between p-2">
                      <div>
                        팁 전달일
                      </div>
                      <div>
                        {
                          (item.tipDate !== undefined && item.tipDate !== null) ? (
                            <div className="flex flex-row items-center">
                              <div className="mr-2">{item.tipDate?.slice(0, 10)}</div>
                              <div className="text-[white] p-1 rounded cursor-pointer select-none" style={{
                                background: colorSet.name,
                              }} onClick={() => {
                                setShowAddTipDate(true);
                                setSelectedToursGuideInfo(item);
                              }}>
                                수정
                              </div>
                            </div>
                          ) :
                            <div className="text-[white] p-1 rounded cursor-pointer select-none" style={{
                              background: colorSet.name,
                            }} onClick={() => {
                              setShowAddTipDate(true);
                              setSelectedToursGuideInfo(item);
                            }}>
                              등록
                            </div>
                        }
                      </div>
                    </div>
                  </div>
                );
              })
            }

          </div>
        )
      case 3:
        return (
          <div>
            <div className="select-none">추가정보</div>

          </div>
        )
    }
  }

  async function fetchData() {
    let response = await HTTPManager.GetUserList({
      accessToken: userInfo.accessToken,
    });

    if (response !== undefined && response.data?.result !== undefined) {
      // var sortList = [...response.data.result];
      // sortList.sort((a, b) => a.name < b.name ? -1 : a.name > b.name ? 1 : 0);
      setUserList(response.data.result);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      {/* top menu */}
      <div className="flex flex-row justify-between rounded bg-white m-2 p-1">
        {
          menuList.map(item => {
            return (
              <div key={item.id} className="w-full text-[white] rounded py-1 mr-1 font-bold cursor-pointer select-none"
                style={{
                  background: selectedIndex === item.id ? colorSet.addTopBarBackground : "white",
                  color: selectedIndex === item.id ? colorSet.addTopBarText : "black",
                  fontWeight: selectedIndex === item.id ? "bold" : "normal",
                }}
                onClick={() => {
                  setSelectedIndex(item.id);
                }}>
                {item.name}
              </div>
            );
          })
        }
      </div>
      {/* body */}
      <div className="bg-[white] m-2 p-1 rounded">
        {
          selectedBodyBox()
        }
      </div>

      {
        // 가이드 추가 정보 수정
        showModifyGuideInfo &&
        <ModifyToursGuideDialog
          setShow={setShowModifyGuideInfo}
          tourInfo={Props.tourInfo}
          tourGuideInfo={Props.tourGuideInfo}
          userList={userList}
          refresh={Props.refresh}
        />
      }
      {
        showAddHotelPay &&
        <>
          <div className="opacity-25 fixed inset-0 z-40 bg-black" />
          <div className="fixed inset-0 top-[70%] bg-[white] z-40 px-20 md:px-2">
            <div className="flex justify-end items-end pr-1 pt-4">
              <FontAwesomeIcon icon={faX} className="cursor-pointer text-[black]" onClick={() => {
                setShowAddHotelPay(false);
                setInputPay("");
              }} />
            </div>
            <div className="flex z-40 bg-[white] items-center justify-start mb-2">
              <div className="mr-2">
                [{selectedToursHotelInfo.tourId}]
              </div>
              <div className="font-bold">
                호텔 : {hotelList.find(hotel => hotel.id === selectedToursHotelInfo.hotelId)?.name}({selectedToursHotelInfo.startDate.slice(0, 10)}~{selectedToursHotelInfo.endDate.slice(2, 10)})
              </div>
            </div>
            <input
              className="w-full placeholder:text-center placeholder:text-[13px] placeholder:text-slate-400 block bg-white border border-slate-300 rounded-md py-2 pl-9 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 text-xl mb-3"
              placeholder={"결제금액"}
              type="text"
              name="userId"
              onChange={(e) => {
                setInputPay(e.target.value);
              }}
              value={inputPay}
            />
            <ColorButton title="등록" color="slateblue" handler={async () => {
              let res = await HTTPManager.UpdateToursHotelPay({
                accessToken: userInfo.accessToken,
                id: selectedToursHotelInfo.id,
                pay: inputPay,
              });

              if (res.data !== undefined && res.data.result) {
                setShowAddHotelPay(false);
                setInputPay("");
                Props.refresh();
              }
            }} />
          </div>
        </>
      }
      {
        showAddBusPay &&
        <>
          <div className="opacity-25 fixed inset-0 z-40 bg-black" />
          <div className="fixed inset-0 top-[70%] bg-[white] z-40 px-20 md:px-2">
            <div className="flex justify-end items-end pr-1 pt-4">
              <FontAwesomeIcon icon={faX} className="cursor-pointer text-[black]" onClick={() => {
                setShowAddBusPay(false);
                setInputPay("");
              }} />
            </div>
            <div className="flex z-40 bg-[white] items-center justify-start mb-2">
              <div className="mr-2">
                [{selectedToursBusInfo.tourId}]
              </div>
              <div className="font-bold">
                버스 : {busList.find(bus => bus.id === selectedToursBusInfo.busId)?.name}
              </div>
            </div>
            <input
              className="w-full placeholder:text-center placeholder:text-[13px] placeholder:text-slate-400 block bg-white border border-slate-300 rounded-md py-2 pl-9 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 text-xl mb-3"
              placeholder={"결제금액"}
              type="text"
              name="userId"
              onChange={(e) => {
                setInputPay(e.target.value);
              }}
              value={inputPay}
            />
            <ColorButton title="등록" color="slateblue" handler={async () => {
              let res = await HTTPManager.UpdateToursBusPay({
                accessToken: userInfo.accessToken,
                id: selectedToursBusInfo.id,
                pay: inputPay,
              });

              if (res.data !== undefined && res.data.result) {
                setShowAddBusPay(false);
                setInputPay("");
                Props.refresh();
              }
            }} />
          </div>
        </>
      }
      {
        showAddTipDate &&
        <>
          <div className="opacity-25 fixed inset-0 z-40 bg-black" />
          <div className="fixed inset-0 top-[70%] bg-[white] z-40 px-20 md:px-2">
            <div className="flex justify-end items-end pr-1 pt-4">
              <FontAwesomeIcon icon={faX} className="cursor-pointer text-[black]" onClick={() => {
                setShowAddTipDate(false);
              }} />
            </div>
            <div className="flex z-40 bg-[white] items-center justify-start mb-2">
              <div className="mr-2">
                [{selectedToursGuideInfo.tourId}]
              </div>
              <div className="mr-2">
                {selectedToursGuideInfo.name}
              </div>
            </div>
            <div className="flex flex-row">
              <div className="flex w-full">팁 전달일</div>
              <DatePicker onChange={date => {
                setTipDate(Utils.convertLocalToUTCDate(date));
              }}
                selected={Utils.convertUTCToLocalDate(tipDate)}
                locale={ko}
                dateFormat="yyyy/MM/dd"
                customInput={<DateCustomInput />}
                showPopperArrow={false}
              />
            </div>
            <ColorButton title="등록" color="slateblue" handler={async () => {
              let res = await HTTPManager.ModifyToursGuide({
                accessToken: userInfo.accessToken,
                id: selectedToursGuideInfo.id,
                tipDate: tipDate,
              })

              if (res !== undefined && res.data.result) {
                setShowAddTipDate(false);
                Props.refresh();
              }
            }} />
          </div>
        </>
      }
    </div>
  )
}