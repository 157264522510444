
import React, { useState, } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAnglesLeft } from "@fortawesome/free-solid-svg-icons";
import { faAnglesRight } from "@fortawesome/free-solid-svg-icons";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";

export default function PageCounter(Props) {
  const [startIndex, setStartIndex] = useState(0);

  function getTextColor(index) {
    if (Props.selectedIndex === index)
      return "white"
    else
      return "black"
  }

  function getBackgroundColor(index) {
    if (Props.selectedIndex === index)
      return "black"
    else
      return "white"
  }

  function makeCounter(count) {
    let arr = [];
    let maxLength = count >= 10 ? 10 : count;
    for (let i = 0; i < maxLength; i++) {
      arr.push(
        <span key={"pageCounter" + i} className="w-[45px] text-[15px] text-center cursor-pointer py-1 select-none"
          style={{
            color: getTextColor(startIndex + i),
            background: getBackgroundColor(startIndex + i),
          }}
          onClick={() => {
            Props.setSelectedIndex(startIndex + i);
          }}>
          {startIndex + i + 1}
        </span>
      )
    }

    return arr;
  }

  return (
    <div className="grid grid-flow-col auto-cols-max border-[1px] rounded divide-x-2">
      <span className="text-[15px] text-center cursor-pointer p-2 py-1"
        onClick={(e) => {
          if (startIndex - 10 >= 0) {
            setStartIndex(startIndex - 10);
            Props.setSelectedIndex(startIndex - 10);
          }
        }}>
        <FontAwesomeIcon icon={faAnglesLeft} style={{ color: "#aaa" }} />
      </span>
      {/* <span className="text-[15px] text-center cursor-pointer p-2 py-1">
        <FontAwesomeIcon icon={faAngleLeft} style={{ color: "#aaa" }} />
      </span> */}
      {
        makeCounter(Props.totalPageCount)
      }
      {/* <span className="text-[15px] text-center cursor-pointer p-2 py-1">
        <FontAwesomeIcon icon={faAngleRight} style={{ color: "#aaa" }} />
      </span> */}
      <span className="text-[15px] text-center cursor-pointer p-2 py-1"
        onClick={(e) => {
          if (Props.totalPageCount < startIndex + 10)
            return;

          setStartIndex(startIndex + 10);
          Props.setSelectedIndex(startIndex + 10);
        }}>
        <FontAwesomeIcon icon={faAnglesRight} style={{ color: "#aaa" }} />
      </span>
    </div>
  )
}